import { createContext,useEffect, useState } from 'react'
import Axios from 'axios'

export const SenderContext = createContext();

export const SenderProvider = ({children}) =>{

   const [users, setUsers] = useState(undefined)

   const [senderData, setsenderData] = useState(undefined)
   
   const [senderDataToShow, setsenderDataToShow] = useState(undefined)

   const [filteredSenderData, setFilteredSenderData] = useState(undefined)

   const [sender_filter_option, setsender_filter_option] = useState({
    country: [],
    mode: [],
    status: []
  })
  const [individualdata, setindividualdata] = useState(undefined)
  const UpdateNewData = (data)=>{
    setindividualdata(data)
    //  console.log(individualdata, "data-from-new-data")
   }
   const valPhoto = (user_id) => {
    const photo_item = []
        users?.data?.all_users?.filter(
            (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
            if(element.user_id === user_id){
                element.profile_photo.state = "verified"
                photo_item.push(element)
            }
            else{
                photo_item.push(element)
            }
        })

        users.data.all_users =photo_item
   }

   const rejPhoto = (user_id) => {
    const photo_item = []
        users?.data?.all_users?.filter(
            (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
            if(element.user_id === user_id){
                element.profile_photo.state = "not_valid"
                photo_item.push(element)
            }
            else{
                photo_item.push(element)
            }
        })

        users.data.all_users =photo_item
   }


   const valFormId = (user_id) => {
    const photo_item = []
        users?.data?.all_users?.filter(
            (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
            if(element.user_id === user_id){
                element.proof_of_identity.state = "verified"
                photo_item.push(element)
            }
            else{
                photo_item.push(element)
            }
        })

        users.data.all_users =photo_item
   }

   const rejFormId = (user_id) => {
    const photo_item = []
        users?.data?.all_users?.filter(
            (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
            if(element.user_id === user_id){
                element.proof_of_identity.state = "not_valid"
                photo_item.push(element)
            }
            else{
                photo_item.push(element)
            }
        })

        users.data.all_users =photo_item
   }

    return(
        <SenderContext.Provider value={{
               
                setsenderData,
               senderData,
               setsenderDataToShow,
               senderDataToShow,


               valPhoto,
               rejPhoto,
               valFormId,
               rejFormId,

               setsender_filter_option,
               sender_filter_option,
               setFilteredSenderData,
               filteredSenderData,

               setUsers,
               individualdata,
               UpdateNewData,
               users
            }}>
            {children}
        </SenderContext.Provider>
    )
}