/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import "./Corporate.css";
import PuffLoader from "react-spinners/PuffLoader";
import approvedicon from "../../assets/approvedicon.svg";
import { FormControl, Button, InputLabel } from "@material-ui/core";
import "jspdf-autotable";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";

import Navbar from "../../components/Navbar";
import "./UserDrilldown.css";
import Pagination from "rc-pagination";
import axios from "axios";
import DialogOutClick from "../../components/DialogOutClick";
import { ConnContext } from "../../context/ConnContext";
import InputField from "../../InputField";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import PhoneNumber from "../AdminUsers/PhoneNumber";
import { Capitalizer } from "../../utils/Capitalizer";
import { AiOutlineWarning } from "react-icons/ai";

import { UserContext } from "../../context/UserContext";
import moment from "moment";
import { Avatar, Grid } from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const SenderDrillDown = () => {
  const { setUsers, users } = useContext(UserContext);

  let { id } = useParams();
  const [showing, setshowing] = useState("Profile");

  const location = useLocation();
  const tabs = ["Profile", "Actions", "Transactions", "Feedbacks"];

  const endpoint = process.env.REACT_APP_API;
  const { visitingCountryCode } = useContext(ConnContext);
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const [submitting, setsubmitting] = useState(false);
  const endPoint = process.env.REACT_APP_API;
  // const endPoint2 = process.env.REACT_APP_API_PLATFORM;

  const [dataToShow, setdataToShow] = useState([]);
  const [permanentlyDelete, setpermanentlyDelete] = useState("");

  const [ShowPerma, setShowPerma] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [Showpop, setShowpop] = useState(false);
  const [showreset, setShowreset] = useState(false);
  const [Showpopemail, setShowpopemail] = useState(false);
  const [Showpopsms, setShowpopsms] = useState(false);
  const [Showpopsuspend, setShowpopsuspend] = useState(false);
  const [Showpopenable, setShowpopenable] = useState(false);
  const [Showpopresetpassword, setShowpopresetpassword] = useState(false);

  const [ios, setios] = useState("");
  const [iemi, setiemi] = useState("");
  const [iosversion, setiosversion] = useState("");
  const [emailsubject, setemailsubject] = useState("");
  const [emailtextarea, setemailtextarea] = useState("");
  const [emailreset, setemailreset] = useState("");
  const [smssubject, setsmssubject] = useState("");
  const [phone_num, setphone_num] = useState("");

  const [addingUser, setaddingUser] = useState(false);

  const table_header = [
    "Delivery Time Frame",
    "Delivery Address",
    "Delivery ID",
    "Item Description",
    "Provider Name",
  ];

  const Feedbackdetails = [
    {
      Thead: "",
    },
    {
      Thead: "1 month",
    },
    {
      Thead: "2 months",
    },

    {
      Thead: "3 months",
    },
  ];

  const Feedbackbodydetails = [
    {
      Positive: "Positive",
      firstmonth: "NA",
      secondmonth: "NA",
      thirdmonth: "NA",
    },
    {
      Positive: "Negative",
      firstmonth: "NA",
      secondmonth: "NA",
      thirdmonth: "NA",
    },
  ];

  const Buttoncard = ({
    className,
    onClick,
    disabled,
    color,
    text,
    loading,
  }) => {
    return (
      <div>
        {" "}
        <button
          onClick={onClick}
          className={className}
          disabled={disabled}
          color={color}
        >
          {loading ? (
            <PuffLoader
              color="white"
              loading={loading}
              speedMultiplier={1}
              size={24}
            />
          ) : (
            text
          )}
        </button>
      </div>
    );
  };

  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });

  const handlesendEmial = async (e) => {
    e.preventDefault();
    setsubmitting(true);

    await authAxios
      .post(`${endPoint}adminportal/v3/ng/send_email`, {
        email: [dataToShow[0].user_id],
        email_subject: emailsubject,
        message_body: emailtextarea,
      })
      .then((response) => {
        if (response) {
          setShowpopemail(!Showpopemail);
          setsubmitting(false);
          if (response.status === 200) {
            setShowpopemail(!Showpopemail);
            setemailsubject("");
            setemailtextarea("");

            Swal.fire({
              icon: "success",
              title: "Email Sent",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error?.response);
        setShowpopemail(!Showpopemail);
        setsubmitting(false);
        if (error) {
          setemailsubject("");
          setemailtextarea("");
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.msg}`,
            confirmButtonColor: "red",
          });
        }
      });
  };

  const handlesendSms = async (e) => {
    e.preventDefault();
    setsubmitting(true);

    await authAxios
      .post(`${endPoint}adminportal/v3/ng/send_sms`, {
        phone_number: dataToShow?.[0]?.phone_number?.phone_number,
        message_body: smssubject,
      })
      .then((response) => {
        if (response) {
          setShowpopsms(!Showpopsms);
          setsubmitting(false);
          if (response.status === 200) {
            setShowpopsms(!Showpopsms);
            setsmssubject("");

            Swal.fire({
              icon: "success",
              title: "Sms Sent",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 2000,
            });
          }
        }
      })
      .catch((error) => {
        setShowpopsms(!Showpopsms);
        setsubmitting(false);
        if (error) {
          setsmssubject("");
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "red",
          });
        }
      });
  };
  const handledevicereset = async (e) => {
    e.preventDefault();
    setsubmitting(true);

    const authAxios = Axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    });
    await authAxios
      .patch(`${endPoint}m-dillivry-admin-portal`, {
        user_id: dataToShow?.[0]?.user_id,
        // email: emailreset,
        // ios: ios,
        //  ios_version: iosversion,
        //  iemi: iemi
      })
      .then((response) => {
        if (response) {
          setShowreset(!showreset);
          setsubmitting(false);
          if (response.status === 200) {
            setShowreset(!showreset);
            setemailreset("");
            setios("");
            setiosversion("");
            setiemi("");

            Swal.fire({
              icon: "success",
              title: `${response?.data?.msg}`,
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 6000,
            });
          }
        }
      })
      .catch((error) => {
        // setShowpopemail(!Showpopemail);
        setShowreset(!showreset);
        setsubmitting(false);
        if (error) {
          setemailreset("");
          setios("");
          setiosversion("");
          setiemi("");
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${error.response.data.msg}`,
            confirmButtonColor: "red",
          });
        }
      });
  };

  const handleSuspendacct = async (e) => {
    setsubmitting(true);
    console.log("hello");

    await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "disable_user",
        email: dataToShow?.[0].user_id,
        country_code: visitingCountryCode,
      },
    })
      .then((res) => {
        setsubmitting(false);

        if (res.status === 200) {
          setShowpopsuspend(false);
          Swal.fire({
            icon: "success",
            title: "Account Suspended",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 6000,
          });
        }
      })
      .catch((error) => {
        setsubmitting(false);
        setShowpopsuspend(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };

  const handleDelete = async (e) => {
    setsubmitting(true);

    await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "delete_user",
        email: dataToShow?.[0]?.user_id,
        vcc: visitingCountryCode,
      },
    })
      .then((res) => {
        setsubmitting(false);

        if (res.status === 200) {
          setShowpop(false);
          Swal.fire({
            icon: "success",
            title: "User deleted successfully",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 2000,
          }).then(() => {
            refetch();
          });
        }
      })
      .catch((error) => {
        setsubmitting(false);
        setShowpop(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.error}`,
            confirmButtonColor: "grey",
          });
        }
      })
      .finally(() => setpermanentlyDelete(""));
  };

  const handleDeletePerma = async (e) => {
    setsubmitting(true);
    await Axios.delete(`${endPoint}adminportal/v3/ng/remove_user`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        user_id: dataToShow?.[0]?.user_id,
      },
    })
      .then((res) => {
        setsubmitting(false);

        if (res.status === 200) {
          setShowPerma(false);
          Swal.fire({
            icon: "success",
            title: "User deleted permanently successfully",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 2000,
          }).then(() => {
            history.goBack();
          });
        }
      })
      .catch((error) => {
        setsubmitting(false);
        setShowPerma(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.error}`,
            confirmButtonColor: "grey",
          });
        }
      })
      .finally(() => setpermanentlyDelete(""));
  };

  // Table Pagination
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  // Table Pagination
  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const getData = (current, pageSize) => {
    if (dataToShow === undefined && dataToShow[0]?.pending_deliveries) {
      return dataToShow[0]?.pending_deliveries?.slice(
        (current - 1) * pageSize,
        current * pageSize
      );
    } else {
      return dataToShow?.slice((current - 1) * pageSize, current * pageSize);
    }
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(
      dataToShow[0]?.pending_deliveries.length / value
    );
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <MdKeyboardArrowLeft />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <MdKeyboardArrowRight />
        </button>
      );
    }
    return originalElement;
  };

  const handleenableacct = async (e) => {
    setsubmitting(true);

    await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "enable_user",
        email: dataToShow?.[0].user_id,
        country_code: visitingCountryCode,
      },
    })
      .then((res) => {
        setsubmitting(false);

        if (res.status === 200) {
          setShowpopenable(false);
          Swal.fire({
            icon: "success",
            title: "Account Enabled",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 6000,
          });
        }
      })
      .catch((error) => {
        setsubmitting(false);
        setShowpopenable(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };

  const handleresetPassword = (e) => {
    setisloading(true);
    authAxios
      .patch(`adminportal/v3/ng/reset_password`, {
        email: dataToShow?.[0]?.user_id,
      })
      .then((response) => {
        setisloading(false);
        setShowpopresetpassword(false);
        Swal.fire({
          icon: "success",
          text: `A reset passcode has been sent to this user`,
          showConfirmButton: false,
          confirmButtonColor: "var(--main)",
          timer: 3000,
        });
      })
      .catch((error) => {
        setisloading(false);
        setShowpopresetpassword(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };

  useEffect(() => {
    setloading(true);

    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      } else {
        setloading(true);
        // Axios.get(`${endPoint}platform/v3/ng/user_details?user_id=${id}`, {
        Axios.get(`${endPoint}admin/v1/ng/user_details?user_id=${id}`, {
          headers: {
            Authorization: localStorage.getItem("o_kj_"),
          },
        }).then((response) => {
          if (response.status === 200) {
            console.log(response, "response for sender details");
            setdataToShow(response?.data?.data);
            setloading(false);
          }
        });
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);

  const refetch = () => {
    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      } else {
        setloading(true);
        // Axios.get(`${endPoint}platform/v3/ng/user_details?user_id=${id}`, {
        Axios.get(`${endPoint}admin/v1/ng/user_details?user_id=${id}`, {
          headers: {
            Authorization: localStorage.getItem("o_kj_"),
          },
        }).then((response) => {
          if (response.status === 200) {
            // setUsers(response);
            setdataToShow(response?.data?.data);
            setloading(false);
          }
        });
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  };

  const [isloading, setisloading] = useState(false);

  const cartHistory = dataToShow[0]?.cart_history?.deliveries;

  return (
    <div>
      {loading === true ? (
        <div>
          <Box sx={{ width: "100%", marginTop: "2px" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : (
        <div className="main-grand">
          <div className="drill-down-main">
            <Navbar
              dashboardtext="Back to dashboard"
              familyname={localStorage.getItem("fam")}
              givenname={localStorage.getItem("name")}
              page={location.state}
              role={dataToShow?.[0]?.role}
            />
          </div>

          <div className="drill-down-main">
            {dataToShow?.map((corp) => (
              <div key={corp?.account_id} className="drill-down-main-container">
                <Grid container spacing={4}>
                  <Grid item sm={3}>
                    <div className="cards_container">
                      <Avatar
                        sx={{ width: 65, height: 65 }}
                        alt={corp?.cop_logo}
                        src={`${corp?.cop_logo}`}
                      />
                      <div>
                        <div className="verify_name">
                          {Capitalizer(corp?.given_name)}
                        </div>
                        <div className="verify_flex">
                          <img src={approvedicon} alt="" />
                          <span>Verified</span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={9}>
                    <div className="move_tabs">
                      {tabs.map((x, i) => (
                        <span
                          key={i}
                          onClick={() => setshowing(x)}
                          className={
                            x === showing ? "tab_flex_active" : "tab_flex"
                          }
                        >
                          {x}
                        </span>
                      ))}
                    </div>
                    <div>
                      {showing === "Profile" ? (
                        <div className="move_profile_container">
                          <div className="content_flex">
                            <span className="bold_span">Email</span>
                            <span>{corp?.user_id}</span>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">Name</span>
                            <span>{corp?.given_name}</span>
                          </div>

                          <div className="content_flex">
                            <span className="bold_span">Phone number</span>
                            <span>{corp?.phone_number?.phone_number}</span>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">Wallet balance</span>
                            <span>{corp?.wallet_balance}</span>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">Date registered</span>
                            <span>
                              {moment(corp?.account_status.joined_date).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          </div>

                          <div className="content_flex">
                            <span className="bold_span">Status</span>
                            <span>
                              {/* {Capitalizer(corp?.account_status.state).replace(
                                /_/g,
                                " "
                              )} */}
                              {corp?.account_status.deleted_date
                                ? Capitalizer(
                                    corp.account_status.deleted_date.state
                                  ).replace(/_/g, " ")
                                : Capitalizer(
                                    corp?.account_status.state
                                  ).replace(/_/g, " ")}
                            </span>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">
                              Total delivery request
                            </span>
                            <span>
                              {
                                corp?.delivery_locations?.[0]?.delivery_id
                                  ?.length
                              }
                            </span>
                          </div>
                          <div className="content_flex _last">
                            <span className="bold_span">Completed request</span>
                            <span>Not coming yet</span>
                          </div>
                          <div className="content_flex _last">
                            <span className="bold_span">Positive feedback</span>
                            <span>Not coming yet</span>
                          </div>
                        </div>
                      ) : showing === "Actions" ? (
                        <div className="move_profile_container">
                          <div className="content_flex">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpopresetpassword(true)}
                            >
                              Reset password
                            </span>
                          </div>

                          <div className="content_flex">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpopemail(true)}
                            >
                              Email user
                            </span>
                          </div>
                          <div className="content_flex">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpopsms(true)}
                            >
                              SMS user
                            </span>
                          </div>
                          <div className="content_flex">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpopsuspend(true)}
                            >
                              Suspend account
                            </span>
                          </div>
                          <div className="content_flex">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpopenable(true)}
                            >
                              Restore/enable account
                            </span>
                          </div>
                          {corp?.account_status?.deleted_date?.state !==
                          "deleted" ? (
                            <div className="content_flex _last">
                              <span
                                className="cursor_span"
                                onClick={() => setShowpop(true)}
                              >
                                Delete account
                              </span>
                            </div>
                          ) : (
                            <div className="content_flex _last">
                              <span
                                className="cursor_span"
                                onClick={() => setShowPerma(true)}
                              >
                                Delete account permanently
                              </span>
                            </div>
                          )}
                        </div>
                      ) : showing === "Transactions" ? (
                        // <div className="move_profile_container move_profile_container_smaller">
                        //   <div className="content_flex _last">
                        //     <span>Recent Transactions</span>
                        //   </div>
                        //   <div className="content_flex">
                        //     <span>Delivery ID</span>
                        //     <span>Amount</span>
                        //   </div>
                        //   <div className="content_flex">
                        //     <span>NG637463764736</span>
                        //     <span className="blue_span"> &#8358; 20,000</span>
                        //   </div>
                        //   <div className="content_flex">
                        //     <span>NG637463764736</span>
                        //     <span className="blue_span"> &#8358; 20,000</span>
                        //   </div>
                        // </div>
                        <div className="table_container">
                          <div className="table-filter-info">
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              {/* <div className="search-container">
                                <AiOutlineSearch
                                  className="search-icon"
                                  size={20}
                                />
                                <input
                                  type="text"
                                  placeholder="Search"
                                  value={searchValue}
                                  onChange={handleSearch}
                                  className="search-input"
                                />
                              </div> */}
                              <span
                                style={{
                                  position: "relative",
                                  fontWeight: 400,
                                  fontSize: 15,
                                }}
                              >
                                Recent Transactions
                              </span>
                            </div>
                            <div className="paginated__container">
                              <div>
                                <div>
                                  <Pagination
                                    className="pagination-data"
                                    onChange={PaginationChange}
                                    total={
                                      dataToShow[0]?.pending_deliveries?.length
                                    }
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                    itemRender={PrevNextArrow}
                                    // itemRender={itemRender}
                                    onShowSizeChange={PerPageChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {!cartHistory ? (
                            <>
                              <h2
                                style={{
                                  position: "relative",
                                  fontWeight: 400,
                                  fontSize: 15,
                                  textAlign: "center",
                                  padding: "20px 0",
                                }}
                              >
                                No Recent Transactions
                              </h2>
                            </>
                          ) : (
                            <table id="thead-data">
                              {
                                <tr>
                                  {table_header?.map((item, index) => (
                                    <>
                                      <th>{item}</th>
                                    </>
                                  ))}
                                </tr>
                              }

                              <tbody>
                                {Object.values(cartHistory).map((x, i) => (
                                  <tr key={i} className="table__row">
                                    <td>
                                      {moment(x?.time_stamp).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )}
                                    </td>
                                    <td>{x?.delivery_address}</td>
                                    <td>
                                      <Link
                                        to={`/deliveries/${x?.delivery_id}`}
                                      >
                                        {x?.delivery_id}
                                      </Link>
                                    </td>
                                    <td>{x?.item_desc}</td>
                                    <td>{x?.provider_name}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                          {/* <table id="thead-data">
                            {
                              <tr>
                                {table_header?.map((item, index) => (
                                  <>
                                    <th>{item}</th>
                                  </>
                                ))}
                              </tr>
                            }

                            <tbody>
                                  {Object.values(cartHistory).map(
                                    (x, i) => (
                                      <tr key={i} className="table__row">
                                         <td>
                                         {moment(x?.time_stamp).format("YYYY-MM-DD HH:mm:ss")}

                                        </td>
                                        <td>
                                          {x?.delivery_address}
                                        </td>
                                        <td>
                                          <Link to={`/deliveries/${x?.delivery_id}`}>
                                          {x?.delivery_id}
                                          </Link>
                                         </td>
                                         <td>
                                          {x?.item_desc}
                                        </td>
                                        <td>
                                        {x?.provider_name}
                                        </td>
                                       
                                       
                                      </tr>
                                    )
                                  )}
                            </tbody>
                          </table> */}
                        </div>
                      ) : showing === "Feedbacks" ? (
                        <div className="feedback_container">
                          <div className="feedback_">
                            <Grid container className="feed_heading">
                              <Grid item xs={1.5}>
                                Rating
                              </Grid>
                              <Grid item xs={2}>
                                Item
                              </Grid>
                              <Grid item xs={2.5}>
                                Left by
                              </Grid>
                              <Grid item xs={5}>
                                Comment
                              </Grid>
                              <Grid item xs={1}>
                                Date
                              </Grid>
                            </Grid>
                          </div>
                          <div className="feedback_">
                            <Grid container className="feed_body">
                              <Grid item xs={1.5}>
                                Rating
                              </Grid>
                              <Grid item xs={2}>
                                Item
                              </Grid>
                              <Grid item xs={2.5}>
                                Left by
                              </Grid>
                              <Grid item xs={5}>
                                Comment
                              </Grid>
                              <Grid item xs={1}>
                                Date
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      ) : (
                        <>others</>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
        </div>
      )}

      <DialogOutClick open={Showpop} handleClose={() => setShowpop(false)}>
        <div style={{ padding: "30px 0 20px ", width: "400px" }}>
          <div style={{ padding: "0 30px" }}>
            <div className="caution_card">
              <AiOutlineWarning color="red" size={24} />
              <div>Deleting a user, removes the user from Dillivry </div>
            </div>

            <div className="cofirm-text">
              To confirm deletion, type delete in the field
            </div>
            <input
              type={"text"}
              value={permanentlyDelete}
              onChange={(e) => setpermanentlyDelete(e.target.value)}
              placeholder={"delete"}
              className="perm-input"
            />
          </div>
          <div className="btn-parent">
            <Buttoncard
              onClick={() => {
                setShowpop(false);
                setpermanentlyDelete("");
              }}
              text="Cancel"
              className="canceller"
            />
            {permanentlyDelete === "delete" ? (
              <Buttoncard
                onClick={handleDelete}
                text="Delete"
                loading={submitting}
                className="deleter"
              />
            ) : (
              <Buttoncard
                text="Delete"
                loading={submitting}
                className="deleter_disable"
              />
            )}
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick open={ShowPerma} handleClose={() => setShowPerma(false)}>
        <div style={{ padding: "30px 0 20px ", width: "400px" }}>
          <div style={{ padding: "0 30px" }}>
            <div className="caution_card">
              <AiOutlineWarning color="red" size={24} />
              <div>
                Deleting a user, removes the user permanently from Dillivry{" "}
              </div>
            </div>

            <div className="cofirm-text">
              To confirm deletion, type Permanently delete in the field
            </div>
            <input
              type={"text"}
              value={permanentlyDelete}
              onChange={(e) => setpermanentlyDelete(e.target.value)}
              placeholder={"Permanently delete"}
              className="perm-input"
            />
          </div>
          <div className="btn-parent">
            <Buttoncard
              onClick={() => {
                setShowPerma(false);
                setpermanentlyDelete("");
              }}
              text="Cancel"
              className="canceller"
            />
            {permanentlyDelete === "Permanently delete" ? (
              <Buttoncard
                onClick={handleDeletePerma}
                text="Delete"
                loading={submitting}
                className="deleter"
              />
            ) : (
              <Buttoncard
                text="Delete"
                loading={submitting}
                className="deleter_disable"
              />
            )}
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopemail}
        handleClose={() => setShowpopemail(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p style={{ fontSize: "12px", color: "black" }}>
            Complete the form below to send email to the selected users{" "}
          </p>

          <form
            onSubmit={handlesendEmial}
            style={{ marginTop: "10px", width: "100%" }}
          >
            <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "10px", width: "100%" }}
            >
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />{" "}
                Subject
              </InputLabel>
              <InputField
                required
                name="text"
                id="text"
                type="text"
                value={emailsubject}
                labelWidth={80}
                onChange={(e) => {
                  setemailsubject(e.target.value);
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl>
            {/* {
  console.log(item_to_modify,"item_to_modify")
} */}
            <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "20px", width: "100%" }}
            >
              <textarea
                style={{
                  borderRadius: "10px",
                  height: "150px",
                  padding: "12px 20px",
                  outline: "none",
                  border: "1px solid #b2c2c2",
                }}
                placeholder="Message body"
                required
                value={emailtextarea}
                onChange={(e) => {
                  setemailtextarea(e.target.value);
                }}
              ></textarea>
            </FormControl>

            <div className="deletBtn">
              <Button
                size="large"
                variant="contained"
                type="submit"
                className="btnnn"
              >
                <span className="btnlabel">
                  {submitting ? "loading..." : "Email"}{" "}
                </span>
              </Button>
            </div>
          </form>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopresetpassword}
        handleClose={() => setShowpopresetpassword(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Reset user's password</p>
          <div className="reset_pass_con">
            <button
              className="reset_pass_btn1"
              onClick={() => setShowpopresetpassword(false)}
            >
              Cancel
            </button>
            <button className="reset_pass_btn2" onClick={handleresetPassword}>
              {isloading ? (
                <PuffLoader
                  color="white"
                  loading={isloading}
                  speedMultiplier={1}
                  size={34}
                />
              ) : (
                <span> Reset</span>
              )}
            </button>
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopsms}
        handleClose={() => setShowpopsms(false)}
      >
        <div style={{ padding: "30px", width: "300px", marginBottom: "20px" }}>
          <p>Complete the form below</p>

          <form className="cFrorm" onSubmit={handlesendSms}>
            <div>
              <div
                className="phoneTopic"
                style={{ marginTop: "15px", fontSize: "13px" }}
              >
                Phone Number
              </div>
              <PhoneNumber
                value={dataToShow?.[0]?.phone_number?.phone_number}
                disabled
              />
            </div>

            <FormControl variant="outlined">
              <textarea
                style={{
                  width: "50",
                  height: "150px",
                  padding: "15px",
                  borderRadius: "10px",
                }}
                placeholder="SMS body"
                required
                value={smssubject}
                onChange={(e) => {
                  setsmssubject(e.target.value);
                }}
              ></textarea>
            </FormControl>

            {smssubject === "" ? (
              <Button
                size="large"
                variant="contained"
                type="submit"
                disabled
                style={{
                  backgroundColor: "rgb(151, 199, 168)",
                  width: "100%",
                  marginTop: "10px",
                  color: "white",
                  borderRadius: "10px",
                  padding: "8px",
                  textTransform: "none",
                }}
              >
                <span className="btnlabel">
                  {submitting ? "loading" : "Send sms"}{" "}
                </span>
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "var(--main)",
                  width: "100%",
                  marginTop: "10px",
                  color: "white",
                  borderRadius: "10px",
                  padding: "8px",
                  textTransform: "none",
                }}
              >
                {addingUser ? (
                  <PuffLoader
                    color="white"
                    loading={addingUser}
                    speedMultiplier={1}
                    size={24}
                  />
                ) : (
                  <span className="btnlabel">
                    {submitting ? "loading" : "Send sms"}
                  </span>
                )}
              </Button>
            )}
          </form>
        </div>
      </DialogOutClick>

      <DialogOutClick open={showreset} handleClose={() => setShowreset(false)}>
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to reset this device?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handledevicereset}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowreset(false)}
              text="No"
              className="success"
            />
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopsuspend}
        handleClose={() => setShowpopsuspend(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to suspend this user?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleSuspendacct}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpopsuspend(false)}
              text="No"
              className="success"
            />
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopenable}
        handleClose={() => setShowpopenable(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to Enable this user?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleenableacct}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpopenable(false)}
              text="No"
              className="success"
            />
          </div>
        </div>
      </DialogOutClick>
    </div>
  );
};

export default SenderDrillDown;
