import React from 'react';
import { OutlinedInput, InputLabel} from '@material-ui/core';
import './InputField.css'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#50B14A',
    },
    secondary: {
      main: '#11cb5f',
    },
  },
});


export default function InputField({endAdornment,size,autoFocus, disabled, required,defaultValue, inputProps, type,value,lbl, onChange,placeholder,id, labelWidth, error,onFocus,onBlur, min, max}) {
  
  return (
    <ThemeProvider theme={theme}>
    <div className='InputField'> 
        <InputLabel htmlFor={id}>{lbl}</InputLabel>
        {/* <InputBase  */}
        <OutlinedInput
          disabled={disabled}
          required={required}
            autoComplete='off'
            error={error}
            placeholder={placeholder}
            type={type}
            id={id}
            labelWidth={labelWidth}
            fullWidth
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            variant="filled"
            min={min}
            max={max}
            // helperText={helperText}
            // autoFocus={autoFocus}
            onFocus={onFocus}
            onBlur={onBlur}
            inputProps={inputProps}
            endAdornment={endAdornment}
            size="large"
            />
    </div>
    </ThemeProvider>
  );
}