/* eslint-disable import/no-anonymous-default-export */
export default {
    GET_DEFAULT_DATA: 'GET_DEFAULT_DATA',
    Val_Bank_Details: 'Val_Bank_Details',
    Val_Profile_Photo: 'Val_Profile_Photo',
    Val_Insurance: "Val_Insurance",
    Val_Form_Id: "Val_Form_Id",
    General_Val: "General_Val",

    Rej_Bank_Details: 'Rej_Bank_Details',
    Rej_Profile_Photo: 'Rej_Profile_Photo',
    Rej_Insurance: "Rej_Insurance",
    Rej_Form_Id: "Rej_Form_Id",
   
    // General_Val: "General_Val"
  };