import React, { useState, useEffect, useContext, useRef } from "react";
import {
  DataGrid,
} from "@mui/x-data-grid";

import Avatar from "@mui/material/Avatar";
import { DeliveryContext } from "../context/Deliveries";
import { CircularProgress, ClickAwayListener, Grid, Pagination, TablePagination } from '@mui/material'

import "jspdf-autotable";
import { useHistory } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import moment from "moment";
import { ProviderContext } from "../context/ProviderContext";
import { v4 as uuidv4 } from 'uuid';

function TableComponent(rows) {

  const columns = [
    {
      field: "image_urls",
      headerName: "Image",

      renderCell: (params) => (
        <Avatar alt={params.row.item_desc} src={`${params.row.image_urls[0]}`} />
      ),
    },
    { field: "delivery_id", headerName: "Delivery ID", width: 130 },
    { field: "item_desc", headerName: "Item", width: 170 },

    {
      field: "pickup_details",
      headerName: "Pick up",

      minWidth: 220,
      renderCell: (params) => params.row.from || "",
      valueGetter: (params) => params.row?.from
    },
    {
      field: "delivery_details",
      headerName: "Destination",

      width: 220,
      renderCell: (params) => params.row.to || "",
      valueGetter: (params) => params.row?.to
    },
    {
      field: "mode_of_movement",
      headerName: "Mode",

      renderCell: (params) =>
        params.row.mode_of_movement
          ? params.row.mode_of_movement
          : params.row.mode_of_delivery || "",
      valueGetter: (params) => params.row?.mode_of_movement
        ? params.row.mode_of_movement
        : params.row.mode_of_delivery || "",
    },
    {
      field: "delivery_time_frame",
      headerName: "Timeframe",

      width: 100,
      renderCell: (params) =>
        params.row?.delivery_time_frame || "",
    },
    { field: "distance", headerName: "Distance" },
    {
      field: "date_listed",
      headerName: "Listed",
      width: 170,
      renderCell: (params) =>
        params.row?.date_listed || "",
      valueGetter: (params) => params.row?.delivery_details?.delivery_time_frame || "",
      //moment(params.row?.date_listed).format("DD-MM-YYYY , h:mm:ss a") || "",
    },
    {
      field: "quote",
      headerName: "Quotes",

      renderCell: ({ row }) => row?.quote || "",
      valueGetter: (params) => params.row?.quote || "",
    },
    {
      field: "quotes",
      headerName: "Best Quote",

      renderCell: (params) =>
        params.row?.best_quote || "",
      valueGetter: (params) => params.row?.best_quote || "",
    },
  ];

  const history = useHistory();


  // const joiner = (data) => {
  //   const newarrrr = data.split("-");
  //   const merge = newarrrr[0].concat(newarrrr[1], newarrrr[2]);
  //   const newarrr = merge.split(" ");
  //   const merged = newarrr[0].concat(newarrr[1]);
  //   const newarrrf = merged.split(":");
  //   const mergedd = newarrrf[0].concat(newarrrf[1], newarrrf[2]);
  //   const newarrrff = mergedd.split("+");
  //   const mergeddd = newarrrff[0].concat(newarrrff[1]);
  //   const newarrrfff = mergeddd.split(".");
  //   const mergedddd = newarrrfff[0].concat(newarrrfff[1]);
  //   const newarrrffff = mergedddd.split("T");
  //   const mergeddddd = newarrrffff[0].concat(newarrrffff[1]);
  //   return mergeddddd;
  // };
  const { opend_last_evaluated_key, opend_setlast_evaluated_key } = useContext(ProviderContext);

  const { openddata, setopenddata } =
    useContext(DeliveryContext);

  const [paginateActivePage, setPaginateActivePage] = useState(1);
  const [loading, setloading] = useState(false);

  const handleGetRowId = (e) => {
    return e.delivery_id;
  };
  const [page1, setPage1] = useState(0);
  const [pageSize, setPageSize] = useState(100)
  const [searchValue, setSearchValue] = useState("");
  const [searchstate, setSearchstate] = useState(false)
  const [debouncedValue, setDebouncedValue] = useState('')


  const [rowsPerPage1, setRowsPerPage1] = useState(20);
  const [lastpage, setLastPage] = useState(false)
  const [newopendata, setNewOpendata] = useState()

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
    // console.log(newPage, 'newpageeee')

  };

  const handleChangeRowsPerPage1 = (event) => {
    // console.log(event.target.value, 'event.target.value')
    setRowsPerPage1(parseInt(event.target.value));
    setPage1(0);
    setPageSize(parseInt(event.target.value))
    // console.log(event.target.value, 'event.target.value')
  };


  const HandleMoreData = () => {
    // console.log(last_evaluated_key, 'last_evaluated_key---new')
    console.log(opend_last_evaluated_key, 'last_evaluated_key')
    // console.log(dataToShow, 'datatoshowww')
    setloading(true)
    if (opend_last_evaluated_key !== " ") {
      // console.log(dataToShow, 'dataToshow')
      // setDataLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("o_kj_"));
      myHeaders.append("Content-Type", "application/json");


      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };


      // fetch(`${process.env.REACT_APP_API_OPEN}${'ng'}/deliveries?last_evaluated_key=${opend_last_evaluated_key}`, requestOptions)
      // `${process.env.REACT_APP_API}opendelivery/v3/${countryCode}/deliveries`)
      fetch(`${process.env.REACT_APP_API}opendelivery/v3/${'ng'}/deliveries?last_evaluated_key=${opend_last_evaluated_key}`, requestOptions)
        .then(response => response.json())
        .then((result) => {

          console.log(result.data[0]?.data, 'resulttttt')
          // console.log(result?.data[0]?.data)
          // console.log(result?.data[0]?.last_evaluated_key)

          // console.log(result.Last_evaluated_key, 'result.Last_evaluated_key')

          if (result?.Last_evaluated_key !== "") {
            setloading(false)
            // console.log(result, 'resultttt')
            opend_setlast_evaluated_key(result?.data[0]?.last_evaluated_key)

            // const newData = (
            //   [...result.fulfillers.active_accounts.filter(
            //     (x) => x.role === "dillivry-corp-sys-admin"
            //   ),
            //   ...result.fulfillers.account_pending_validation.filter(
            //     (x) => x.role === "dillivry-corp-sys-admin"
            //   )]
            // )

            const newData = (result?.data[0]?.data)

            // var uniqueData = newData.filter(function (item, index, self) {
            //     return self.map(function (i) { return i.user_id; }).indexOf(item.user_id) === index;
            // });
            var uniqueData = [...newopendata, ...newData]

            console.log(uniqueData, 'uniquedataaa')
            // setopenddata(uniqueData);
            setNewOpendata(uniqueData)

            // setdataToShow([...dataToShow, ...newData])

            // setopenddata(
            //   uniqueData
            // )

            // setopenddata(
            //   uniqueData
            // )

            setloading(false)
            // setFilteredCorpProvider(uniqueData);
          }
        })
        .catch(error => console.log('error', error));

    } else {
      console.log('no more data')
    }



  }




  const totalPages = Math.ceil(newopendata?.length / rowsPerPage1);

  useEffect(() => {
    if (page1 >= totalPages) {
      setPage1(0)
    }
    else if (page1 === totalPages - 1) {
      setLastPage(true)
    } else {
      setLastPage(false)
    }
  }, [page1, totalPages])


  useEffect(() => {
    setNewOpendata(openddata)
  }, [openddata]);



  const TablePagination1 = () => {


    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}  >

        <div>
          <TablePagination
            component="div"
            count={newopendata?.length}
            page={page1}
            rowsPerPage={rowsPerPage1}
            onPageChange={handleChangePage1}
            onRowsPerPageChange={handleChangeRowsPerPage1}
            rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}

          />


        </div>
        <div style={{
          marginLeft: '20px'
        }}  >

          {lastpage && opend_last_evaluated_key !== " " && <button onClick={HandleMoreData}
          // style={{

          //     backgroundColor: lastpage ? 'green' : 'white',
          //     color: lastpage ? 'white' : 'black',

          // }}
          >More data</button>}

        </div>
      </div>
    );
  }



  return (
    <div className="main-grand">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "none",
            position: "fixed",
            top: "0",
            paddingTop: "10px",
            zIndex: "10000",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : null}

      <div style={{ height: "80vh", width: "100%" }}>
        <DataGrid
          density="comfortable"
          sx={{
            p: "30px",
            background: "#fff",
            "& .MuiDataGrid-cell": {
              whiteSpace: "break-spaces !important",
            },
          }}
          rows={
            newopendata !== undefined && newopendata?.length !== 0
              ?
              newopendata
              : []
          }
          columns={columns}

          autoHeight={true}
          onCellClick={(params, event, details) => {
            history.push({
              pathname: `/open/${params.row.delivery_id}`,
              state: paginateActivePage,
            });
          }}

          rowsPerPageOptions={[5, 10, 20]}
          key={(params) => params.row.delivery_id}






          getRowId={() => uuidv4()}
          getRowClassName={(params) => `${localStorage.getItem(`id_clicked_corp`) === params.row.account_name ? 'blackbg' : 'whitebg'}    `}

          page={page1}
          rowsPerPage={rowsPerPage1}
          // onPageChange={(newPage) => setPage1(newPage)}


          onPageSizeChange={handleChangeRowsPerPage1}
          // rowsPerPageOptions={[5, 10, 20, 50, 100]}
          pageSize={pageSize}

          // pagination




          components={{
            Pagination: TablePagination1,
          }}





        />
      </div>
    </div>
  );
}

export default TableComponent;
