import React, { useState, useEffect, useContext, useRef } from "react";
import "jspdf-autotable";
import { DataContext } from "../../context/DataContext/DataProvider";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useReactToPrint } from "react-to-print";
import { DeliveryContext } from "../../context/Deliveries";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import { BiExport } from "react-icons/bi";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { AiOutlineClear } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { GridCellParams } from "@mui/x-data-grid";
import { join } from "lodash";
import TableWithPagination from "../../components/DefaultTableFolder/TablePaginated";
import DialogPop from "../AdminUsers/DialogPop";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { ConnContext } from "../../context/ConnContext";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import InputField from "../../InputField";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaGripHorizontal } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import PuffLoader from "react-spinners/PuffLoader";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import { CSVLink, CSVDownload } from "react-csv";
import { CiExport } from "react-icons/ci";
//   {
//     field: "image_urls",
//     headerName: "Image",

//     renderCell: (params) => (
//       <Avatar
//         alt={params?.row?.item_desc}
//         src={`${params?.row?.image_urls[0]}`}
//       />
//     ),
//   },
//   { field: "delivery_id", headerName: "Delivery ID", width: 130 },

//   { field: "item_desc", headerName: "Item", width: 170 },

//   {
//     field: "pickup_details",
//     headerName: "Pick up",
//     minWidth: 220,
//     renderCell: (params) => params?.row?.pickup_details?.pickup_address || "",
//     valueGetter: (params) => params.row?.pickup_details?.pickup_address,
//   },
//   {
//     field: "delivery_details",
//     headerName: "Destination",

//     width: 220,
//     renderCell: (params) =>
//       params?.row?.delivery_details?.delivery_address || "",
//     valueGetter: (params) => params.row?.delivery_details?.delivery_address,
//   },
//   {
//     field: "mode_of_movement",
//     headerName: "Mode",

//     renderCell: (params) =>
//       params.row.mode_of_movement
//         ? params.row.mode_of_movement
//         : params.row.mode_of_delivery || "",
//     valueGetter: (params) =>
//       params.row?.mode_of_movement
//         ? params.row.mode_of_movement
//         : params.row.mode_of_delivery || "",
//   },
//   {
//     field: "delivery_time_frame",
//     headerName: "Timeframe",

//     width: 100,
//     renderCell: (params) =>
//       params.row.delivery_details?.delivery_time_frame || "",
//     valueGetter: (params) =>
//       params.row?.delivery_details?.delivery_time_frame || "",
//   },
//   { field: "distance", headerName: "Distance" },
//   {
//     field: "date_listed",
//     headerName: "Listed",
//     width: 170,
//     renderCell: (params) =>
//       moment(params.row?.date_listed).format("DD-MM-YYYY , h:mm:ss a") || "",
//   },
//   {
//     field: "quote",
//     headerName: "Quotes",

//     renderCell: ({ row }) => Object.keys(row?.quote).length || "",
//     valueGetter: (params) => params.row?.quote || "",
//   },
//   {
//     field: "quotes",
//     headerName: "Best Quote",

//     renderCell: ({ row }) =>
//       Object.values(row?.quote)
//         .map((x) => x?.quote_amount)
//         ?.sort()[0] || "",
//     valueGetter: (params) => params.row?.best_quote || "",
//   },
// ];

export default function Quoted() {
  const [page, setpage] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [deleteuser, setdeleteUser] = useState();
  const [showdelete, setshowdelete] = useState(false);
  const [actions, setactions] = useState([]);
  const [showpopup, setShowpopup] = useState(false);
  const { visitingCountryCode } = useContext(ConnContext);
  const [provider, setprovider] = useState("");
  const [amount, setamount] = useState("");
  const [required, setrequired] = useState(false);

  const [perpage, setperpage] = useState(15);
  const [item_category, setitem_category] = useState("");
  const [assigning, setassigning] = useState(false);
  const [updating, setupdating] = useState(false);
  const [updatepayment, setupdatepayment] = useState(false);
  const [itemdescUpdate, setitemdescUpdate] = useState("");
  const [isloading, setisloading] = useState(false);
  const [showupdate, setShowupdate] = useState(false);
  const [exportData, setExportData] = useState()

  const {
    setallDeliveries,
    quotedDeliveries,
    setquotedDeliveries,
    allDeliveries,
    updatedeliveries,
    deliveriesnew,
    UpdateNewData,
    individualdata,
  } = useContext(DeliveryContext);

  const [exceldata, setExceldata] = useState([]);
  const [newdata, setnewdata] = useState([]);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: `100%`,
    },
  }));

  const classes = useStyles();

  const [Refetcheddata, setRefetcheddata] = useState(false);

  const DefaultFetch = (val) => {
    // console.log(`do what you want with me`, val)
    setRefetcheddata(true);
    setloading(true);
    setallDeliveries([undefined]);
  };

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input?.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  const joiner = (data) => {
    const newarrrr = data.split("-");
    const merge = newarrrr[0].concat(newarrrr[1], newarrrr[2]);
    const newarrr = merge.split(" ");
    const merged = newarrr[0].concat(newarrr[1]);
    const newarrrf = merged.split(":");
    const mergedd = newarrrf[0].concat(newarrrf[1], newarrrf[2]);
    const newarrrff = mergedd.split("+");
    const mergeddd = newarrrff[0].concat(newarrrff[1]);
    const newarrrfff = mergeddd.split(".");
    const mergedddd = newarrrfff[0].concat(newarrrfff[1]);
    const newarrrffff = mergedddd.split("T");
    const mergeddddd = newarrrffff[0].concat(newarrrffff[1]);
    return mergeddddd;
  };

  const [status, setStatus] = useState("");
  const [show, setshow] = useState(false);
  const [search, setsearch] = useState("");
  const [dataclciked, setdataclciked] = useState("");
  const [deliveriesData, setDeliveriesData] = useState([]);

  const [dataToShow, setdataToShow] = useState([]);
  const [dataToShowduplicate, setdataToShowduplicate] = useState([]);
  const [allprovider, setallprovider] = useState([]);

  const [amountpaid, setamountpaid] = useState("");
  const [deliveryId, setdeliveryId] = useState("");
  const [deliveryIdwithdraw, setdeliveryIdwithdraw] = useState("");
  const [fulfilername, setfulfilename] = useState("");
  const [fulfilernamewithdraw, setfulfilenamewithdraw] = useState("");
  const [selectvalue, setSelectvalue] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [typeclicked, setTypedClicked] = useState("");

  const endPoint = process.env.REACT_APP_API;
  // const endPoint2 = process.env.REACT_APP_API_PLATFORM;

  const authAxios = Axios.create({
    baseURL: `${endPoint}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });

  const [item_to_modify, setitem_to_modify] = useState([]);

  const options = allprovider?.map((str, index) => ({ value: str, name: str }));

  const actionClick = (val, row) => {
    setitem_to_modify(row);
    if (val === "Update") {
      // console.log(row, val);
      setShowupdate(true);
      // console.log(row, 'rooooo')
      setSelectedItem(row);
      // setTypedClicked('re_assign')
    } else if (val === "Assign") {
      console.log("Assignnnn");
      setShowpopup(true);
      console.log(row, "rooooo");
      setSelectedItem(row);
      setTypedClicked("assign_agent");
    } else if (val === "Re-Assign") {
      setShowpopup(true);
      console.log(row, "rooooo");
      setSelectedItem(row);
      setTypedClicked("re_assign");
    } else if (val === "Restore") {
    } else if (val === "Delete") {
    }
  };

  const actionselectedClick = (val) => {
    // console.log(val, 'valalala')
    // setShowpop(true)
    // console.log(val?.user_id)
    // val && console.log(val?.map(x => x[0]))
    if (val?.user_id) {
      setdeleteUser(val);
      setshowdelete(true);
      // setShowpopemail(false)
    }
  };

  const Delivery_props = [
    "Image",
    "Delivery ID",
    "Item",
    "Pickup",
    "Destination",
    "Mode",
    "Distance",
    "Listed",
    "Quotes",
    // "Best Quote",
  ];

  const NewData = [
    "image_urls",
    "delivery_id",
    "item_desc",
    "pickup_address",
    "delivery_address",
    "mode_of_delivery",
    "distance",
    "date_listed",
    "quote",
    // "best_quote"
  ];

  // const searchProperty = [
  //   'Delivery ID',
  //   'Mode',
  //   'Pick up',
  //   'Destination',
  //   'Item',
  //   'Distance'
  // ]

  const searchProperty = [
    {
      "name" : "Delivery ID",
      "type" : "delivery_id"
    },
    {
      "name" : "Mode",
      "type" : "mode_of_delivery"
    },
    {
      "name" : "Pick up",
      "type" : "pickup_address"
    },
    {
      "name" : "Destination",
      "type" : "delivery_address"
    },
    {
      "name" : "Item",
      "type" : "item_desc"
    },
    {
      "name" : "Distance",
      "type" : "distance"
    },
  ]


  const refetchDeliveries = async () => {
    setloading(true);

    await Axios?.get(`${endPoint}admin/v1/ng/deliveries`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response, 'resonseee ');
          setallDeliveries(response);
          updatePage(response);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setloading(false);
      });
  };

  // Object.keys(myArray).length

  const Drop_down = () => (
    <div
      style={{
        zIndex: "100",
        position: "absolute",
        top: "40px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        boxShadow: "1px 1px 44px rgba(96, 97, 96, 0.3)",
      }}
    >
      <div
        className="each"
        onClick={() => {
          filterByStatus("all");
          setshow(!show);
        }}
      >
        <span style={{ marginLeft: "10px" }}>All</span>
      </div>
      <div
        className="each"
        onClick={() => {
          filterByStatus("awaiting_quote");
          setshow(!show);
        }}
      >
        <span style={{ marginLeft: "10px" }}>Awaiting quote</span>
      </div>
      <div
        className="each"
        onClick={() => {
          filterByStatus("awaiting_acceptance");
          setshow(!show);
        }}
      >
        <span style={{ marginLeft: "10px" }}>Awaiting quote acceptance</span>
      </div>
      <div
        className="each"
        onClick={() => {
          filterByStatus("awaiting_agent_assignment");
          setshow(!show);
        }}
      >
        <span style={{ marginLeft: "10px" }}>Awaiting agent assignment</span>
      </div>
      <div
        className="each"
        onClick={() => {
          filterByStatus("awaiting_pickup");
          setshow(!show);
        }}
      >
        <span style={{ marginLeft: "10px" }}>Awaiting pickup</span>
      </div>
      <div
        className="each"
        onClick={() => {
          filterByStatus("picked_up");
          setshow(!show);
        }}
      >
        <span style={{ marginLeft: "10px" }}>Picked up</span>
      </div>
      <div
        className="each"
        onClick={() => {
          filterByStatus("out_for_delivery");
          setshow(!show);
        }}
      >
        <span style={{ marginLeft: "10px" }}>Out for delivery</span>
      </div>
      <div
        className="each"
        onClick={() => {
          filterByStatus("missed_delivery");
          setshow(!show);
        }}
      >
        <span style={{ marginLeft: "10px" }}>Missed delivery</span>
      </div>
      <div
        className="each"
        onClick={() => {
          filterByStatus("delivered");
          setshow(!show);
        }}
      >
        <span style={{ marginLeft: "10px" }}>Completed delivery</span>
      </div>

      <div
        className="each"
        onClick={() => {
          filterByStatus("deleted");
          setshow(!show);
        }}
      >
        <span style={{ marginLeft: "10px" }}>Deleted delivery</span>
      </div>
    </div>
  );

  const [paginateActivePage, setPaginateActivePage] = useState(1);
  const [statusClicked, setStatusClicked] = useState("Quoted Deliveries");
  const [loading, setloading] = useState(false);
  // const endPoint = process.env.REACT_APP_API;

  // FILTER
  const filterByStatus = (status) => {
    if (status === "all") {
      const filterQuoted = individualdata.filter((quoted) => {
        return quoted?.delivery_type === "bid";
      });
      setdataToShow([...filterQuoted]);
      setdataToShowduplicate([...filterQuoted]);

      setPaginateActivePage(1);
      setStatusClicked("all");
    } else if (status === "awaiting_acceptance") {
      setStatusClicked("Awaiting Acceptance");
      setStatus(status);

      const filterQuoted = individualdata.filter((quoted) => {
        return (
          quoted?.delivery_type === "bid" &&
          quoted?.delivery_status === "awaiting_quote" &&
          Object.getOwnPropertyNames(quoted?.quote)?.length !== 0
        );
      });

      setdataToShow([...filterQuoted]);
      setdataToShowduplicate([...filterQuoted]);

      setPaginateActivePage(1);
    } else {
      setStatus(status);
      setStatusClicked(
        status?.charAt(0)?.toUpperCase()?.replace(/_/g, "") +
          status?.slice(1)?.replace(/_/g, " ")
      );
      const filterQuoted = individualdata.filter((quoted) => {
        return (
          quoted?.delivery_type === "bid" && quoted?.delivery_status === status
        );
      });
      setdataToShow([...filterQuoted]);
      setdataToShowduplicate([...filterQuoted]);

      setPaginateActivePage(1);
    }
  };

  const updatePage = (datum) => {
    // console.log(datum.data, 'datum.....');
    // const filterQuoted = datum.data.filter((quoted) => {
    //   return quoted?.delivery_type === "bid";
    // });

    // const filterQuoted = datum?.data?.data[0]?.data

    setallDeliveries(datum?.data?.data[0]?.data);

    setquotedDeliveries(datum?.data?.data[0]?.data);

    // filterQuoted.sort(function (a, b) {
    //   return joiner(a?.date_listed) - joiner(b?.date_listed);
    // });
    // const reversed = reverseArr(filterQuoted);

    //update local data
    // setdataToShow([...reversed]);
    // setdataToShowduplicate([...reversed]);

    //update context data to keep only quoted deliveires
    // setquotedDeliveries([...reversed]);

    // setnewdata([...reversed]);

    //update context data
    // UpdateNewData([...reversed]);

    // setExceldata([...reversed]);

    // setPaginateActivePage(location.state);
  };

  const Allfilter = (datum) => {
    if (location.state === undefined) {
      // const filterQuoted = datum?.data?.data[0]?.data.filter((quoted) => {
      //   return quoted?.delivery_type === "bid";
      // });
      const filterQuoted = datum?.data?.data[0]?.data;
      filterQuoted.sort(function (a, b) {
        return joiner(a?.date_listed) - joiner(b?.date_listed);
      });
      const reversed = reverseArr(filterQuoted);

      setnewdata([...reversed]);

      setExceldata([...reversed]);
    } else {
      const filterQuoted = datum?.data?.data[0]?.data;
      filterQuoted.sort(function (a, b) {
        return joiner(a?.date_listed) - joiner(b?.date_listed);
      });
      const reversed = reverseArr(filterQuoted);

      setnewdata([...reversed]);

      setExceldata([...reversed]);

      setPaginateActivePage(location.state);
    }
  };

  // console.log(exceldata, "exceldata");

  const Datatouse = exceldata?.map((x, i) => {
    return [
      [...x.delivery_id].join(""),
      [...x?.item_desc].join(""),
      [
        x?.pickup_details ? x?.pickup_details.pickup_address : x.pickup_address,
      ].join(""),
      [
        x?.delivery_details
          ? x?.delivery_details.delivery_address
          : x.delivery_address,
      ].join(""),
      [x?.mode_of_movement ? x?.mode_of_movement : x?.mode_of_delivery].join(
        ""
      ),
      [x?.delivery_details?.delivery_time_frame].join(""),
      [x?.distance].join(""),
      [moment(x?.date_listed).format("DD-MM-YYYY , h:mm:ss a")].join(""),
      [Object.keys(x?.quote).length].join(""),
      [
        Object.values(x?.quote)
          .map((x) => x?.quote_amount)
          ?.sort()[0],
      ].join(""),
    ];
  });

  const realresult = Datatouse.map((x) => ({
    DeliveryID: x[0],
    Itemdesc: x[1],
    Pickup: x[2],
    Destination: x[3],
    Mode: x[4],
    Timeframe: x[5],
    Distance: x[6],
    DateListed: x[7],
    Quote: x[8],
    BestQuote: x[9],
  }));

  useEffect(() => {
    setloading(true);
    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      } else {
        setloading(true);
        console.log(allDeliveries, "aaaa");
        if (
          allDeliveries?.length !== 0 &&
          allDeliveries[0] !== undefined &&
          allDeliveries === undefined
          // allDeliveries !== undefined
        ) {
          // console.log(allDeliveries, 'alll deliveries')
          updatePage(allDeliveries);
          // setDeliveriesData(allDeliveries);
          setloading(false);
          setallDeliveries(allDeliveries);
        } else {
          setloading(true);
          // Axios?.get(`${endPoint}platform/v3/ng/deliveries`, {
          Axios?.get(`${endPoint}admin/v1/ng/deliveries`, {
            headers: {
              Authorization: localStorage.getItem("o_kj_"),
            },
          })
            .then((response) => {
              console.log(response, "response-delivery ");
              if (response?.status === 200) {
                
                updatePage(response?.data?.data[0]?.data);
                setallDeliveries(response?.data?.data[0]?.data);
                setloading(false);
                setExportData(response?.data?.data[0]?.data)
              }
            })
            .catch((error) => {
              console.log(error, "error");
              setloading(false);
            });
        }
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);

  // useEffect(() => {
  //   console.log(visitingCountryCode)
  //   authAxios.post(`v2/m-dillivry-admin-portal`,
  //     {
  //       action_type: "get_providers",

  //     })

  //     .then((res) => {
  //       setallprovider(res?.data);
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       console.log(err.data)
  //     });
  // }, [visitingCountryCode]);

  const handleGetRowId = (e) => {
    return e.delivery_id;
  };

  const [pageSize, setPageSize] = useState(10);

  // search functionality
  const handleSearch = async (e) => {
    const handleValue = e.target.value;
    const changeUpper = handleValue.toUpperCase();
    setSearchValue(changeUpper);
    await authAxios
      .post(`${endPoint}m-platform-admin-dashboard?item=${changeUpper}`, {
        requestType: "get_deliveries",
      })
      .then((res) => {
        if (res.status === 200) {
          const newEndArray = res.data.deliveries;
          setdataToShowduplicate(newEndArray);
        }
      })
      .catch((error) => {
        console.log(error.response);

        if (error) {
          console.log(error);
        }
      });
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button variant="outlined" size="large" onClick={() => setshow(!show)}>
          Add filter <KeyboardArrowDownIcon />{" "}
        </Button>
        {show ? <Drop_down /> : null}
        <h4>{statusClicked}</h4>
        <SearchBar />

        <CSVLink
          style={{
            color: "#50B14A",
            textDecoration: "none",
          }}
          data={realresult}
        >
          {" "}
          <BiExport />
          EXPORT
        </CSVLink>
      </GridToolbarContainer>
    );
  };

  const SearchBar = () => {
    return (
      // <div className='search' >
      <div className="search-input">
        <div className="searchandclear">
          <div className="search__container">
            {/* <ReactSearchAutocomplete
              items={dataToShowduplicate?.map((name) => {
                name["newdestina"] = name?.delivery_details?.delivery_address;
                name["newpickup"] = name?.pickup_details?.pickup_address;
                return name;
              })}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              // autoFocus
              formatResult={formatResult}
              fuseOptions={{
                keys: ["delivery_id", "item_desc", "newpickup", "newdestina"],
              }}
              // fuseOptions={{ keys: ["delivery_details"] }}
              // fuseOptions={{ keys: ["delivery_address"] }}
              resultStringKeyName="delivery_id"
            /> */}
            <input
              type="text"
              autoFocus="autoFocus"
              value={searchValue}
              onChange={handleSearch}
              placeholder="Search"
              className="input__search"
            />
            <div
              onClick={() => setdataToShowduplicate(dataToShow)}
              className="clearbtn"
            >
              <AiOutlineClear data-tip="Clear Search" />
              <ReactTooltip type="light" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    var arrOfObj = dataToShow;
    const result = arrOfObj.map((name) => {
      name["newdestina"] = name?.delivery_details?.delivery_address;
      name["newpickup"] = name?.pickup_details?.pickup_address;
      return name;
    });
    // console.log(result);
  }, [dataToShow, dataToShowduplicate]);

  const [ld, setld] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setld(true);
      setpage(localStorage.getItem("pagenumber"));
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const updateItemDesc = (e) => {
    e.preventDefault();
    setisloading(true);

    authAxios
      .patch(`${endPoint}adminportal/v3/ng/item_desc_update`, {
        delivery_id: selectedItem[0]?.delivery_id,
        item_desc: itemdescUpdate,
      })
      .then((response) => {
        setisloading(false);
        setShowupdate(false);
        Swal.fire({
          icon: "success",
          text: `Item description updated successfully`,
          showConfirmButton: "true",
          confirmButtonColor: "var(--main)",
        });
        refetchDeliveries();
      })
      .catch((error) => {
        setisloading(false);
        setShowupdate(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };

  const handleProviderSelect = (e) => {
    setprovider(e);
    authAxios
      .post(`v2/m-dillivry-admin-portal`, {
        action_type: "get_providers",
      })

      .then((res) => {
        setallprovider(res?.data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.data);
      });
  };

  const HandleAssignSubmission = () => {
    if (provider === "" || amount === "") {
      setrequired(true);
    } else {
      setassigning(true);
      authAxios
        .post(`v2/m-dillivry-platform-agent-assignment`, {
          action_type: `${
            typeclicked === "Assign" ? "assign_agent" : "re_assign"
          }`,
          delivery_id: selectedItem[0]?.delivery_id,
          fulfiller_id: provider,
          delivery_status: selectedItem[0]?.delivery_status,
          quote_amount: amount,
        })
        .then((res) => {
          setShowpopup(false);
          Swal.fire({
            icon: "success",
            text: `Item reassign to ${provider}`,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        })
        .catch((err) => {
          setShowpopup(false);
          Swal.fire({
            icon: "error",
            text: `An error occured`,
            showConfirmButton: "true",
            confirmButtonColor: "red",
          });
        })
        .finally(() => setassigning(false));
    }
  };

  const handleUpdatePayment = () => {
    if (deliveryId === "" || fulfilername === "" || amountpaid === "") {
      setrequired(true);
    } else {
      setassigning(true);
      authAxios
        .post(`m-dillivry-admin-portal`, {
          payment_type: "cash",
          delivery_id: deliveryId,
          fulfiller_id: fulfilername,
          amount_paid: amountpaid,
          payment_gateway: "NA",
        })
        .then((res) => {
          setassigning(false);
          setShowpopup(false);
          setdeliveryId("");
          setfulfilename("");
          setamountpaid("");
          setupdatepayment(false);
          Swal.fire({
            icon: "success",
            text: `Payment update successfully`,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        })
        .catch((err) => {
          setassigning(false);
          setupdatepayment(false);
          setdeliveryId("");
          setfulfilename("");
          setamountpaid("");
          console.log(err);
          console.log(err.response);

          if (err) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.msg}`,
              showConfirmButton: "true",
              confirmButtonColor: "grey",
            });
          }
        });
    }
  };


  return (
    <div className="main-grand">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "none",
            position: "fixed",
            top: "0",
            paddingTop: "10px",
            zIndex: "10000",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="success" />
          </Box>
          {console.log(exceldata, 'exceldata')}
        </div>
      ) : (
        <div className="main-grand">
          <div className="top__navigation" style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: "20px"
          }}>
          <Breadcrumbs/>

         
          </div>
          <TableWithPagination
            ident="delivery_id"
            // actions={["Delete", "Update", "Suspend", "Restore", "Sms", "Email"]}
            onActionClick={(val, row) => actionClick(val, row)}
            onSelected={(val) => actionselectedClick(val)}
            actionUpdate={(val) => {
              if (val > 1) {
                setactions(["Assign", "Re-Assign", "Update"]);
              } else {
                setactions(["Assign", "Re-Assign", "Update"]);
              }
            }}
            actions={actions}
            data={allDeliveries}
            inputplaceholder="Search using just the Delivery Number"
            props={Delivery_props}
            rowsPerPage={5}
            currentPage={0}
            itemsPerPage={10}
            NewData={NewData}
            more="deliveries"
            onFetchSuccess={(val) => setallDeliveries(val)}
            property_name="Delivery ID"
            linkclicked={(data) => {
              history.push({
                pathname: `/deliveries/${data?.delivery_id}`,
                state: paginateActivePage,
                backupdata: allDeliveries,
              });
              localStorage.setItem("backupdata", JSON.stringify(allDeliveries));
            }}
            // refetchDefault={(val) => DefaultFetch(val)}
            refetchDefault={refetchDeliveries}
            selecteddata
            searchProperty={searchProperty}
          />
        </div>
      )}

      <DialogPop
        openPopup={showpopup}
        setopenPopup={setShowpopup}
        head="Reassign to provider"
      >
        <div className="app-">
          <div style={{ margin: "0 auto", width: 300 }}>
            <SelectSearch
              options={options}
              search
              filterOptions={fuzzySearch}
              placeholder="Select Provider"
              value={provider}
              // onClick={handleProviderSelect}
              onChange={(e) => handleProviderSelect}
              // onChange={(e) => setprovider(e)}
            />
          </div>
          <button onClick={handleProviderSelect}>View Providers</button>
        </div>

        <form>
          {required ? (
            <div
              style={{
                color: "red",
                fontSize: "12px",
                marginBottom: "15px",
              }}
            >
              All field is required
            </div>
          ) : (
            <></>
          )}

          <FormControl
            variant="outlined"
            className={classes.formControl}
            // style={{marginBottom: "30px"}}
          >
            <InputLabel id="select">
              <span style={{ color: "var(--main)", marginRight: "10px" }}>
                <FaGripHorizontal />
              </span>
              Quote Amount
            </InputLabel>
            <InputField
              required
              name="email"
              id="email"
              type="number"
              value={amount}
              labelWidth={90}
              onChange={(e) => {
                setamount(e.target.value);
                setrequired(false);
              }}
              icon={
                <RiLockPasswordLine
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />
              }
            />
          </FormControl>

          <Button
            style={{
              background: "var(--main)",
              fontSize: "13px",
              textTransform: "none",
              marginTop: "20px",
              boxShadow: "none",
              border: "1px solid var(--main)",
            }}
            color="success"
            className="btn-"
            size="small"
            variant="contained"
            onClick={HandleAssignSubmission}
            disabled={assigning}
          >
            {assigning ? "Loading..." : "Reassign"}
          </Button>
        </form>
      </DialogPop>

      <DialogPop
        openPopup={showupdate}
        setopenPopup={setShowupdate}
        head="Update Description"
      >
        <div className="update_itemdesc_con">
          <form className="update_itemdesc_form">
            <textarea
              type="text"
              style={{ outlineColor: "var(--main)" }}
              placeholder="Update item desc.."
              onChange={(e) => {
                setitemdescUpdate(e.target.value);
              }}
              value={itemdescUpdate}
              className="update_itemdesc_text"
            />
            <div className="update_itemdesc_btncon">
              {itemdescUpdate === "" || itemdescUpdate === undefined ? (
                <button disabled className="update_itemdesc_btn">
                  Update
                </button>
              ) : (
                <button
                  onClick={updateItemDesc}
                  className="update_itemdesc_btn"
                >
                  {isloading ? (
                    <PuffLoader
                      color="white"
                      loading={isloading}
                      speedMultiplier={1}
                      size={34}
                    />
                  ) : (
                    <span>Update</span>
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
      </DialogPop>
    </div>
  );
}
