import { createContext, useEffect, useState } from 'react'
import Axios from 'axios'

export const ProviderContext = createContext();

export const ProviderProvider = ({ children }) => {

  const [providers, setProvider] = useState(undefined)
  const [corporateProvider, setCorporateProvider] = useState(undefined)
  const [filteredCorpProvider, setFilteredCorpProvider] = useState([undefined])
  const [last_evaluated_key, setlast_evaluated_key] = useState()
  const [opend_last_evaluated_key, opend_setlast_evaluated_key] = useState()

  const [corp_filter_option, setCorp_filter_option] = useState({
    country: [],
    mode: [],
    status: []
  })

  const [individualdata, setindividualdata] = useState(undefined)
  const [filteredIndiProvider, setFilteredIndiProvider] = useState([undefined])
  const [last_evaluated_keyind, setlast_evaluated_keyind] = useState()
  const [last_evaluated_keyagt, setlast_evaluated_keyagt] = useState()
  const [last_evaluated_keyadmin, setlast_evaluated_keyadmin] = useState()
  const [individual_filter_option, setindividual_filter_option] = useState({
    country: [],
    mode: [],
    status: []
  })

  const ValPhoto = (user_id) => {
    const photo_item = []
    // data.data[0]
    // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    providers.data.data[0].forEach(function (element) {
      if (element.user_id === user_id) {
        element.profile_photo.state = "verified"
        photo_item.push(element)
      }
      else {
        photo_item.push(element)
      }
    })
    providers.data.data[0] = photo_item
  }

  const UpdateNewData = (data) => {
    setindividualdata(data)
    //  console.log(individualdata, "data-from-new-data")
  }

  const rejectPhoto = (user_id) => {
    const photo_item = []
    // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    providers.data.data[0].forEach(function (element) {
      if (element.user_id === user_id) {
        element.profile_photo.state = "not_valid"
        photo_item.push(element)
      }
      else {
        photo_item.push(element)
      }
    })
    // providers.data.fulfillers.account_pending_validation = photo_item
    providers.data.data[0] = photo_item

  }

  const valMessage = (user_id) => {
    const photo_item = []
    // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    providers.data.data[0].forEach(function (element) {
      if (element.user_id === user_id) {
        element.profile_message.state = "verified"
        photo_item.push(element)
      }
      else {
        photo_item.push(element)
      }
    })
    // providers.data.fulfillers.account_pending_validation = photo_item
    providers.data.data[0] = photo_item

  }

  const rejectMessage = (user_id) => {
    const photo_item = []
    // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    providers.data.data[0].forEach(function (element) {
      if (element.user_id === user_id) {
        element.profile_message.state = "not_valid"
        photo_item.push(element)
      }
      else {
        photo_item.push(element)
      }
    })
    // providers.data.fulfillers.account_pending_validation = photo_item
    providers.data.data[0] = photo_item
  }

  const valFormID = (user_id) => {
    console.log(user_id, 'user_id')
    // const photo_item = []
    // // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    //   providers.data.data[0].forEach(function (element) {
    //   if (element.user_id === user_id) {
    //     element.proof_of_identity.state = "verified"
    //     photo_item.push(element)
    //   }
    //   else {
    //     photo_item.push(element)
    //   }
    // })
    // // providers.data.fulfillers.account_pending_validation = photo_item
    // providers.data.data[0] = photo_item

  }

  const rejectFormID = (user_id) => {
    const photo_item = []
    // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    providers.data.data[0].forEach(function (element) {
      if (element.user_id === user_id) {
        element.proof_of_identity.state = "not_valid"
        photo_item.push(element)
      }
      else {
        photo_item.push(element)
      }
    })
    // providers.data.fulfillers.account_pending_validation = photo_item
    providers.data.data[0] = photo_item

  }

  const valInsurance = (user_id) => {
    const photo_item = []
    // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    providers.data.data[0].forEach(function (element) {
      if (element.user_id === user_id) {
        element.good_in_transit_insurance.state = "verified"
        photo_item.push(element)
      }
      else {
        photo_item.push(element)
      }
    })
    // providers.data.fulfillers.account_pending_validation = photo_item
    providers.data.data[0] = photo_item

  }

  const rejectInsurance = (user_id) => {
    const photo_item = []
    // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    providers.data.data[0].forEach(function (element) {
      if (element.user_id === user_id) {
        element.good_in_transit_insurance.state = "not_valid"
        photo_item.push(element)
      }
      else {
        photo_item.push(element)
      }
    })
    // providers.data.fulfillers.account_pending_validation = photo_item
    providers.data.data[0] = photo_item
  }

  const valBank = (user_id) => {
    const photo_item = []
    // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    providers.data.data[0].forEach(function (element) {
      if (element.user_id === user_id) {
        element.bank_detail.state = "verified"
        photo_item.push(element)
      }
      else {
        photo_item.push(element)
      }
    })
    // providers.data.fulfillers.account_pending_validation = photo_item
    providers.data.data[0] = photo_item
  }

  const rejectBank = (user_id) => {
    const photo_item = []
    // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    providers.data.data[0].forEach(function (element) {
      if (element.user_id === user_id) {
        element.bank_detail.state = "not_valid"
        photo_item.push(element)
      }
      else {
        photo_item.push(element)
      }
    })
    // providers.data.fulfillers.account_pending_validation = photo_item
    providers.data.data[0] = photo_item
  }

  const generalVal = (user_id) => {
    const photo_item = []
    // providers.data.fulfillers.account_pending_validation.forEach(function (element) {
    providers.data.data[0].forEach(function (element) {
      if (element.user_id === user_id) {
        element.account_status.state = "active"
        photo_item.push(element)
      }
      else {
        photo_item.push(element)
      }
    })
    // providers.data.all_users = photo_item
    providers.data.data[0] = photo_item

  }

  return (
    <ProviderContext.Provider value={{

      ValPhoto,
      rejectPhoto,
      valMessage,
      rejectMessage,
      valFormID,
      rejectFormID,
      valInsurance,
      rejectInsurance,
      valBank,
      rejectBank,
      generalVal,
      UpdateNewData,

      providers,
      setProvider,

      corporateProvider,
      setCorporateProvider,
      filteredCorpProvider,
      setFilteredCorpProvider,
      corp_filter_option,
      setCorp_filter_option,

      individualdata,
      setindividualdata,
      filteredIndiProvider,
      setFilteredIndiProvider,
      individual_filter_option,
      setindividual_filter_option,
      last_evaluated_key, setlast_evaluated_key, last_evaluated_keyind, setlast_evaluated_keyind,
      last_evaluated_keyagt, setlast_evaluated_keyagt,
      opend_last_evaluated_key, opend_setlast_evaluated_key, last_evaluated_keyadmin, setlast_evaluated_keyadmin
    }}>
      {children}
    </ProviderContext.Provider>
  )
}