import {createContext, useReducer} from 'react';
import DataReducer, { INITIAL_STATE } from './AgentReducer';
import DataTypes from './AgentType';

export const AgentContext = createContext({
    ...INITIAL_STATE
  });

const AgentProvider = ({ children }) => {
    const [store, dispatch] = useReducer(DataReducer, INITIAL_STATE);
    const { datas } = store;

    const updateData = data => {
        dispatch({
          type: DataTypes.GET_DEFAULT_DATA,
          payload: data
        });
      };

      const ValBankState = data => {
        dispatch({
          type: DataTypes.Val_Bank_Details,
          payload: data
        });
      };
      const RejBankState = data => {
        dispatch({
          type: DataTypes.Rej_Bank_Details,
          payload: data
        });
      };


      const ValInsurance = data => {
        dispatch({
          type: DataTypes.Val_Insurance,
          payload: data
        });
      };

      const RejInsurance = data => {
        dispatch({
          type: DataTypes.Rej_Insurance,
          payload: data
        });
      };

      const ValProfilePhoto = data => {
        dispatch({
          type: DataTypes.Val_Profile_Photo,
          payload: data
        });
      };
      const RejProfilePhoto = data => {
        dispatch({
          type: DataTypes.Rej_Profile_Photo,
          payload: data
        });
      };

      
      const ValFormId = data => {
        dispatch({
          type: DataTypes.Val_Form_Id,
          payload: data
        });
      };

      const RejFormId = data => {
        dispatch({
          type: DataTypes.Rej_Form_Id,
          payload: data
        });
      };
      
      const GeneralVal = data => {
        dispatch({
          type: DataTypes.General_Val,
          payload: data
        });
      };



      return (
        <AgentContext.Provider value={{
            updateData,
            ValFormId,
            ValBankState,
            ValInsurance,
            ValProfilePhoto,
            GeneralVal,
            RejBankState,
            RejFormId,
            RejInsurance,
            RejProfilePhoto,
            datas
        }}>
          {children}
        </AgentContext.Provider>
      );
}
export default AgentProvider;