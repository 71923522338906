import dataType from './DataType';

export const INITIAL_STATE =  []


const DataReducer = (state, action) => {
    switch(action.type) {

      case dataType.GET_DASHDATA_NEW:
        return{
          newdata: action.payload
        }



      case dataType.GET_DELIVERIES_DATA:
        return{
         
          newdata: action.payload
        }



      case dataType.GET_DEFAULT_DATA:
        return {
            datas: action.payload
        };




        case dataType.GET_OPENDELIVERIES_DATA:
          return{
            opendeliveriesdata: action.payload
          }


        case dataType.Val_Bank_Details:
            const bank_item = []

            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.bank_detail.state = "verified"
                  // console.log(element.bank_detail.state)
                  bank_item.push(element)
                }
                else{
                  bank_item.push(element)
                }
              })

              state.datas.data.all_users = bank_item

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };



            case dataType.Rej_Bank_Details:
              const bank_item2 = []
  
              state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                  if(element.user_id === action.payload){
                    // element.user_id = "new_email";
                    element.bank_detail.state = "not_valid"
                    // console.log(element.bank_detail.state)
                    bank_item2.push(element)
                  }
                  else{
                    bank_item2.push(element)
                  }
                })
  
                state.datas.data.all_users = bank_item2
  
                //console.log( state, 'updateed data')
  
              return {
                  datas: state.datas
  
              };

            
            case dataType.Val_Profile_Photo:
            const photo_item = []
            //console.log( state.datas.data, 'cotext-data')
            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.profile_photo.state = "verified"
                  // console.log(element.bank_detail.state)
                  photo_item.push(element)
                }
                else{
                  photo_item.push(element)
                }
              })

              state.datas.data.all_users =photo_item

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };
             
            case dataType.Rej_Profile_Photo:
              const photo_item2 = []
  
              state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                  if(element.user_id === action.payload){
                    // element.user_id = "new_email";
                    element.profile_photo.state = "not_valid"
                    // console.log(element.bank_detail.state)
                    photo_item2.push(element)
                  }
                  else{
                    photo_item2.push(element)
                  }
                })
  
                state.datas.data.all_users =photo_item2
  
                //console.log( state, 'updateed data')
  
              return {
                  datas: state.datas
  
              };


              case dataType.Val_Profile_Message:
                const message_item = []
                const accounts = [ ...state.datas.data.fulfillers.account_pending_validation, ...state.datas.data.fulfillers.active_accounts]
    
               accounts.forEach(function (element) {
                    if(element.user_id === action.payload){
                      // element.user_id = "new_email";
                      element.profile_message.state = "verified"
                     
                      
                      message_item.push(element)
                    }
                    else{
                      message_item.push(element)
                      
                    }
                  })
    
                  state.datas.data.all_users =message_item
    
                  //console.log( state, 'updateed data')
    
                return {
                    datas: state.datas
    
                };
              
            case dataType.Rej_Profile_Message:
              const message_item2 = []
              const account = [ ...state.datas.data.fulfillers.account_pending_validation, ...state.datas.data.fulfillers.active_accounts]
  
              account.forEach(function (element) {
                  if(element.user_id === action.payload){
                   
                    element.profile_message.state = "not_valid"
                   
                    message_item2.push(element)
                  }
                  else{
                    message_item2.push(element)
                  }
                })
  
                state.datas.data.all_users =message_item2
  
                //console.log( state, 'updateed data')
  
              return {
                  datas: state.datas
  
              };


            case dataType.Val_Insurance:
            const ins_item = []

            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.good_in_transit_insurance.state = "verified"
                  // console.log(element.bank_detail.state)
                  ins_item.push(element)
                }
                else{
                  ins_item.push(element)
                }
              })

              state.datas.data.all_users = ins_item

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };

            case dataType.Rej_Insurance:
            const ins_item2 = []

            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.good_in_transit_insurance.state = "not_valid"
                  // console.log(element.bank_detail.state)
                  ins_item2.push(element)
                }
                else{
                  ins_item2.push(element)
                }
              })

              state.datas.data.all_users = ins_item2

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };


            case dataType.Val_Form_Id:
            const id_item = []

            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.proof_of_identity.state = "verified"
                  id_item.push(element)
                }
                else{
                  id_item.push(element)
                }
              })

              state.datas.data.all_users = id_item

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };


            case dataType.Rej_Form_Id:
            const id_item2 = []

            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.proof_of_identity.state = "not_valid"
                  console.log(element)
                  id_item2.push(element)
                }
                else{
                  id_item2.push(element)
                }
              })

              state.datas.data.all_users = id_item2

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };







            case dataType.General_Val:
            const general_item = []

            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.account_status.state = "active"
                  // console.log(element.bank_detail.state)
                  general_item.push(element)
                }
                else{
                  general_item.push(element)
                }
              })

              state.datas.data.all_users =general_item

            return {
                datas: state.datas

            };
      default:
        return state;
    }
  };

export default DataReducer;