import React from "react";
import Navbar from "../../components/Navbar";
import './Disputes.css'
import samplereceipt from '../../assets/sample-receipt.JPG'

const DisputesSettlement = () => {
  return (
    <>
      {/* <div className="drill-down-top"> */}
        <Navbar
          dashboardtext="Back to dashboard"
          familyname={localStorage?.getItem("fam")}
          givenname={localStorage?.getItem("name")}
          //   page={location?.state}
          //   role={corporatedetails[0]?.role}
        />
      {/* </div> */}

      <div className="dispute__settlement--container">
        <div className="dispute__settlement--container__description">
            <h2>Hello, there's a new dispute created with the following information</h2>
        </div>
        <div className="dispute__settlement--container__information">
            <p>Sender's Name: <span>Himanshu Chaubey</span></p>
            <p>Amount: <span>&#x20A6;5,000</span></p>
            <p>Paid by: <span>Transfer</span></p>
            <div className="dispute__transaction--receipt">
                <p>Transaction Receipt</p>  
                <img src={samplereceipt} alt="Sample Receipt" />
            </div>
            <div className="dispute__settlement--buttons">
            <button className="dispute__button--reject">Reject</button>
            <button className="dispute__button--approve">Approve</button>
            </div>
            
        </div>
      </div>
    </>
  );
};

export default DisputesSettlement;
