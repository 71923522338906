import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./DisputeDrilldown.css";
import Axios from "axios";
import Swal from "sweetalert2";

const DisputeDrilldown = () => {
  const { disputeId, userId } = useParams();
  const history = useHistory();

  const endPoint = process.env.REACT_APP_API;

  const authAxios = Axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });

  const [disputeDetails, setDisputeDetails] = useState([]);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    authAxios
      .get(
        `${endPoint}dispute/v1/ng/dispute_details?user_id=${userId}&dispute_id=${disputeId}`
      )
      .then((response) => {
        setIsLoading(true);
        if (response && response.status === 200) {
          setIsLoading(false);
          console.log(
            response.data.data[0].date.date_created,
            "disputedetails"
          );
          setDisputeDetails(response.data.data[0], "response");
        }
      })
      .catch((error) => {
        if (error) {
          Swal.fire({
            icon: "error",
            title: error.message,
            confirmButtonColor: "red",
          });
          console.log(error.message, "error");
        }
      });
  }, []);

  const handleApproveButton = async (e) => {
    e.preventDefault();

    const authAxios = Axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    });
    await authAxios
      .patch(`${endPoint}resolve/v1/ng/approve`, {
        user_id: userId,
        dispute_id: disputeId,
      })
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Dispute Successfully Approved",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 6000,
            });

            history.push("/disputes");
          }
        }
      })

      .catch((error) => {
        if (error) {
          Swal.fire({
            icon: "error",
            title: error,
            confirmButtonColor: "red",
          });
          // console.log(error, "errorrrr");
        }
      });
  };
  return (
    <>
      {/* <div className="disputedrilldown__container">
        <div className="dispute__heading">
          <p>Dispute {disputeId}</p>
        </div>
        <div className="dispute__card--container">
          <div className="dispute__card--personal">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="dispute__accordion--summary"
              >
                <h3>Personal Information</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div className="dispute__details--info">
                  <p>Name</p>
                  <p>Himanshu Chaubey</p>
                </div>
                <div className="dispute__details--info">
                  <p>Account Type</p>
                  <p>Corporate Provider</p>
                </div>

                <button className="dispute__details--button">
                  Account Balance
                </button>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="dispute__card--details">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className="dispute__accordion--summary"
              >
                <h3>Dispute Details</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div className="dispute__details--info">
                  <p>Date Created</p>
                  <p>18th April 2023</p>
                </div>
                <div className="dispute__details--info">
                  <p>Amount</p>
                  <p>5,000</p>
                </div>
                <div className="dispute__details--info">
                  <p>Complaint</p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Corporis, culpa?
                  </p>
                </div>
                <div className="dispute__details--info">
                  <p>Status</p>
                  <p>pending</p>
                </div>
                <div className="dispute__details--info">
                  <p>Transaction Evidence</p>
                  <p>5,000</p>
                </div>

                
                <button
                  className="dispute__action--buttons"
                  onClick={handleOpen}
                >
                  Approve
                </button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <Box sx={style}>
                      <div className="dispute__info--modal">
                        <h2>ochuba2345677889</h2>
                        <div className="dispute__info--details">
                          <p>Date Created:</p>
                          <span>12th June, 2023</span>
                        </div>
                        <div className="dispute__info--details">
                          <p>Amount:</p>
                          <span>&#x20A6; 5000</span>
                        </div>
                        <div className="dispute__info--details">
                          <p>Sender's Name: </p>
                          <span>Chimezie</span>
                        </div>
                        <div className="dispute__info--details">
                          <p>User ID:</p>
                          <span>ochuba6@gmail.com</span>
                        </div>
                        <div className="dispute__complaint--para dispute__info--para">
                          <p>Complaint: </p>
                          <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Modi illo eligendi necessitatibus, similique
                            iusto unde nulla dolores deserunt magnam neque
                            obcaecati perspiciatis, adipisci ut fugit dolore
                            maxime. Officiis, facilis cupiditate.
                          </p>
                        </div>
                        
                      </div>
                      <button className="dispute__info--modal-btn" onClick={handleApproveButton}>Confirm</button>

                    </Box>
                  </Fade>
                </Modal>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div> */}

      <div className="disputedrilldown__container">
        <h2>Dispute ID: {disputeDetails.dispute_id}</h2>

        <div className="disputedrilldown__info">
          <div className="disputedrilldown__child">
            <p>User ID</p>
            <p>{disputeDetails?.user_id}</p>
          </div>
          <div className="disputedrilldown__child">
            <p>Account Name</p>
            <p>{disputeDetails?.account_name}</p>
          </div>
          {/* <div className="disputedrilldown__child">
            <p>Date Created</p>
            <p>{disputeDetails.date.date_created}</p>
          </div> */}
          <div className="disputedrilldown__child">
            <p>Amount</p>
            <p>{disputeDetails?.amount}</p>
          </div>
          <div className="disputedrilldown__child--text">
            <p>Complaint</p>
            <p>{disputeDetails?.complaint}</p>
          </div>
          <div className="disputedrilldown__child--image">
            <p>Transaction Evidence</p>
            <img src={disputeDetails?.image_url} alt="Transaction Evidence" />
          </div>
          <div className="disputedrilldown__child">
            <p>Status</p>
            <p>{disputeDetails?.status}</p>
          </div>
        </div>
        {disputeDetails.status !== "pending" ? (
          <></>
        ) : (
          <button onClick={handleApproveButton}>Approve</button>
        )}
      </div>
    </>
  );
};

export default DisputeDrilldown;
