import { createContext, useState } from 'react'

export const AgentContext = createContext();

export const AgentProvider = ({ children }) => {

    const [agentsData, setAgentsData] = useState(undefined)
    const [agentsDataToShow, setAgentsDataToShow] = useState(undefined)
    const [last_evaluated_keyagt, setlast_evaluated_keyagt] = useState()

    const [filteredAgents, setFilteredAgents] = useState([undefined])

    const [agent_filter_option, setAgent_filter_option] = useState({
        country: [],
        mode: [],
        status: []
    })


    // const valPhoto = (user_id) => {
    //     const photo_item = []
    //     users?.data?.data[0]?.filter(
    //         (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
    //             if (element.user_id === user_id) {
    //                 element.profile_photo.state = "verified"
    //                 photo_item.push(element)
    //             }
    //             else {
    //                 photo_item.push(element)
    //             }
    //         })
    //     // users.data.all_users = photo_item
    //     users.data.data.data[0].data = photo_item
    //     // users.data?.data[0]?.data = photo_item
    // }

    // const rejPhoto = (user_id) => {
    //     const photo_item = []
    //     users?.data?.data[0]?.data.filter(
    //         (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
    //             if (element.user_id === user_id) {
    //                 element.profile_photo.state = "not_valid"
    //                 photo_item.push(element)
    //             }
    //             else {
    //                 photo_item.push(element)
    //             }
    //         })
    //         users.data.data.data[0].data = photo_item
    //     }


    // const valFormId = (user_id) => {
    //     const photo_item = []
    //     users?.data?.data[0]?.data.filter(
    //         (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
    //             if (element.user_id === user_id) {
    //                 element.proof_of_identity.state = "verified"
    //                 photo_item.push(element)
    //             }
    //             else {
    //                 photo_item.push(element)
    //             }
    //         })
    //         users.data.data.data[0].data = photo_item
    //     }

    // const rejFormId = (user_id) => {
    //     const photo_item = []
    //     users?.data?.data[0]?.data.filter(
    //         (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
    //             if (element.user_id === user_id) {
    //                 element.proof_of_identity.state = "not_valid"
    //                 photo_item.push(element)
    //             }
    //             else {
    //                 photo_item.push(element)
    //             }
    //         })
    //         users.data.data.data[0].data = photo_item
    //     }

    return (
        <AgentContext.Provider
            value={{
                // valPhoto,
                // rejPhoto,
                // valFormId,
                // rejFormId,
                agentsDataToShow, 
                setAgentsDataToShow,
                agentsData,
                setAgentsData,

                setAgent_filter_option,
                agent_filter_option,
                setFilteredAgents,
                filteredAgents,
               
                last_evaluated_keyagt, setlast_evaluated_keyagt,

            }}
        >
            {children}
        </AgentContext.Provider>
    );
}