import React, { useState, useEffect, useContext } from "react";
import "./Corporate.css";
import "jspdf-autotable";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";
import { ProviderContext } from "../../context/ProviderContext"
import IndividualTable from "./IndividualTable";
import TableWithPagination from "../../components/DefaultTableFolder/TablePaginated";
import PuffLoader from "react-spinners/PuffLoader";
import DialogOutClick from "../../components/DialogOutClick";
import { FormControl, Button, InputLabel } from "@material-ui/core";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlineWarning } from "react-icons/ai";
import InputField from "../../InputField";
import { RiLockPasswordLine } from "react-icons/ri";
import PhoneNumber from "../AdminUsers/PhoneNumber";
import Breadcrumbs from "../../components/Breadcrumbs";
import { CSVLink, CSVDownload } from "react-csv";
import { CiExport } from "react-icons/ci";

export default function Individual() {

  const history = useHistory();
  const endPoint = process.env.REACT_APP_API;

  const {
    providers,
    setProvider,
    setindividualdata,
    setFilteredIndiProvider, setlast_evaluated_key, last_evaluated_keyind, setlast_evaluated_keyind, setCorporateProvider, filteredIndiProvider } = useContext(ProviderContext);

  const [loading, setloading] = useState(false);
  const [item_to_modify, setitem_to_modify] = useState([])

  const [validData, setValiddata] = useState()
  const [Refetcheddata, setRefetcheddata] = useState(false)

  const [corpdata, setCorpdata] = useState(undefined)


  const [emailarray, setemailarray] = useState("");
  const [addingUser, setaddingUser] = useState(false);
  const [smssubject, setsmssubject] = useState("");
  const [submitting, setsubmitting] = useState(false);
  const [phone_num, setphone_num] = useState("");
  const [emailsubject, setemailsubject] = useState("");
  const [emailtextarea, setemailtextarea] = useState("");
  const [emailreset, setemailreset] = useState("");
  const [Showpop, setShowpop] = useState(false);
  const [Showpopemail, setShowpopemail] = useState(false);
  const [Showpopsms, setShowpopsms] = useState(false);
  const [Showpopsuspend, setShowpopsuspend] = useState(false);
  const [Showpopenable, setShowpopenable] = useState(false);
  const [Showpopresetpassword, setShowpopresetpassword] = useState(false);
  const [showreset, setShowreset] = useState(false);
  const [permanentlyDelete, setpermanentlyDelete] = useState('')
  const [actions, setactions] = useState([])
  const [exportData, setExportData] = useState()

  const actionClick = (val, row) => {
    setitem_to_modify(row)

    if (val === 'Send Email') {
      setShowpopemail(true)
      setemailarray(row.map((ro) => ro.user_id))
    }
    else if (val === 'Send sms') {
      setShowpopsms(true)
      setphone_num(row?.[0]?.phone_number)
    }
    else if (val === 'Suspend') {
      setShowpopsuspend(true)
    }
    else if (val === 'Restore') {
      setShowpopenable(true)
    }
    else if (val === 'Delete') {
      setShowpop(true)
    }
  }




  const authAxios = Axios.create({
    baseURL: `${endPoint}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });

  const Corporate_props = [
    'Image',
    'Account name',
    'Account Id',
    'Email',
    'Account Status',
    'Phone number',
    'Insurance',
    'Account status'
  ]

  const NewData = [
    'cop_logo',
    'account_name',
    'account_id',
    'user_id',
    'account_status',
    'phone_number',
    'good_in_transit_insurance',
    'account_status'
  ]

  const searchProperty = [
    {
      "name" : "Account name",
      "type" : "account_name"
    },
    {
      "name" : "Account Id",
      "type" : "account_id"
    },
    {
      "name" : "Email",
      "type" : "user_id"
    },
    {
      "name" : "Phone number",
      "type" : "phone_number"
    },
    // {
    //   "name" : "Insurance",
    //   "type" : "good_in_transit_insurance"
    // },
    // {
    //   "name" : "Account status",
    //   "type" : "account_status"
    // },
  ]

  const Buttoncard = ({
    className,
    onClick,
    disabled,
    color,
    text,
    loading,
  }) => {
    return (
      <div>
        {" "}
        <button
          onClick={onClick}
          className={className}
          disabled={disabled}
          color={color}
        >
          {loading ? (
            <PuffLoader
              color="white"
              loading={loading}
              speedMultiplier={1}
              size={24}
            />
          ) : (
            text
          )}
        </button>
      </div>
    );
  };


  const handleDelete = async (e) => {
    setsubmitting(true);
    await Axios.delete(`${endPoint}adminportal/v3/ng/remove_user`, {
      headers: {
        Authorization: localStorage?.getItem("o_kj_"),
      },
      data: {
        user_id: item_to_modify?.[0]?.user_id,
      },
    })
      .then((res) => {
        setsubmitting(false);

        if (res.status === 200) {
          setShowpop(false);
          Swal.fire({
            icon: "success",
            title: "User deleted successfully",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 2000,
          })
            .then(() => {
              refetchUsers()
            })
        }
      })
      .catch((error) => {
        console.log(error.response);
        setsubmitting(false);
        setShowpop(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.error === undefined ? error?.response?.data?.message : 'An error occured'}`,
            confirmButtonColor: "grey",
          });
        }
      });
  };


  const handlesendEmial = async (e) => {
    e.preventDefault();
    setsubmitting(true);

    // console.log(emailarray, emailsubject, emailtextarea)

    await authAxios
      .post(`${endPoint}adminportal/v3/ng/send_email`, {
        email: emailarray,
        email_subject: emailsubject,
        message_body: emailtextarea,
      })
      .then((response) => {
        if (response) {
          setShowpopemail(!Showpopemail);
          setsubmitting(false);
          if (response.status === 200) {
            setShowpopemail(!Showpopemail);
            setemailarray([]);
            setemailsubject("");
            setemailtextarea("");

            Swal.fire({
              icon: "success",
              title: "Email Sent",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 6000,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error?.response);
        setShowpopemail(!Showpopemail);
        setsubmitting(false);
        if (error) {
          setemailsubject("");
          setemailtextarea("");
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.msg}`,
            confirmButtonColor: "red",
          });
        }
      });
  };



  const handlesendSms = async (e) => {
    e.preventDefault();
    setsubmitting(true);

    await authAxios
      .post(`${endPoint}adminportal/v3/ng/send_sms`, {
        phone_number: phone_num,
        message_body: smssubject,
      })
      .then((response) => {
        if (response) {
          setShowpopsms(!Showpopsms);
          setsubmitting(false);
          if (response.status === 200) {
            setShowpopsms(!Showpopsms);
            setphone_num(" ");
            setsmssubject("");

            Swal.fire({
              icon: "success",
              title: "Sms Sent",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 2000,
            });
          }
        }
      })
      .catch((error) => {
        setShowpopsms(!Showpopsms);
        setsubmitting(false);
        if (error) {
          setphone_num(" ");
          setsmssubject("");
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "red",
          });
        }
      });
  };



  const handleSuspendacct = async (e) => {
    setsubmitting(true);
    await Axios?.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "disable_account",
        email: item_to_modify?.[0]?.user_id,
        account_name: item_to_modify?.[0]?.account_name       // account_name: corporatedetails.account_name,
        // email: corporatedetails.user_id,
        // country_code: visitingCountryCode,
      },
    }).then((res) => {
      setsubmitting(false);
      console.log(res);
      if (res.status === 200) {
        setShowpopsuspend(false);
        Swal.fire({
          icon: "success",
          title: "Account Suspended",
          showConfirmButton: "false",
          confirmButtonColor: "grey",
          timer: 6000,
        });
      }
      history.push('/individual')

    })
      .catch((error) => {
        console.log(error);
        setsubmitting(false);
        setShowpopsuspend(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };



  const handleenableacct = async (e) => {
    setsubmitting(true);
    await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "enable_account",
        email: item_to_modify?.[0]?.user_id,
        account_name: item_to_modify?.[0]?.account_name       // account_name: corporatedetails.account_name,
        // email: corporatedetails.user_id,
        // country_code: visitingCountryCode,
      },
    })
      .then((res) => {
        setsubmitting(false);

        console.log(res.response);
        if (res.status === 200) {
          setShowpopenable(false);
          Swal.fire({
            icon: "success",
            title: "Account Enabled",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 6000,
          });
        }
        history.push('/individual')

      })
      .catch((error) => {
        console.log(error.response);
        setsubmitting(false);
        setShowpopenable(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };


  const DefaultFetch = (val) => {
    // console.log(`do what you want with me`, val)
    setRefetcheddata(true);
    setloading(true)
    setFilteredIndiProvider([undefined])
  }


  const updatePage = (datum) => {
    console.log(datum, 'datummmmmm')
    setlast_evaluated_key(datum?.data?.data[0]?.last_evaluated_key)
    setFilteredIndiProvider(datum?.data?.data[0]?.data);
  };




  useEffect(() => {
    setloading(true);

    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;
      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      } else {
        setloading(true);
        if (filteredIndiProvider !== undefined && filteredIndiProvider[0] !== undefined) {
          console.log(filteredIndiProvider, 'filteredCorpProvider')
          setValiddata(filteredIndiProvider)
          setloading(false);
          setRefetcheddata(false);
        }
        else {
          setloading(true);

          Axios.get(
            // `${endPoint}platform/v3/ng/individual_provider`,
            `${endPoint}admin/v1/ng/individual_provider`,
            {
              headers: {
                Authorization: localStorage.getItem("o_kj_"),
              },
            }
          )
            .then((response) => {
              console.log(response, 'response')
              if (response.status === 200) {
                setValiddata(response?.data?.data[0]?.data)
                updatePage(response);
                // setProvider(response)
                setloading(false);
                setRefetcheddata(false);
                filteredIndiProvider(response?.data?.data[0]?.data);
                setlast_evaluated_key(response?.data?.data[0]?.last_evaluated_key)
                setExportData(response?.data?.data[0]?.data)
              }
            })
            .catch((error) => {
              console.log(error, "error");
            })
            .finally(() => {
              // setloading(false)
            });
        }

      }
    } else {
      history.push("/");
      localStorage.clear();
    }

  }, [Refetcheddata]);





  const refetchUsers = async () => {
    setloading(true);

    await Axios.get(
      // `${endPoint}platform/v3/ng/corporate_provider`,
      `${endPoint}admim/v1/ng/corporate_provider`,
      {
        headers: {
          Authorization: localStorage.getItem("o_kj_"),
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setFilteredIndiProvider(response);
          updatePage(response);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setloading(false)
      });
  }
  // const updatePage = (datum) => {
  //   console.log(datum, 'datummmmmm')
  //   setlast_evaluated_keyind(datum?.data?.data[0]?.last_evaluated_key)
  //   // setFilteredIndiProvider([
  //   //   ...datum.data.fulfillers.active_accounts.filter(
  //   //     (x) => x.role === "dillivry-sole-trader-ops"
  //   //   ),
  //   //   ...datum.data.fulfillers.account_pending_validation.filter(
  //   //     (x) => x.role === "dillivry-sole-trader - ops"
  //   //   )
  //   // ]);

  //   setFilteredIndiProvider(datum?.data?.data[0]?.data.filter(
  //     (x) => x?.role === "dillivry-sole-trader-ops"
  //   ))


  //   setCorporateProvider(datum?.data?.data[0]?.data.filter(
  //     (x) => x?.role === "dillivry-corp-sys-admin"
  //   ))

  //   // setindividualdata([...datum.data.fulfillers.active_accounts.filter((x) => x.role === "dillivry-sole-trader-ops"), ...datum.data.fulfillers.account_pending_validation.filter((x) => x.role === "dillivry-sole-trader-ops")])

  //   setindividualdata(datum?.data?.data[0]?.data?.filter((x) => x.role === "dillivry-sole-trader-ops"))


  //   // console.log([
  //   //   ...datum.data.fulfillers.active_accounts.filter(
  //   //     (x) => x.role === "dillivry-sole-trader-ops"
  //   //   ),
  //   //   ...datum.data.fulfillers.account_pending_validation.filter(
  //   //     (x) => x.role === "dillivry-sole-trader - ops"
  //   //   )
  //   // ], 'corp-datatatatat')

  // };

  const csvData = exportData
  console.log(typeof csvData, 'csxvvvvv')

  return (

    <div>
      {loading === true ? (
        <div>
          <Box sx={{ width: "100%", marginTop: "2px" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : (

        <div className="main-grand">
          <div className="top__navigation" style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: "20px"
          }}>
          <Breadcrumbs/>

          {/* <div>
            {
              csvData !== undefined ?  
              <CSVLink
                data={filteredIndiProvider}
                filename='individual provider'
                // headers={csvHeader}
                style={{
                  border: "none",
                  padding: "7px 17px",
                  borderRadius: "7px",
                  fontSize: "13px",
                  color: "#fff",
                  backgroundColor: "#50B14A",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  justifyContent: 'end'
                }}
              >
                Export CSV <CiExport size={20} />
              </CSVLink>
               : null
             } 
          </div> */}
          </div>
          {/* <Breadcrumbs/> */}
          <TableWithPagination
            ident="user_id"
            // actions={['Delete', 'Update', 'Suspend', 'Restore', 'Sms', 'Email']}
            // onActionClick={(val, row) => actionClick(val, row)}

            actions={actions}
            onActionClick={(val, row) => actionClick(val, row)}
            actionUpdate={(val) => {
              if (val > 1) {
                setactions(['Delete'])
              }
              else {
                setactions(['Send sms', 'Send Email', 'Delete', 'Suspend', 'Restore'])
              }
            }}
            data={filteredIndiProvider}
            inputplaceholder='Filter by account name'
            props={Corporate_props}
            rowsPerPage={5}
            currentPage={0}
            itemsPerPage={10}
            NewData={NewData}
            more="providers"
            onFetchSuccess={(val) => setFilteredIndiProvider(val)}
            linkclicked={(item) => history.push(`/users/${item?.account_name}`)}
            refetchDefault={(val) => DefaultFetch(val)}
            property_name="Account Name"
            searchProperty={searchProperty}

          />
        </div>
      )}





      <DialogOutClick open={Showpop} handleClose={() => setShowpop(false)}>
        <div style={{ padding: "30px 0 20px ", width: "400px" }}>
          <div style={{ padding: "0 30px" }}>
            <div className="caution_card">
              <AiOutlineWarning color="red" size={24} />
              <div>Deleting a user, removes the user permanently from Dillivry </div>
            </div>

            <div className="cofirm-text">To confirm deletion, type delete in the field</div>
            <input
              type={"text"}
              value={permanentlyDelete}
              onChange={(e) => setpermanentlyDelete(e.target.value)}
              placeholder={"delete"}
              className="perm-input"
            />
          </div>
          <div className="btn-parent">

            <Buttoncard
              onClick={() => setShowpop(false)}
              text="Cancel"
              className="canceller"
            />
            {
              permanentlyDelete === "delete" ?
                <Buttoncard
                  onClick={handleDelete}
                  text="Delete"
                  loading={submitting}
                  className="deleter"
                />
                :
                <Buttoncard
                  text="Delete"
                  loading={submitting}
                  className="deleter_disable"
                />

            }

          </div>
        </div>
      </DialogOutClick>




      <DialogOutClick
        open={Showpopemail}
        handleClose={() => setShowpopemail(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p style={{ fontSize: '12px', color: 'black' }}>Complete the form below to send email to the selected users </p>

          <form onSubmit={handlesendEmial} style={{ marginTop: "10px", width: '100%' }}>
            <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "10px", width: '100%' }}
            >
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />{" "}
                Subject
              </InputLabel>
              <InputField
                required
                name="text"
                id="text"
                type="text"
                value={emailsubject}
                labelWidth={80}
                onChange={(e) => {
                  setemailsubject(e.target.value);
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl>
            {/* {
  console.log(item_to_modify,"item_to_modify")
} */}
            <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "20px", width: '100%' }}
            >
              <textarea
                style={{ borderRadius: "10px", height: "150px", padding: "12px 20px", outline: 'none', border: '1px solid #b2c2c2' }}
                placeholder="Message body"
                required
                value={emailtextarea}
                onChange={(e) => {
                  setemailtextarea(e.target.value);
                }}
              ></textarea>
            </FormControl>

            <div className="deletBtn">
              <button
                size="large"
                variant="contained"
                type="submit"
                className="send-btnnn"
              >
                <span className="btnlabel">
                  {submitting ? "loading..." : "Email"}{" "}
                </span>
              </button>
            </div>
          </form>
        </div>
      </DialogOutClick>




      <DialogOutClick
        open={Showpopsms}
        handleClose={() => setShowpopsms(false)}
      >
        <div style={{ padding: "30px", width: "300px", marginBottom: "20px" }}>
          <p>Complete the form below</p>

          <form className="cFrorm" onSubmit={handlesendSms}>
            <div>
              <div className="phoneTopic" style={{ marginTop: '15px', fontSize: '13px' }}>Phone Number</div>
              <PhoneNumber value={phone_num} setValue={setphone_num} disabled />
            </div>

            <FormControl variant="outlined">
              <textarea
                style={{ width: "50", height: "150px", padding: "15px", borderRadius: '10px' }}
                placeholder="SMS body"
                required
                value={smssubject}
                onChange={(e) => {
                  setsmssubject(e.target.value);
                }}
              ></textarea>
            </FormControl>

            {phone_num === "" ? (
              <button
                size="large"
                variant="contained"
                type="submit"
                disabled
                style={{
                  backgroundColor: "rgb(151, 199, 168)",
                  width: "100%",
                  marginTop: "10px",
                  color: "white",
                  borderRadius: "10px",
                  padding: "8px",
                  textTransform: "none",
                  border: '1px solid green'
                }}
              >
                <span className="btnlabel">
                  {submitting ? "loading" : "Send sms"}{" "}
                </span>
              </button>
            ) : (
              <button
                size="large"
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "var(--main)",
                  width: "100%",
                  marginTop: "10px",
                  color: "white",
                  borderRadius: "10px",
                  padding: "8px",
                  textTransform: "none",
                  border: '1px solid green'
                }}
              >
                {addingUser ? (
                  <PuffLoader
                    color="white"
                    loading={addingUser}
                    speedMultiplier={1}
                    size={24}
                  />
                ) : (
                  <span className="btnlabel">
                    {submitting ? "loading" : "Send sms"}
                  </span>
                )}
              </button>
            )}
          </form>
        </div>
      </DialogOutClick>






      <DialogOutClick
        open={Showpopsuspend}
        handleClose={() => setShowpopsuspend(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to suspend
            {/* {corporatedetails.given_name} */}
            ?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleSuspendacct}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpopsuspend(false)}
              text="No"
              className="success"
            />
          </div>
        </div>
      </DialogOutClick>



      <DialogOutClick
        open={Showpopenable}
        handleClose={() => setShowpopenable(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to Enable this user?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleenableacct}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpopenable(false)}
              text="No"
              className="success"
            />
          </div>
        </div>
      </DialogOutClick>





    </div>
  );
}