import React from 'react'
import "./App.css"
import Routing from './Routing'
import DataProvider from './context/DataContext/DataProvider'
import { ConnProvider } from './context/ConnContext'
import { DeliveriesProvider } from './context/Deliveries'
import { SettlementProvider } from './context/SettlementContext'
import { UserProvider } from './context/UserContext'
import { ProviderProvider } from './context/ProviderContext'
import { AgentProvider } from './context/AgentContext'
import { SenderProvider } from './context/SenderContext'
export default function App() {
  return (
    <div>
      <DataProvider>
        {/* <AgentProvider> */}
          <ConnProvider>
            <DeliveriesProvider>
              <UserProvider>
                <ProviderProvider>
                  <SettlementProvider>
                    <AgentProvider>
                      <SenderProvider>
                        <Routing/>
                      </SenderProvider>
                    </AgentProvider>
                  </SettlementProvider>
                </ProviderProvider>
              </UserProvider>
            </DeliveriesProvider>
          </ConnProvider>
        {/* </AgentProvider> */}
      </DataProvider>

          {/* <ConnProvider>
            <DeliveriesProvider>
              <UserProvider>
                <ProviderProvider>
                  <Routing />
                </ProviderProvider>
              </UserProvider>
            </DeliveriesProvider>
          </ConnProvider>
        </AgentProvider>
      </DataProvider> */}
    </div>
  )
}