import { Grid, Paper, Box, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import "./Quoted_View.css";
import { AiFillFileText } from "react-icons/ai";
import GoogleMapWidget from "../../components/Map/GoogleMapWidget";
import { Link, useHistory, useParams } from "react-router-dom";
import { DataContext } from "../../context/DataContext/DataProvider";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Mode from "../../assets/Mode.svg";
import Category from "../../assets/Category.svg";
import Pickup from "../../assets/Pickup.svg";
import Document from "../../assets/Document.svg";
import Distance from "../../assets/Distance.svg";
import Destination from "../../assets/Destination.svg";
import userAdd from "../../assets/user-add.png";
import smsicon from "../../assets/smsicon.svg";
import profile from "../../assets/profile-circle.png";
import Breadcrumbs from '../../components/Breadcrumbs'
import Navbar from "../../components/Navbar";
import copy from "../../assets/copy.png";
import DialogPop from "../AdminUsers/DialogPop";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineEdit } from "react-icons/md";
import InputField from "../../InputField";
import { RiLockPasswordLine } from "react-icons/ri";
import EditIcon from "@mui/icons-material/Edit";
import {
  FaShuttleVan,
  FaTruck,
  FaMotorcycle,
  FaGripHorizontal,
  FaChair,
  FaObjectGroup,
} from "react-icons/fa";
import { AiOutlineHome, AiOutlineDropbox, AiFillCar } from "react-icons/ai";
import { GiTotem, GiMechanicGarage } from "react-icons/gi";
import { CgDatabase } from "react-icons/cg";
import { MdPayment } from "react-icons/md";
import { ConnContext } from "../../context/ConnContext";
import moment from "moment";
import SelectSearch, { fuzzySearch } from "react-select-search";
import ReactTooltip from "react-tooltip";
import { IconContext } from "react-icons";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RiEdit2Fill } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { border } from "@mui/system";
import PuffLoader from "react-spinners/PuffLoader";
import { Refresh } from "@mui/icons-material";
import { DeliveryContext } from "../../context/Deliveries";
import { useLocation } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

import TableWithPagination from "../../components/DefaultTableFolder/TablePaginated";
import { BiMessageRoundedDots } from "react-icons/bi";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Quoted_View() {
  let { id } = useParams();
  const { visitingCountryCode } = useContext(ConnContext);
  const history = useHistory();

  const [activeDeliveriesDup, setActiveDeliveriesDup] = useState([]);
  const [activeDeliveries, setActiveDeliveries] = useState([]);
  const [paginateActivePage, setPaginateActivePage] = useState(1);
  const [allprovider, setallprovider] = useState([]);
  const {
    updatedeliveries,
    deliveriesnew,
    setallDeliveries,
    setquotedDeliveries,
    allDeliveries,
  } = useContext(DeliveryContext);
  const [itemLength, setitemLength] = useState(0);
  // const [perpage, setperpage] = React.useState(50);
  const [origin, setorigin] = useState([]);
  const [destination, setdestination] = useState([]);
  const { updateData, datas } = useContext(DataContext);
  const [loading, setloading] = useState(false);
  const endPoint = process.env.REACT_APP_API;
  const [itemDetails, setitemDetails] = useState([]);
  const [itemDetailsquote, setitemDetailsquote] = useState([]);
  const [showpopup, setShowpopup] = useState(false);
  const [typeclicked, settypeclicked] = useState("");
  const [updatepayment, setupdatepayment] = useState(false);
  const [withdrawquote, setwithdrawquote] = useState(false);
  const [showupdate, setShowupdate] = useState(false);
  const [catIsValid, setcatIsValid] = useState(false);
  const [amount, setamount] = useState("");
  const [amountpaid, setamountpaid] = useState("");
  const [deliveryId, setdeliveryId] = useState();
  const [deliveryIdwithdraw, setdeliveryIdwithdraw] = useState("");
  const [fulfilername, setfulfilename] = useState("");
  const [fulfilernamewithdraw, setfulfilenamewithdraw] = useState("");
  const [provider, setprovider] = useState("");
  const [selectvalue, setSelectvalue] = useState("");

  const [perpage, setperpage] = useState(15);
  const [dataToShow, setdataToShow] = useState([]);
  const [item_category, setitem_category] = useState("");
  const [assigning, setassigning] = useState(false);
  const [updating, setupdating] = useState(false);

  const [itemdescUpdate, setitemdescUpdate] = useState("");

  const [required, setrequired] = useState(false);
  const [isloading, setisloading] = useState(false);

  const [quotename, setQuotename] = useState('')

  const n = 15;

  // for clicking of button
  const [currentIndex, setCurrentIndex] = useState(0);
  const [datatouse, setDatatouse] = useState();
  const [dataIds, setDataIds] = useState();
  const [backDataIds, setBackDataIds] = useState();

  // const [currentElement, setCurrentElement] = useState(dataIds);

  // States for next and back button
  // const pageNumberLimit = individualdata?.length;
  // const [maxPageLimit, setMaxPageLimit] = useState(individualdata?.length);
  // const [minPageLimit, setMinPageLimit] = useState(0);
  // const [currentPage, setCurrentPage] = useState(itemDetails?.length) // should be filtered data length

  // const uBackupdata = location.backupdata
  // console.log(uBackupdata, 'ubavckkkkk')

  //   const handleNextClick = ()=>{
  //     if(currentPage+1 > maxPageLimit){
  //         setMaxPageLimit(maxPageLimit + pageNumberLimit);
  //         setMinPageLimit(minPageLimit + pageNumberLimit);
  //     }
  //     setCurrentPage(prev=>prev+1);
  //     history.push({
  //       pathname: `/delivery/${filteredArr.delivery_id}`,
  //     });
  //  }

  // console.log(filteredArr, "filtered now");
  // console.log(pageNumberLimit, "pageeeenn");

  // end

  const table_data = [
    'Quote Amount',
    'Logistics Provider',
    'Date',
    'Quote Status',
    'Payment Status',
    'Message'
  ]

  const Refresh = () => {
    window.location.reload(true);
  };
  const options = allprovider?.map((str, index) => ({ value: str, name: str }));
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: `100%`,
    },
  }));

  const location = useLocation();

  // end

  const authAxios = Axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });

  const classes = useStyles();

  // update a delivery description
  const updateItemDesc = (e) => {
    e.preventDefault();
    setisloading(true);

    authAxios
      .patch(`${endPoint}adminportal/v3/ng/item_desc_update`, {
        delivery_id: itemDetails[0]?.delivery_id,
        item_desc: itemdescUpdate,
      })
      .then((response) => {
        setisloading(false);
        setShowupdate(false);
        Swal.fire({
          icon: "success",
          text: `Item description updated successfully`,
          showConfirmButton: "true",
          confirmButtonColor: "var(--main)",
        });
        Refresh();
      })
      .catch((error) => {
        setisloading(false);
        setShowupdate(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };

  const handleUpdatePayment = () => {
    if (deliveryId === "" || fulfilername === "" || amountpaid === "") {
      setrequired(true);
    } else {
      setassigning(true);
      authAxios
        .post(`v2/m-dillivry-admin-portal`, {
          payment_type: "cash",
          delivery_id: deliveryId,
          fulfiller_id: fulfilername,
          amount_paid: amountpaid,
          payment_gateway: "NA",
        })
        .then((res) => {
          setassigning(false);
          setShowpopup(false);
          setdeliveryId("");
          setfulfilename("");
          setamountpaid("");
          setupdatepayment(false);
          Swal.fire({
            icon: "success",
            text: `Payment update successfully`,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        })
        .catch((err) => {
          setassigning(false);
          setupdatepayment(false);
          setdeliveryId("");
          setfulfilename("");
          setamountpaid("");
          console.log(err);
          console.log(err.response);

          if (err) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.msg}`,
              showConfirmButton: "true",
              confirmButtonColor: "grey",
            });
          }
        });
    }
  };

  const handleQuoteWithdraw = () => {
    if (deliveryIdwithdraw === "" || fulfilernamewithdraw === "") {
      setrequired(true);
    } else {
      setassigning(true);
      authAxios
        .patch(`m-dillivry-admin-portal`, {
          fulfiller_id: fulfilernamewithdraw,
          delivery_id: deliveryIdwithdraw,
        })
        .then((res) => {
          setassigning(false);
          // setShowpopup(false);
          setdeliveryIdwithdraw("");
          setfulfilenamewithdraw("");

          setwithdrawquote(false);
          Swal.fire({
            icon: "success",
            text: ` Quote withdraw  successfully`,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        })
        .catch((err) => {
          setassigning(false);
          setdeliveryIdwithdraw("");
          setfulfilenamewithdraw("");

          setwithdrawquote(false);
          console.log(err);
          console.log(err.response);

          if (err) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.msg}`,
              showConfirmButton: "true",
              confirmButtonColor: "grey",
            });
          }
        });
    }
  };

  const HandleAssignSubmission = () => {
    if (provider === "" || amount === "") {
      setrequired(true);
    } else {
      setassigning(true);
      authAxios
        .post(`v2/m-dillivry-platform-agent-assignment?delivery_location=ng`, {
          action_type: `${typeclicked === "Assign" ? "assign_agent" : "re_assign"
            }`,
          delivery_id: itemDetails[0]?.delivery_id,
          quote_amount: amount,
          fulfiller_id: provider,
          delivery_status: itemDetails[0]?.delivery_status,
        })
        .then((res) => {
          setShowpopup(false);
          Swal.fire({
            icon: "success",
            text: `Item assign to ${provider}`,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
            timer: 3000,
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        })
        .catch((err) => {
          setShowpopup(false);

          // .catch((err) => {
          //   setShowpopup(false);
          //   Swal.fire({
          //     icon: "error",
          //     text: `An error occured`,
          //     showConfirmButton: "true",
          //     confirmButtonColor: "red",
          //   })
          // })
          // .finally(() => setassigning(false))

          if (err) {
            console.log(err.response)
            setShowpopup(false);
            Swal.fire({
              icon: "error",
              text: `An error occured`,
              showConfirmButton: "true",
              confirmButtonColor: "red",
            })
          }

          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: ` ${err?.response?.data?.msg}`,
              showConfirmButton: "true",
              confirmButtonColor: "red",
              // timer: 3000
            }).then((result) => {
              if (result.value) {
                window.location.reload();
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              text: ` ${err}`,
              showConfirmButton: "true",
              confirmButtonColor: "red",
            }).then((result) => {
              if (result.value) {
                window.location.reload();
              }
            });
          }
        })
        .finally(() => setassigning(false));
    }
  };

  useEffect(() => {
    // console.log(location, "locationnn");

    // console.log(location, 'locationnn')
    // console.log(location.backupdata, 'backupdata')

    authAxios
      .post(`v2/m-dillivry-admin-portal`, {
        action_type: "get_providers",
      })
      .then((res) => {
        setallprovider(res?.data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.data);
      });
  }, [visitingCountryCode]);

  const handleButtonClick = (data) => {
    console.log(data, "datttta");
    // eslint-disable-next-line array-callback-return
    const newElement = data.map((element, index) => {
      if (index === currentIndex) {
        setCurrentIndex(currentIndex + 1);
        history.push(`/deliveries/${element}`);
        window.location.reload(true);
      }
    });
  };

  const handleGoBack = (data) => {
    //eslint-disable-next-line array-callback-return

    const newElement = data.map((element, index) => {
      if (index === currentIndex) {
        setCurrentIndex(currentIndex + 1);
        history.push(`/deliveries/${element}`);
        window.location.reload(true);
      }
      if (currentIndex === data.length - 1) {
        setCurrentIndex(0);
        history.push("/deliveries");
      }
    });
  };

  const getValues = (obj) => {
    return Object.values(obj)
  }

  const getKeys = (obj) => {
    return Object.keys(obj)
  }

  const updatePage = (datum) => {
    // to get the delivery id of the quote clicked
    // const filteredid = filterQuoted[0]?.delivery_id;
    const filteredid = datum?.data?.data[0]?.delivery_id;

    console.log(datum.data.data[0].quote, 'newdatumm--')

    const delivery_quote = datum?.data?.data[0]?.quote;
    const key = Object.keys(delivery_quote)[0];

    console.log(key, 'key')
    const customer = delivery_quote[key];

    console.log(delivery_quote, "delivery_quote----");

    setQuotename(key)
    setitemDetailsquote(delivery_quote);
    setitemDetails(datum?.data?.data);
    setdeliveryId(datum?.data?.data[0]?.delivery_id);

    console.log(location, "location");

    const availabledata = location?.backupdata
      ? location?.backupdata
      : JSON.parse(localStorage.getItem("backupdata"));

    console.log(availabledata, "availabledataaaa");

    console.log(datum?.data?.data[0], 'datattta');

    const startLat = parseFloat(datum?.data?.data[0]?.start_location.lat);
    const startlng = parseFloat(datum?.data?.data[0]?.start_location.lng);
    const endLat = parseFloat(datum?.data?.data[0]?.end_location.lat);
    const endLng = parseFloat(datum?.data?.data[0]?.end_location.lng);

    console.log(startLat, 'startlat', startlng, 'startlng', endLat, 'endLat', endLng, 'endLag')

    setorigin([
      {
        id: "pickup",
        title: "Pickup",
        coordinates: {
          lat: startLat,
          lon: startlng,
        },
      },
    ]);
    setdestination([
      {
        id: "destination",
        title: "Destination",
        coordinates: {
          lat: endLat,
          lon: endLng,
        },
      },
    ]);

    // to filter out the element in the list of array and get other elements after it
    const loopedArr = availabledata?.filter((item, index) => {
      return (
        index >=
        availabledata.findIndex((obj) => obj.delivery_id === filteredid) &&
        index <
        availabledata.findIndex((obj) => obj.delivery_id === filteredid) +
        availabledata.length
      );
    });

    const loopedBackArr = availabledata
      ?.map((object) => object.delivery_id)
      .indexOf(filteredid);

    const backwardarray = availabledata?.filter(
      (element, index) => index < loopedBackArr
    );

    const removedArr = loopedArr.filter((x, i) => {
      return x?.delivery_id !== filteredid;
    });

    setDatatouse(removedArr);

    const getMyId = removedArr?.map((loop) => loop.delivery_id);

    const getBackwardId = backwardarray
      .map((element) => element.delivery_id)
      .reverse();

    setDataIds(getMyId);
    setBackDataIds(getBackwardId);

    setdataToShow(Object.entries(datum?.data.data[0].quote).slice(0, perpage));
    // setActiveDeliveries([...datum?.data?.data[0]?.data].slice(0, perpage));
    setActiveDeliveriesDup(datum?.data?.data[0]?.data);
    // setitemLength(datum?.data?.data[0]?.data.length);
  };

  useEffect(() => {
    if (localStorage?.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage?.clear();
            history.push("/");
          }
        });
      } else {
        setloading(true)
        if (
          allDeliveries?.length !== 0 &&
          allDeliveries[0] !== undefined &&
          allDeliveries === undefined
        ) {
          updatePage(allDeliveries);
          setloading(false)
        } else {
          setloading(true);
          // authAxios
          //   .get(
          //     `${endPoint}platform/v3/ng/delivery_details?delivery_id=${id}`,
          //   )
          authAxios
            .get(
              `${endPoint}admin/v1/ng/delivery_details?delivery_id=${id}`,
            )
            .then((response) => {
              console.log(response, "response");
              setloading(false);
              updatePage(response);
              updatedeliveries(response);
              // setallDeliveries(response);
            });
        }
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);

  const [pageSize, setPageSize] = useState(10);
  const handleGetRowId = (e) => {
    return e.provider;
  };

  // const columns = [
  //   {
  //     field: "quote_amount",
  //     headerName: "QuoteAmount",
  //     renderCell: (params) => <div>&#8358;{params.rows.quote_amount}</div>,
  //   },

  //   {
  //     field: "quote_amount",
  //     headerName: "Quote Amount",
  //     width: 200,
  //   },
  //   {
  //     field: "provider",
  //     headerName: "Logistics provider",
  //     width: 200,
  //   },
  //   {
  //     field: "time_stamp",
  //     headerName: "Date",
  //     width: 200,
  //     renderCell: ({ value }) => (
  //       <>
  //         {moment(value)?.format("llll").slice(0, 3)}
  //         {moment(value)?.format("llll").slice(4, 12)}
  //         {moment(value)?.format("llll").slice(17, 27)}
  //       </>
  //     ),
  //   },
  //   {
  //     field: "quote_status",
  //     headerName: "Quote Status",
  //     renderCell: (params) => (
  //       <span>
  //         {params.row.quote_status.charAt(0)?.toUpperCase() +
  //           params.row.quote_status.slice(1).replace(/_/g, " ")}
  //       </span>
  //     ),
  //     width: 200,
  //   },
  //   {
  //     field: "Providers",
  //     headerName: "Providers info",
  //     width: 200,
  //     renderCell: (params) => (
  //       <img
  //         src={profile}
  //         onClick={() => history.push(`/users/${params.id}`)}
  //         style={{ paddingLeft: "20px" }}
  //         alt=""
  //       />
  //     ),
  //   },
  //   {
  //     field: "message",
  //     headerName: "Message",
  //     width: 200,
  //     renderCell: (params) => (
  //       <img
  //         alt=""
  //         src={smsicon}
  //         onClick={() => history.push(`/messages/view`)}
  //         style={{ paddingLeft: "20px" }}
  //       />
  //     ),
  //   },
  // ];



  return (
    <div className="quoted_view_con">
      {
        console.log(itemDetails?.delivery_id, "itemdetails----")
      }
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "none",
            position: "fixed",
            top: "0",
            paddingTop: "10px",
            zIndex: "10000",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : null}
      <div className="qoute-nav">
        <Navbar
          role={itemDetails?.delivery_type}
          dashboardtext="Back to dashboard"
          familyname={localStorage.getItem("fam")}
          givenname={localStorage.getItem("name")}
          page={location.state}
          state={localStorage.getItem("pagenumber")}
          forwardtext="Go Forward"
          forwarddata={itemDetails}
        />
        {/* <h2 onClick={handleNextClick}>Next</h2> */}
      </div>

      <Breadcrumbs/>

      {itemDetails?.map((itemDetail, index) => {
        return (
          <div key={index} className="delivery-wrapper">
            <div className="topper">
              <span>
                Category:{" "}
                {itemDetail?.item_category.charAt(0)?.toUpperCase() +
                  itemDetail?.item_category.slice(1).replace(/_/g, " ")}
              </span>
              <span>ID: {itemDetail?.delivery_id}</span>

              <div className="arrow__swipe">
                <div className="arrow__swipe--left">
                  <BsArrowLeft
                    onClick={() =>
                      console.log(
                        datatouse,
                        "datatouse",
                        handleGoBack(backDataIds),
                        "getmyid"
                      )
                    }
                    className="arrow__icon"
                  />
                  <p>Prev</p>
                </div>
                <div className="arrow__swipe--right">
                  <BsArrowRight
                    onClick={() =>
                      console.log(
                        datatouse,
                        "datatouse",
                        handleButtonClick(dataIds),
                        "getmyid"
                      )
                    }
                    className="arrow__icon"
                  />
                  <p>Next</p>
                </div>
              </div>
            </div>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <div className="image-quote">
                    <div className="item_imges_con">
                      <div
                        className="item_imges"
                        style={{
                          backgroundImage: `url(${itemDetail?.image_urls[0]})`,
                        }}
                      ></div>
                    </div>
                  </div>
                </Grid>
                <Grid className="google__map--container" item xs={12} sm={8}>
                  {/* <Item> */}
                    {/* {
                  console.log(destination, 'destination')
                }
                {
                  console.log(origin, 'origin')
                } */}
                    <div className="view_Map">
                      <GoogleMapWidget
                        origins={destination}
                        destinations={origin}
                      />
                    </div>
                  {/* </Item> */}
                </Grid>
              </Grid>
            </Box>

            <div className="item_detail">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4.5} md={4.5}>
                  <Item sx={{ textAlign: "unset !important", p: 2 }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Delivery infomation
                    </Typography>
                    <div className="itemDescs">
                      <div className="itemDesc itemDescTop">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Document} alt="mode" />
                          </div>
                          <div>
                            {itemDetail?.item_desc.charAt(0)?.toUpperCase() +
                              itemDetail?.item_desc.slice(1).replace(/_/g, " ")}
                          </div>
                        </div>

                        <div onClick={() => setShowupdate(true)}>
                          <EditIcon fontSize="small" />
                        </div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Category} alt="mode" />
                          </div>
                          <div>
                            {itemDetail?.item_category.charAt(0)?.toUpperCase() +
                              itemDetail?.item_category
                                .slice(1)
                                .replace(/_/g, " ")}
                          </div>
                        </div>

                        <div></div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Pickup} alt="mode" />
                          </div>
                          <div>
                            {itemDetail?.pickup_details?.pickup_address}
                          </div>
                        </div>
                        <div></div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Destination} alt="mode" />
                          </div>
                          <div>
                            {itemDetail?.delivery_details?.delivery_address}
                          </div>
                        </div>
                        <div></div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <span>
                              <AiFillFileText size={16} color="var(--main)" />{" "}
                            </span>
                          </div>
                          <div>
                            {itemDetail?.delivery_status
                              ?.charAt(0)
                              ?.toUpperCase() +
                              itemDetail?.delivery_status
                                ?.slice(1)
                                .replace(/_/g, " ")}
                          </div>
                        </div>
                        <div></div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Distance} alt="mode" />
                          </div>
                          <div>{itemDetail?.distance}</div>
                        </div>
                        <div></div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Mode} alt="mode" />
                          </div>
                          <div>
                            {itemDetail?.mode_of_delivery
                              ? itemDetail?.mode_of_delivery
                                ?.charAt(0)
                                ?.toUpperCase() +
                              itemDetail?.mode_of_delivery
                                ?.slice(1)
                                .replace(/_/g, " ")
                              : itemDetail?.mode_of_movement
                                ?.charAt(0)
                                ?.toUpperCase() +
                              itemDetail?.mode_of_movement
                                ?.slice(1)
                                .replace(/_/g, " ")}
                          </div>
                        </div>
                        <div></div>
                      </div>

                      {itemDetail?.delivery_status === "awaiting_quote" ||
                        itemDetail?.delivery_status === "delivered" ? null : (
                        <div className="itemDesc">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div>
                              <img src={Document} alt="mode" />
                            </div>
                            <div>
                              {Object.values(itemDetail?.quote).map(
                                (x, index) => {
                                  return x?.quote_status === "accepted" ||
                                    x?.quote_status === "out_for_delivery" ||
                                    x?.quote_status === "missed_delivery" ? (
                                    <span className="icon-desc">
                                      {x?.payment_status === "success"
                                        ? "Paid"
                                        : x?.payment_status ===
                                          "pay_on_delivery"
                                          ? "Pay on delivery"
                                          : x?.payment_status}
                                    </span>
                                  ) : null;
                                }
                              )}
                            </div>
                          </div>
                          <div></div>
                        </div>
                      )}
                      {itemDetail?.payer ? (
                        <div className="itemDesc" style={{ border: "0px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div>
                              <MdPayment
                                style={{
                                  color: "var(--main",
                                  fontSize: "18px",
                                }}
                              />
                            </div>
                            {itemDetail?.payer ? (
                              <div>Payer: {itemDetail?.payer}</div>
                            ) : null}
                          </div>
                          <div></div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={4.5} md={4.5}>
                  <Item sx={{ textAlign: "unset !important", p: 2 }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Sender/Reciever infomation
                    </Typography>
                    <div
                      className="sender_data_con"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        gap: "15px",
                      }}
                    >
                      <div className="itemDescs">
                        <div className="itemDesc itemDescTop">
                          <b>Sender's name:</b>
                          <span className="">
                            {itemDetail?.sender_contact?.sender_name}
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b> Sender's email:</b>
                          <span className="">
                            {itemDetail?.sender_contact?.sender_email}
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b> Sender's number:</b>
                          <span className="">
                            {
                           itemDetail?.sender_contact?.sender_phone_no !== "" ? itemDetail?.sender_contact?.sender_phone_no?.match(
                                /\d+/g
                              )[0] : <p>NA</p>
                            }
                           
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b>Reciever's name:</b>
                          <span className="">
                            {
                              itemDetail?.delivery_details?.delivery_contact
                                .delivery_name
                            }
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b>Reciever's email:</b>
                          <span className="">
                            {
                              itemDetail?.delivery_details?.delivery_contact
                                .delivery_email
                            }
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b>Reciever's number:</b>
                          <span className="">
                            {
                             
                              itemDetail?.delivery_details?.delivery_contact.delivery_phone_no !== "" ? itemDetail?.delivery_details?.delivery_contact.delivery_phone_no?.match(
                                  /\d+/g
                                )[0] : <p>NA</p>
                            }
                          </span>
                        </div>

                        <div className="itemDesc">
                          <b>Pickup's name:</b>
                          <span className="">
                            {
                              itemDetail?.pickup_details?.pickup_contact
                                ?.pickup_name
                            }
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b>Pickup's email:</b>
                          <span className="">
                            {
                              itemDetail?.pickup_details?.pickup_contact
                                ?.pickup_email
                            }
                          </span>
                        </div>
                        <div className="itemDesc" style={{ border: "0px" }}>
                          <b> Pickup's number:</b>
                          <span className="">
                            {
                              // itemDetail?.pickup_details?.pickup_contact?.pickup_phone_no.match(
                              //   /\d+/g
                              // )[0]

                              itemDetail?.pickup_details?.pickup_contact?.pickup_phone_no !== "" ? itemDetail?.pickup_details?.pickup_contact?.pickup_phone_no?.match(
                                /\d+/g
                              )[0] : <p>NA</p>
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  {itemDetail?.delivery_status === "awaiting_quote" &&
                    Object.getOwnPropertyNames(itemDetail?.quote)?.length !==
                    0 ? (
                    <Button
                      onClick={() => setwithdrawquote(true)}
                      sx={{ gap: 2, color: "#000", display: "flex" }}
                    >
                      <img src={userAdd} alt="user Add" />
                      Withdraw quote
                    </Button>
                  ) : null}

                  {/* <Button
                    onClick={() => {
                      return setShowpopup(true), settypeclicked("Assign");
                    }}
                    sx={{ gap: 2, color: "#000", display: "flex" }}
                  > */}
                    {itemDetail?.delivery_status === 'draft' || itemDetail?.delivery_status === 'delivered' ?
                      null :
                      <>
                       <Button
                    onClick={() => {
                      return setShowpopup(true), settypeclicked("Assign");
                    }}
                    sx={{ gap: 2, color: "#000", display: "flex" }}
                  >
                                          <img src={userAdd} alt="user Add" /> Assign provider

                    </Button>

                    <Button
                      onClick={() => {
                        return setShowpopup(true), settypeclicked("ReAssign");
                      }}
                      sx={{ gap: 2, color: "#000", display: "flex" }}
                    >
                      <img src={userAdd} alt="user Add" /> Re-assign provider
                    </Button>
                      </>}
                    {/* <img src={userAdd} alt="user Add" /> { itemDetail?.delivery_status !== 'draft' ? <span>Assign provider</span>  : <span></span>} */}

                  {/* {itemDetail?.delivery_status !== "awaiting_quote" ? (
                    <Button
                      onClick={() => {
                        return setShowpopup(true), settypeclicked("ReAssign");
                      }}
                      sx={{ gap: 2, color: "#000", display: "flex" }}
                    >
                      <img src={userAdd} alt="user Add" /> Re-assign provider
                    </Button>
                  ) : null} */}

{/* itemDetail?.delivery_status === "delivered" || */}


                  {itemDetail?.delivery_status ===
                    "awaiting_agent_assignment" ||
                    itemDetail?.delivery_status ===
                    "awaiting_provider_acknowledgment" ||
                    itemDetail?.delivery_status === "awaiting_pickup" ||
                    itemDetail?.delivery_status === "out_for_delivery" ||
                    itemDetail?.delivery_status === "picked_up" ||
                    itemDetail?.delivery_status === "missed_delivery" ? (
                    <Button
                      onClick={() => setupdatepayment(true)}
                      sx={{ gap: 2, color: "#000", display: "flex" }}
                    >
                      <img
                        src={userAdd}
                        alt="user Add"
                        onClick={() => setupdatepayment(true)}
                      />{" "}
                      Update payment
                    </Button>
                  ) : null}
                  <Item sx={{ textAlign: "unset !important", p: 2 }}>
                    <div className="itemDescs">
                      <div className="itemDesc">
                        <b> Pickup code : </b>
                        <span className="">
                          {Object.keys(itemDetail?.quote).map((key) => {
                            if (key.length !== 0) {
                              return itemDetail?.quote[key]?.pickup_code?.token;
                            }
                          })}
                        </span>
                      </div>
                      <div className="itemDesc" style={{ border: "0px" }}>
                        <b> Delivery code : </b>
                        <span className="">
                          {Object.keys(itemDetail?.quote).map((key) => {
                            if (key.length !== 0) {
                              return itemDetail?.quote[key]?.delivery_code
                                ?.token;
                            }
                          })}
                        </span>
                      </div>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </div>
            {Object.keys(itemDetail?.quote).length === 0 ? (
              <></>
            ) : (

            
              <div className="quote__table--container">
                <h3>Quote History</h3>
                <table className="quotehistory__table">
                  <thead>
                    <tr>
                      {
                        table_data?.map(header => (
                          <th>{header}</th>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody>

                    {
                      getValues(itemDetailsquote).map((val, i) => (
                        <tr>

                          <td>{val.quote_amount}</td>
                          <td className="quote__provider--link">
                            <Link to={`/users/${getKeys(itemDetailsquote)[i]}`}>
                              {getKeys(itemDetailsquote)[i]}
                            </Link>
                          </td>
                          <td>{moment(val.time_stamp).format("YYYY-MM-DD HH:mm")}</td>
                          <td>{val.quote_status}</td>
                          <td>{val.payment_status}</td>
                          <td>
                            <Link to={`/messages/view`}>
                              <BiMessageRoundedDots color="#555" size={25} />
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

            )}

            <DialogPop
              openPopup={withdrawquote}
              setopenPopup={setwithdrawquote}
              head="Withdraw quote"
            >
              <div className="app-">
                <div style={{ margin: "0 auto", width: "auto" }}></div>
              </div>

              <form style={{ margin: "0 auto", width: 300 }}>
                {required ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "15px",
                    }}
                  >
                    All field is required
                  </div>
                ) : (
                  <></>
                )}

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginBottom: "10px" }}
                >
                  <InputLabel id="select">
                    <span style={{ color: "var(--main)", marginRight: "10px" }}>
                      <FaGripHorizontal />
                    </span>
                    Delivery Id
                  </InputLabel>
                  <InputField
                    required
                    name="email"
                    id="email"
                    type="text"
                    value={deliveryIdwithdraw}
                    labelWidth={90}
                    onChange={(e) => {
                      setdeliveryIdwithdraw(e.target.value);
                      setrequired(false);
                    }}
                    icon={
                      <RiLockPasswordLine
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <div style={{ margin: "0 auto", width: "auto" }}>
                  <SelectSearch
                    options={options}
                    search
                    filterOptions={fuzzySearch}
                    placeholder="Select Provider"
                    value={fulfilernamewithdraw}
                    onChange={(e) => setfulfilenamewithdraw(e)}
                  />
                </div>

                <Button
                  style={{
                    background: "var(--main)",
                    fontSize: "13px",
                    textTransform: "none",
                    marginTop: "20px",
                    boxShadow: "none",
                    border: "1px solid var(--main)",
                  }}
                  color="success"
                  className="btn-"
                  size="small"
                  variant="contained"
                  onClick={handleQuoteWithdraw}
                  disabled={assigning}
                >
                  {assigning ? "Loading..." : "Withdraw quote"}
                </Button>
              </form>
            </DialogPop>

            <DialogPop
              openPopup={updatepayment}
              setopenPopup={setupdatepayment}
              head="Update cash payment"
            >
              <div className="app-">
                <div style={{ margin: "0 auto", width: "auto" }}></div>
              </div>

              <form style={{ margin: "0 auto", width: 300 }}>
                {required ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "15px",
                    }}
                  >
                    All field is required
                  </div>
                ) : (
                  <></>
                )}

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginBottom: "10px" }}
                >
                  <InputLabel id="select">
                    <span style={{ color: "var(--main)", marginRight: "10px" }}>
                      <FaGripHorizontal />
                    </span>
                    Delivery Id
                  </InputLabel>
                  <InputField
                    required
                    type="text"
                    value={deliveryId}
                    labelWidth={100}
                    disabled
                    onChange={(e) => {
                      setdeliveryId(e.target.value);
                      setrequired(false);
                    }}
                    icon={
                      <RiLockPasswordLine
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <div style={{ margin: "0 auto", width: "auto" }}>
                  <SelectSearch
                    options={options}
                    search
                    filterOptions={fuzzySearch}
                    placeholder="Select Provider"
                    value={fulfilername}
                    onChange={(e) => setfulfilename(e)}
                  />
                </div>

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                // style={{marginBottom: "30px"}}
                >
                  <InputLabel id="select">
                    <span style={{ color: "var(--main)", marginRight: "10px" }}>
                      <FaGripHorizontal />
                    </span>
                    Amount Paid
                  </InputLabel>
                  <InputField
                    required
                    name="email"
                    id="email"
                    type="number"
                    value={amountpaid}
                    labelWidth={120}
                    onChange={(e) => {
                      setamountpaid(e.target.value);
                      setrequired(false);
                    }}
                    icon={
                      <RiLockPasswordLine
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <Button
                  style={{
                    background: "var(--main)",
                    fontSize: "13px",
                    textTransform: "none",
                    marginTop: "20px",
                    boxShadow: "none",
                    border: "1px solid var(--main)",
                  }}
                  color="success"
                  className="btn-"
                  size="small"
                  variant="contained"
                  onClick={handleUpdatePayment}
                  disabled={assigning}
                >
                  {assigning ? "Loading..." : "Update payment"}
                </Button>
              </form>
            </DialogPop>

            <DialogPop
              openPopup={showpopup}
              setopenPopup={setShowpopup}
              head="Assign to provider"
            >
              <div className="app-">
                <div style={{ margin: "0 auto", width: "auto" }}>
                  <SelectSearch
                    options={options}
                    search
                    filterOptions={fuzzySearch}
                    placeholder="Select Provider"
                    value={provider}
                    onChange={(e) => setprovider(e)}
                  />
                </div>
              </div>

              <form>
                {required ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "15px",
                    }}
                  >
                    All field is required
                  </div>
                ) : (
                  <></>
                )}

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="select">
                    <span style={{ color: "var(--main)", marginRight: "10px" }}>
                      <FaGripHorizontal />
                    </span>
                    Quote Amount
                  </InputLabel>
                  <InputField
                    required
                    name="email"
                    id="email"
                    type="number"
                    value={amount}
                    labelWidth={130}
                    onChange={(e) => {
                      setamount(e.target.value);
                      setrequired(false);
                    }}
                    icon={
                      <RiLockPasswordLine
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <Button
                  style={{
                    background: "var(--main)",
                    fontSize: "13px",
                    textTransform: "none",
                    marginTop: "20px",
                    boxShadow: "none",
                    border: "1px solid var(--main)",
                  }}
                  color="success"
                  className="btn-"
                  size="small"
                  variant="contained"
                  onClick={HandleAssignSubmission}
                  disabled={assigning}
                >
                  {assigning ? "Loading..." : "Assign"}
                </Button>
              </form>
            </DialogPop>
            <DialogPop
              openPopup={showupdate}
              setopenPopup={setShowupdate}
              head="Update Description"
            >
              <div className="update_itemdesc_con">
                <form className="update_itemdesc_form">
                  <textarea
                    type="text"
                    style={{ outlineColor: "var(--main)" }}
                    placeholder="Update item desc.."
                    onChange={(e) => {
                      setitemdescUpdate(e.target.value);
                    }}
                    value={itemdescUpdate}
                    className="update_itemdesc_text"
                  />
                  <div className="update_itemdesc_btncon">
                    {itemdescUpdate === "" || itemdescUpdate === undefined ? (
                      <button disabled className="update_itemdesc_btn">
                        Update
                      </button>
                    ) : (
                      <button
                        onClick={updateItemDesc}
                        className="update_itemdesc_btn"
                      >
                        {isloading ? (
                          <PuffLoader
                            color="white"
                            loading={isloading}
                            speedMultiplier={1}
                            size={34}
                          />
                        ) : (
                          <span>Update</span>
                        )}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </DialogPop>

            <ReactTooltip
              id="mode"
              aria-haspopup="true"
              type="light"
              role="example"
            >
              <p>Edit item description</p>
            </ReactTooltip>
          </div>
        );
      })}
    </div>
  );
}

export default Quoted_View;