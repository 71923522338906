import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Avatar,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Pagination,
  TablePagination,
} from "@mui/material";
import exporticon from "../../assets/exporticon.svg";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "jspdf-autotable";
import { useHistory } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
// import { DataContext } from "../../context/DataContext/DataProvider";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import Axios from "axios";
import { Box } from "@mui/system";
import { useReactToPrint } from "react-to-print";
import { SettlementContext } from "../../context/SettlementContext";
import { CSVLink, CSVDownload } from "react-csv";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import TableWithPagination from "../../components/DefaultTableFolder/TablePaginated";
import moment from "moment";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function Transactons() {
  const {
    settdata,
    updateConSettlementData,
    last_evaluated_key,
    setlast_evaluated_key,
  } = useContext(SettlementContext);
  const numberWithComma = new Intl.NumberFormat("en-GB");
  const [isloading, setIsloading] = useState(false);
  const history = useHistory();
  const endPoint = process.env.REACT_APP_API;
  const [page1, setPage1] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const [searchstate, setSearchstate] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState("");

  const [rowsPerPage1, setRowsPerPage1] = useState(20);
  const [lastpage, setLastPage] = useState(false);

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value));
    setPage1(0);
    setPageSize(parseInt(event.target.value));
  };

  // const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const updateDataView = (datum) => {
    setdataToShow(datum.slice(0, perpage));
    setitemLength([...datum].length);
  };
  // const handlevalue = (event) => {
  //   console.log(event.target.value);
  //   setperpage(event.target.value);
  //   setdataToShow([...transactionData].slice(0, event.target.value))
  //   console.log(transactionData.slice(0, event.target.value))
  // };

  const [search, setsearch] = useState("");

  const filterData = (e) => {
    setsearch(e.target.value.toLowerCase());
    const filteredData = transactionData.filter((names) =>
      names.fulfiller_id.toLowerCase().includes(e.target.value.toLowerCase())
    );
    updateDataView(filteredData);
  };

  const [paginateActivePage, setPaginateActivePage] = useState(1);
  const n = 50;
  const [itemLength, setitemLength] = useState(0);
  const [perpage, setperpage] = React.useState(50);
  const [transactionData, settransactionData] = useState([]);
  const [dataToShow, setdataToShow] = useState([]);

  const [item_to_modify, setitem_to_modify] = useState([]);

  const actionClick = (val, row) => {
    setitem_to_modify(row);
    if (val === "Update") {
      console.log(row, val);
    } else if (val === "Email") {
    } else if (val === "Sms") {
    } else if (val === "Suspend") {
    } else if (val === "Restore") {
    } else if (val === "Delete") {
    }
  };

  const Transaction_props = [
    "Date",
    "Name",
    "Type",
    "ID",
    "Gross revenue",
    "Net revenue",
    "Platform commission",
    "Payment status",
  ];

  const NewData = [
    "time_stamp",
    // moment(time_stamp).format("YYYY-MM-DD HH:mm:ss"),
    "fulfiller_id",
    "delivery_type",
    "reference_id",
    "gross_amount",
    "amount_due_to_fulfiller",
    "amount_due_to_platform",
    "payment_status",
  ];

  const totalPages = Math.ceil(transactionData?.length / rowsPerPage1);

  useEffect(() => {
    if (page1 >= totalPages) {
      setPage1(0);
    } else if (page1 === totalPages - 1) {
      setLastPage(true);
    } else {
      setLastPage(false);
    }
  }, [page1, totalPages]);

  // const Datatouse = transactionData.map((x, i) => {
  //   return (

  //     [
  //       [x?.payment_date].join(""),
  //       [x?.fulfiller_id].join(""),

  //       [[x?.delivery_type === "bid" ? "Quoted" : x.delivery_type === "nobid" ? "Booked" : null]].join(""),

  //       [x?.transaction].join(""),
  //       [[numberWithComma.format(x?.gross_amount)]].join(""),
  //       [[numberWithComma.format(x?.amount_due_to_fulfiller)]].join(""),
  //       [[numberWithComma.format(x?.amount_due_to_platform)]].join(""),
  //       [x?.payment_status].join(""),

  //     ]
  //   );
  // });

  // const realresult = Datatouse.map((x) => ({
  //   Date: x[0],
  //   Name: x[1],
  //   Type: x[2],
  //   ID: x[3],
  //   GrossRevenue: x[4],
  //   NetRevenue: x[5],
  //   PlatformCommission: x[6],
  //   PaymentStatus: x[7],

  // }));

  useEffect(() => {
    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        console.log("session expired");
        setIsloading(false);
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            history.push(`/`);
            setIsloading(false);
            localStorage.clear();
          }
        });
      } else if (settdata !== undefined) {
        console.log(settdata, "setdataaaaa");
      } else {
        setIsloading(true);
        // Axios.get(`${endPoint}platform/v3/ng/transactions`, {
        Axios.get(`${endPoint}admin/v1/ng/transactions`, {
          headers: {
            Authorization: localStorage.getItem("o_kj_"),
          },
        })
          .then((response) => {
            console.log(response, 'response---')
            setlast_evaluated_key(response?.data?.data[0]?.last_evaluated_key);
            setIsloading(false);
            updateDataView(response?.data?.data[0]?.data);
            settransactionData(response?.data?.data[0]?.data);
          })
          .catch((err) => {
            console.log(err);

            if (err?.response?.status === 403) {
              setIsloading(false);
              Swal.fire({
                icon: "error",
                title: "Session Timeout",
                showCancelButton: false,
              });
              history.push(`/`);
            } else {
              setIsloading(false);
              Swal.fire({
                icon: "error",
                title: "error",
                allowOutsideClick: false,
                confirmButtonColor: "var(--main)",
                showCancelButton: false,
              }).then((result) => {
                if (result.value) {
                  // auth.logout();
                  history.push(`/`);
                }
              });
            }
          });
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);

  // const columns = [
  //   {
  //     field: "payment_date",
  //     headerName: "Date",
  //     minWidth: 100,
  //     flex: 1,
  //   },
  //   {
  //     field: "fulfiller_id",
  //     headerName: "Name",
  //     minWidth: 170,
  //     flex: 1,
  //   },
  //   {
  //     field: "delivery_type",
  //     headerName: "Type",
  //     renderCell: ({ value }) => value === "bid" ? "Quoted" : value === "nobid" ? "Booked" : null,
  //     minWidth: 80,
  //     flex: 1,
  //   },
  //   {
  //     field: "transaction",
  //     headerName: "ID",
  //     minWidth: 90,
  //     flex: 1,
  //   },
  //   {
  //     field: "gross_amount",
  //     headerName: "Gross revenue",
  //     renderCell: ({ value }) => numberWithComma.format(value),
  //     minWidth: 100,
  //     flex: 1,
  //   },
  //   {
  //     field: "amount_due_to_fulfiller",
  //     headerName: "Net revenue",
  //     renderCell: ({ value }) => numberWithComma.format(value),
  //     minWidth: 100,
  //     flex: 1,
  //   },
  //   {
  //     field: "amount_due_to_platform",
  //     headerName: "Platform commission",
  //     renderCell: ({ value }) => numberWithComma.format(value),
  //     minWidth: 130,
  //     flex: 1,
  //   },
  //   {
  //     field: "payment_status",
  //     headerName: "Payment status",
  //     minWidth: 120,
  //     flex: 1,
  //   },
  // ];
  const [pageSize, setPageSize] = useState(20);
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {/* <Button variant="outlined" size="large" onClick={() => setshow(!show)}>Add filter <KeyboardArrowDownIcon/> </Button>
        {show ? <Drop_down /> : null} */}
        <h4>Transactions</h4>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };
  const handleGetRowId = (e) => {
    return e.delivery_id;
  };

  const HandleMoreData = () => {
    console.log(last_evaluated_key, "last_evaluated_key", lastpage, "lastpage");
    setIsloading(true);
    if (last_evaluated_key !== " ") {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("o_kj_"));
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        // `${endPoint}platform/v3/ng/transactions?last_evaluated_key=${last_evaluated_key}`,
        `${endPoint}admin/v1/ng/transactions?last_evaluated_key=${last_evaluated_key}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result, "resulttttt");

          if (result?.Last_evaluated_key !== "") {
            setIsloading(false);
            setlast_evaluated_key(result?.data[0]?.last_evaluated_key);

            const newData = result?.data[0]?.data;

            var uniqueData = [...transactionData, ...newData];

            settransactionData(uniqueData);

            settransactionData(uniqueData);

            settransactionData(uniqueData);

            setIsloading(false);
            settransactionData(uniqueData);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      console.log("no more data");
    }
  };

  // const TablePagination1 = () => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <div>
  //         <TablePagination
  //           component="div"
  //           count={transactionData?.length}
  //           page={page1}
  //           rowsPerPage={rowsPerPage1}
  //           onPageChange={handleChangePage1}
  //           onRowsPerPageChange={handleChangeRowsPerPage1}
  //           rowsPerPageOptions={[20, 50, 100, { label: "All", value: -1 }]}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           marginLeft: "20px",
  //         }}
  //       >
  //         {lastpage && last_evaluated_key !== " " && (
  //           <button onClick={HandleMoreData}>More data</button>
  //         )}

  //         {/* <button onClick={HandleMoreData} >More details</button> */}
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="main-grand">
      {isloading ? (
        <Box sx={{ width: "100%", marginBottom: "5px" }}>
          <LinearProgress color="success" />
        </Box>
      ) : (
        <div className="main-grand">
         <Breadcrumbs/>
          <TableWithPagination
            ident="fulfiller_id"
            actions={["Delete", "Update", "Suspend", "Restore", "Sms", "Email"]}
            onActionClick={(val, row) => actionClick(val, row)}
            data={transactionData}
            inputplaceholder="Filter by Delivery ID"
            props={Transaction_props}
            rowsPerPage={5}
            currentPage={0}
            itemsPerPage={20}
            NewData={NewData}
            more="transactions"
            onFetchSuccess={(val) => settdata(val)}
            property_name="Delivery ID"
          />
        </div>
      )}
    </div>
  );
}
