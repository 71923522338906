import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import 'bootstrap/dist/css/bootstrap.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#50B14A',
    },
    secondary: {
      main: '#11cb5f',
    },
  },
});
ReactDOM.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
    <App/>
    </ThemeProvider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
