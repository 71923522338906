import React, { useState, useEffect, useContext } from "react";
import "./Sidebar.css";
import { Link, withRouter } from "react-router-dom";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, MenuList } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore, Person } from "@mui/icons-material";
import Deliveriess from "../assets/Deliveriess.svg";
import opendeliveriesdark from "../assets/opendeliveriesdark.svg";
import opendeliverieswhite from "../assets/opendeliverieswhite.svg";
import settlements from "../assets/settlements.svg";
import adminusers from "../assets/adminusers.svg";
import smsicon from "../assets/smsicon.svg";
import deliverieshover from "../assets/deliverieshover.svg";
import settingsicon from "../assets/settingsicon.svg";
import userhover from "../assets/userhover.svg";
import settlementhovericon from "../assets/settlementhovericon.svg";
import adminhovericon from "../assets/adminhovericon.svg";
import smshovericon from "../assets/settlementhovericon.svg";
import settingshovericon from "../assets/settingshovericon.svg";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { FaUserPlus, FaUsers } from "react-icons/fa";
import { BiMoney, BiSend, BiUserPlus } from "react-icons/bi";
import { HiOutlineUserGroup, HiOutlineUsers, HiUsers } from "react-icons/hi";
import { TbTruckDelivery, TbUserCheck } from "react-icons/tb";
import {
  RiAdminFill,
  RiAdminLine,
  RiUser3Fill,
  RiUser3Line,
  RiUser6Fill,
  RiUserFill,
  RiUserLine,
  RiSettings2Fill,
  RiSettings2Line,
  RiMessage2Fill,
  RiMessage2Line,
} from "react-icons/ri";
import { AiOutlineSend } from "react-icons/ai";
import {
  MdAccountBalance,
  MdDeliveryDining,
  MdOutlineAccountBalance,
  MdOutlineDeliveryDining,
} from "react-icons/md";
import { BsAspectRatio, BsAspectRatioFill } from "react-icons/bs";
import { ProviderContext } from "../context/ProviderContext";
import { Tooltip } from "@material-ui/core";
import {CiMoneyCheck1} from 'react-icons/ci'

function Sidebar({ onclick, Title, Icon, location: { pathname } }) {
  const history = useHistory();
  const [activePath, setActivePath] = useState(
    localStorage.getItem("activePath") || "deliveries"
  );
  const [open, setOpen] = React.useState(false);
  const [deliveriesopen, setdeliveriesopen] = React.useState(false);
  const [opendeli, setopendeli] = useState(false);
  const [settlementopen, setsettlementopen] = React.useState(false);
  const [deliselected, setdeliselected] = useState(false);
  const [newopend, setnewopend] = useState(false);
  const [userhoveri, setuserhoveri] = useState(false);
  const [settlementhover, setsettlementhover] = useState(false);
  const [adminhover, setadminhover] = useState(false);
  const [smshover, setsmshover] = useState(false);
  const [settingshover, setsettingshover] = useState(false);
  const [isExpanded, setExpendState] = useState(false);

  const {
    setProvider,
    filteredCorpProvider,
    setCorporateProvider,
    setFilteredCorpProvider,
    setlast_evaluated_key,
    setFilteredIndiProvider,
  } = useContext(ProviderContext);

  const ListData = {
    Users: [
      {
        path: "deliveries",
        name: "Deliveries",
        icon1: <TbTruckDelivery />,
        icon2: <TbTruckDelivery />,
      },
      {
        path: "corporate",
        name: "Corporate providers",
        icon1: <FaUsers />,
        icon2: <HiOutlineUserGroup />,
      },
      {
        path: "individual",
        name: "Individual providers",
        icon1: <FaUserPlus />,
        icon2: <BiUserPlus />,
      },
      {
        path: "agents",
        name: "Agents",
        icon1: <RiUser3Fill />,
        icon2: <RiUser3Line />,
      },
      {
        path: "senders",
        name: "Senders",
        icon1: <HiOutlineUsers />,
        icon2: <HiUsers />,
      },
      {
        path: "opendeliveries",
        name: "Open Deliveries",
        icon1: <MdOutlineDeliveryDining />,
        icon2: <MdDeliveryDining />,
      },

      {
        path: "transaction_",
        name: "Transaction",
        icon1: <BsAspectRatio />,
        icon2: <BsAspectRatioFill />,
      },

      {
        path: "disputes",
        name: "Dispute Resolution",
        icon1: <CiMoneyCheck1 size={20}/>,
        icon2: <CiMoneyCheck1 />,
      },

      // {
      //   path: "account_",
      //   name: "Account",
      //   icon1: <MdAccountBalance />,
      //   icon2: <MdOutlineAccountBalance />,
      // },
      {
        path: "adminusers",
        name: "Admin Users",
        icon1: <RiAdminLine />,
        icon2: <RiAdminFill />,
      },
      {
        path: "messages",
        name: "Messages",
        icon1: <RiMessage2Line />,
        icon2: <RiMessage2Fill />,
      },
      {
        path: "settings",
        name: "Settings",
        icon1: <RiSettings2Line />,
        icon2: <RiSettings2Fill />,
      },
    ],
  };

  const Deliveries = {
    deliveries: [
      {
        path: "quoted_deliveries",
        name: "Quoted",
      },
      {
        path: "booked_deliveries",
        name: "Booked delivery",
      },
    ],
  };

  const Settlement = {
    settlement: [
      {
        path: "account",
        name: "Account",
      },
      {
        path: "transactions",
        name: "Transactions",
      },
    ],
  };

  useEffect(() => {
    // if (pathname === "/corporate") {
    //   handleClick();
    // }

    // if (pathname === "/user/individual") {
    //   handleClick();
    // }

    // if (pathname === "/user/agents") {
    //   handleClick();
    // }

    // if (pathname === "/user/senders") {
    //   handleClick();
    // }

    // if (pathname === "/deliveries/quoted_deliveries") {
    //   handledeliveriescheck();
    // }

    // if (pathname === "/deliveries/booked_deliveries") {
    //   handledeliveriescheck();
    // }

    // if (pathname === "/deliveries/out_for_delivery") {
    //   handledeliveriescheck();
    // }
    // if (pathname === "/deliveries/completed") {
    //   handledeliveriescheck();
    // }

    // if (pathname === "/settlement/account") {
    //   handlesettlementcheck();
    // }

    // if (pathname === "/settlement/transactions") {
    //   handlesettlementcheck();
    // }

    localStorage.setItem("activePath", activePath);
  }, [pathname, activePath]);

  const handledashboardclick = () => {
    setadminhover(false);
    setdeliveriesopen(false);
    setOpen(false);
    setdeliselected(false);
    setuserhoveri(false);
    setsettlementhover(false);
    setsettlementopen(false);
    setsmshover(false);
    setsettingshover(false);

    setnewopend(false);
    setopendeli(false);
  };

  const handleClick = () => {
    setOpen(true);
    setdeliveriesopen(false);
    setsettlementopen(false);
    setdeliselected(false);
    setuserhoveri(true);
    setsettlementhover(false);
    setadminhover(false);
    setsmshover(false);
    setsettingshover(false);

    setnewopend(false);
    setopendeli(false);
  };

  const handledeliveriescheck = () => {
    setdeliveriesopen(true);
    setOpen(true);
    setsettlementopen(false);
    setdeliselected(true);
    setuserhoveri(false);
    setsettlementhover(false);
    setadminhover(false);
    setsmshover(false);
    setsettingshover(false);
    setnewopend(false);
    setopendeli(false);

    setnewopend(false);
    setopendeli(false);
  };

  const handleOpendeliveriescheck = () => {
    setnewopend(true);
    setopendeli(true);
    setOpen(false);
    setsettlementopen(false);
    setuserhoveri(false);
    setsettlementhover(false);
    setadminhover(false);
    setsmshover(false);
    setsettingshover(false);

    setdeliveriesopen(false);
    setOpen(false);
    setdeliselected(false);
  };

  const handlesettlementcheck = () => {
    setsettlementopen(true);
    setdeliveriesopen(false);
    setOpen(false);
    setdeliselected(false);
    setuserhoveri(false);
    setsettlementhover(true);
    setadminhover(false);
    setsmshover(false);
    setsettingshover(false);

    setnewopend(false);
    setopendeli(false);
  };

  const handleadminuserclick = () => {
    setadminhover(true);
    setdeliveriesopen(false);
    setOpen(false);
    setdeliselected(false);
    setuserhoveri(false);
    setsettlementhover(false);
    setsettlementopen(false);
    setsmshover(false);
    setsettingshover(false);

    setnewopend(false);
    setopendeli(false);
  };

  const handleMessageclick = () => {
    setsmshover(true);
    setadminhover(false);
    setdeliveriesopen(false);
    setOpen(false);
    setdeliselected(false);
    setuserhoveri(false);
    setsettlementhover(false);
    setsettlementopen(false);
    setsettingshover(false);

    setnewopend(false);
    setopendeli(false);
  };

  const handleSettingsClick = () => {
    setsettingshover(true);
    setsmshover(false);
    setadminhover(false);
    setdeliveriesopen(false);
    setOpen(false);
    setdeliselected(false);
    setuserhoveri(false);
    setsettlementhover(false);
    setsettlementopen(false);

    setnewopend(false);
    setopendeli(false);
  };

  const Logout = () => {
    localStorage.clear();
    history.push("/");
    setFilteredCorpProvider([undefined]);
  };

  return (
    <>
      <div
        className={
          isExpanded
            ? "side-nav-container"
            : "side-nav-container side-nav-container-NX"
        }
      >
        <button
          className={
            isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"
          }
          onClick={() => setExpendState(!isExpanded)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="nav-menu">
          {ListData?.Users.map(({ id, name, text, path, icon1, icon2 }) => {
            return (
              <MenuList
                key={name}
                className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
                component={Link}
                to={`${path === "opendeliveries"
                    ? "/opendeliveries"
                    : path === "deliveries"
                      ? "/deliveries"
                      : path === "transaction_"
                        ? "/transactions"
                        : path === "account_"
                          ? "/account"
                          : path === "disputes"
                          ? "/disputes"
                          : path === "adminusers"
                            ? "/adminusers"
                            : path === "messages"
                              ? "/messages"
                              : path === "settings"
                                ? "/settings"
                                : `/${path}`
                  }`}
                onClick={() => setActivePath(path)}
                style={{
                  marginBottom: "12px",
                }}
              >
                <div className="sidebar__icon"

                >
                  <div>
                    <Tooltip title={name} placement="right">
                      {icon1}
                    </Tooltip>

                  </div>
                  {isExpanded && <p style={{ marginLeft: '10px' }}>{name}</p>}
                </div>
              </MenuList>
            );
          })}
        </div>
      </div>

      <div className="nav-footer">
        {isExpanded && (
          <div className="nav-details">
            <Button
              color="success"
              className="btn-"
              size="small"
              variant="contained"
              onClick={Logout}
            >
              Log out
            </Button>
          </div>
        )}
      </div>

    </>
  );
}

export default withRouter(Sidebar);
