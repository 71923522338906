import React, { useState, useContext } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Button, Grid } from "@material-ui/core";
// import {Link} from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import Tooltip from "@material-ui/core/Tooltip";
import "./login.css";
import loginLogo from "../../assets/Logo.png";
import sideImage from "../../assets/sideImage.svg";
import imageBG from "../../assets/imageBG.png";
import { Checkbox } from "@mui/material";
import { DataContext } from "../../context/DataContext/DataProvider";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Axios from "axios";
import { Link } from "react-router-dom";
import { ConnContext } from "../../context/ConnContext";
import axios from "axios";

const clientId = "Your-Client-Id";

export default function Login() {
  const { visitingCountryCode } = useContext(ConnContext);
  const { updateData, getData, getOpendeliveriesdata } =
    useContext(DataContext);

  const history = useHistory();
  const endPoint = process.env.REACT_APP_API;

  // ********Login API call********

  const handleLogin = async (e) => {
    e.preventDefault();


    const encodedPassword = btoa(password);
    setsubmitting(true);

    // await Axios.post(`${endPoint}m-dillivry-auth-manager`, {
    await Axios.post(`${endPoint}auth/v1/ng/login`, {
      // https://{{basepath}}/auth/v1/ng/login
      // https://dev-api.dillivry.com/auth/v1/ng/login
      // await Axios.post(`https://prod-api.dillivry.com/auth/v1/ng/login`, {
      auth_type: "login",
      username: username,
      password: encodedPassword,
      vcc: visitingCountryCode,
    })
      .then((response) => {
        // console.log(response, "login-response, login admin");
        if (response) {

          if (response.data.auth_status === "success") {
            console.log(response, 'response');
            const { IdToken } = response.data.token;
            const token = IdToken.split(".")[1];
            const encodedToken = atob(token);
            const tokenJSON = JSON.parse(encodedToken);
            // console.log("loca",response.data)
            localStorage.setItem("loginData", JSON.stringify(response.data));
            localStorage.setItem("fam", tokenJSON.family_name);
            localStorage.setItem("name", tokenJSON.given_name);
            localStorage.setItem("o_kj_", IdToken);
            localStorage.setItem("t_r_st", username);

            

            history.push({
              pathname: "/deliveries",
            })
            console.log(response.data.auth_status, 'response.data.auth_status')
          }
          else if (response?.data?.auth_status === "new_password_required") {
            history.push({
              pathname: "/resetpassword",
              state: username,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          console.log(error.response);

          Swal.fire({
            icon: "error",
            title: "Invalid",
            text: "Invalid username or password",
            confirmButtonColor: "green",
          });
        }
        setsubmitting(false);
      });
  };

  // ******Google auth Api call******

  const onLoginSuccess = (res) => {
    //console.log('Login Success:', res.profileObj);
    setShowloginButton(false);
    setShowlogoutButton(true);
  };
  // const onLoginFailure = (res) => {
  //   console.log('Login Failed:', res);
  // };

  const onSignoutSuccess = () => {
    alert("You have been logged out successfully");
    //console.clear();
    setShowloginButton(true);
    setShowlogoutButton(false);
  };

  const [showloginButton, setShowloginButton] = useState(true);
  const [showlogoutButton, setShowlogoutButton] = useState(false);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [submitting, setsubmitting] = useState(false);
  const [showpassword, setshowpassword] = useState(false);

  return (
    <>
      <Grid container className="LoginContainer">
        {/* Dillivry logo */}
        <Grid item xs={12} className="LogoContainer">
          <div>
            <img src={loginLogo} alt="logo" />
          </div>
        </Grid>

        <Grid container className="Form-Image">
          <Grid
            item
            xs={6}
            className="LoginForm"
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            <form className="FormInput-Button" onSubmit={handleLogin}>
              <div className="LoginHeader">Login</div>

              <div className="formCont">
                <div className="contact_input">
                  <label htmlFor="name">Email</label>
                  <input
                    required
                    name="username"
                    id="username"
                    type="email"
                    value={username}
                    // labelWidth={60}
                    onChange={(e) => {
                      setusername(e.target.value);
                    }}
                    placeholder="example@example.com"
                  />
                </div>
                <div className="contact_input_pass">
                  <label htmlFor="name">Password</label>
                  <div className="contact_passw">
                    <input
                      required
                      name="password"
                      id="password"
                      type={showpassword ? "text" : "password"}
                      // labelWidth={60}
                      value={password}
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                      placeholder="Password"
                    />
                    {showpassword ? (
                      <Tooltip title="Hide password" interactive>
                        <MdVisibilityOff
                          style={{
                            color: "grey",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => setshowpassword(!showpassword)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Show password" interactive>
                        <MdVisibility
                          style={{
                            color: "grey",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => setshowpassword(!showpassword)}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
                <Grid
                  className="passwordSave"
                  container
                  style={{ textAlign: "left" }}
                >
                  <Grid className="RememberMe" item xs={6}>
                    {" "}
                    <Checkbox color="success" value={"yes"} />
                    <label style={{ color: "white" }}>Remember me</label>
                  </Grid>{" "}
                  <Grid
                    item
                    xs={6}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Link to="/password-reset" className="ForgotPass">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>

                {/* ***Login Button*** */}

                <Grid className="lgBtnn">
                  {submitting ? (
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      disabled
                      style={{
                        backgroundColor: "var(--main)",
                        width: "100%",
                        color: "white",
                        borderRadius: "10px",
                        padding: "10px",
                        height: "40px",
                        textTransform: "none",
                      }}
                    >
                      <span className="btnlabe">
                        <PuffLoader
                          color="white"
                          loading={submitting}
                          speedMultiplier={1}
                          size={24}
                        />
                      </span>
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      disableElevation
                      style={{
                        backgroundColor: "var(--main)",
                        width: "100%",
                        color: "white",
                        borderRadius: "5px",
                        padding: "10px",
                        height: "40px",
                        textTransform: "none",
                      }}
                    >
                      <span className="btnlabe">Login</span>
                    </Button>
                  )}
                </Grid>
                {/* <Grid item xs={6}>
                  {" "}
                  <span style={{ color: "white" }}>Not registered yet? </span>
                  <span className="createAccount">Create an account </span>{" "}
                </Grid> */}
              </div>
            </form>
          </Grid>
          <Grid
            justifyContent="flex-start"
            alignItems="center"
            className="LoginImage"
            container
            item
            xs={6}
          >
            {/* ***Side Image*** */}
            <div>
              <img
                style={{ width: "100%", maxHeight: "700px" }}
                src={imageBG}
                alt=""
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
