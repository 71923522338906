import React from 'react';
import './ValidPopup.css'
import CloseIcon from '@material-ui/icons/Close';


function ValidPopup(props){
    return(props.trigger)? (
        <div className="vpopup">
            {/* <button onClick={()=>props.setTrigger(false)} className="clsBtn"> close</button> */}
            
            
            <div className="vpopup-inner">
                <div className="headingg">
                    <span className='acctTyp'>{props.title}</span>
                    <CloseIcon onClick={()=>props.setTrigger(false)} style={{width:'25px', height:'35px', color:'black'}}></CloseIcon>
                </div>
                {
                    props.children
                }
            </div>

            {/* <div  style={{padding:"10px"}}>
                {props.children}
                </div> */}
        </div>
    )
    :"";

}
export default ValidPopup