import React, { useContext, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { ConnContext } from "../context/ConnContext";
import axios from "axios";
import { DataContext } from "../context/DataContext/DataProvider";
import { DeliveryContext } from "../context/Deliveries";
import { ProviderContext } from "../context/ProviderContext";

const flags = [
  {
    name: "Canada",
    countryCode: "ca",
  },
  {
    name: "Ghana",
    countryCode: "gh",
  },
  {
    name: "Kenya",
    countryCode: "ke",
  },
  {
    name: "Nigeria",
    countryCode: "ng",
  },
  {
    name: "Rwanda",
    countryCode: "rw",
  },
  {
    name: "South Africa",
    countryCode: "za",
  },
  {
    name: "United Kingdom",
    countryCode: "gb",
  },
  {
    name: "USA",
    countryCode: "us",
  },
];
const Dropdowncountries = ({ match, location }) => {
  const {
    UpdateOpendData,
  } = useContext(DeliveryContext);

  const [submitting, setsubmitting] = useState(false);
  const [countryCode, setcountryCode] = useState("ng");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { getOpendeliveriesdata } =
    useContext(DataContext);

  const handleClose = (value) => {
    setcountryCode(value);
    setAnchorEl(null);
    console.log(value, "value");
  };

  const { opend_last_evaluated_key, opend_setlast_evaluated_key } = useContext(ProviderContext);





  useEffect(() => {

    function reverseArr(input) {
      var ret = new Array();
      for (var i = input?.length - 1; i >= 0; i--) {
        ret.push(input[i]);
      }
      return ret;
    }

    const joiner = (data) => {
      const newarrrr = data.split("-");
      const merge = newarrrr[0].concat(newarrrr[1], newarrrr[2]);
      const newarrr = merge.split(" ");
      const merged = newarrr[0].concat(newarrr[1]);
      const newarrrf = merged.split(":");
      const mergedd = newarrrf[0].concat(newarrrf[1], newarrrf[2]);
      return mergedd;
    };
    const Authorization = "";

    const authAxios = axios.create({
      baseURL: `${process.env.REACT_APP_API}`,
      headers: {
        Authorization: Authorization,
      },
    });

    authAxios
      // .get(`${process.env.REACT_APP_API}m-dillivry-search?vcc=${countryCode}`)
      .get(`${process.env.REACT_APP_API}opendelivery/v3/${countryCode}/deliveries`)
      .then((res) => {
        console.log(res?.data?.data[0], "searchdeliveriesdata");
        opend_setlast_evaluated_key(res?.data?.data[0]?.last_evaluated_key)
        if (res.status === 200) {
          // getOpendeliveriesdata(res?.data?.default_data);
          // UpdateOpendData(res?.data?.default_data);
          getOpendeliveriesdata(
            reverseArr(
              res?.data?.data[0].data?.sort(function (a, b) {
                return joiner(a?.time_stamp) - joiner(b?.time_stamp);
              }))
          )

          UpdateOpendData(
            reverseArr(
              res?.data?.data[0].data?.sort(function (a, b) {
                return joiner(a?.time_stamp) - joiner(b?.time_stamp);
              })
            )
          );

          setsubmitting(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [countryCode]);

  return (
    <>
      <span
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <span className={`flag-icon flag-icon-${countryCode}`}></span>
      </span>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(countryCode)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            gap: "2px",
          }}
        >
          {flags.map((item, key) => {
            return (
              // <Link  key={key} to={generateLanguage(item.countryCode, history.location)}>
              <MenuItem
                style={{
                  width: "100%",
                  justifyContent: "flex-start",
                  padding: "4px",
                }}
                key={key}
                onClick={() => handleClose(item.countryCode)}
              >
                <span
                  className={`flag-icon flag-icon-${item.countryCode}`}
                ></span>
                <span style={{ marginLeft: "10px" }}>{item.name}</span>
              </MenuItem>
              // </Link>
            );
          })}
        </div>
      </Menu>
    </>
  );
};

export default Dropdowncountries;
