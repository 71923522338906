import { Button, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material'
import React from 'react'
import Passport from '../assets/Passport.png'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useHistory } from "react-router-dom";
import Breadcrumbs from '../components/Breadcrumbs';
export default function Setting() {
    var loginData= JSON.parse(localStorage.getItem("loginData"))
    console.log("loginData",loginData.data.user_info)
    const {first_name,last_name,role}=loginData.data.user_info
    const [values, setValues] = React.useState({
        name: first_name+" "+last_name,
        Position: role==="datamellon-platform-sys-admin"?"Admin":"",
        Email: loginData.user_name,
        password: '',
        showPassword: false,
      });

      let history = useHistory();
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      };
    
      const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    return (
      <>
     
      <Breadcrumbs/>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: 'column',
          justifyContent: "center",
          alignItems: 'center',
          paddingTop: "50px",
          userSelect: "none",
        }}
      >
        <div style={{ width: "600px" }}>
          <Grid container spacing={3}>
            <Grid item container xs={12}>
            <Grid item xs={4}>
              <div
                className="passport"
                style={{
                  background: `url(${Passport})`,
                  width: "120px",
                  height: "120px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
              </Grid>
              <Grid item xs={6}>
                <h4>{first_name+" "+last_name}</h4>
                <h4>{role==="datamellon-platform-sys-admin"?"Admin":""}</h4>
                <h4>{loginData.user_name}</h4>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span style={{ fontSize: "14px" }}>Full Name</span>{" "}
              <TextField
                hiddenLabel
                size="small"
                fullWidth
                type="text"
                value={values.name}
                onChange={handleChange("name")}
                variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
              <span style={{ fontSize: "14px" }}>Position</span>{" "}
              <TextField
                hiddenLabel
                size="small"
                fullWidth
                disabled
                type="text"
                value={values.Position}
                onChange={handleChange("Position")}
                variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
              <span style={{ fontSize: "14px" }}>Email</span>{" "}
              <TextField
                hiddenLabel
                size="small"
                fullWidth
                type="email"
                value={values.Email}
                onChange={handleChange("Email")}
                variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
              {/* <span style={{ fontSize: "14px" }}>Password</span>
              <TextField
                hiddenLabel
                size="small"
                fullWidth
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              /> */}
              <div
                style={{
                  display: "flex",
                  fontSize: "14px",
                  color: "var(--main)",
                  cursor: "pointer",
                  marginTop: "10px",
                  justifyContent: "flex-end",
                  width: "100%",
                }}

                onClick={()=> history.push('/ForgotPassword')}
              >
                Change password
              </div>
            </Grid>
            {/* <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  fontSize: "14px",
                  color: "var(--main)",
                  cursor: "pointer",
                  marginTop: "10px",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button size='small' variant="outlined">Cancel</Button> &nbsp;&nbsp;&nbsp;
                <Button size='small' variant="contained" sx={{color:"#fff"}}>Save</Button>
              </div>
            </Grid> */}

            <Grid item xs={12}></Grid>
          </Grid>
        </div>
      </div>
      </>
    );
}
