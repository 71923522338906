import React from "react";

import "./Navbar.css";
import logo from "../assets/logo.svg";
import Profileicon from "../assets/Profileicon.svg";
import Flag from "../assets/Flag.svg";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";

export default function Navbar({
  familyname,
  givenname,
  datatouse,
  dashboardtext,
  forwarddata,
  forwardtext,
  page,
  role,
  state,
}) {
  let history = useHistory();

  useEffect(() => {
    console.log(state, "stateeee");
  }, []);

  const handleGoBack = () => {
    history.goBack();
    window.location.reload(true);
  };

  return (
    <div className="navbar">
      <div className="navbar-div">
        <div className="navbar-logo">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              justifyContent: "space-around",
            }}
          >
            <div>
              <img
                src={logo}
                alt="logo"
                onClick={() => history.push("/deliveries")}
              />
            </div>

            <div>
              <h3
                className="back-to-dashboard"
                onClick={
                  () => {
                    history.push('/deliveries');
                  }
                }
              >
                {dashboardtext}
              </h3>
            </div>
          </div>
        </div>

        <div className="navbar-text">
        <Link to='/settings' style={{display: 'flex', alignItems: 'center'}}>
          <div className="navbar-profile-icon">
            <img
              src={Profileicon}
              alt="profile-icon"
              className="profile-icon"
              style={{marginRight: '10px'}}
            />
          </div>
          <div className="navbar-user-name-text" style={{color: 'black'}}>
            Hello {familyname} {givenname}         
          </div>
          {/* <div className="navbar-flag">
            <img src={Flag} alt="flag" />{" "}
          </div> */}
          </Link>
        </div>

        {/* <div>

        </div> */}
      </div>
    </div>
  );
}