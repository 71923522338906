import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Pagination from "rc-pagination";
import "./Paginated.css";
import { AiOutlineMail, AiOutlineSearch, AiOutlineUser } from "react-icons/ai";
import { GrRefresh } from "react-icons/gr";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  MdDescription,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineCall,
} from "react-icons/md";
import { capitalize } from "@mui/material";
import Axios from "axios";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useRef } from "react";
import { debounce } from "lodash";
import { FaShuttleVan, FaTimes } from "react-icons/fa";
import { ProviderContext } from "../../context/ProviderContext";
import PuffLoader from "react-spinners/PuffLoader";
import moment from "moment";
import { CgProfile } from "react-icons/cg";
import { ConnContext } from "../../context/ConnContext";
import { FiUser, FiUsers } from "react-icons/fi";
import { CSVLink, CSVDownload } from "react-csv";
import { CiExport } from "react-icons/ci";

const TableWithPagination = ({
  more,
  onFetchSuccess,
  refetchDefault,
  linkclicked,
  data,
  props,
  inputplaceholder,
  actionProps,
  NewData,
  actions,
  onActionClick,
  actionUpdate,
  ident,
  key,
  selecteddata,
  onSelected,
  property_name,
  createUser,
  searchProperty,
  csvData,
}) => {
  const { visitingCountryCode } = useContext(ConnContext);
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [actionclciked, setactionclciked] = useState(false);
  const endPoint2 = process.env.REACT_APP_API;
  const [SearchResult, setSearchResult] = useState();
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchstate, setSearchstate] = useState(false);
  const [dataToShow, setdataToShow] = useState();
  const [dataToShowduplicate, setdataToShowduplicate] = useState();
  const history = useHistory();
  const nodeRef = useRef(null);
  const [firstclcik, setFirstclick] = useState(false);
  const [cancelclick, setCancelClick] = useState(false);
  const [providerclciked, setproviderclciked] = useState(false);
  const [dataloading, setDataLoading] = useState(false);
  const { last_evaluated_key, setlast_evaluated_key } =
    useContext(ProviderContext);
  const [showlasticon, setShowLastIcon] = useState(false);

  const [lastApiCallTimestamp, setlastApiCallTimestamp] = useState(
    new Date().getTime()
  );

  const [itemselected, setItemSelected] = useState(false);

  const [secondclick, setSecondClick] = useState(false);

  const [captured, setCaptured] = useState("");

  const HandleMoreData = () => {
    setDataLoading(true);
    if (last_evaluated_key !== " ") {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("o_kj_"));
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      // fetch(
      //     `${endPoint2}platform/v3/ng/${more}?last_evaluated_key=${last_evaluated_key}`,
      //     requestOptions
      // )
      fetch(
        `${endPoint2}admin/v1/ng/${more}?last_evaluated_key=${last_evaluated_key}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setDataLoading(false);
          setlastApiCallTimestamp(new Date().getTime());
          if (result?.Last_evaluated_key !== "") {
            setDataLoading(false);
            setlast_evaluated_key(result?.data[0]?.last_evaluated_key);
            const newData = result?.data[0]?.data;
            var uniqueData = [...data, ...newData];

            setdataToShow(uniqueData);
            onFetchSuccess(uniqueData);
            setDataLoading(false);
            onFetchSuccess(uniqueData);
            setDummyData(uniqueData);
            // setCurrent(current + 1)
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      setDataLoading(false);
    }
  };

  function getLastApiCallTimeAgo() {
    const now = new Date().getTime();
    const elapsedTime = now - lastApiCallTimestamp;
    if (elapsedTime < 60 * 1000) {
      // less than 1 minute
      return "Last fetched just now";
    }
    const minutes = Math.floor(elapsedTime / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    if (hours === 0) {
      return `Last fetched ${minutes} minutes ago`;
    }
    return `Last fetched ${hours} hours ago`;
  }

  const getData = (current, pageSize) => {
    if (dataToShow === undefined && data) {
      return data?.slice((current - 1) * pageSize, current * pageSize);
    } else {
      return dataToShow?.slice((current - 1) * pageSize, current * pageSize);
    }
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(data.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <MdKeyboardArrowLeft />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <MdKeyboardArrowRight />
        </button>
      );
    }
    return originalElement;
  };

  useEffect(() => {
    if (SearchResult?.length === 0 || dataToShow === undefined) {
      setdataToShowduplicate();
    } else {
      setdataToShowduplicate(SearchResult);
    }
  }, [SearchResult]);

  useEffect(() => {
    if (open === false) {
      setSearchResult();
    }
  }, [open]);

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  useEffect(() => {
    const lastPage = Math.ceil(data?.length / size);
    const isLastPage = current === lastPage;

    if (current === 1) {
      console.log(`stay active PAGE${current}`);
    }
    if (current === 2) {
      console.log(`in active page${current}`);
    }
    if (isLastPage) {
      console.log(`stay active, lAST PAGE${current}`);
    }

    if (current === 1 || isLastPage) {
      console.log("valid");
      setShowLastIcon(true);
    } else {
      console.log("Not Valid");
      setShowLastIcon(false);
    }
  }, [current, size, data?.length]);

  const SelectedItem = (item, mainitem) => {
    const searchParams = new URLSearchParams(window.location.search);
    console.log(searchParams, "searchparamss");
    searchParams.set("mainitem", mainitem?.account_name);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    console.log(newUrl, "newurl");
    history.push(newUrl);
    setSearchResult(item);
    setproviderclciked(true);
    setOpen(false);
    setdataToShow([mainitem]);
    setItemSelected(true);
    setSecondClick(false);
  };

  useEffect(() => {
    getData();
  }, [itemselected]);

  const [datacall, setDataCall] = useState(false);

  const ExecuteSearch = async (val) => {
    setSearchstate(true);
    setDataCall(true);

    // Axios.get(`${endPoint2}platform/v3/ng/${more}?value=${val}`, {
    Axios.get(`${endPoint2}admin/v1/ng/${more}?value=${val}`, {
      headers: {
        Authorization: localStorage?.getItem("o_kj_"),
      },
    })
      .then((response) => {
        setDataCall(false);
        if (response.status === 200) {
          setDataCall(false);
          setSearchResult(response?.data.data[0].data);
          setdataToShowduplicate(response?.data.data[0].data);
          setFirstclick(false);
          setOpen(true);
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setDataCall(false);
      })
      .finally(() => {
        setSearchstate(false);
        setDataCall(false);
      });
  };

  const defaultData = data;
  const [dummyData, setDummyData] = useState(data);

  console.log(dummyData, "dummydata");

  const localSearch = (e) => {
    setSearchValue(e);
    let newArray = defaultData.filter((search) =>
      search?.[captured]?.toLowerCase()?.includes(e.toLowerCase())
    );
    // setDummyData(newArray);
    console.log(newArray, "newarray");
    setDummyData(newArray);
  };

  const debouncedSearch = useRef(
    debounce(async (criteria) => {
      await ExecuteSearch(criteria);
    }, 1000)
  ).current;

  const SearchNow = async (e) => {
    e.preventDefault();
    let str = e.target.value;
    let searchdetail = str.substring(str.indexOf("=") + 1).trim();

    if (captured !== "") {
      localSearch(searchdetail);
    } else {
      // let regex =
      //   /^Search Id|Search id|search id =|=|Search Id =|Search id = ` /gi;
      console.log(searchdetail, "searchdetail");
      debouncedSearch(searchdetail?.toString());
    }
  };

  const handelSearch = (e) => {
    if (e?.target?.value.length === 0 || e.target.value.length < 1) {
      setdataToShow(data);
      setSearchValue(e.target.value);
    } else if (e.target.value.length !== 0 && e.target.value.length > 12) {
      SearchNow(e);
      setSearchValue(e.target.value);
      setOpen(true);
    } else {
      SearchNow(e);
      setSearchValue(e.target.value); // the one working before
      //   setSearchValue("Search Id = ");

      // setSearchValue(e.target.value);
      // setSearchValue();
    }
  };

  const HandlePropertySelect = (item) => {
    //main one
    setFirstclick(true);
    setCancelClick(true);
    if (secondclick === true) {
      setFirstclick(false);
    }
  };

  // wasn't here in previous code
  const HandlePropertySelected = () => {
    setOpen(true);
    setSearchValue(`${searchProperty} =`);
    // setSearchValue("Search Id = ");
    // setSearchValue(property_name + '' + '=')
  };

  const HandlePropertyNew = (item) => {
    setCaptured(item.type);
    setSecondClick(true);
    setFirstclick(false);
    setSearchValue(`${item.name} =`);
    setCancelClick(true);
  };

  const HandleCancelIcon = () => {
    setFirstclick(false);
    setSecondClick(false);
    setSearchValue("");
    setOpen(false);
    setproviderclciked(false);
    setdataToShow(data);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("item");
    searchParams.delete("mainitem");
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    history.replace(newUrl);
  };

  function isNumber(str) {
    return !isNaN(Number(str));
  }

  const MyComponentWithClickAway = React.forwardRef((props, ref) => {
    const handleClickAway = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
        // setFirstclick(false);
        setSecondClick(false);
      }
    };
    return (
      <>
        {
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="search-result-container">
              {firstclcik ? (
                <div ref={ref} className={`search-action_elementtwo`}>
                  <div className="search_ul_action">
                    <p className="propertiesTag">Properties </p>
                    <ul
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      {/* <li
                                            className="serach_title"
                                            onClick={HandlePropertySelected}
                                        >
                                            <span className="title_name">Account Name</span>{" "}
                                        </li> */}
                      {/* <li className='serach_title' onClick={HandlePropertySelected}  ><span className='title_name'  >{property_name === 'Delivery ID' ? "Delivery ID" : "Account Name"}</span> </li> */}
                      <li
                        className="serach_title"
                        // onClick={HandlePropertySelected}
                      >
                        <div className="title_name">
                          {searchProperty.map((item, index) => (
                            <p onClick={() => HandlePropertyNew(item)}>
                              {item.name}
                            </p>
                            // <p key={index} onClick={() => setCaptured(item.type)}></p>
                          ))}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        }
      </>
    );
  });

  const MyComponentWithClickAwayOther = React.forwardRef((props, ref) => {
    const handleClickAway = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
        setFirstclick(false);
      }
    };
    return (
      <>
        {
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="search-result-container">
              {secondclick ? (
                <div ref={ref} className={`search-action_elementtwo`}>
                  <div className="search_ul_action">
                    <p className="propertiesTag">
                      {captured
                        .replace(/_/g, " ")
                        .replace(/(?:^|\s)\S/g, function (a) {
                          return a.toUpperCase();
                        })}{" "}
                      values
                    </p>
                    {dummyData.map((datum) => (
                      <div
                        style={{
                          borderTop: "1px solid #ccc",
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "5px",
                          paddingBottom: "10px",
                          marginLeft: "5px",
                          fontSize: "14px",
                        }}
                        onClick={() => SelectedItem(dummyData, datum)}
                      >
                        <div>
                          {captured
                            .replace(/_/g, " ")
                            .replace(/(?:^|\s)\S/g, function (a) {
                              return a.toUpperCase();
                            })}{" "}
                          = {datum?.[captured]}
                          <div>
                            {datum?.[captured] ===
                            datum.delivery_id ? null : datum?.[captured] ===
                              datum.item_desc ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "15px",
                                  marginTop: "7px",
                                }}
                              >
                                {/* <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <FaShuttleVan
                                  size={20}
                                  color="#777"
                                  style={{ marginRight: "10px" }}
                                />
                                {datum?.[captured]}
                              </p> */}
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <MdDescription
                                    size={20}
                                    color="#777"
                                    style={{ marginRight: "5px" }}
                                  />
                                  {datum?.delivery_id}
                                </p>
                              </div>
                            ) : datum?.[captured] ===
                              datum.account_name ? //   <div
                            //   style={{
                            //     display: "flex",
                            //     flexDirection: "column",
                            //     marginBottom: "15px",
                            //     marginTop: "7px",
                            //   }}
                            // >
                            //   <p
                            //     style={{
                            //       display: "flex",
                            //       alignItems: "center",
                            //       marginBottom: "5px",
                            //     }}
                            //   >
                            //     <AiOutlineUser
                            //       size={20}
                            //       color="#777"
                            //       style={{ marginRight: "10px" }}
                            //     />
                            //     {datum?.[captured]}
                            //   </p>

                            // </div>
                            null : datum?.[captured] === datum.user_id ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "15px",
                                  marginTop: "7px",
                                }}
                              >
                                {/* <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <AiOutlineMail
                                  size={20}
                                  color="#777"
                                  style={{ marginRight: "10px" }}
                                />
                                {datum?.[captured]}
                              </p> */}
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FiUser
                                    size={20}
                                    color="#777"
                                    style={{ marginRight: "5px" }}
                                  />
                                  {datum?.account_name}
                                </p>
                              </div>
                            ) : datum?.[captured] === datum.name ? //   <div
                            //   style={{
                            //     display: "flex",
                            //     flexDirection: "column",
                            //     marginBottom: "15px",
                            //     marginTop: "7px",
                            //   }}
                            // >
                            //   <p
                            //     style={{
                            //       display: "flex",
                            //       alignItems: "center",
                            //       marginBottom: "5px",
                            //     }}
                            //   >
                            //     <FiUser
                            //       size={20}
                            //       color="#777"
                            //       style={{ marginRight: "10px" }}
                            //     />
                            //     {datum?.[captured]}
                            //   </p>

                            // </div>
                            null : datum?.[captured] === datum.phone_number ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "15px",
                                  marginTop: "7px",
                                }}
                              >
                                {/* <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <MdOutlineCall
                                  size={20}
                                  color="#777"
                                  style={{ marginRight: "10px" }}
                                />
                                {datum?.[captured]}
                              </p> */}
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <AiOutlineUser
                                    size={20}
                                    color="#777"
                                    style={{ marginRight: "5px" }}
                                  />
                                  {datum?.account_name}
                                </p>
                              </div>
                            ) : datum?.[captured] === datum.account_id ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "15px",
                                  marginTop: "7px",
                                }}
                              >
                                {/* <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <FiUser
                                  size={20}
                                  color="#777"
                                  style={{ marginRight: "10px" }}
                                />
                                {datum?.[captured]}
                              </p> */}
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FiUser
                                    size={20}
                                    color="#777"
                                    style={{ marginRight: "5px" }}
                                  />
                                  {datum?.account_name}
                                </p>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "15px",
                                  marginTop: "7px",
                                }}
                              >
                                {/* <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <FaShuttleVan
                                    size={20}
                                    color="#777"
                                    style={{ marginRight: "10px" }}
                                  />
                                  {datum?.[captured]}
                                </p> */}
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <MdDescription
                                    size={20}
                                    color="#777"
                                    style={{ marginRight: "5px" }}
                                  />
                                  {datum?.delivery_id}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        }
      </>
    );
  });

  const MyComponentWithClickAwayopen = React.forwardRef((props, ref) => {
    const handleClickAway = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
        setFirstclick(false);
        setSecondClick(false);
      }
    };

    return (
      <>
        <ClickAwayListener onClickAway={handleClickAway}>
          {
            datacall ? (
              <div className="search-result-container">
                {/* {open && SearchResult?.length > 0 ? ( */}
                <div ref={ref} className={`search-action_elementone`}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <PuffLoader
                      color="green"
                      loading={datacall}
                      speedMultiplier={1}
                      size={24}
                    />
                  </div>
                </div>
                {/* ) : null} */}
              </div>
            ) : (
              <div className="search-result-container">
                {open && SearchResult?.length > 0 ? (
                  <div ref={ref} className={`search-action_elementone`}>
                    {SearchResult?.slice(0, 10)?.map((act, index) => (
                      <li
                        className="filtered_name"
                        key={index}
                        onClick={() => SelectedItem(SearchResult, act)}
                      >
                        {property_name === "Delivery ID" ? (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FaShuttleVan
                                size={20}
                                color="#777"
                                style={{ marginRight: "10px" }}
                              />
                              {act?.delivery_id}
                            </div>
                            <div
                              style={{
                                borderBottom: "1px solid #ccc",
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "15px",
                                paddingBottom: "10px",
                                marginTop: "7px",
                              }}
                            >
                              <MdDescription
                                size={20}
                                color="#777"
                                style={{ marginRight: "10px" }}
                              />
                              {act?.item_desc}
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CgProfile
                                size={20}
                                color="#777"
                                style={{ marginRight: "10px" }}
                              />
                              {act?.account_name}
                            </div>
                            <div
                              style={{
                                borderBottom: "1px solid #ccc",
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "15px",
                                marginTop: "7px",
                                paddingBottom: "10px",
                              }}
                            >
                              <AiOutlineMail
                                size={20}
                                color="#777"
                                style={{ marginRight: "10px" }}
                              />
                              {act?.user_id}
                            </div>
                          </>
                        )}
                      </li>
                    ))}
                  </div>
                ) : null}
              </div>
            )

            // SearchResult?.slice(0, 10)?.map((act, index) => (
            //                     <li className='filtered_name' key={index} onClick={() => SelectedItem(SearchResult, act)}>{property_name} : {property_name === "Delivery ID" ? act?.delivery_id : act?.account_name}</li>
            //                     ))
          }
          {/* <div className="search-result-container">
                        {open && SearchResult?.length > 0 ? (
                            <div ref={ref} className={`search-action_elementone`}>
                                {/* {SearchResult?.slice(0, 10)?.map((act, index) => (
                                    <li
                                        className="filtered_name"
                                        key={index}
                                        onClick={() => SelectedItem(SearchResult, act)}
                                    >
                                        Account Name : {act?.account_name}
                                    </li>
                                ))} */}
          {/* {
                                    SearchResult?.slice(0, 10)?.map((act, index) => (
                                        <li className='filtered_name' key={index} onClick={() => SelectedItem(SearchResult, act)}>{property_name} : { property_name === "Delivery ID" ? act?.delivery_id : act?.account_name}</li>
                                    ))
                                } */}
          {/* </div>
                        ) : null}
                    </div>  */}
        </ClickAwayListener>
      </>
    );
  });

  return (
    <>
      {console.log(captured, "captured")}

      <div className="top_action">
        <div className="first_item">
          <div className="time_fetched">{getLastApiCallTimeAgo()}</div>

          <div className="refresh_icon" onClick={() => refetchDefault(data)}>
            {<GrRefresh color="#545b64" />}
          </div>
        </div>
        <ClickAwayListener onClickAway={() => setactionclciked(false)}>
          <div className="action_container">
            <div
              className="second_item"
              onClick={() => {
                if (selected?.length > 0) {
                  setactionclciked(!actionclciked);
                  actionUpdate(selected.length);
                }
              }}
            >
              <div className="action"> Actions </div>
              <IoMdArrowDropdown size={20} />
            </div>

            {actionclciked && (
              <div className="action_element">
                <ul className="ul_action">
                  {actions?.map((act) => (
                    // <li     onClick={() => onActionClick(act, selected) onSelected() }>{act}</li>
                    <li
                      onClick={() => {
                        return act === "Delete"
                          ? onSelected(selected[0])
                          : onActionClick(act, selected);
                        // onSelected(selected[0])
                      }}
                    >
                      {act}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </ClickAwayListener>

        {/* <div className="third_item">
                    <button className="create_btn">Create function</button>
                </div> */}
        {history.location.pathname === "/adminusers" && (
          <div className="third_item">
            <button className="create_btn" onClick={() => createUser()}>
              Create User
            </button>
          </div>
        )}
      </div>

      <div className="table_container">
        <div className="table-filter-info">
          <div
            style={{
              position: "relative",
            }}
          >
            <div className="search-container">
              <AiOutlineSearch className="search-icon" size={20} />
              <input
                type="text"
                placeholder={inputplaceholder}
                value={searchValue}
                onChange={handelSearch}
                className="search-input"
                onClick={HandlePropertySelect}
              />
              {/* {cancelclick && <FaTimes className='search-icon times ' size={16} onClick={HandleCancelIcon} />} */}
              {searchValue.length >= 2 && (
                <FaTimes
                  className="search-icon times "
                  size={16}
                  onClick={HandleCancelIcon}
                />
              )}
            </div>
            {providerclciked && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>{searchValue}</div>

                <div>
                  <FaTimes
                    className="search-icon"
                    size={16}
                    onClick={HandleCancelIcon}
                  />
                </div>
              </div>
            )}
            {firstclcik ? (
              <MyComponentWithClickAway ref={nodeRef} />
            ) : open ? (
              <MyComponentWithClickAwayopen ref={nodeRef} />
            ) : null}

            {/* {secondclick ? (
              <MyComponentWithClickAwayOther ref={nodeRef} />
            ) : open ? (
              <MyComponentWithClickAwayopen ref={nodeRef} />
            ) : null} */}
            {secondclick ? (
              <MyComponentWithClickAwayOther ref={nodeRef} />
            ) : null}
          </div>
          {/* <div>
            {dummyData !== undefined && (
              <CSVLink
                data={dummyData}
                filename={more}
                style={{
                  border: "none",
                  padding: "7px 17px",
                  borderRadius: "7px",
                  fontSize: "13px",
                  color: "#fff",
                  backgroundColor: "#50B14A",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                Export CSV <CiExport size={20} />
              </CSVLink>
            )}
          </div> */}

          <div
            className="paginated__container"
            style={{
              overflow: "scroll",
            }}
          >
            <div>
              <div>
                <Pagination
                  className="pagination-data"
                  onChange={PaginationChange}
                  total={data?.length}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                  itemRender={PrevNextArrow}
                  // itemRender={itemRender}
                  onShowSizeChange={PerPageChange}
                />
              </div>
              {showlasticon && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "relative",
                  }}
                >
                  <span
                    onClick={() => HandleMoreData()}
                    style={{ position: "absolute", top: -25, right: 8 }}
                  >
                    <MdKeyboardArrowRight
                      color="#656f84"
                      className="last_icon_class"
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <table id="thead-data">
          {
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={
                    data?.length > 0 &&
                    data?.every((row) => selectedRows?.includes(row?.[ident]))
                  }
                  onChange={() => {
                    if (
                      data?.length > 0 &&
                      data?.every((row) => selectedRows?.includes(row?.[ident]))
                    ) {
                      setSelectedRows([]);
                      setSelected([]);
                    } else {
                      setSelectedRows(data.map((row) => row?.[ident]));
                      setSelected(data);
                      selecteddata(data);
                    }
                    console.log(data, "datatat");
                  }}
                />
              </th>
              {props?.map((item, index) => (
                <>
                  <th>{item}</th>
                </>
              ))}
            </tr>
          }

          <tbody>
            {getData(current, size)?.map((item, i) => (
              <tr key={i} className="table__row">
                <td>
                  <input
                    type="checkbox"
                    checked={selectedRows?.includes(item?.[ident])}
                    onChange={() => {
                      if (selectedRows?.includes(item?.[ident])) {
                        setSelectedRows(
                          selectedRows.filter((id) => id !== item?.[ident])
                        );
                        setSelected(
                          selected.filter((id) => id?.[ident] !== item?.[ident])
                        );
                      } else {
                        setSelectedRows([...selectedRows, item?.[ident]]);
                        setSelected([...selected, item]);
                      }
                    }}
                  />
                </td>
                {NewData?.map((x, i) => (
                  // {isNumber(item?.[x])
                  //   ? item?.[x]
                  //   : moment(item?.[x]).isValid()
                  //   ? moment(item?.[x]).format("YYYY-MM-DD HH:mm:ss")
                  //   : item?.[x]
                  //   ? capitalize(item?.[x])
                  //   : ""}
                  <>
                    {x?.includes(".") ? (
                      <td key={i} className="table_logo">
                        {x.split(".").length === 2 ? ( 
                         item[x.split(".")[0]][x.split(".")[1]]
                        
                        ) : x.split(".").length === 3 ? (
                          item[x.split(".")[0]][x.split(".")[1]][
                            x.split(".")[2]
                          ]
                        ) : (
                          <></>
                        )}
                      </td>
                    ) : Array.isArray(item?.[x]) &&
                      item?.[x]?.[0]?.startsWith("http") ? (
                      <td key={i} className="table_logo">
                        <img
                          className="corp_img"
                          src={item?.[x][0]}
                          alt="itens"
                        />
                      </td>
                    ) : typeof item?.[x] === "object" ? (
                      <td key={i}>{Object.keys(item?.[x]).length}</td>
                    ) : item?.[x]?.startsWith("http") ? (
                      <td key={i} className="table_logo">
                        <img className="corp_img" src={item?.[x]} alt="itens" />
                      </td>
                    ) : (
                      <td key={i} onClick={() => linkclicked(item)}>
                        <p
                          className={
                            x === `account_name`
                              ? `account_name_class`
                              : `item_classname`
                          }
                        >
                          {isNumber(item?.[x])
                            ? item?.[x]
                            : moment(item?.[x]).isValid()
                            ? moment(item?.[x]).format("YYYY-MM-DD HH:mm:ss")
                            : item?.[x]
                            ? capitalize(item?.[x])
                            : ""}
                        </p>
                      </td>
                    )}
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableWithPagination;
