import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./Disputes.css";
import Swal from "sweetalert2";
import Axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";

const DisputeForm = ({ handleClose }) => {
  const [amount, setAmount] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [message, setMessage] = useState("");
  const [accountType, setAccountType] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [base64Image, setBase64Image] = useState(null);
  const [addingDispute, setAddingDispute] = useState(false);
  const [imageVisibility, setimageVisibility] = useState(false);

  const [errors, setErrors] = useState({
    user_id: "",
    account_type: "",
    payment_mode: "",
    sender_name: "",
    amount: "",
    complaint: "",
    image: "",
  });

  // const [summary, setSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const endPoint = process.env.REACT_APP_API;

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setimageVisibility(true);

    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
      setBase64Image(reader.result.split(",")[1]);
    };
    reader.readAsDataURL(file);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    setErrors({
      user_id: "",
      account_type: "",
      payment_mode: "",
      sender_name: "",
      amount: "",
      complaint: "",
      image: "",
    });

    let hasErrors = false;

    // Form Validation
    if (!amount) {
      hasErrors = true;
      setErrors((prevErrors) => ({
        ...prevErrors,
        amount: "Please enter an amount",
      }));
    } else if (isNaN(amount)) {
      hasErrors = true;
      setErrors((prevErrors) => ({
        ...prevErrors,
        amount: "Amount must be a valid number",
      }));
    }

    if (!accountType) {
      hasErrors = true;
      setErrors((prevErrors) => ({
        ...prevErrors,
        account_type: "Please select an account type",
      }));
    }

    if (!paymentMode) {
      hasErrors = true;
      setErrors((prevErrors) => ({
        ...prevErrors,
        payment_mode: "Please select a payment mode",
      }));
    }

    if (!accountName) {
      hasErrors = true;
      setErrors((prevErrors) => ({
        ...prevErrors,
        sender_name: "Please enter an account name",
      }));
    }

    if (!accountEmail) {
      hasErrors = true;
      setErrors((prevErrors) => ({
        ...prevErrors,
        user_id: "Please enter an account email",
      }));
    }

    if (!message) {
      hasErrors = true;
      setErrors((prevErrors) => ({
        ...prevErrors,
        complaint: "Please enter a complaint message",
      }));
    }

    if (!base64Image) {
      hasErrors = true;
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "Please select an image to upload",
      }));
    }

    if (hasErrors) {
      return;
    }

    setAddingDispute(true);
    setIsLoading(true);
    const authAxios = Axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    });
    await authAxios
      .post(`${endPoint}dispute/v1/ng/create_dispute`, {
        user_id: accountEmail,
        account_type: accountType,
        payment_mode: paymentMode,
        sender_name: accountName,
        amount: amount,
        complaint: message,
        image: base64Image,
      })
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            setAddingDispute(false);
            handleClose(handleClose);
            setIsLoading(false);
            console.log(response.data.data, "dispute response");

            history.push("/disputes/summary", {
              disputeSummary: response.data.data,
            });
          }
        }
      })
      .catch((error) => {
        setAddingDispute(false);
        handleClose(handleClose);
        setIsLoading(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: error.message,
            confirmButtonColor: "red",
          });
          console.log(error.message, "error");
        }
      });
  };

  return (
    <>
      {!isLoading ? (
        <form className="dispute__form--container" onSubmit={submitForm}>
          <div className="dispute__input--parent">
            <input
              type="text"
              name=""
              id=""
              placeholder="Account Name"
              value={accountName}
              onChange={(e) => {
                setAccountName(e.target.value);
              }}
            />
            {errors.sender_name && (
              <p className="dispute__error-message">{errors.sender_name}</p>
            )}
          </div>

          <div className="dispute__input--parent">
            <input
              type="text"
              name=""
              id=""
              placeholder="Account Email"
              value={accountEmail}
              onChange={(e) => {
                setAccountEmail(e.target.value);
              }}
            />
            {errors.user_id && (
              <p className="dispute__error-message">{errors.user_id}</p>
            )}
          </div>

          <div className="dispute__input--parent">
            <select
              value={accountType}
              onChange={(e) => {
                setAccountType(e.target.value);
              }}
            >
              <option value="">Account Type</option>
              <option value="corporate provider">Corporate Provider</option>
              <option value="individual provider">Individual Provider</option>
              <option value="sender">Sender</option>
            </select>
            {errors.account_type && (
              <p className="dispute__error-message">{errors.account_type}</p>
            )}
          </div>

          <div className="dispute__input--parent">
            <input
              type="number"
              name=""
              id=""
              placeholder="Enter an Amount"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
            {errors.amount && (
              <p className="dispute__error-message">{errors.amount}</p>
            )}
          </div>

          <div className="dispute__input--parent">
            <select
              value={paymentMode}
              onChange={(e) => {
                setPaymentMode(e.target.value);
              }}
            >
              <option value="">Payment Mode</option>
              <option value="transfer">Bank Transfer</option>
              <option value="card">Debit Card</option>
            </select>
            {errors.payment_mode && (
              <p className="dispute__error-message">{errors.payment_mode}</p>
            )}
          </div>

          <div className="dispute__input--parent">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              value={message}
              placeholder="A short description..."
            ></textarea>
            {errors.complaint && (
              <p className="dispute__error-message">{errors.complaint}</p>
            )}
          </div>

          <div className="upload-container">
            <label htmlFor="file-input" className="custom-file-upload">
              <i className="fa fa-cloud-upload"></i> Upload Images
            </label>
            <input id="file-input" type="file" onChange={handleFileSelect} />
          </div>
          <div className="preview-container">
            {imageVisibility && (
              <img src={imagePreview} alt="Preview" className="image-preview" />
            )}
          </div>
          <button>Create Dispute</button>
        </form>
      ) : (
        <div className="loader__container">
          <BounceLoader
            color="green"
            loading={isLoading}
            speedMultiplier={1}
            size={50}
          />
        </div>
      )}
    </>
  );
};

export default DisputeForm;
