import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import "./Disputes.css";
import DisputeForm from "./DisputeForm";
import TableWithPagination from "../../components/DefaultTableFolder/TablePaginated";

import Axios from "axios";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "100%",
  bgcolor: "background.paper",
  border: "none",
  p: 4,
  overflow: "auto", // Enable vertical scroll if content overflows
  maxHeight: "80vh",
};

// const style = {
//   position: "absolute",
//   // width: 1101,
//   // height: 2395,
//   top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//   width: "40vw",
//   bgcolor: "#F9F9F9",
//   border: "1px solid #ccc",
//   borderRadius: 2,
//   outline: "none",
//   overflow: "auto", // Enable vertical scroll if content overflows
//   maxHeight: "80vh",
//   margin: "70px 150px 0 150px",
// };

// const style = {
//   position: "absolute",
//   // top: "50%",
//   // left: "50%",
//   // transform: "translate(-50%, -50%)",
//   width: "40vw",
//   // height: 572,
//   bgcolor: "#F9F9F9",
//   border: "1px solid #ccc",
//   borderRadius: 2,
//   outline: "none",
//   overflow: "auto", // Enable vertical scroll if content overflows
//   maxHeight: "80vh",
//   //   margin: "70px 150px 0 150px",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// };

const Disputes = () => {
  const [dispute, setDispute] = useState([]);
  const [loading, setloading] = useState(false);
  const [actions, setactions] = useState([]);
  const [item_to_modify, setitem_to_modify] = useState([]);
  const [showdelete, setshowdelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const history = useHistory();
  const location = useLocation();

  const endPoint = process.env.REACT_APP_API;

  const authAxios = Axios.create({
    baseURL: `${endPoint}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });

  const dispute_props = [
    "Dispute ID",
    "Email",
    "Account Name",
    "Sender Name",
    "Amount",
    "Date",
    "Status",
  ];

  const NewData = [
    "dispute_id",
    "user_id",
    "account_name",
    "sender_name",
    "amount",
    "date.date_created",
    "status",
  ];

  // const actionClick = (val, row) => {
  //   setitem_to_modify(row);
  //   if (val === "Delete") {
  //     console.log(row, "row--");
  //     setshowdelete(false);
  //     setSelectedItem(row)
  //     // setShowpopup(true)
  //   }
  // };

  // const actionselectedClick = (val) => {
  //   console.log(val.delivery_id, "vallll");
  //   if (val?.delivery_id) {
  //     setClickedDeliveryId(val.delivery_id)
  //     setdeleteUser(val);
  //     setshowdelete(true);
  //   }
  // };

  useEffect(() => {
    setloading(true);
    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      } else {
        setloading(true);
        Axios?.get(`${endPoint}dispute/v1/ng/disputes`, {
          headers: {
            Authorization: localStorage.getItem("o_kj_"),
          },
        })
          .then((response) => {
            console.log(response, "response-delivery ");
            if (response?.status === 200) {
              const disputeData = response?.data?.data[0]?.data
              const sortedRepos = disputeData.sort((a, b) => new Date(b.date.date_created) - new Date(a.date.date_created));
              setDispute(sortedRepos);
              setloading(false);
            }
          })
          .catch((error) => {
            console.log(error, "error");
            setloading(false);
          });
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);

  const refetchDisputes = async () => {
    setloading(true);

    await Axios?.get(`${endPoint}dispute/v1/ng/disputes`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response, "resonseee ");
          setDispute(response);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <div className="dispute__resolution--container">
      <div className="dispute__top--container">
        <h2>Dispute Dashboard</h2>
        <div>
          <button onClick={handleOpen} className="dispute__new--btn">
            New Dispute
          </button>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <DisputeForm handleClose={handleClose} />
              </Box>
            </Fade>
          </Modal>
        </div>
      </div>
      {/* <div className="dispute__resolution--stats">
        <div className="dispute__children">
          <h3>Pending Disputes</h3>
          <span>Pending Disputes</span>
          <p>25</p>
          
        </div>
        <div className="dispute__children">
          <h3>Approved Disputes</h3>
          <span>Approved Disputes</span>
          <p>75</p>
        </div>
        <div className="dispute__children">
          <h3>All Disputes</h3>
          <span>All Disputes</span>
          <p>100</p>
          <Link to="/alldisputes">View all Disputes</Link>
        </div>
      </div> */}
      <TableWithPagination
        ident="dispute_id"
        data={dispute}
        inputplaceholder="Search using the Dispute ID"
        props={dispute_props}
        rowsPerPage={5}
        currentPage={0}
        itemsPerPage={10}
        NewData={NewData}
        more="dispute"
        // onFetchSuccess={(val) => setal(val)}
        property_name="Delivery ID"
        linkclicked={(data) => {
          history.push({
            pathname: `/disputes/${data?.dispute_id}/${data?.user_id}`,
            backupdata: dispute,
          });
          localStorage.setItem("backupdata", JSON.stringify(dispute));
        }}
        // refetchDefault={(val) => DefaultFetch(val)}
        refetchDefault={refetchDisputes}
        // selecteddata
        // searchProperty={searchProperty}
      />

      {/* <div className="dispute__resolution--table">
        <h2>Latest Dispute Transactions</h2>
        <table id="thead-data">
          {
            <tr>
              {table_header?.map((item, index) => (
                <>
                  <th>{item}</th>
                </>
              ))}
            </tr>
          }

          <tbody>
            {dummyData?.map((x, i) => (
              <tr key={i} className="table__row">
                <Link to={`/disputes/${x.transactionId}`}>
                  <td>{x?.transactionId}</td>
                </Link>
                <td>{x?.date_created}</td>
                <td>{x?.amount}</td>
                <td>
                  {x?.sender_name}
                </td>
                <td>{x?.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

export default Disputes;
