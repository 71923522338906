import { createContext, useState } from 'react'


export const SettlementContext = createContext([]);

export const SettlementProvider = ({ children }) => {

    //    const [SettData, setSettData] = useState([])
    const [settdata, setsettdata] = useState(undefined)
    const [last_evaluated_key, setlast_evaluated_key] = useState()

    const updateConSettlementData = (data) => {
        setsettdata(data)
    }

    return (
        <SettlementContext.Provider value={{
            updateConSettlementData,
            settdata, last_evaluated_key, setlast_evaluated_key
        }}>
            {children}
        </SettlementContext.Provider>
    )
}