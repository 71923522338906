import React, { useState, useContext, useEffect, useRef } from "react";
import "./accounts.css";
import exporticon from "../../assets/exporticon.svg";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "jspdf-autotable";
import { useHistory } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { DataContext } from "../../context/DataContext/DataProvider";
import { ProviderContext } from "../../context/ProviderContext";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import Axios from "axios";
import { Box } from "@mui/system";
import { useReactToPrint } from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import NewTable from "../../components/DefaultTableFolder/NewTable";
import Pagination from "rc-pagination";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import ReactPaginate from "react-paginate";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function Accounts() {
  // const { settdata, updateConSettlementData } = useContext(SettlementContext);
  const { provider, setProvider } = useContext(ProviderContext);
  const { datas, updateData } = useContext(DataContext);
  const [isloading, setIsloading] = useState(false);
  const history = useHistory();
  const endPoint = process.env.REACT_APP_API;
  const [search, setsearch] = useState("");
  const componentRef = useRef();
  const [paginateActivePage, setPaginateActivePage] = useState(1);
  const n = 100;
  const [itemLength, setitemLength] = useState(0);
  //  const [perpage, setperpage] = React.useState(10);
  // const [dataToShow, setdataToShow] = useState([])
  const [accountData, setaccountData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [lastApiCallTimestamp, setlastApiCallTimestamp] = useState(
    new Date().getTime()
  );
  const [dataToShow, setdataToShow] = useState();
  const [dataToShowduplicate, setdataToShowduplicate] = useState();
  const [showlasticon, setShowLastIcon] = useState(false);
  const [itemselected, setItemSelected] = useState(false);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {/* <Button variant="outlined" size="large" onClick={() => setshow(!show)}>Add filter <KeyboardArrowDownIcon/> </Button>
          {show ? <Drop_down /> : null} */}
        <h4>Accounts</h4>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  // const handlePrint = useReactToPrint({
  // content: () => componentRef.current,
  // });
  const filterData = (e) => {
    setsearch(e.target.value.toLowerCase());
    const filteredData = accountData.filter(
      (names) =>
        names.account_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        names.account_holder.first_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        names.account_holder.last_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    updateDataView(filteredData);
  };
  const handlevalue = (event) => {
    setPerPage(event.target.value);
    setdataToShow([...accountData].slice(0, event.target.value));
  };
  const updateDataView = (datum) => {
    setdataToShow(datum.slice(0, perPage));
    setitemLength([...datum].length);
  };

  const Tabledetails = [
    "Provider",
    "Account name",
    "Account number",
    "Bank name",
  ];

  const getData = (current, pageSize) => {
    // console.log(dataToShow, 'datatshooo')
    // console.log(data?.data?.data, 'datatatat')

    if (accountData === undefined) {
      return accountData?.slice((current - 1) * pageSize, current * pageSize);
    } else {
      return accountData?.slice((current - 1) * pageSize, current * pageSize);
    }
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(accountData.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <MdKeyboardArrowLeft />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <MdKeyboardArrowRight />
        </button>
      );
    }
    return originalElement;
  };

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  useEffect(() => {
    const lastPage = Math.ceil(accountData?.length / size);
    const isLastPage = current === lastPage;

    if (current === 1) {
      console.log(`stay active PAGE${current}`);
    }
    if (current === 2) {
      console.log(`in active page${current}`);
    }
    if (isLastPage) {
      console.log(`stay active, lAST PAGE${current}`);
    }

    if (current === 1 || isLastPage) {
      console.log("valid");
      setShowLastIcon(true);
    } else {
      console.log("Not Valid");
      setShowLastIcon(false);
    }
  }, [current, size, accountData?.length]);

  const Datatouse = accountData.map((x, i) => [
    [[x?.account_holder.first_name + "  " + x?.account_holder.last_name]].join(
      ""
    ),
    [x?.account_name].join(""),

    [x?.bank_detail.account_number].join(""),

    [x?.bank_detail.bank_name].join(""),
  ]);

  const realresult = Datatouse.map((x) => ({
    ProfileName: x[0],
    AccountName: x[1],
    AccountNumber: x[2],
    BankName: x[3],
  }));

  useEffect(() => {
    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        console.log("session expired");
        setIsloading(false);
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            history.push(`/`);
            setIsloading(false);
            localStorage.clear();
          }
        });
      } else if (provider !== undefined) {
        updateDataView(provider.data.fulfillers.active_accounts);
        setaccountData(provider.data.fulfillers.active_accounts);
      } else {
        setIsloading(true);
        Axios.post(
          `${endPoint}v2/m-platform-admin-dashboard`,
          {
            requestType: "get_providers",
          },
          {
            headers: {
              Authorization: localStorage.getItem("o_kj_"),
            },
          }
        )
          // Axios.get(`${endPoint}m-dillivry-dashboard-manager`, {
          //   headers: {
          //     Authorization: localStorage.getItem("o_kj_"),
          //   }
          // })
          .then((response) => {
            console.log(response.data.fulfillers.active_accounts, "data------");
            // updateData(response)
            setProvider(response);
            setIsloading(false);

            updateDataView(response.data.fulfillers.active_accounts);
            setaccountData(response.data.fulfillers.active_accounts);
          })
          .catch((err) => {
            console.log(err);

            if (err?.response?.status === 403) {
              setIsloading(false);
              Swal.fire({
                icon: "error",
                title: "Session Timeout",
                showCancelButton: false,
              });
              history.push(`/`);
            } else {
              setIsloading(false);
              Swal.fire({
                icon: "error",
                title: "error",
                allowOutsideClick: false,
                confirmButtonColor: "var(--main)",
                showCancelButton: false,
              }).then((result) => {
                if (result.value) {
                  history.push(`/`);
                }
              });
            }
          });
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);
  const columns = [
    {
      field: "account_holder",
      headerName: "Profile name",
      renderCell: (params) => (
        <div>
          {params.row.account_holder.first_name +
            " " +
            params.row.account_holder.last_name}
        </div>
      ),
      minWidth: 180,
      flex: 1,
    },
    {
      field: "account_name",
      headerName: "Account name",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "bank_detail",
      headerName: "Account number",
      renderCell: (params) => (
        <div>{params.row.bank_detail.account_number}</div>
      ),
      minWidth: 170,
      flex: 1,
    },
    {
      field: "bank_details",
      headerName: "Bank name",
      renderCell: (params) => <div>{params.row.bank_detail.bank_name}</div>,
      minWidth: 170,
      flex: 1,
    },
    // {
    //   field: "Providers",
    //   headerName: "Providers info",
    //   width:200,
    //   renderCell: (params) => <img src={profile} onClick={() => history.push(`/users/${params.id}`)}
    //   style={{paddingLeft:"20px"}}/>,
    // },
    // {
    //   field: "message",
    //   headerName: "Message",
    //   width:200,
    //   renderCell: (params) => <img src={smsicon} onClick={() => history.push(`/messages/view`)} style={{paddingLeft:"20px"}}/>,
    // },
  ];
  const [pageSize, setPageSize] = useState(10);
  const handleGetRowId = (e) => {
    return e.account_name;
  };
  return (
    <div className="main-grand">
      {isloading ? (
        <Box sx={{ width: "100%", marginBottom: "5px" }}>
          <LinearProgress color="success" />
        </Box>
      ) : null}
      {/* <div className="main-parent">
        <div className="main-father">
          <header className="Acct-header">
            <h4>Accounts</h4>
          </header>
          <div className='search' >
                <div className='search-input'>
                    <input
                    type="text"
                    value={search}
                    placeholder="search.."  onChange={filterData}/>
                </div>
            </div>
            <CSVLink data={realresult}>  <img src={exporticon} alt="exporticon" /></CSVLink>
          </div>
        <div>
          <div className="viewmenudesktop">
          <div className="flexboxes">
                <span
                  className="paginateButtons2"
                  style={{
                    color: paginateActivePage === 1 ? "white" : "",
                    backgroundColor: paginateActivePage === 1 ? "var(--main)" : "",
                    cursor: paginateActivePage === 1 ? "not-allowed" : "pointer",
                  }}
                  onClick={() => {
                    setdataToShow([...accountData].slice(0, n));
                    setPaginateActivePage(1);
                  }}
                >
                  First
                </span>
                {paginateActivePage === 1 ? (
                  <span
                    className="paginateButtons2"
                    style={{ color: "#989898", cursor: "not-allowed" }}
                  >
                    Previous
                  </span>
                ) : (
                  <span
                    className="paginateButtons2"
                    onClick={() => {
                      setdataToShow(
                        [...accountData].slice(
                          (paginateActivePage - 2) * n,
                          (paginateActivePage - 1) * n
                        )
                      );
                      setPaginateActivePage(paginateActivePage - 1);
                    }}
                  >
                    Previous
                  </span>
                )}
                <span className="pagination">
                <ol>
                {[...Array(Math.ceil(itemLength / n))].map((e, i) => (
                <li key={i} className={paginateActivePage === i + 1 ? "active paginateButtons" : "paginateButtons"}
                style={{
                    backgroundColor:
                      paginateActivePage === i + 1 ? "var(--main)" : "",
                    color: paginateActivePage === i + 1 ? "white" : "",
                  }}
                onClick={() => {
                  setdataToShow([...accountData].slice(perpage * i,perpage * i + perpage));
                  setPaginateActivePage(i + 1);
                }}>
                  <a href="#">{i + 1}</a>
              </li>
            ))}
          </ol>
                   </span>
                {paginateActivePage === Math.ceil(itemLength / n) ? (
                  <span
                    className="paginateButtons2"
                    style={{ color: "#989898", cursor: "not-allowed" }}
                  >
                    Next
                  </span>
                ) : (
                  <span
                    className="paginateButtons2"
                    onClick={() => {
                      setdataToShow(
                        [...accountData]?.slice(
                          n * paginateActivePage,
                          n * (paginateActivePage + 1)
                        )
                      );
                      setPaginateActivePage(paginateActivePage + 1);
                    }}
                  >
                    Next
                  </span>
                )}
                <span
                  className="paginateButtons2"
                  style={{
                    backgroundColor:
                      paginateActivePage === Math.ceil(itemLength / n)
                        ? "var(--main)"
                        : "",
                    color:
                      paginateActivePage === Math.ceil(itemLength / n)
                        ? "white"
                        : "",
                  }}
                  onClick={() => {
                    setdataToShow(
                      [...accountData]?.slice(
                        Math.floor(itemLength / n) * n,
                        itemLength
                      )
                    );
                    setPaginateActivePage(Math.ceil(itemLength / n));
                  }}
                >
                  Last
                </span>
              </div>
          </div>
        </div>
      </div> */}

      {/* <div style={{ width: "100%" }}>
        <DataGrid
          density="comfortable"
          sx={{
            p: "30px",
            background: "#fff",
            '& .MuiDataGrid-cell': {
              whiteSpace: "break-spaces !important",
            },
          }}
          rows={
            accountData !== undefined && accountData.length !== 0
              ? accountData
              : []
          }
          columns={columns}
          pageSize={pageSize}
          autoHeight={true}
          // onCellClick={(params, event, details) =>{

          //   history.push({
          //     pathname: `/delivery/${params.row.delivery_id}`,
          //     state: paginateActivePage,
          //   })}
          // }
          onPageSizeChange={(value) => setPageSize(value)}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={handleGetRowId}
          components={{
            Toolbar: CustomToolbar,
          }}
        // checkboxSelection
        />
      </div> */}
      <Breadcrumbs/>

      <div className="table_container">
        <div className="table-filter-info">
          <div
            style={{
              position: "relative",
            }}
          ></div>
          <div className="paginated__container">
            <div>
              <div>
                <Pagination
                  className="pagination-data"
                  onChange={PaginationChange}
                  total={accountData?.length}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                  itemRender={PrevNextArrow}
                  onShowSizeChange={PerPageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <table id="thead-data">
          {
            <tr>
              {Tabledetails?.map((item, index) => (
                <>
                  <th>{item}</th>
                </>
              ))}
            </tr>
          }

          <tbody>
            {accountData?.map((x, i) => (
              <tr key={i} className="table__row">
                <>
                  <td>
                    {[
                      [
                        x?.account_holder.first_name +
                          "  " +
                          x?.account_holder.last_name,
                      ],
                    ].join("")}
                  </td>
                  <td>{x.account_name}</td>
                  <td>{x?.bank_detail?.account_number}</td>
                  <td>{x?.bank_detail?.bank_name}</td>
                </>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
