// import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as React from 'react';
// import Controls from "./controls/Controls";
// import CloseIcon from '@material-ui/icons/Close';
import CloseIcon from "@mui/icons-material/Close"
import "./dialog.css";
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

function DialogPop({ head, children, openPopup, setopenPopup, onClick }) {
    // const {head, children, openPopup, setopenPopup} = props;
    return (
       
        <Dialog open={openPopup} fullWidth >
            <DialogTitle>
                <div style={{ display: "flex" }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }} onClick={onClick}>
                        {head}
                    </Typography>
                    <CloseIcon  className="closicon"  onClick={() => { setopenPopup(false) }}></CloseIcon>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
        
        
    )
}
export default DialogPop;