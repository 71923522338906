/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import "./Corporate.css";
import PuffLoader from "react-spinners/PuffLoader";
import approvedicon from "../../assets/approvedicon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import formdocumenticon from "../../assets/formdocumenticon.svg";
import { FormControl, Button, InputLabel } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import "jspdf-autotable";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";
import InputField from "../../InputField";
import Navbar from "../../components/Navbar";
import "./UserDrilldown.css";
import { RiLockPasswordLine } from "react-icons/ri";
import Verified from "../../assets/Verified.svg";
import ValidPopup from "../../components/DialogPop/ValidPopup";
import axios from "axios";
import DialogOutClick from "../../components/DialogOutClick";
import { ConnContext } from "../../context/ConnContext";
import { HiOutlineMail } from "react-icons/hi";
import SecureLS from "secure-ls";
import PhoneNumber from "../AdminUsers/PhoneNumber";
import { ProviderContext } from "../../context/ProviderContext";
import { Grid } from "@mui/material";
import { Capitalizer } from "../../utils/Capitalizer";
import Avatar from "@mui/material/Avatar";
import {
  AiFillCloseSquare,
  AiOutlineSearch,
  AiOutlineWarning,
} from "react-icons/ai";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "rc-pagination";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import TableWithPagination from "../../components/DefaultTableFolder/TablePaginated";

const Userdrilldown = () => {
  const [pageSize, setPageSize] = useState(10);

  const [fetchingTransa, setfetchingTransa] = useState(false);
  const [fetchingFeedback, setfetchingFeedback] = useState(false);
  const [providerTransactions, setproviderTransactions] = useState([]);
  const [pendingDeleveries, setPendingDeleveries] = useState([]);
  const [providerFeedback, setproviderFeedback] = useState([]);
  const [acctName, setacctName] = useState("");
  const [permanentlyDelete, setpermanentlyDelete] = useState("");
  const [ShowPerma, setShowPerma] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [showlasticon, setShowLastIcon] = useState(false);
  const [dataToShow, setdataToShow] = useState();

  const [actions, setactions] = useState([]);
  const [item_to_modify, setitem_to_modify] = useState([]);
  const [showdelete, setshowdelete] = useState(false);
  const [deleteuser, setdeleteUser] = useState();
  const [selectedItem, setSelectedItem] = useState()
  const [clickedDeliveryId, setClickedDeliveryId] = useState()


  const Corporate_props = [
    "Delivery Address",
    "Delivery ID",
    "Delivery Name",
    "Delivery Phone Number",
    "Delivery Time Frame",
  ];

  const NewData = [
    "delivery_details.delivery_address",
    "delivery_id",
    "delivery_details.delivery_contact.delivery_name",
    "delivery_details.delivery_contact.delivery_phone_no",
    "delivery_details.delivery_time_frame",
  ];

  const getUserTransactions = async (acct, reg_county) => {
    setacctName(acct);
    setfetchingTransa(true);
    axios
      // .get(`${endPoint}platform/v3/ng/provider_jobs?value=${acct}`, {
      .get(`${endPoint}admin/v1/ng/provider_jobs?value=${acct}`, {
        headers: {
          Authorization: localStorage.getItem("o_kj_"),
        },
      })
      .then((res) => {
        console.log(res.data.data[0], "response--transc");
        // setproviderTransactions(res?.data?.data)
        setproviderTransactions(res?.data?.data[0]);
      })
      .catch((err) => {
        console.log(err, "error");
      })
      .finally(() => {
        setfetchingTransa(false);
      });
  };

  // delete a pending delivery
  const handleRemovePending = async (e) => {
    setsubmitting(true);
    await authAxios
      .post(
        `${endPoint}admin/v1/ng/remove-from-list?delivery_id=${clickedDeliveryId}&provider_id=${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("o_kj_"),
          },
        }
      )
      .then((res) => {
        setsubmitting(false);
        console.log(res);
        if (res.status === 200) {
          setshowdelete(false);
          Swal.fire({
            icon: "success",
            title: "Delivery Removed Successfully",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 6000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setsubmitting(false);
        setshowdelete(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: error,
            confirmButtonColor: "grey",
          });
        }
      });
  };

  // get pending deliveries
  const getUserPendingDeleveries = async (acct) => {
    setacctName(acct);
    setfetchingTransa(true);
    axios
      // .get(`${endPoint}platform/v3/ng/provider_details?provider_id=${acct}`, {
      .get(`${endPoint}admin/v1/ng/provider_details?provider_id=${acct}`, {
        headers: {
          Authorization: localStorage.getItem("o_kj_"),
        },
      })
      .then((res) => {
        console.log(res, "response--transc");
        // setproviderTransactions(res?.data?.data)
        setPendingDeleveries(res?.data?.data[0]);
      })
      .catch((err) => {
        console.log(err, "error");
      })
      .finally(() => {
        setfetchingTransa(false);
      });
  };

  const getFeedback = async (acct, reg_county) => {
    console.log(acct, "acctttt");
    setacctName(acct);
    setfetchingFeedback(true);
    axios
      .get(
        // `${endPoint}m-platform-admin-dashboard?requestType=get_feedback&provider_id=${acct}&country_code=${reg_county}`,
        // `${endPoint}platform/v3/ng/provider_feedbacks?value=${acct}`,
        `${endPoint}admin/v1/ng/provider_feedbacks?value=${acct}`,
        {
          headers: {
            Authorization: localStorage.getItem("o_kj_"),
          },
        }
      )
      .then((res) => {
        console.log(res?.data?.data[0]?.data, "ressss-feedback");
        setproviderFeedback(res?.data?.data[0]?.data);
      })
      .catch((err) => {
        console.log(err, "error");
      })
      .finally(() => {
        setfetchingFeedback(false);
      });
  };

  const location = useLocation();
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const [submitting, setsubmitting] = useState(false);
  const [rejecting, setrejecting] = useState(false);
  const [comment, setcomment] = useState("");
  const endPoint = process.env.REACT_APP_API;
  const [emailarray, setemailarray] = useState([]);
  const [email, setemail] = useState("");
  const [ios, setios] = useState("");
  const [iemi, setiemi] = useState("");
  const [iosversion, setiosversion] = useState("");
  const [emailsubject, setemailsubject] = useState("");
  const [emailtextarea, setemailtextarea] = useState("");
  const [emailreset, setemailreset] = useState("");
  const [smssubject, setsmssubject] = useState("");
  const [sms, setsms] = useState("");
  const [phone_num, setphone_num] = useState("");
  const [showing, setshowing] = useState("Profile");
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const {
    updateProviders,
    providers,
    ValPhoto,
    rejectPhoto,
    valMessage,
    rejectMessage,
    valFormID,
    rejectFormID,
    valInsurance,
    rejectInsurance,
    rejectBank,
    valBank,
    generalVal,
  } = useContext(ProviderContext);

  const [showpop, setshowpop] = useState(false);
  const [clicked, setclicked] = useState("");
  const [corporatedetails, setcorporatedetails] = useState([]);
  const [showReason, setshowReason] = useState(false);
  const [updateinsuranceamount, setupdateinsuranceamount] = useState(false);
  const [toreject, setToreject] = useState("");
  const [showAmount, setshowAmount] = useState(false);
  const [amount, setamount] = useState("");
  const numberWithComma = new Intl.NumberFormat("en-GB");
  const [Showpop, setShowpop] = useState(false);
  const [showreset, setShowreset] = useState(false);
  const [Showpopemail, setShowpopemail] = useState(false);
  const [Showpopsms, setShowpopsms] = useState(false);
  const [Showpopsuspend, setShowpopsuspend] = useState(false);
  const [Showpopenable, setShowpopenable] = useState(false);
  const [Showpopresetpassword, setShowpopresetpassword] = useState(false);
  const [addingUser, setaddingUser] = useState(false);
  const [newcorp, setNewCorp] = useState([]);
  const [searchField, setSearchField] = useState("");

  const checkInsurance = () => {
    if (corporatedetails[0].good_in_transit_insurance.policy_number === "") {
      validateInsurance("validate");
    } else {
      setshowAmount(true);
    }
  };

  let { id } = useParams();

  // const endpoint = process.env.REACT_APP_API;
  // const endPoint2 = process.env.REACT_APP_API_PLATFORM;

  const { visitingCountryCode } = useContext(ConnContext);

  const refetch = () => {
    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      } else {
        setloading(true);
        Axios.get(
          // `${endPoint}platform/v3/ng/provider_details?provider_id=${id}`,
          `${endPoint}admin/v1/ng/provider_details?provider_id=${id}`,
          {
            headers: {
              Authorization: localStorage.getItem("o_kj_"),
            },
          }
        )
          .then((response) => {
            console.log(response, "responseee");
            if (response.status === 200) {
              setcorporatedetails(response?.data?.data);
              setemailarray(response?.data?.data?.user_id);
              updateProviders(response);
            }
          })
          .catch((err) => console.log(err?.response))
          .finally(() => setloading(false));
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  };

  // Table Pagination
  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const getData = (current, pageSize) => {
    if (dataToShow === undefined && corporatedetails[0]?.pending_deliveries) {
      return corporatedetails[0]?.pending_deliveries?.slice(
        (current - 1) * pageSize,
        current * pageSize
      );
    } else {
      return dataToShow?.slice((current - 1) * pageSize, current * pageSize);
    }
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(
      corporatedetails[0]?.pending_deliveries.length / value
    );
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <MdKeyboardArrowLeft />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <MdKeyboardArrowRight />
        </button>
      );
    }
    return originalElement;
  };

  useEffect(() => {
    const lastPage = Math.ceil(
      corporatedetails[0]?.pending_deliveries?.length / size
    );
    const isLastPage = current === lastPage;

    if (current === 1) {
      console.log(`stay active PAGE${current}`);
    }
    if (current === 2) {
      console.log(`in active page${current}`);
    }
    if (isLastPage) {
      console.log(`stay active, lAST PAGE${current}`);
    }

    if (current === 1 || isLastPage) {
      console.log("valid");
      setShowLastIcon(true);
    } else {
      console.log("Not Valid");
      setShowLastIcon(false);
    }
  }, [current, size, corporatedetails[0]?.pending_deliveries?.length]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredDelivery = corporatedetails[0]?.pending_deliveries?.filter(
    (id) => {
      return console.log(
        id.delivery_id.toLowerCase().includes(searchValue.toLowerCase()),
        "searchid---"
      );
      // id.delivery_id.toLowerCase().includes(searchValue.toLowerCase())
    }
  );

  console.log(
    corporatedetails[0]?.pending_deliveries[0],
    "filteredDelivery---"
  );

  const table_header = [
    "Delivery Address",
    "Delivery ID",
    "Delivery Name",
    "Delivery Phone Number",
    "Delivery Time Frame",
  ];

  function searchList() {
    return <>{filteredDelivery}</>;
  }

  const Cards = ({ x, i, title, stateCheck, type }) => {
    return (
      <div>
        {stateCheck?.state === undefined ||
        stateCheck?.state === "not uploaded" ? (
          <div className="profile-photo-pending">{`${title} not uploaded yet`}</div>
        ) : (
          <div
            key={i}
            className={
              stateCheck?.state === "verified"
                ? "profile-photo"
                : stateCheck?.state === "not_verified" ||
                  stateCheck?.state === "Pending admin review"
                ? "profile-photo-pending"
                : "profile-photo-reject"
            }
            onClick={() => {
              setshowpop(!showpop);
              setclicked(type);
            }}
          >
            <div className="photoicon">
              {stateCheck?.state === "verified" ? (
                <img src={Verified} alt="form-icons" />
              ) : stateCheck?.state === "not_verified" ||
                stateCheck?.state === "Pending admin review" ? (
                <img src={formdocumenticon} alt="form-icons" />
              ) : (
                <img src={formdocumenticon} alt="form-icons" />
              )}
            </div>
            <div className="photo-text">
              <p className="upper-text">{title}</p>
              <p className="status-verified-or-pending">
                {stateCheck?.state === "verified"
                  ? "Verified"
                  : stateCheck?.state === "not_verified" ||
                    stateCheck?.state === "Pending admin review"
                  ? "Pending"
                  : "Rejected"}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const authAxios = axios.create({
    baseURL: `${endPoint}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });

  const validateProfileMessage = (type) => {
    if (type === "validate") {
      setsubmitting(true);
      authAxios
        .post(`v2/m-fulfiller-document-verify`, {
          action_type: "validate_acct",
          validation: "validated",
          account_name: corporatedetails[0].account_name,
          attribute_to_change: "profile_message",
          vcc: visitingCountryCode,
        })
        .then((response) => {
          setsubmitting(false);
          if (response.status === 201) {
            // valMessage(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "success",
              title: "Validated",
              showConfirmButton: false,
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
          if (response.status === 200) {
            // valMessage(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "success",
              title: "Validated",
              showConfirmButton: false,
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setsubmitting(false);
          if (err.response?.status === 403) {
            Swal.fire({
              icon: "info",
              text: "Session expired",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                history.push(`/`);
                localStorage.clear();
              }
            });
          }
        });
    }
    if (type === "reject") {
      setrejecting(true);
      authAxios
        // .post(`m-fulfiller-document-verify`, {
        .post(`v2/m-fulfiller-document-verify`, {
          action_type: "validate_acct",
          validation: "rejected",
          account_name: corporatedetails[0].account_name,
          attribute_to_change: "profile_message",
          comment: comment,
          vcc: visitingCountryCode,
        })
        .then((response) => {
          setrejecting(false);
          setshowReason(false);
          setcomment("");
          if (response.status === 200) {
            // rejectMessage(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "info",
              text: "Rejected",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            Swal.fire({
              icon: "info",
              text: "Session expired",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                history.push(`/`);
                localStorage.clear();
              }
            });
          }
        });
    }
  };

  const validateProfile_photo = (type) => {
    console.log(corporatedetails[0], "v--acct");
    if (type === "validate") {
      setsubmitting(true);

      authAxios
        // .post(`m-fulfiller-document-verify`, {
        .post(`v2/m-fulfiller-document-verify`, {
          action_type: "validate_acct",
          validation: "validated",
          account_name: corporatedetails[0]?.account_name,
          attribute_to_change: "profile_photo",
          // vcc: visitingCountryCode,
          vcc: visitingCountryCode,
        })
        .then((response) => {
          setsubmitting(false);
          if (response.status === 201) {
            // ValPhoto(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "success",
              title: "Validated",
              showConfirmButton: false,
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
          if (response?.status === 200) {
            // ValPhoto(corporatedetails[0].user_id);
            // generalVal(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "success",
              title: "Validated",
              showConfirmButton: false,
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setsubmitting(false);
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "info",
              text: "Session expired",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                history.push(`/`);
                localStorage.clear();
              }
            });
          }
        });
    }
    if (type === "reject") {
      setrejecting(true);
      authAxios
        // .post(`m-fulfiller-document-verify`, {
        .post(`v2/m-fulfiller-document-verify`, {
          action_type: "validate_acct",
          validation: "rejected",
          account_name: corporatedetails[0].account_name,
          attribute_to_change: "profile_photo",
          comment: comment,
          vcc: visitingCountryCode,
        })
        .then((response) => {
          setrejecting(false);
          setshowReason(false);
          setupdateinsuranceamount(false);
          setcomment("");
          if (response.status === 201) {
            // rejectPhoto(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "info",
              text: "Rejected",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            Swal.fire({
              icon: "info",
              text: "Session expired",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                history.push(`/`);
                localStorage.clear();
              }
            });
          }
        });
    }
  };

  const validateProof = (type) => {
    if (type === "validate") {
      setsubmitting(true);

      authAxios
        // .post(`m-fulfiller-document-verify`, {
        .post(`v2/m-fulfiller-document-verify`, {
          action_type: "validate_acct",
          validation: "validated",
          account_name: corporatedetails[0].account_name,
          attribute_to_change: "proof_of_identity",
          // vcc: visitingCountryCode,
          vcc: visitingCountryCode,
        })
        .then((response) => {
          console.log(response, "responseeee");
          setsubmitting(false);
          if (response?.status === 201) {
            // setshowpop(false);
            console.log(
              corporatedetails[0]?.user_id,
              "corporatedetails[0].user_id"
            );
            // valFormID(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "success",
              title: "Validated",
              showConfirmButton: false,
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
          if (response.status === 200) {
            // valFormID(corporatedetails[0]?.user_id);
            // generalVal(corporatedetails[0]?.user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "success",
              title: "Validated",
              showConfirmButton: false,
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "info",
              text: "Session expired",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                history.push(`/`);
                localStorage.clear();
              }
            });
          }
        });
    }

    if (type === "reject") {
      setrejecting(true);
      authAxios
        // .post(`m-fulfiller-document-verify`, {
        .post(`v2/m-fulfiller-document-verify`, {
          action_type: "validate_acct",
          validation: "rejected",
          account_name: corporatedetails[0].account_name,
          attribute_to_change: "proof_of_identity",
          comment: comment,
          vcc: visitingCountryCode,
          // vcc: visitingCountryCode,
        })
        .then((response) => {
          setrejecting(false);
          setshowReason(false);
          setupdateinsuranceamount(false);
          setcomment("");

          if (response.status === 201) {
            // rejectFormID(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "info",
              text: "Rejected",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            Swal.fire({
              icon: "info",
              text: "Session expired",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                history.push(`/`);
                localStorage.clear();
              }
            });
          }
        });
    }
  };
  const validateBank = (type) => {
    if (type === "validate") {
      setsubmitting(true);

      authAxios
        // .post(`m-fulfiller-document-verify`, {
        .post(`v2/m-fulfiller-document-verify`, {
          action_type: "validate_acct",
          validation: "validated",
          account_name: corporatedetails[0].account_name,
          attribute_to_change: "bank_detail",
          // vcc: visitingCountryCode,
          vcc: visitingCountryCode,
        })
        .then((response) => {
          setsubmitting(false);
          if (response.status === 201) {
            // valBank(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "success",
              title: "Validated",
              showConfirmButton: false,
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
          if (response.status === 200) {
            // valBank(corporatedetails[0].user_id);
            // generalVal(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "success",
              title: "Validated",
              showConfirmButton: false,
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            Swal.fire({
              icon: "info",
              text: "Session expired",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                history.push(`/`);
                localStorage.clear();
              }
            });
          }
        });
    }
    if (type === "reject") {
      setrejecting(true);

      authAxios
        // .post(`m-fulfiller-document-verify`, {
        .post(`v2/m-fulfiller-document-verify`, {
          action_type: "validate_acct",
          validation: "rejected",
          account_name: corporatedetails[0].account_name,
          attribute_to_change: "bank_detail",
          comment: comment,
          vcc: visitingCountryCode,
          // vcc: visitingCountryCode,
        })
        .then((response) => {
          setrejecting(false);
          setshowReason(false);
          setupdateinsuranceamount(false);
          setcomment("");

          if (response.status === 201) {
            // rejectBank(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "info",
              text: "Rejected",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            Swal.fire({
              icon: "info",
              text: "Session expired",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                history.push(`/`);
                localStorage.clear();
              }
            });
          }
        });
    }
  };
  const validateInsurance = (type) => {
    if (type === "validate") {
      setsubmitting(true);
      // console.log(amount === "" ? "None" :`Good in Transit up to N${numberWithComma.format(parseInt(amount))}`)

      authAxios
        // .post(`m-fulfiller-document-verify`, {
        .post(`v2/m-fulfiller-document-verify`, {
          action_type: "validate_acct",
          validation: "validated",
          account_name: corporatedetails[0].account_name,
          attribute_to_change: "good_in_transit_insurance",
          insurance_coverage:
            amount === ""
              ? "None"
              : `Good in Transit up to N${numberWithComma.format(
                  parseInt(amount)
                )}`,
          // vcc: visitingCountryCode,
          vcc: visitingCountryCode,
        })
        .then((response) => {
          console.log(response, "response");
          setsubmitting(false);
          setshowAmount(false);
          if (response?.status === 201) {
            // valInsurance(corporatedetails[0]?.user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "success",
              title: "Validated",
              showConfirmButton: false,
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
          if (response.status === 200) {
            // valInsurance(corporatedetails[0].user_id);
            // generalVal(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "success",
              title: "Validated",
              showConfirmButton: false,
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            Swal.fire({
              icon: "info",
              text: "Session expired",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                history.push(`/`);
                localStorage.clear();
              }
            });
          }
        });
    }
    if (type === "reject") {
      setrejecting(true);
      authAxios
        // .post(`m-fulfiller-document-verify`, {
        .post(`v2/m-fulfiller-document-verify`, {
          action_type: "validate_acct",
          validation: "rejected",
          account_name: corporatedetails[0].account_name,
          attribute_to_change: "good_in_transit_insurance",
          comment: comment,
          vcc: visitingCountryCode,
          // vcc: visitingCountryCode,
        })
        .then((response) => {
          setrejecting(false);
          setshowReason(false);
          setcomment("");
          if (response.status === 201) {
            // rejectInsurance(corporatedetails[0].user_id);
            refetchData();
            setshowpop(false);
            Swal.fire({
              icon: "info",
              text: "Rejected",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            Swal.fire({
              icon: "info",
              text: "Session expired",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                history.push(`/`);
                localStorage.clear();
              }
            });
          }
        });
    }
  };

  const handleDelete = async (e) => {
    setsubmitting(true);

    await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "delete_user",
        email: corporatedetails[0]?.user_id,
        vcc: visitingCountryCode,
      },
    })
      .then((res) => {
        setsubmitting(false);

        if (res.status === 200) {
          setShowpop(false);
          Swal.fire({
            icon: "success",
            title: "User deleted successfully",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 2000,
          }).then(() => {
            refetch();
          });
        }
      })
      .catch((error) => {
        // console.log(error.response);
        setsubmitting(false);
        setShowpop(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.error}`,
            confirmButtonColor: "grey",
          });
        }
      })
      .finally(() => setpermanentlyDelete(""));
  };

  const handleDeletePerma = async (e) => {
    setsubmitting(true);
    await Axios.delete(`${endPoint}adminportal/v3/ng/remove_user`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        user_id: corporatedetails[0]?.user_id,
      },
    })
      .then((res) => {
        setsubmitting(false);

        if (res.status === 200) {
          setShowPerma(false);
          Swal.fire({
            icon: "success",
            title: "User deleted permanently successfully",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 2000,
          }).then(() => {
            history.goBack();
          });
        }
      })
      .catch((error) => {
        setsubmitting(false);
        setShowPerma(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.error}`,
            confirmButtonColor: "grey",
          });
        }
      })
      .finally(() => setpermanentlyDelete(""));
  };

  const handleSuspendacct = async (e) => {
    setsubmitting(true);
    await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "disable_account",
        account_name: corporatedetails[0].account_name,
        email: corporatedetails[0].user_id,
        country_code: visitingCountryCode,
      },
    })
      .then((res) => {
        setsubmitting(false);

        // console.log(res);
        if (res.status === 200) {
          setShowpopsuspend(false);
          Swal.fire({
            icon: "success",
            title: "Account Suspended",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 6000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setsubmitting(false);
        setShowpopsuspend(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };

  const handleenableacct = async (e) => {
    setsubmitting(true);
    await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "enable_account",
        account_name: corporatedetails[0].account_name,
        email: corporatedetails[0].user_id,
        country_code: visitingCountryCode,
      },
    })
      .then((res) => {
        setsubmitting(false);
        if (res.status === 200) {
          setShowpopenable(false);
          Swal.fire({
            icon: "success",
            title: "Account Enabled",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 6000,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        setsubmitting(false);
        setShowpopenable(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };

  
  const actionClick = (val, row) => {
    setitem_to_modify(row);
    if (val === "Delete") {
      console.log(row, "row--");
      setshowdelete(false);
      setSelectedItem(row)
      // setShowpopup(true)
    }
  };

  const actionselectedClick = (val) => {
    console.log(val.delivery_id, "vallll");
    if (val?.delivery_id) {
      setClickedDeliveryId(val.delivery_id)
      setdeleteUser(val);
      setshowdelete(true);
    }
  };

  const handlesendEmial = async (e) => {
    e.preventDefault();
    setsubmitting(true);

    const authAxios = Axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    });
    await authAxios
  
      .post(`${endPoint}adminportal/v3/ng/send_email`, {
        email: emailarray,
        email_subject: emailsubject,
        message_body: emailtextarea,
      })
      .then((response) => {
        if (response) {
          setShowpopemail(!Showpopemail);
          setsubmitting(false);
          if (response.status === 200) {
            // console.log(response);
            setShowpopemail(!Showpopemail);
            setemailarray([]);
            // setemail("");
            setemailsubject("");
            setemailtextarea("");

            Swal.fire({
              icon: "success",
              title: "Email Sent",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 6000,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error?.response);
        setShowpopemail(!Showpopemail);
        setsubmitting(false);
        if (error) {
          setemail("");
          setemailsubject("");
          setemailtextarea("");
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.msg}`,
            confirmButtonColor: "red",
          });
        }
      });
  };

  const handledevicereset = async (e) => {
    e.preventDefault();
    setsubmitting(true);
    const authAxios = Axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    });
    await authAxios
      .patch(`${endPoint}m-dillivry-admin-portal`, {
        action_type: "update_device",
        email: emailreset,
        ios: ios,
        //  ios_version: iosversion,
        //  iemi: iemi
      })
      .then((response) => {
        if (response) {
          setShowreset(!showreset);
          setsubmitting(false);
          if (response.status === 200) {
            setShowreset(!showreset);
            setemailreset("");
            setios("");
            setiosversion("");
            setiemi("");

            Swal.fire({
              icon: "success",
              title: "Sms Sent",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 6000,
            });
          }
        }
      })
      .catch((error) => {
        // setShowpopemail(!Showpopemail);
        console.log(error.response);
        setShowreset(!showreset);
        setsubmitting(false);
        if (error) {
          setemailreset("");
          setios("");
          setiosversion("");
          setiemi("");
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "red",
          });
        }
      });
  };

  const handlesendSms = async (e) => {
    e.preventDefault();
    setsubmitting(true);

    const authAxios = Axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    });
    await authAxios
      // .post(`${endPoint}m-dillivry-admin-portal`, {
      //   action_type: "send_sms",
      //   phone_number: phone_num,
      //   message_body: smssubject,
      // })
      .post(`${endPoint}adminportal/v3/ng/send_sms`, {
        phone_number: phone_num,
        message_body: smssubject,
      })
      .then((response) => {
        if (response) {
          setShowpopsms(!Showpopsms);
          setsubmitting(false);
          if (response.status === 200) {
            // console.log(response);
            setShowpopsms(!Showpopsms);
            setphone_num(" ");
            setsmssubject("");

            Swal.fire({
              icon: "success",
              title: "Sms Sent",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 6000,
            });
          }
        }
      })
      .catch((error) => {
        // setShowpopemail(!Showpopemail);
        setShowpopsms(!Showpopsms);
        console.log(error?.response);
        setsubmitting(false);
        if (error) {
          setemail("");
          setphone_num(" ");
          setsmssubject("");
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "red",
          });
        }
      });
  };

  const handleresetPassword = (e) => {
    setsubmitting(true);
    // authAxios.patch(`m-dillivry-admin-portal`, {
    authAxios
      .patch(`adminportal/v3/ng/reset_password`, {
        // action_type: "reset_password",
        email: corporatedetails[0].user_id,
        // country_code: visitingCountryCode,
        // vcc: visitingCountryCode
      })
      .then((response) => {
        // console.log(response)
        setsubmitting(false);
        setShowpopresetpassword(false);
        Swal.fire({
          icon: "success",
          text: `A reset passcode has been sent to this user`,
          showConfirmButton: false,
          confirmButtonColor: "var(--main)",
          timer: 3000,
        });
      })
      .catch((error) => {
        setsubmitting(false);
        setShowpopresetpassword(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.msg}`,
            confirmButtonColor: "grey",
          });
        }
      });
  };

  const refetchData = () => {
    Axios.get(
      // `${endPoint}platform/v3/ng/provider_details?provider_id=${id}`,
      `${endPoint}admin/v1/ng/provider_details?provider_id=${id}`,
      // {
      //   requestType: "get_providers",
      // },
      {
        headers: {
          Authorization: localStorage.getItem("o_kj_"),
        },
      }
    )
      .then((response) => {
        console.log(response, "responseee");
        if (response.status === 200) {
          setcorporatedetails(response?.data?.data);
          setemailarray(response?.data?.data?.user_id);
          updateProviders(response);
        }
      })
      .catch((err) => console.log(err?.response))
      .finally(() => setloading(false));
  };

  useEffect(() => {
    // console.log(providers, 'providersss')

    if (
      localStorage.getItem("o_kj_") !== null ||
      localStorage.getItem("o_kj_") !== ""
    ) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      } else {
        if (
          providers !== undefined &&
          providers?.data?.data[0]?.data?.length <= 1
        ) {
          console.log(
            providers?.data?.data[0]?.data?.length,
            "providersss--new"
          );
          setcorporatedetails(providers.data.data[0]);
          console.log(
            providers?.data?.data[0]?.data.filter(
              (x) => x?.account_name === id
            ),
            "00000"
          );

          setcorporatedetails(
            providers?.data?.data[0]?.data.filter((x) => x?.account_name === id)
          );

          // setemailarray(dataCD[0].user_id)
          setemailarray(providers.user_id);
          // setphone_num(dataCD[0]?.phone_number.phone_number)
          setphone_num(providers.data.data[0].user_id);
        } else {
          setloading(true);
          // Axios.post(
          //   `${endPoint}m-platform-admin-dashboard`,
          Axios.get(
            // `${endPoint}platform/v3/ng/provider_details?provider_id=${id}`,
            `${endPoint}admin/v1/ng/provider_details?provider_id=${id}`,
            // {
            //   requestType: "get_providers",
            // },
            {
              headers: {
                Authorization: localStorage.getItem("o_kj_"),
              },
            }
          )
            .then((response) => {
              console.log(response, "responseee");
              if (response.status === 200) {
                setcorporatedetails(response?.data?.data);
                setemailarray(response?.data?.data?.user_id);
                updateProviders(response);
              }
            })
            .catch((err) => console.log(err?.response))
            .finally(() => setloading(false));
        }
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);

  const columns = [
    {
      field: "delivery_id",
      headerName: "Delivery ID",
      flex: 1.5,
      renderCell: (params) => params.row.delivery_id,
    },
    {
      field: "delivery_status",
      headerName: "Delivery status",
      flex: 2,
      renderCell: (params) =>
        Capitalizer(params.row.delivery_status).replace(/_/g, " ") || "",
    },

    {
      field: "quote",
      headerName: "Quote amount",
      flex: 2,
      renderCell: (params) =>
        <>&#8358;{numberWithComma.format(params?.row?.quote_amount)}</> || "",
      // renderCell: (params) => <>&#8358;{numberWithComma.format(params?.row?.quote[acctName]?.quote_amount)}</> || "",
    },
  ];

  // const columns2 = [
  //   {
  //     field: "delivery_address",
  //     headerName: "Delivery Address",
  //     flex: 2.5,
  //     renderCell: (params) => params?.row?.delivery_address,
  //   },
  //   {
  //     field: "delivery_id",
  //     headerName: "Delivery ID",
  //     flex: 2.5,
  //     renderCell: (params) => params.row.delivery_id,
  //   },
  //   {
  //     field: "delivery_name",
  //     headerName: "Delivery Name",
  //     flex: 2,
  //     renderCell: (params) => params.row.delivery_name,
  //   },
  //   {
  //     field: "delivery_phone_no",
  //     headerName: "Delivery Phone number",
  //     flex: 2.5,
  //     renderCell: (params) => params.row.delivery_phone_no,
  //   },
  //   {
  //     field: "delivery_status",
  //     headerName: "Delivery status",
  //     flex: 2,
  //     renderCell: (params) =>
  //       Capitalizer(params?.row?.delivery_status).replace(/_/g, " ") || "",
  //   },
  //   {
  //     field: "delivery_time_frame",
  //     headerName: "Delivery time frame",
  //     flex: 2,
  //     renderCell: (params) =>
  //       Capitalizer(params?.row?.delivery_status).replace(/_/g, " ") || "",
  //   },
  //   // {
  //   //   field: "quote",
  //   //   headerName: "Quote amount",
  //   //   flex: 2,
  //   //   renderCell: (params) => <>&#8358;{numberWithComma.format(params.row?.quote_amount)}</> || "",
  //   // },
  //   // {
  //   //   field: "quote",
  //   //   headerName: "Quote amount",
  //   //   flex: 2,
  //   //   renderCell: (params) => <>&#8358;{numberWithComma.format(params.row?.quote_amount)}</> || "",
  //   // },
  //   // {
  //   //   field: "time_stamp",
  //   //   headerName: "Time Stamp",
  //   //   flex: 2,
  //   //   renderCell: (params) => <>&#8358;{numberWithComma.format(params.row?.quote_amount)}</> || "",
  //   //   // renderCell: (params) => <>&#8358;{numberWithComma.format(params.row?.quote[acctName]?.quote_amount)}</> || "",
  //   // },
  // ];

  const tabs = [
    "Profile",
    "Documents",
    "Actions",
    "Transactions",
    "Feedbacks",
    "Pending Deliveries",
  ];

  const Buttoncard = ({
    className,
    onClick,
    disabled,
    color,
    text,
    loading,
  }) => {
    return (
      <div>
        {" "}
        <button
          onClick={onClick}
          className={className}
          disabled={disabled}
          color={color}
        >
          {loading ? (
            <PuffLoader
              color="white"
              loading={loading}
              speedMultiplier={1}
              size={24}
            />
          ) : (
            text
          )}
        </button>
      </div>
    );
  };

  return (
    <div>
      {loading === true ? (
        <div>
          <Box sx={{ width: "100%", marginTop: "2px" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : (
        <div className="main-grand">
          <div className="drill-down-top">
            <Navbar
              dashboardtext="Back to dashboard"
              familyname={localStorage?.getItem("fam")}
              givenname={localStorage?.getItem("name")}
              page={location?.state}
              role={corporatedetails[0]?.role}
            />
          </div>

          {
            corporatedetails?.map((corp) => (
              <div key={corp?.account_id} className="drill-down-main-container">
                <Grid container spacing={4}>
                  <Grid item sm={3}>
                    <div className="cards_container">
                      <Avatar
                        sx={{ width: 65, height: 65 }}
                        alt={corp?.cop_logo}
                        src={`${corp?.cop_logo}`}
                      />
                      <div>
                        <div className="verify_name">
                          {Capitalizer(corp?.account_name)}
                        </div>
                        <div className="verify_flex">
                          {corp.account_status.state === "active" ? (
                            <img src={approvedicon} alt="" />
                          ) : (
                            <AiFillCloseSquare size={16} color="red" />
                          )}
                          <span>Verified</span>
                        </div>
                        <div className="verify_flex">
                          {corp?.good_in_transit_insurance
                            ?.insurance_coverage === "None" ? (
                            <AiFillCloseSquare size={16} color="red" />
                          ) : (
                            <img src={approvedicon} alt="" />
                          )}
                          <span>Insurance</span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={9}>
                    <div className="move_tabs">
                      {tabs?.map((x, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            setshowing(x);
                            if (x === "Transactions") {
                              getUserTransactions(
                                corp?.account_name,
                                corp?.registered_countries[0]?.toLowerCase()
                              );
                            }
                            if (x === "Feedbacks") {
                              getFeedback(
                                corp?.account_name,
                                corp?.registered_countries[0]?.toLowerCase()
                              );
                            }
                            if (x === "Pending Deliveries") {
                              getUserPendingDeleveries(corp?.account_name);
                            }
                          }}
                          className={
                            x === showing ? "tab_flex_active" : "tab_flex"
                          }
                        >
                          {x}
                        </span>
                      ))}
                    </div>

                    {console.log(corp, "corppp")}

                    <div>
                      {showing === "Profile" ? (
                        <div className="move_profile_container">
                          <div className="content_flex">
                            <span className="bold_span">Email</span>
                            <span>{corp?.user_id}</span>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">Phone number</span>
                            <span>{corp?.phone_number?.phone_number}</span>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">Type</span>
                            <span>{Capitalizer(corp?.account_type)}</span>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">Date registered</span>
                            <span>
                              {moment(corp?.account_status.joined_date).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">Status</span>
                            <span>
                              {Capitalizer(corp?.account_status.state).replace(
                                /_/g,
                                " "
                              )}
                            </span>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">Location</span>
                            <span>
                              {corp?.registered_countries[0]?.toLowerCase() ===
                              "ng"
                                ? "Nigeria"
                                : "United kingdom"}
                            </span>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">
                              Means of transport
                            </span>
                            <div
                              style={{
                                width: "400px",
                                display: "flex",
                                gap: "5px",
                                justifyContent: "flex-end",
                              }}
                            >
                              {corp?.mode_of_delivery?.map((x) => (
                                <span
                                  key={x}
                                  className="chipss"
                                  style={{ width: "60px" }}
                                >
                                  {Capitalizer(x)}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">Bank details</span>
                            <div>
                              <div>{corp?.bank_detail?.account_number}</div>
                              <div>{corp?.bank_detail?.bank_name}</div>
                              <div>{corp?.bank_detail?.account_name}</div>
                            </div>
                          </div>
                          <div className="content_flex">
                            <span className="bold_span">Agents</span>
                            <span>list of agent here</span>
                          </div>
                          <div className="content_flex _last">
                            <span className="bold_span">
                              Insurance coverage
                            </span>
                            <span>
                              {corp?.good_in_transit_insurance
                                ?.insurance_coverage === "None"
                                ? "None"
                                : corp?.good_in_transit_insurance
                                    ?.insurance_coverage}
                            </span>
                          </div>
                        </div>
                      ) : showing === "Documents" ? (
                        <div className="move_profile_container">
                          <div className="inner-documents">
                            {corporatedetails?.map((x, i) => {
                              return (
                                <Cards
                                  x={x}
                                  i={i}
                                  title="Profile message"
                                  stateCheck={x?.profile_message}
                                  type="profile_message"
                                />
                              );
                            })}
                            {corporatedetails?.map((x, i) => {
                              return (
                                <Cards
                                  x={x}
                                  i={i}
                                  title="Profile Photo"
                                  stateCheck={x?.profile_photo}
                                  type="profile_photo"
                                />
                              );
                            })}
                            {corporatedetails?.map((x, i) => {
                              return (
                                <Cards
                                  x={x}
                                  i={i}
                                  title="Form of ID"
                                  stateCheck={x?.proof_of_identity}
                                  type="proof_of_identity"
                                />
                              );
                            })}
                            {corporatedetails?.map((x, i) => {
                              return (
                                <Cards
                                  x={x}
                                  i={i}
                                  title="Insurance Policy"
                                  stateCheck={x?.good_in_transit_insurance}
                                  type="good_in_transit_insurance"
                                />
                              );
                            })}
                            {corporatedetails?.map((x, i) => {
                              return (
                                <Cards
                                  x={x}
                                  i={i}
                                  title="Bank Details"
                                  stateCheck={x?.bank_detail}
                                  type="bank_detail"
                                />
                              );
                            })}
                          </div>
                        </div>
                      ) : showing === "Actions" ? (
                        <div className="move_profile_container">
                          <div className="content_flex">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpopresetpassword(true)}
                            >
                              Reset password
                            </span>
                          </div>
                          {/* <div className="content_flex">
                                  <span className="cursor_span" >Update profile</span>
                                </div> */}
                          <div className="content_flex">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpopemail(true)}
                            >
                              Email user
                            </span>
                          </div>
                          <div className="content_flex">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpopsms(true)}
                            >
                              SMS user
                            </span>
                          </div>
                          <div className="content_flex">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpopsuspend(true)}
                            >
                              Suspend account
                            </span>
                          </div>
                          <div className="content_flex">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpopenable(true)}
                            >
                              Restore/enable account
                            </span>
                          </div>
                          <div className="content_flex _last">
                            <span
                              className="cursor_span"
                              onClick={() => setShowpop(true)}
                            >
                              Delete account
                            </span>
                          </div>
                        </div>
                      ) : showing === "Transactions" ? (
                        <div className="move_profile_container move_profile_container_smaller">
                          <div className="content_flex _last">
                            <span>Recent Transactions</span>
                          </div>
                          {fetchingTransa ? (
                            <div
                              style={{
                                display: "flex",
                                padding: "20px",
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress color="inherit" />
                            </div>
                          ) : (
                            <div style={{ width: "100%" }}>
                              {console.log(
                                providerTransactions,
                                "providerTransactions"
                              )}
                              <DataGrid
                                density="comfortable"
                                sx={{
                                  p: "20px",
                                  cursor: "pointer",
                                  background: "#fff",
                                  "& .MuiDataGrid-cell": {
                                    whiteSpace: "break-spaces !important",
                                  },
                                }}
                                rows={
                                  providerTransactions !== undefined &&
                                  providerTransactions.length !== 0
                                    ? providerTransactions
                                    : []
                                }
                                columns={columns}
                                pageSize={pageSize}
                                autoHeight={true}
                                key={(params) => params.row.delivery_id}
                                onCellClick={(params) => {
                                  history.push(`/deliveries/${params.row.delivery_id}`)
                                }}
                                onPageSizeChange={(value) => setPageSize(value)}
                                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                getRowId={() => uuidv4()}
                              />
                            </div>
                          )}
                        </div>
                      ) : showing === "Feedbacks" ? (
                        <div className="feedback_container">
                          <div className="feedback_">
                            <Grid container className="feed_heading">
                              <Grid item xs={1.5}>
                                Rating
                              </Grid>
                              <Grid item xs={2}>
                                Item
                              </Grid>
                              <Grid item xs={2.5}>
                                Left by
                              </Grid>
                              <Grid item xs={4}>
                                Comment
                              </Grid>
                              <Grid item xs={2}>
                                Date
                              </Grid>
                            </Grid>
                          </div>
                          {fetchingFeedback ? (
                            <div
                              style={{
                                display: "flex",
                                padding: "20px",
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress color="inherit" />
                            </div>
                          ) : (
                            <>
                              {providerFeedback.map((feedback) => (
                                <div className="feedback_">
                                  {console.log(feedback, "feedbacks")}
                                  <Grid container className="feed_body">
                                    <Grid item xs={1.5}>
                                      {feedback?.rating}
                                    </Grid>
                                    <Grid item xs={2}>
                                      {feedback?.svc_rating}
                                    </Grid>
                                    <Grid item xs={2.5}>
                                      {feedback?.sender_name}
                                    </Grid>
                                    <Grid item xs={4}>
                                      {feedback?.additional_comments}
                                    </Grid>
                                    <Grid item xs={2}>
                                      {moment(feedback?.time_stamp).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </Grid>
                                  </Grid>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      ) : showing === "Pending Deliveries" ? (
                        

                        <TableWithPagination
                          ident="delivery_id"
                          // actions={['Delete', 'Update', 'Suspend', 'Restore', 'Sms', 'Email']}
                          actions={actions}
                          onActionClick={(val, row) => actionClick(val, row)}
                          onSelected={(val) => actionselectedClick(val)}
                          actionUpdate={(val) => {
                            if (val === 1) {
                              setactions(["Delete"]);
                            }
                          }}
                          selecteddata
                          data={corporatedetails[0]?.pending_deliveries}
                          inputplaceholder="Filter by Delivery ID"
                          props={Corporate_props}
                          rowsPerPage={5}
                          currentPage={0}
                          itemsPerPage={10}
                          NewData={NewData}
                        />
                      ) : (
                        <>others</>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))

            // console.log(corporatedetails, 'cccpdtls')
          }
        </div>
      )}

      {showpop ? (
        <ValidPopup
          title={
            clicked === "profile_photo"
              ? "Profile Photo"
              : clicked === "proof_of_identity"
              ? "Form of ID"
              : clicked === "good_in_transit_insurance"
              ? "Insurance Policy"
              : clicked === "bank_detail"
              ? "Bank Details"
              : clicked === "profile_message"
              ? "Profile message"
              : null
          }
          trigger={showpop}
          setTrigger={setshowpop}
        >
          <div className="pop-up-content-parent">
            <div className="pop-up-content">
              <div className="img-content">
                {clicked === "profile_message" ? (
                  corporatedetails[0].profile_message.msg_profile ===
                  "not uploaded" ? (
                    <>Profile message not uploaded </>
                  ) : (
                    <p>{corporatedetails[0].profile_message.msg_profile}</p>
                  )
                ) : clicked === "profile_photo" ? (
                  <img
                    src={corporatedetails[0].profile_photo.profile_photo}
                    alt="corporate-logo"
                    className="image-zoom"
                    width="150px"
                  />
                ) : clicked === "proof_of_identity" ? (
                  <img
                    src={corporatedetails[0].proof_of_identity.form_of_id}
                    alt="corporate-logo"
                    className="image-zoom"
                    width="250px"
                  />
                ) : clicked === "good_in_transit_insurance" ? (
                  corporatedetails[0].good_in_transit_insurance
                    .policy_number === "" ? (
                    <>Insurance policy not uploaded</>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "15px",
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          Policy number:{" "}
                        </span>
                        <span>
                          {
                            corporatedetails[0].good_in_transit_insurance
                              .policy_number
                          }
                        </span>
                      </div>
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          Provider Name:{" "}
                        </span>
                        <span>
                          {
                            corporatedetails[0].good_in_transit_insurance
                              .provider_name
                          }
                        </span>
                      </div>
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          Validity period:{" "}
                        </span>
                        <span>
                          {
                            corporatedetails[0].good_in_transit_insurance
                              .good_in_transit_insurance_validity.valid_from
                          }{" "}
                          -{" "}
                          {
                            corporatedetails[0].good_in_transit_insurance
                              .good_in_transit_insurance_validity.valid_to
                          }
                        </span>
                      </div>
                      <img
                        src={
                          corporatedetails[0].good_in_transit_insurance
                            .policy_image
                        }
                        alt="corporate-logo"
                        width="250px"
                        className="image-zoom"
                      />
                    </div>
                  )
                ) : clicked === "bank_detail" ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: "bold" }}>Account name: </span>
                      <span>
                        {corporatedetails[0].bank_detail.account_name}
                      </span>
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>
                        Account number:{" "}
                      </span>
                      <span>
                        {corporatedetails[0].bank_detail.account_number}
                      </span>
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>Bank name: </span>
                      <span>{corporatedetails[0].bank_detail.bank_name}</span>
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>BVN: </span>
                      <span>{corporatedetails[0].bank_detail.bvn}</span>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="pending">
                {clicked === "profile_photo"
                  ? corporatedetails[0].profile_photo.state === "verified"
                    ? "Verified"
                    : corporatedetails[0].profile_photo.state === "not_verified"
                    ? "Pending"
                    : "Rejected"
                  : clicked === "proof_of_identity"
                  ? corporatedetails[0].proof_of_identity.state === "verified"
                    ? "Verified"
                    : corporatedetails[0].proof_of_identity.state ===
                      "not_verified"
                    ? "Pending"
                    : "Rejected"
                  : clicked === "good_in_transit_insurance"
                  ? corporatedetails[0].good_in_transit_insurance.state ===
                    "verified"
                    ? "Verified"
                    : corporatedetails[0].good_in_transit_insurance.state ===
                      "not_verified"
                    ? "Pending"
                    : "Rejected"
                  : clicked === "bank_detail"
                  ? corporatedetails[0].bank_detail.state === "verified"
                    ? "Verified"
                    : corporatedetails[0].bank_detail.state === "not_verified"
                    ? "Pending"
                    : "Rejected"
                  : null}
              </div>
            </div>
            <div className="content">
              {clicked === "profile_photo" ? (
                <div>
                  Make sure the face on the profile photo is clear with good
                  lighting <br /> No object is covering the face on the profile
                  photo.
                </div>
              ) : clicked === "proof_of_identity" ? (
                <div>
                  Make sure the photo on the From of Identification is clear
                  with good lighting.
                  <br />
                  No object is covering the face on the From of Identification.
                  <br />
                  All infomation on the From of Identification is visible.
                </div>
              ) : clicked === "good_in_transit_insurance" ? (
                <div>
                  Make sure the Insurance policy is clear with good lighting
                  <br />
                  No object is covering the any infomation on the documents.
                </div>
              ) : clicked === "bank_detail" ? (
                <div>
                  Make sure you verify account number <br />
                  The Bank Verification Number is included
                </div>
              ) : null}
            </div>

            <div className="btn-parent">
              {clicked === "profile_message" ? (
                corporatedetails[0].profile_message.state === "verified" ? (
                  <>
                    <Buttoncard text="Validate" disabled className="disabled" />
                    <Buttoncard text="Reject" disabled className="disabled" />
                  </>
                ) : corporatedetails[0].profile_message.state ===
                  "Pending admin review" ? (
                  <>
                    <Buttoncard
                      onClick={() => validateProfileMessage("validate")}
                      text="Validate"
                      loading={submitting}
                      className="success"
                    />
                    <Buttoncard
                      onClick={() => {
                        setshowReason(true);
                        setToreject("message");
                      }}
                      text="Reject"
                      className="reject"
                    />
                  </>
                ) : (
                  <>
                    <Buttoncard text="Validate" disabled className="disabled" />
                    <Buttoncard text="Reject" disabled className="disabled" />
                  </>
                )
              ) : clicked === "profile_photo" ? (
                corporatedetails[0]?.profile_photo.state === "verified" ? (
                  <>
                    <Buttoncard text="Validate" disabled className="disabled" />
                    <Buttoncard text="Reject" disabled className="disabled" />
                  </>
                ) : corporatedetails[0].profile_photo.state ===
                  "not_verified" ? (
                  <>
                    {console.log(
                      corporatedetails[0],
                      "corporatedetails[0]-----"
                    )}
                    <Buttoncard
                      onClick={() => validateProfile_photo("validate")}
                      text="Validate"
                      loading={submitting}
                      className="success"
                    />
                    <Buttoncard
                      onClick={() => {
                        setshowReason(true);
                        setToreject("photo");
                      }}
                      text="Reject"
                      className="reject"
                    />
                  </>
                ) : (
                  <>
                    <Buttoncard text="Validate" disabled className="disabled" />
                    <Buttoncard text="Reject" disabled className="disabled" />
                  </>
                )
              ) : clicked === "proof_of_identity" ? (
                corporatedetails[0].proof_of_identity.state === "verified" ? (
                  <>
                    <Buttoncard text="Validate" disabled className="disabled" />
                    <Buttoncard text="Reject" disabled className="disabled" />
                  </>
                ) : corporatedetails[0].proof_of_identity.state ===
                  "not_verified" ? (
                  <>
                    <Buttoncard
                      onClick={() => validateProof("validate")}
                      text="Validate"
                      loading={submitting}
                      className="success"
                    />
                    <Buttoncard
                      onClick={() => {
                        setshowReason(true);
                        setToreject("id");
                      }}
                      text="Reject"
                      className="reject"
                    />
                  </>
                ) : (
                  <>
                    <Buttoncard text="Validate" disabled className="disabled" />
                    <Buttoncard text="Reject" disabled className="disabled" />
                  </>
                )
              ) : clicked === "good_in_transit_insurance" ? (
                corporatedetails[0].good_in_transit_insurance.state ===
                "verified" ? (
                  <>
                    <Buttoncard text="Validate" disabled className="disabled" />
                    <Buttoncard text="Reject" disabled className="disabled" />
                  </>
                ) : corporatedetails[0].good_in_transit_insurance.state ===
                  "not_verified" ? (
                  <>
                    {/* <Buttoncard onClick={()=>validateInsurance("validate")} text='Validate' loading={submitting} className='success'/> */}
                    <Buttoncard
                      onClick={checkInsurance}
                      text="Validate"
                      className="success"
                      loading={submitting}
                    />
                    <Buttoncard
                      onClick={() => {
                        setshowReason(true);
                        setToreject("insurance");
                      }}
                      text="Reject"
                      className="reject"
                    />
                  </>
                ) : (
                  <>
                    <Buttoncard text="Validate" disabled className="disabled" />
                    <Buttoncard text="Reject" disabled className="disabled" />
                  </>
                )
              ) : clicked === "bank_detail" ? (
                corporatedetails[0].bank_detail.state === "verified" ? (
                  <>
                    <Buttoncard text="Validate" disabled className="disabled" />
                    <Buttoncard text="Reject" disabled className="disabled" />
                  </>
                ) : corporatedetails[0].bank_detail.state === "not_verified" ? (
                  <>
                    <Buttoncard
                      onClick={() => validateBank("validate")}
                      text="Validate"
                      loading={submitting}
                      className="success"
                    />
                    <Buttoncard
                      onClick={() => {
                        setshowReason(true);
                        setToreject("bank");
                      }}
                      text="Reject"
                      className="reject"
                    />
                  </>
                ) : (
                  <>
                    <Buttoncard text="Validate" disabled className="disabled" />
                    <Buttoncard text="Reject" disabled className="disabled" />
                  </>
                )
              ) : null}
            </div>
          </div>
        </ValidPopup>
      ) : null}

      <DialogOutClick
        open={showAmount}
        handleClose={() => setshowAmount(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Input the Amount</p>
          <div style={{ padding: "15px 0" }}>
            <input
              type="number"
              value={amount}
              onChange={(e) => setamount(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                border: "none",
                borderRadius: "5px",
                outline: "1px solid var(--main)",
              }}
            />
          </div>
          {/* <Buttoncard onClick={()=>validateProfile_photo("reject")} text='Validate' loading={rejecting} className='reject'/> */}
          {amount !== "" ? (
            <Buttoncard
              onClick={() => validateInsurance("validate")}
              text="Validate"
              loading={submitting}
              className="success"
            />
          ) : (
            <Buttoncard text="Validate" disabled className="disabled" />
          )}
        </div>
      </DialogOutClick>

      <DialogOutClick open={Showpop} handleClose={() => setShowpop(false)}>
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to delete this user?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleDelete}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpop(false)}
              text="No"
              className="success"
            />
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick open={showreset} handleClose={() => setShowreset(false)}>
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Please complete the form below </p>

          <form onSubmit={handledevicereset} style={{ marginTop: "20px" }}>
            <FormControl
              className="deleteform"
              variant="outlined"
              style={{ marginBottom: "10px" }}
            >
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />{" "}
                User Email
              </InputLabel>
              <InputField
                required
                name="email"
                id="email"
                type="email"
                value={emailreset}
                labelWidth={90}
                onChange={(e) => {
                  setemailreset(e.target.value);
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl>

            <FormControl
              className="deleteform"
              variant="outlined"
              style={{ marginBottom: "10px" }}
            >
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />{" "}
                Ios name
              </InputLabel>
              <InputField
                required
                name="text"
                id="text"
                type="text"
                value={ios}
                labelWidth={90}
                onChange={(e) => {
                  setios(e.target.value);
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl>

            {/* <FormControl className="deleteform" variant="outlined"  style={{marginBottom: "10px", }}>
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />{" "}
                Ios version
              </InputLabel>
              <InputField

                name="text"
                id="text"
                type="number"
                value={iosversion}
                labelWidth={90}
                onChange={(e) => {
                  setiosversion(e.target.value);
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl> */}

            {/* <FormControl className="deleteform" variant="outlined"  style={{marginBottom: "10px", }}>
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px",  color: "var(--main)" }}
                />{" "}
                Iemi name
              </InputLabel>
              <InputField
                // required
                name="text"
                id="text"
                type="text"
                value={iemi}
                labelWidth={90}
                onChange={(e) => {
                  setiemi(e.target.value);
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl> */}

            {/* <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "20px" }}
            >
              <textarea
                style={{ width: "50", height: "150px", padding: "12px 20px" }}
                placeholder="message body"
                required
                value={emailtextarea}
                onChange={(e) => {
                  setemailtextarea(e.target.value);
                }}
              ></textarea>
            </FormControl> */}

            <div className="deletBtn">
              <Button
                size="large"
                variant="contained"
                type="submit"
                className="btnnn"
              >
                <span className="btnlabel">
                  {submitting ? "loading..." : "Reset"}{" "}
                </span>
              </Button>
            </div>
          </form>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopemail}
        handleClose={() => setShowpopemail(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Please complete the form below </p>

          <form onSubmit={handlesendEmial} style={{ marginTop: "20px" }}>
            <FormControl className="deleteform" variant="outlined">
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />{" "}
                User Email
              </InputLabel>
              <InputField
                required
                name="email"
                id="email"
                type="email"
                value={emailarray}
                labelWidth={90}
                onChange={(e) => {
                  // setemail(e.target.value);
                  setemailarray([e.target.value]);
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl>

            <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "20px" }}
            >
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />{" "}
                Subject
              </InputLabel>
              <InputField
                required
                name="text"
                id="text"
                type="text"
                value={emailsubject}
                labelWidth={90}
                onChange={(e) => {
                  setemailsubject(e.target.value);
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl>

            <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "20px" }}
            >
              <textarea
                style={{ width: "50", height: "150px", padding: "12px 20px" }}
                placeholder="message body"
                required
                value={emailtextarea}
                onChange={(e) => {
                  setemailtextarea(e.target.value);
                }}
              ></textarea>
            </FormControl>

            <div className="deletBtn">
              <Button
                size="large"
                variant="contained"
                type="submit"
                className="btnnn"
              >
                <span className="btnlabel">
                  {submitting ? "loading..." : "Email"}{" "}
                </span>
              </Button>
            </div>
          </form>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopsms}
        handleClose={() => setShowpopsms(false)}
      >
        <div style={{ padding: "30px", width: "300px", marginBottom: "20px" }}>
          <p>Please complete the form below</p>

          <form className="cFrorm" onSubmit={handlesendSms}>
            <div>
              <div className="phoneTopic">Phone Number</div>
              <PhoneNumber value={phone_num} setValue={setphone_num} />
            </div>

            <FormControl variant="outlined">
              <textarea
                style={{ width: "50", height: "150px", padding: "12px 20px" }}
                placeholder="message body"
                required
                value={smssubject}
                onChange={(e) => {
                  setsmssubject(e.target.value);
                }}
              ></textarea>
            </FormControl>

            {phone_num === "" ? (
              <Button
                size="large"
                variant="contained"
                type="submit"
                disabled
                style={{
                  backgroundColor: "rgb(151, 199, 168)",
                  width: "100%",
                  marginTop: "20px",
                  color: "white",
                  borderRadius: "10px",
                  padding: "8px",
                  textTransform: "none",
                }}
              >
                <span className="btnlabel">
                  {submitting ? "loading" : "Send sms"}{" "}
                </span>
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "var(--main)",
                  width: "100%",
                  marginTop: "20px",
                  color: "white",
                  borderRadius: "10px",
                  padding: "8px",
                  textTransform: "none",
                }}
              >
                {addingUser ? (
                  <PuffLoader
                    color="white"
                    loading={addingUser}
                    speedMultiplier={1}
                    size={24}
                  />
                ) : (
                  <span className="btnlabel">
                    {submitting ? "loading" : "Send sms"}
                  </span>
                )}
              </Button>
            )}
          </form>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopresetpassword}
        handleClose={() => setShowpopresetpassword(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Reset user's password</p>
          <div className="reset_pass_con">
            <button
              className="reset_pass_btn1"
              onClick={() => setShowpopresetpassword(false)}
            >
              Cancel
            </button>
            <button className="reset_pass_btn2" onClick={handleresetPassword}>
              {submitting ? (
                <PuffLoader
                  color="white"
                  loading={submitting}
                  speedMultiplier={1}
                  size={34}
                />
              ) : (
                <span> Reset</span>
              )}
            </button>
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopsuspend}
        handleClose={() => setShowpopsuspend(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to suspend this user?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleSuspendacct}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpopsuspend(false)}
              text="No"
              className="success"
            />
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopenable}
        handleClose={() => setShowpopenable(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to Enable this user?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleenableacct}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpopenable(false)}
              text="No"
              className="success"
            />
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={showReason}
        handleClose={() => setshowReason(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>State reason for rejection</p>
          <div style={{ padding: "15px 0" }}>
            <input
              type="text"
              value={comment}
              onChange={(e) => setcomment(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                border: "none",
                borderRadius: "5px",
                outline: "1px solid var(--main)",
              }}
            />
          </div>
          {toreject === "photo" ? (
            <Buttoncard
              onClick={() => validateProfile_photo("reject")}
              text="Reject"
              loading={rejecting}
              className="reject"
            />
          ) : toreject === "id" ? (
            <Buttoncard
              onClick={() => validateProof("reject")}
              text="Reject"
              loading={rejecting}
              className="reject"
            />
          ) : toreject === "insurance" ? (
            <Buttoncard
              onClick={() => validateInsurance("reject")}
              text="Reject"
              loading={rejecting}
              className="reject"
            />
          ) : toreject === "bank" ? (
            <Buttoncard
              onClick={() => validateBank("reject")}
              text="Reject"
              loading={rejecting}
              className="reject"
            />
          ) : toreject === "message" ? (
            <Buttoncard
              onClick={() => validateProfileMessage("reject")}
              text="Reject"
              loading={rejecting}
              className="reject"
            />
          ) : null}
        </div>
      </DialogOutClick>

      <DialogOutClick open={Showpop} handleClose={() => setShowpop(false)}>
        <div style={{ padding: "30px 0 20px ", width: "400px" }}>
          <div style={{ padding: "0 30px" }}>
            <div className="caution_card">
              <AiOutlineWarning color="red" size={24} />
              <div>Deleting a user, removes the user from Dillivry </div>
            </div>

            <div className="cofirm-text">
              To confirm deletion, type delete in the field
            </div>
            <input
              type={"text"}
              value={permanentlyDelete}
              onChange={(e) => setpermanentlyDelete(e.target.value)}
              placeholder={"delete"}
              className="perm-input"
            />
          </div>
          <div className="btn-parent">
            <Buttoncard
              onClick={() => {
                setShowpop(false);
                setpermanentlyDelete("");
              }}
              text="Cancel"
              className="canceller"
            />
            {permanentlyDelete === "delete" ? (
              <Buttoncard
                onClick={handleDelete}
                text="Delete"
                loading={submitting}
                className="deleter"
              />
            ) : (
              <Buttoncard
                text="Delete"
                loading={submitting}
                className="deleter_disable"
              />
            )}
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick open={ShowPerma} handleClose={() => setShowPerma(false)}>
        <div style={{ padding: "30px 0 20px ", width: "400px" }}>
          <div style={{ padding: "0 30px" }}>
            <div className="caution_card">
              <AiOutlineWarning color="red" size={24} />
              <div>
                Deleting a user, removes the user permanently from Dillivry{" "}
              </div>
            </div>

            <div className="cofirm-text">
              To confirm deletion, type Permanently delete in the field
            </div>
            <input
              type={"text"}
              value={permanentlyDelete}
              onChange={(e) => setpermanentlyDelete(e.target.value)}
              placeholder={"Permanently delete"}
              className="perm-input"
            />
          </div>
          <div className="btn-parent">
            <Buttoncard
              onClick={() => {
                setShowPerma(false);
                setpermanentlyDelete("");
              }}
              text="Cancel"
              className="canceller"
            />
            {permanentlyDelete === "Permanently delete" ? (
              <Buttoncard
                onClick={handleDeletePerma}
                text="Delete"
                loading={submitting}
                className="deleter"
              />
            ) : (
              <Buttoncard
                text="Delete"
                loading={submitting}
                className="deleter_disable"
              />
            )}
          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={showdelete}
        handleClose={() => setshowdelete(false)}
      >
        <div style={{ padding: "30px 0 20px ", width: "400px" }}>
          <div style={{ padding: "0 30px" }}>
            <div className="caution_card">
              <AiOutlineWarning color="red" size={24} />
              <div>
                Deleting a pending delivery, removes the delivery permanently
                from Dillivry.
              </div>
            </div>

            <div className="cofirm-text">
              To confirm deletion, type delete in the field
            </div>
            <input
              type={"text"}
              value={permanentlyDelete}
              onChange={(e) => setpermanentlyDelete(e.target.value)}
              placeholder={"delete"}
              className="perm-input"
            />
          </div>
          <div className="btn-parent">
            <Buttoncard
              onClick={() => setshowdelete(false)}
              text="Cancel"
              className="canceller"
            />
            {permanentlyDelete === "delete" ? (
              <Buttoncard
                onClick={handleRemovePending}
                text="Delete"
                loading={submitting}
                className="deleter"
              />
            ) : (
              <Buttoncard
                text="Delete"
                loading={submitting}
                className="deleter_disable"
              />
            )}
          </div>
        </div>
      </DialogOutClick>
    </div>
  );
};

export default Userdrilldown;
