import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
// import Dashboard from "./components/Dashboard";

// import User from "../src/Pages/User/User";

import Layout from "./components/Layout";

import Individual from "./Pages/User/Individual";
import Agents from "./Pages/User/Agents";
import Senders from "./Pages/User/Senders";
// import Deliveries from "./Pages/Deliveries";
import Activedeliveries from "./Pages/Activedeliveries";
import AwaitingPickup from "./Pages/AwaitingPickup";
import Outfordelivery from "./Pages/Outfordelivery";
import Completed from "./Pages/Completed";
import Accounts from "./Pages/Settlement/Accounts";
import Trasactions from "./Pages/Settlement/Trasactions";
// import Settlement from "./Pages/Settlement/Settlement";



import Login from "./components/login/Login"
import ForgotPassword from "./components/passwordRest/ForgotPassword";
import ResetPassword from "./components/passwordRest/ResetPassword";
import Quoted from "./Pages/Delivery/Quoted";
import Booked from "./Pages/Delivery/Booked";
import Corporate from "./Pages/User/Corporate";
import Userdrilldown from "./Pages/User/UserDrilldown";
import AdminUsers from "./Pages/AdminUsers/AdminUsers";

import Quoted_View from "./Pages/Delivery/Quoted_View";
import Setting from "./Pages/Setting";
import Message from "./Pages/Message/Message";
import MessageView from "./Pages/Message/MessageView";
import Agentsdrilldown from "./Pages/User/Agentsdrilldown";
import Sendersdrilldown from "./Pages/User/Sendersdrilldown";
import SenderDrillDown from "./Pages/User/SenderDrillDown";
import Open_view from "./Pages/Delivery/Open_view";
import Opendeliveriespage from "./components/Opendeliveriespage";
import NewQuotedView from "./Pages/Delivery/NewQuotedView";
import HomeForgotPassword from './components/passwordRest/HomeForgotPassword'
import Disputes from "./Pages/Disputes/Disputes";
import DisputesSettlement from "./Pages/Disputes/DisputesSettlement";
import DisputeDrilldown from "./Pages/Disputes/DisputeDrilldown";
import DisputeSummary from "./Pages/Disputes/DisputeSummary";


export default function Routing() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/ForgotPassword" component={ForgotPassword} />
          <Route path="/password-reset" component={HomeForgotPassword} />
          <Route path="/ResetPassword" component={ResetPassword} />
          {/* <Route  path="/users/:id" exact component={Userdrilldown}  /> */}
          {/* <Route  path="/deliveries/quoted_deliveries/:id" exact component={Userdrilldown}  /> */}
          <Route path="/users/:id" exact component={Userdrilldown} />
          <Route path="/senders/:id" exact component={SenderDrillDown} />
          <Route path="/agents/:id" exact component={Agentsdrilldown} />

          <Route path="/senders/:id" exact component={Sendersdrilldown} />
          <Route path="/deliveries/:id" exact component={Quoted_View} />
          <Route path="/open/:id" exact component={Open_view} />
          <Route path="/disputes/settlement" exact component={DisputesSettlement} />
          


          <Layout>
            {/* <Route path="/Dashboard" exact component={Dashboard}></Route> */}
            <Route path="/corporate" exact component={Corporate} />
            <Route path="/individual" exact component={Individual} />
            <Route path="/agents" exact component={Agents} />
            <Route path="/senders" exact component={Senders} />
            <Route path="/disputes" exact component={Disputes} />

            {/* <Route path="/user" exact component={User}  /> */}

            <Route path="/deliveries/activedeliveries" exact component={Activedeliveries} />
            <Route path="/deliveries/awaitingpickup" exact component={AwaitingPickup} />
            <Route path="/deliveries/out_for_delivery" exact component={Outfordelivery} />
            <Route path="/deliveries/completed" exact component={Completed} />

            <Route path="/deliveries" exact component={Quoted} />
            <Route path="/deliveries/booked_deliveries" exact component={Booked} />

            <Route path="/deliveries/:id" exact component={Quoted_View} />

            {/* <Route path="/deliveries" exact component={Deliveries} /> */}
            {/* <Route path="/settlement" exact component={Settlement} /> */}
            <Route path="/opendeliveries" exact component={Opendeliveriespage} />
            <Route path="/account" exact component={Accounts} />
            <Route path="/transactions" exact component={Trasactions} />
            <Route path="/settings" exact component={Setting} />


            <Route path="/adminusers" exact component={AdminUsers} />
            <Route path="/messages" exact component={Message} />
            <Route path="/messages/view" exact component={MessageView} />

            <Route path="/disputes/:disputeId/:userId" exact component={DisputeDrilldown} />
            <Route path="/disputes/summary" exact component={DisputeSummary} />
          </Layout>

        </Switch>
      </Router>
    </div>
  );
}