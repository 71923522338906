import React, { useState, useEffect, useRef, useContext } from "react";
import exporticon from "../../assets/exporticon.svg";
import filtersearch from "../../assets/filtersearch.svg";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { DataContext } from "../../context/DataContext/DataProvider";
import Axios from "axios";
import jwt_decode from 'jwt-decode'
import Swal from 'sweetalert2'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { useReactToPrint } from 'react-to-print';
import { DeliveryContext } from "../../context/Deliveries";
import { CSVLink, CSVDownload } from "react-csv";

export default function Booked() {

  const history = useHistory()
  const location = useLocation()

  const { updateData, datas } = useContext(DataContext);
  const { updatedeliveries, deliveriesnew, UpdateNewData, individualdata } = useContext(DeliveryContext)
  const [exceldata, setExceldata] = useState([]);
  const [incoming, setincoming] = useState([]);
  const [newdata, setnewdata] = useState([]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input?.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }
  const joiner = (data) => {
    const newarrrr = data.split("-");
    const merge = newarrrr[0].concat(newarrrr[1], newarrrr[2])
    const newarrr = merge.split(" ");
    const merged = newarrr[0].concat(newarrr[1])
    const newarrrf = merged.split(":");
    const mergedd = newarrrf[0].concat(newarrrf[1], newarrrf[2])
    const newarrrff = mergedd.split("+");
    const mergeddd = newarrrff[0].concat(newarrrff[1])
    const newarrrfff = mergeddd.split(".");
    const mergedddd = newarrrfff[0].concat(newarrrfff[1])
    const newarrrffff = mergedddd.split("T");
    const mergeddddd = newarrrffff[0].concat(newarrrffff[1])
    return (mergeddddd)
  }


  const [status, setStatus] = useState('');
  const [show, setshow] = useState(false);

  const Tabledetails = [
    {
      Thead: "Image",
    },
    {
      Thead: "Delivery ID",
    },
    {
      Thead: "Item",
    },
    {
      Thead: "Pick up",
    },
    {
      Thead: "Destination",
    },
    {
      Thead: "Mode",
    },
    {
      Thead: "Timeframe",
    },
    {
      Thead: "Distance",
    },
    {
      Thead: "Listed",
    },
    {
      Thead: "Quotes",
    },
    {
      Thead: "Best Quote",
    },
  ];

  const Drop_down = () => (
    <div style={{ zIndex: '100', position: 'absolute', top: '40px', display: 'flex', flexDirection: 'column', backgroundColor: 'white', boxShadow: '1px 1px 44px rgba(96, 97, 96, 0.3)' }}>
      <div className="each" onClick={() => { filterByStatus("all"); setshow(!show) }}>
        <span style={{ marginLeft: '10px' }}>All</span>
      </div>
      <div className="each" onClick={() => { filterByStatus("awaiting_quote"); setshow(!show) }}>
        <span style={{ marginLeft: '10px' }}>Awaiting quote</span>
      </div>
      <div className="each" onClick={() => { filterByStatus("awaiting_acceptance"); setshow(!show) }}>
        <span style={{ marginLeft: '10px' }}>Awaiting quote acceptance</span>
      </div>
      <div className="each" onClick={() => { filterByStatus("awaiting_agent_assignment"); setshow(!show) }}>
        <span style={{ marginLeft: '10px' }}>Awaiting agent assignment</span>
      </div>
      <div className="each" onClick={() => { filterByStatus("awaiting_pickup"); setshow(!show) }}>
        <span style={{ marginLeft: '10px' }}>Awaiting pickup</span>
      </div>
      <div className="each" onClick={() => { filterByStatus("picked_up"); setshow(!show) }}>
        <span style={{ marginLeft: '10px' }}>Picked up</span>
      </div>
      <div className="each" onClick={() => { filterByStatus("out_for_delivery"); setshow(!show) }}>
        <span style={{ marginLeft: '10px' }}>Out for delivery</span>
      </div>
      <div className="each" onClick={() => { filterByStatus("missed_delivery"); setshow(!show) }}>
        <span style={{ marginLeft: '10px' }}>Missed delivery</span>
      </div>
      <div className="each" onClick={() => { filterByStatus("delivered"); setshow(!show) }}>
        <span style={{ marginLeft: '10px' }}>Completed delivery</span>
      </div>
    </div>
  )

  const Pagination = () => (
    <div className="viewmenudesktop">


      <div className="flexboxes">
        <span
          className="paginateButtons2"
          style={{
            color: paginateActivePage === 1 ? "white" : "",
            backgroundColor: paginateActivePage === 1 ? "var(--main)" : "",
            cursor: paginateActivePage === 1 ? "not-allowed" : "pointer",
          }}
          onClick={() => {
            UpdateNewData(incoming.slice(0, perpage));
            setPaginateActivePage(1);
          }}
        >
          First
        </span>
        {paginateActivePage === 1 ? (
          <span
            className="paginateButtons2"
            style={{ color: "#989898", cursor: "not-allowed" }}
          >
            Previous
          </span>
        ) : (
          <span
            className="paginateButtons2"
            onClick={() => {
              UpdateNewData(incoming.slice(
                (paginateActivePage - 2) * perpage,
                (paginateActivePage - 1) * perpage
              )
              );
              setPaginateActivePage(paginateActivePage - 1);
            }}
          >
            Previous
          </span>
        )}



        {/* <span
                  className="paginateButtons"
                  style={{
                    color: paginateActivePage === 1 ? "white" : "",
                    backgroundColor:
                      paginateActivePage === 1 ? "var(--main)" : "",
                    cursor:
                      paginateActivePage === 1 ? "not-allowed" : "pointer",
                  }}
                  onClick={() => {
                    setActiveDeliveries(corporatedetails?.slice(0, n));
                    setPaginateActivePage(1);
                  }}
                >
                  First
                </span>
                {paginateActivePage === 1 ? (
                  <span
                    className="paginateButtons"
                    style={{ color: "#989898", cursor: "not-allowed" }}
                  >
                    Previous
                  </span>
                ) : (
                  <span
                    className="paginateButtons"
                    onClick={() => {
                      setActiveDeliveries(
                        corporatedetails?.slice(
                          (paginateActivePage - 2) * n,
                          (paginateActivePage - 1) * n
                        )
                      );
                      setPaginateActivePage(paginateActivePage - 1);
                    }}
                  >
                    Previous
                  </span>
                )} */}






        <span className="pagination">
          <ol>
            {[...Array(Math.ceil(itemLength / perpage))].map((e, i) => (
              // <span
              //   className="paginateButtons"
              //   style={{
              //     backgroundColor:
              //       paginateActivePage === i + 1 ? "var(--main)" : "",
              //     color: paginateActivePage === i + 1 ? "white" : "",
              //   }}
              //   key={i}
              //   onClick={() => {
              //     setActiveDeliveries(
              //       corporatedetails?.slice(n * i, n * i + n)
              //     );
              //     setPaginateActivePage(i + 1);
              //   }}
              // >
              //   {i + 1}
              // </span>
              // ))}


              <li key={i} className={paginateActivePage === i + 1 ? "active paginateButtons" : "paginateButtons"}
                style={{
                  backgroundColor:
                    paginateActivePage === i + 1 ? "var(--main)" : "",
                  color: paginateActivePage === i + 1 ? "white" : "",
                }}
                onClick={() => {
                  UpdateNewData(incoming.slice(perpage * i, perpage * i + perpage));
                  setPaginateActivePage(i + 1);
                }}>
                <a href="#">{i + 1}</a>
              </li>
            ))}
          </ol>
        </span>

        {paginateActivePage === Math.ceil(itemLength / perpage) ? (
          <span
            className="paginateButtons2"
            style={{ color: "#989898", cursor: "not-allowed" }}
          >
            Next
          </span>
        ) : (
          <span
            className="paginateButtons2"
            onClick={() => {
              UpdateNewData(incoming?.slice(
                perpage * paginateActivePage,
                perpage * (paginateActivePage + 1)
              )
              );
              setPaginateActivePage(paginateActivePage + 1);
            }}
          >
            Next
          </span>
        )}
        <span
          className="paginateButtons2"
          style={{
            backgroundColor:
              paginateActivePage === Math.ceil(itemLength / perpage)
                ? "var(--main)"
                : "",
            color:
              paginateActivePage === Math.ceil(itemLength / perpage)
                ? "white"
                : "",
          }}
          onClick={() => {
            UpdateNewData(incoming?.slice(
              Math.floor(itemLength / perpage) * perpage,
              itemLength
            )
            );
            setPaginateActivePage(Math.ceil(itemLength / perpage));
          }}
        >
          Last
        </span>
      </div>

      {/* <span
        className="paginateButtons"
        style={{
            color: paginateActivePage === 1 ? "white" : "",
            backgroundColor: paginateActivePage === 1 ? "var(--main)" : "",
            cursor: paginateActivePage === 1 ? "not-allowed" : "pointer",
        }}
        onClick={() => {
            setActiveDeliveries(activeDeliveriesDup.slice(0, perpage));
            setPaginateActivePage(1);
        }}
        >
        First
        </span>
        {paginateActivePage === 1 ? (
        <span
            className="paginateButtons"
            style={{ color: "#989898", cursor: "not-allowed" }}
        >
            Previous
        </span>
        ) : (
        <span
            className="paginateButtons"
            onClick={() => {
            setActiveDeliveries(
                activeDeliveriesDup.slice(
                (paginateActivePage - 2) * perpage,
                (paginateActivePage - 1) * perpage
                )
            );
            setPaginateActivePage(paginateActivePage - 1);
            }}
        >
            Previous
        </span>
        )}

        {[...Array(Math.ceil(itemLength / perpage))].map((e, i) => (
        <span
            className="paginateButtons"
            style={{
            backgroundColor:
                paginateActivePage === i + 1 ? "var(--main)" : "",
            color: paginateActivePage === i + 1 ? "white" : "",
            }}
            key={i}
            onClick={() => {
            setActiveDeliveries(activeDeliveriesDup.slice(perpage * i, perpage * i + perpage));
            setPaginateActivePage(i + 1);
            }}
        >
            {i + 1}
        </span>
        ))}
        {paginateActivePage === Math.ceil(itemLength / perpage) ? (
        <span
            className="paginateButtons"
            style={{ color: "#989898", cursor: "not-allowed" }}
        >
            Next
        </span>
        ) : (
        <span
            className="paginateButtons"
            onClick={() => {
            setActiveDeliveries(
                activeDeliveriesDup.slice(
                perpage * paginateActivePage,
                perpage * (paginateActivePage + 1)
                )
            );
            setPaginateActivePage(paginateActivePage + 1);
            }}
        >
            Next
        </span>
        )}
        <span
        className="paginateButtons"
        style={{
            backgroundColor:
            paginateActivePage === Math.ceil(itemLength / perpage)
                ? "var(--main)"
                : "",
            color:
            paginateActivePage === Math.ceil(itemLength / perpage)
                ? "white"
                : "",
        }}
        onClick={() => {
            setActiveDeliveries(
                activeDeliveriesDup.slice(
                Math.floor(itemLength / perpage) * perpage,
                itemLength
            )
            );
            setPaginateActivePage(Math.ceil(itemLength / perpage));
        }}
        >
        Last
        </span> */}
    </div>
  )

  const Tablebodydetail = [
    [
      "ID-4548378782",
      "547321089257",
      "Moses  Chigozie",
      "chigozie@email.com",
      "Moses  Chigozie",
      "08150769517",
      "Insurance:",
      "noInsurance-icon",
      "Active",
    ],
    [
      "ID-4548378782",
      "547321089257",
      "Moses  Chigozie",
      "chigozie@email.com",
      "Moses  Chigozie",
      "08150769517",
      "Insurance:",
      "noInsurance-icon",
      "Active",
    ], [
      "ID-4548378782",
      "547321089257",
      "Moses  Chigozie",
      "chigozie@email.com",
      "Moses  Chigozie",
      "08150769517",
      "Insurance:",
      "noInsurance-icon",
      "Active",
    ],
  ]

  const doc = new jsPDF()

  doc.autoTable({
    head: [['Company logo', 'Company Id', 'Company name', 'Registration number', 'Admin Email', 'Telephone number', 'Insurance', 'Status', "lll"]],
    body: Tablebodydetail
  })

  const [activeDeliveriesDup, setActiveDeliveriesDup] = useState([]);
  const [activeDeliveries, setActiveDeliveries] = useState([]);
  const [paginateActivePage, setPaginateActivePage] = useState(1);
  const [itemLength, setitemLength] = useState(0);
  const [perpage, setperpage] = React.useState(50);
  const [loading, setloading] = useState(false);
  const endPoint = process.env.REACT_APP_API
  const [search, setsearch] = useState('')

  const handlevalue = (event) => {
    setperpage(event.target.value);
    setActiveDeliveries([...activeDeliveriesDup].slice(0, event.target.value));
  };

  // const filterByStatus = (status) =>{
  //     if(status === "all"){
  //       const filterQuoted = activeDeliveriesDup.filter((quoted) =>{
  //           return quoted?.delivery_type === "nobid";
  //         })
  //         setExceldata([...filterQuoted])
  //       setActiveDeliveries([...filterQuoted].slice(0, perpage));
  //       setitemLength([...filterQuoted].length);
  //     }
  //     else{
  //       setStatus(status);
  //         const filterQuoted = activeDeliveriesDup.filter((quoted) =>{
  //           return quoted?.delivery_type === "nobid" && quoted?.delivery_status === status;
  //         })
  //         setExceldata([...filterQuoted])
  //           setActiveDeliveries([...filterQuoted].slice(0, perpage));
  //           setitemLength([...filterQuoted].length);
  //     }

  //   }



  const filterByStatus = (status) => {
    // console.log(individualdata, "indidata");
    if (status === "all") {
      const filterQuoted = newdata.filter((quoted) => {
        return quoted?.delivery_type === "nobid";
      })
      setExceldata([...filterQuoted]);

      setitemLength([...filterQuoted].length);
      UpdateNewData([...filterQuoted].slice(0, perpage));
      setincoming(filterQuoted);
      setPaginateActivePage(1);
    } else {
      setStatus(status);
      const filterQuoted = newdata.filter((quoted) => {
        return quoted?.delivery_type === "nobid" && quoted?.delivery_status === status;
      })
      setExceldata([...filterQuoted]);
      setitemLength([...filterQuoted].length);
      UpdateNewData([...filterQuoted].slice(0, perpage));
      setincoming(filterQuoted);
      setPaginateActivePage(1);
    }
  };






  // const setViewData = (viewData) =>{ 
  //   const filterQuoted = viewData.data.deliveries.filter((quoted) =>{
  //       return quoted?.delivery_type === "nobid";
  //     })
  //     filterQuoted.sort(function (a, b) {
  //       return joiner(a?.date_listed) - joiner(b?.date_listed)
  //     })
  //     const reversed = reverseArr(filterQuoted)
  //     setExceldata([...reversed])
  //       setActiveDeliveries([...reversed].slice(0, perpage));
  //       setActiveDeliveriesDup(reversed);
  //       setitemLength([...reversed].length);
  //   }


  const updatePage = (datum) => {
    // console.log(datum)

    if (location.state === undefined) {
      console.log(datum, "datunm")

      const filterQuoted = datum.data.deliveries.filter((quoted) => {
        return quoted?.delivery_type === "nobid";
      })
      filterQuoted.sort(function (a, b) {
        return joiner(a?.date_listed) - joiner(b?.date_listed)
      })
      const reversed = reverseArr(filterQuoted)



      setnewdata([...reversed]);



      UpdateNewData(
        [...reversed].slice(0, perpage)
      )

      setitemLength([...reversed].length);

      setExceldata([...reversed])


      setincoming(
        [...reversed]
      );


    } else {
      // console.log(datum, "datunm")
      const filterQuoted = datum.data.deliveries.filter((quoted) => {
        return quoted?.delivery_type === "nobid";
      })
      filterQuoted.sort(function (a, b) {
        return joiner(a?.date_listed) - joiner(b?.date_listed)
      })
      const reversed = reverseArr(filterQuoted)



      setnewdata([...reversed]);



      UpdateNewData(
        [...reversed].slice(0, perpage)
      )

      setitemLength([...reversed].length);

      setExceldata([...reversed])


      setincoming(
        [...reversed]
      );


      setPaginateActivePage(location.state);
    }
  };



  const Allfilter = (datum) => {
    console.log(datum, "datumm")
    if (location.state === undefined) {
      const filterQuoted = datum.data.deliveries.filter((quoted) => {
        return quoted?.delivery_type === "nobid";
      })
      filterQuoted.sort(function (a, b) {
        return joiner(a?.date_listed) - joiner(b?.date_listed)
      })
      const reversed = reverseArr(filterQuoted)
      setnewdata([...reversed]);
      setitemLength([...reversed].length);

      setExceldata([...reversed])


      setincoming(
        [...reversed]
      );




    } else {
      const filterQuoted = datum.data.deliveries.filter((quoted) => {
        return quoted?.delivery_type === "nobid";
      })
      filterQuoted.sort(function (a, b) {
        return joiner(a?.date_listed) - joiner(b?.date_listed)
      })
      const reversed = reverseArr(filterQuoted)



      setnewdata([...reversed]);



      // UpdateNewData(
      //   [...reversed]
      // );

      setitemLength([...reversed].length);

      setExceldata([...reversed])


      setincoming(
        [...reversed]
      );


      setPaginateActivePage(location.state);
    }
  };


  const filterData = e => {
    setsearch(e.target.value.toLowerCase())
    const filteredData = incoming.filter(names => names.delivery_id.toLowerCase().includes(search)
      || names.item_desc.toLowerCase().includes(search))
    UpdateNewData(filteredData)
  }

  const SearchBar = () => {
    return (
      <div className='search' >
        <div className='search-input'>
          <input type="text"
            value={search}
            autoFocus
            placeholder="search.." onChange={filterData} />
        </div>
      </div>
    )
  }

  // console.log(exceldata, "exceldata")

  const Datatouse = exceldata.map((x, i) => {
    return (
      // [...x.account_name, ...x?.account_id].join(" ")
      [
        [...x.delivery_id].join(""),
        [...x?.item_desc].join(""),
        [...x?.pickup_details?.pickup_address].join(""),
        [...x?.delivery_details?.delivery_address].join(""),
        [...x?.mode_of_movement ? x?.mode_of_movement : x?.mode_of_delivery].join(""),
        [...x?.delivery_details?.delivery_time_frame].join(""),
        [...x?.distance].join(""),
        [...moment(x?.date_listed).format("DD-MM-YYYY , h:mm:ss a")].join(""),
        [Object.keys(x?.quote).length].join(""),
        [Object.values(x?.quote).map(x => x?.quote_amount)?.sort()[0]].join("")


        // [
        //   ...(x?.phone_number?.phone_number
        //     ? x?.phone_number?.phone_number
        //     : x?.phone_number),
        // ].join(""),
        // [
        //   ...(x?.good_in_transit_insurance?.state === "verified"
        //     ? "Yes"
        //     : "No"),
        // ].join(""),
        // [...x.delivery_details?.delivery_time_frame].join(""),
        // [...x?.distance].join(""),
        // [moment(x?.date_listed).format("DD-MM-YYYY , h:mm:ss a")].join("")
      ]
    );
  });

  // console.log(Datatouse, "data-to-use")

  const realresult = Datatouse.map((x) => ({
    DeliveryID: x[0],
    Itemdesc: x[1],
    Pickup: x[2],
    Destination: x[3],
    Mode: x[4],
    Timeframe: x[5],
    Distance: x[6],
    DateListed: x[7],
    Quote: x[8],
    BestQuote: x[9]
    //   Insurance: x[5],
    //   Status: x[7]
  }));

  // useEffect(() => {
  //   if(localStorage.getItem("o_kj_") !== null){
  //       const decoded = jwt_decode(localStorage.getItem("o_kj_"));
  //       const expired = decoded.exp;

  //       if (expired < Date.now() / 1000) {
  //           Swal.fire({
  //               icon: 'warning',
  //               title: 'Session!',
  //               text: 'Session expired login again!',
  //               allowOutsideClick: false,
  //               confirmButtonColor: 'var(--main)'
  //               })
  //               .then((result) => {
  //                   if (result.value) {
  //                       localStorage.clear()
  //                       history.push('/')
  //                   }
  //               });
  //       }
  //       else{
  //           if(deliveriesnew !== undefined){
  //               setViewData(deliveriesnew)
  //               // setExceldata(deliveriesnew)
  //               // console.log(exceldata, "excel-deliveries")
  //           }
  //           else{
  //               //console.log('loading')
  //               setloading(true);
  //               // Axios.get(`${endPoint}m-dillivry-dashboard-manager`, {
  //               //     headers: {
  //               //     Authorization: localStorage.getItem("o_kj_"),
  //               //     }
  //               // })
  //               Axios.post(`${endPoint}m-platform-admin-dashboard`,{
  //                 "requestType":"get_deliveries"

  //             }, {
  //                 headers: {
  //                 Authorization: localStorage.getItem("o_kj_"),
  //                 }
  //             })
  //               .then((response) => {
  //                   setloading(false);
  //                   updatedeliveries(response)
  //                   // updateData(response)
  //                   setViewData(response)
  //                   // setExceldata(response)
  //                   // console.log(exceldata, "response-excel")

  //               })
  //           }
  //       }
  //   }
  //   else{
  //       history.push('/');
  //       localStorage.clear();
  //   }
  // }, []);



  useEffect(() => {
    // console.log(individualdata, "individual-data-useeffect")

    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      } else {
        setloading(true);

        if (individualdata === undefined) {
          console.log("im here");
          if (deliveriesnew !== undefined) {
            updatePage(deliveriesnew);

            setloading(false);
          } else {
            setloading(true);
            Axios.post(
              `${endPoint}m-platform-admin-dashboard`,
              {
                "requestType": "get_deliveries"
              },
              {
                headers: {
                  Authorization: localStorage.getItem("o_kj_"),
                },
              }
            )
              .then((response) => {
                if (response.status === 200) {
                  updatedeliveries(response);
                  updatePage(response);

                  setloading(false);
                }
              })
              .catch((error) => {
                console.log(error, "error");
                setloading(false);
              });
          }
        } else {
          console.log("use me");

          Allfilter(deliveriesnew);
          setloading(false);
        }
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);

  console.log(exceldata, "excel-deliveries")

  return (

    <div className="main-grand">
      {
        loading ?
          <div style={{ width: '100%', height: '100vh', backgroundColor: 'none', position: 'fixed', top: '0', paddingTop: '10px', zIndex: '10000' }}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress color="success" />
            </Box>
          </div>

          : null
      }
      <div className="main-parent">
        <div className="main-father" style={{ position: 'relative' }}>
          <button onClick={() => setshow(!show)}>
            {" "}
            <img src={filtersearch} alt="filtersearch" />
          </button>
          <h4>Booked Deliveries</h4>
          {/* <SearchBar/> */}
          {/* <button onClick={handlePrint}  > */}
          {" "}
          {/* <img src={exporticon} alt="exporticon" /> */}
          <CSVLink data={realresult}>  <img src={exporticon} alt="exporticon" /></CSVLink>
          {/* </button> */}
          {
            show ?
              <Drop_down />
              :
              null
          }
        </div>
        <div>
          <Pagination />
          {/* </div> */}
        </div>
      </div>

      <div className="table-body">
        <table id="pdf-table" ref={componentRef} >
          <thead   >
            <tr>
              {Tabledetails.map((x) => (
                <th onSelect={() => console.log("selected")}>{x.Thead}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {individualdata?.map((x) => {
              return (
                <tr onClick={() => history.push(`/delivery/${x.delivery_id}`)}>
                  {/* {
                    console.log(activeDeliveries, "xxxx")
                  } */}
                  <td> <div className="table_item_image" style={{ backgroundImage: `url(${x?.image_urls[0]})` }}></div> </td>
                  <td>{x?.delivery_id}</td>
                  <td>{x?.item_desc}</td>
                  <td>{x?.pickup_details?.pickup_address}</td>
                  <td>{x?.delivery_details?.delivery_address}</td>
                  <td>{x?.mode_of_movement ? x?.mode_of_movement : x?.mode_of_delivery}</td>
                  <td>{x?.delivery_details?.delivery_time_frame}</td>
                  <td>{x?.distance}</td>
                  <td>{moment(x?.date_listed).format("DD-MM-YYYY , h:mm:ss a")}</td>
                  <td>{Object.keys(x?.quote).length}</td>
                  <td>{Object.values(x?.quote).map(x => x?.quote_amount)?.sort()[0]}</td>


                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="bottom-parent">
          <div className="parent-left"></div>

          <div className="parent-right">
            <div>Rows per page:</div>

            <div>
              <FormControl
                sx={{ m: 1, minWidth: 5, minHeight: 5 }}
                size="small"
              >
                <Select
                  value={perpage}
                  onChange={handlevalue}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  color="success"
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
