import React, { useState, useContext, useEffect } from "react";
import Axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
// import image from "../../../src/assets/images/new logo.001.png";
// import imagee from "../../../src/assets/images/login-diliviry.png";
import PuffLoader from "react-spinners/PuffLoader";
import { FormControl, Button, InputLabel, Grid } from "@material-ui/core";

import { HiOutlineMail } from "react-icons/hi";
import InputField from "../../InputField";
import loginLogo from "../../assets/loginLogo.svg";
import sideImage from "../../assets/resetImage.png";
import { ConnContext } from "../../context/ConnContext";

export default function ForgotPassword() {
  const { visitingCountryCode } = useContext(ConnContext);
  const history = useHistory();
  const location = useLocation();
  const endPoint = process.env.REACT_APP_API;
  // const newendPoint2 = process.env.REACT_APP_API_FORGOT;


  const authAxios = Axios.create({
    baseURL: `${endPoint}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });

  useEffect(() => {
    setusername(location.state);
  }, []);

  const [username, setusername] = useState("");
  const [email, setEmail] = useState("")
  const [submitting, setsubmitting] = useState(false);

  const handleReset = (e) => {
    e.preventDefault();
    setsubmitting(true);

    // Axios.post(`${endPoint}m-dillivry-auth-manager`, {
    //   auth_type: "forgetpass",
    //   username: username,
    //   vcc: visitingCountryCode,
    // })
    authAxios.patch(`${endPoint}adminportal/v3/ng/reset_password`, {
      email: email,
    })
    .then((response) => {
        console.log(response, 'response---auth')
        setsubmitting(false);
        if (response.status === 200) {
          localStorage.setItem(
            "eid",
            // btoa(btoa(response.data.response_body.request_body.username))
            btoa(btoa(response.data))

          );

          Swal.fire({
            icon: "success",
            text: "Password reset passcode as been sent to your mail",

            confirmButtonColor: "#50B14A",
          }).then((result) => {
            history.push({
              pathname: "/ResetPassword",
              // state: username,
               state: email,

            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setsubmitting(false);
      });
  };
  return (
    <>
      <Grid container className="LoginContainer">
        <Grid item xs={12} className="LogoContainer">
          <Link to="/">
            <div>
              <img src={loginLogo} alt="logo" />
            </div>{" "}
          </Link>
        </Grid>
        <Grid container className="Form-Image">
          <Grid
            item
            xs={6}
            className="LoginForm"
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            <form className="FormInput-Button" onSubmit={handleReset}>
              {/* ***Input fields*** */}
              <div className="formCont">
                <div>Email</div>
                <FormControl className="formInput" variant="outlined">
                  {/* <InputLabel htmlFor="username"><HiOutlineMail style={{ marginRight:"5px", color:"var(--main)"}}/>Email</InputLabel> */}
                  <InputField
                    required
                    // name="username"
                    name="email"
                    id="email"
                    // id="username"
                    type="email"
                    // value={username}
                    value={email}
                    // labelWidth={60}
                    onChange={(e) => {
                    // setusername(e.target.value);
                      setEmail(e.target.value);
                    }}
                    icon={
                      <HiOutlineMail
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>
                {/* ***Login Button*** */}

                <Grid className="lgBtnn">
                  {submitting ? (
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      disabled
                      style={{
                        backgroundColor: "var(--main)",
                        width: "100%",
                        color: "white",
                        borderRadius: "10px",
                        padding: "10px",
                        height: "40px",
                        textTransform: "none",
                      }}
                    >
                      <span className="btnlabe">
                        <PuffLoader
                          color="white"
                          loading={submitting}
                          speedMultiplier={1}
                          size={24}
                        />
                      </span>
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      style={{
                        backgroundColor: "var(--main)",
                        width: "100%",
                        color: "white",
                        borderRadius: "5px",
                        padding: "10px",
                        height: "40px",
                        textTransform: "none",
                      }}
                    >
                      <span className="btnlabe">Reset Password</span>
                    </Button>
                  )}
                </Grid>
                {/* <Grid item xs={6} > Not registered yet? <span className="createAccount">Create an Account </span>  </Grid> */}
              </div>
            </form>
          </Grid>
          <Grid
            justifyContent="flex-start"
            alignItems="center"
            className="LoginImage"
            container
            item
            xs={6}
          >
            {/* ***Side Image*** */}
            <div>
              <img style={{ width: "100%" }} src={sideImage} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
