import React, { useState, useContext, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import Toolbar from "@mui/material/Toolbar";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "./Layout.css";

const drawerWidth = 265;

function Layout(props) {
  const [familyname, setfamilyname] = useState("");
  const [givenname, setgivenname] = useState("");

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isExpanded, setExpendState] = useState(true);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{ marginTop: "0px" }}>
      <Toolbar />

      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window()?.document?.body : undefined;

  useEffect(() => {
    setfamilyname(localStorage.getItem("fam"));
    setgivenname(localStorage.getItem("name"));
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        zindex="1000"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        style={{
          backgroundColor: "#ffffff",
          color: "black",
          boxShadow: "0px 0px 0px 0px",
          borderBottom: "1px solid  #C7E6C5",
        }}
      >
        <Navbar familyname={familyname} givenname={givenname} />
      </AppBar>

      {/* <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      > */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: '200px',
            },
          }}
        >
          {/* {drawer} */}
        </Drawer>
        <div>
        {/* <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "1px solid #C7E6C5",
              borderTop: "none",
              marginTop: "75px",
              paddingTop: "0px !important",
            },
          }}
        > */}
            <Sidebar/>
          {/* <Sidebar /> */}
        {/* </Drawer> */}

        </div>
       
      {/* </Box> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

export default Layout;
