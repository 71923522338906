import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import "./Disputes.css";

const DisputeSummary = (props) => {
  const { disputeSummary } = props.location.state;

  return (
    <>
      <div className="dispute__settlement--container">
        <div className="dispute__settlement--container__description">
          <h2>A new dispute has been created, here are the detailed summary</h2>
          <p>{disputeSummary.dispute_id}</p>
        </div>
        <div className="dispute__settlement--container__information">
          <div className="disputedrilldown__child">
            <p>Date Created:</p>
            <span>{disputeSummary.date.date_created}</span>
          </div>
          <div className="disputedrilldown__child">
            <p>Amount:</p>
            <span>&#x20A6;{disputeSummary.amount}</span>
          </div>
          <div className="disputedrilldown__child">
            <p>Sender's Name: </p>
            <span>{disputeSummary.sender_name}</span>
          </div>
          <div className="disputedrilldown__child">
            <p>User ID:</p>
            <span>{disputeSummary.user_id}</span>
          </div>
          <div className="dispute__complaint--para disputedrilldown__child">
            <p>Complaint: </p>
            <p>{disputeSummary.complaint}</p>
          </div>
          <div className="dispute__transaction--receipt">
            <img src={disputeSummary.image_url} alt="Sample Receipt" />
          </div>
        </div>
        <div className="disputelink__container">
          <Link to="/disputes" className="disputelink">
            Continue
          </Link>
        </div>
      </div>
    </>
  );
};

export default DisputeSummary;
