import { Grid, Paper, Box, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import "./Quoted_View.css";
import { AiFillFileText } from "react-icons/ai";
import GoogleMapWidget from "../../components/Map/GoogleMapWidget";
import { useHistory, useParams } from "react-router-dom";
import { DataContext } from "../../context/DataContext/DataProvider";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Mode from "../../assets/Mode.svg";
import Category from "../../assets/Category.svg";
import Pickup from "../../assets/Pickup.svg";
import Document from "../../assets/Document.svg";
import Distance from "../../assets/Distance.svg";
import Destination from "../../assets/Destination.svg";
import userAdd from "../../assets/user-add.png";
import smsicon from "../../assets/smsicon.svg";
import profile from "../../assets/profile-circle.png";



import Navbar from "../../components/Navbar";
import copy from "../../assets/copy.png";
import DialogPop from "../AdminUsers/DialogPop";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineEdit } from "react-icons/md"
import InputField from "../../InputField";
import { RiLockPasswordLine } from "react-icons/ri";
import EditIcon from '@mui/icons-material/Edit';
import {
  FaShuttleVan,
  FaTruck,
  FaMotorcycle,
  FaGripHorizontal,
  FaChair,
  FaObjectGroup,
} from "react-icons/fa";
import { AiOutlineHome, AiOutlineDropbox, AiFillCar } from "react-icons/ai";
import { GiTotem, GiMechanicGarage } from "react-icons/gi";
import { CgDatabase } from "react-icons/cg";
import { MdPayment } from "react-icons/md";
import { ConnContext } from "../../context/ConnContext";
import moment from "moment";
import SelectSearch, { fuzzySearch } from "react-select-search";
import ReactTooltip from "react-tooltip";
import { IconContext } from "react-icons";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RiEdit2Fill } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { border } from "@mui/system";
import PuffLoader from 'react-spinners/PuffLoader';
import { Refresh } from "@mui/icons-material";
import { DeliveryContext } from "../../context/Deliveries";
import { useLocation } from 'react-router-dom'

import { styled } from '@mui/material/styles';
import { DataGrid } from "@mui/x-data-grid";

function Open_view() {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  let { id } = useParams();
  const { visitingCountryCode } = useContext(ConnContext);
  const history = useHistory();
  const [activeDeliveriesDup, setActiveDeliveriesDup] = useState([]);
  const [activeDeliveries, setActiveDeliveries] = useState([]);
  const [paginateActivePage, setPaginateActivePage] = useState(1);
  const [allprovider, setallprovider] = useState([]);
  const { updatedeliveries, deliveriesnew } = useContext(DeliveryContext)
  const [itemLength, setitemLength] = useState(0);
  // const [perpage, setperpage] = React.useState(50);
  const [origin, setorigin] = useState([]);
  const [destination, setdestination] = useState([]);
  const { updateData, datas } = useContext(DataContext);
  const [loading, setloading] = useState(false);
  const endPoint = process.env.REACT_APP_API;
  const [itemDetails, setitemDetails] = useState([]);
  const [itemDetailsquote, setitemDetailsquote] = useState([]);
  const [showpopup, setShowpopup] = useState(false);
  const [updatepayment, setupdatepayment] = useState(false)
  const [withdrawquote, setwithdrawquote] = useState(false)
  const [showupdate, setShowupdate] = useState(false);
  const [catIsValid, setcatIsValid] = useState(false);
  const [amount, setamount] = useState("");
  const [amountpaid, setamountpaid] = useState("")
  const [deliveryId, setdeliveryId] = useState("")
  const [deliveryIdwithdraw, setdeliveryIdwithdraw] = useState("")
  const [fulfilername, setfulfilename] = useState("")
  const [fulfilernamewithdraw, setfulfilenamewithdraw] = useState("")
  const [provider, setprovider] = useState("");
  const [selectvalue, setSelectvalue] = useState("");

  const [perpage, setperpage] = useState(15);
  const [dataToShow, setdataToShow] = useState([]);
  const [item_category, setitem_category] = useState("");
  const [assigning, setassigning] = useState(false);
  const [updating, setupdating] = useState(false);

  const [itemdescUpdate, setitemdescUpdate] = useState("")

  const [required, setrequired] = useState(false);
  const [isloading, setisloading] = useState(false)


  const n = 15;




  const Refresh = () => {

    window.location.reload(true);

  }
  const options = allprovider.map((str, index) => ({ value: str, name: str }));

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: `100%`,
    },
  }));

  const location = useLocation()

  const authAxios = Axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });

  const handleChange = (...args) => {
    // searchInput.current.querySelector("input").value = "";
    console.log("ARGS:", args);

    console.log("CHANGE:");
  };

  const classes = useStyles();

  const updateItemDesc = (e) => {
    e.preventDefault();
    setisloading(true)
    // itemdescUpdate === "" || itemdescUpdate === undefined ?
    // setisloading(false)
    // :
    console.log(itemdescUpdate, "gg")

    authAxios.patch(`m-dillivry-admin-portal`, {
      action_type: "update_item_desc",
      delivery_id: itemDetails[0].delivery_id,
      item_desc: itemdescUpdate


    })
      .then((response) => {

        setisloading(false)
        setShowupdate(false)
        Swal.fire({
          icon: "success",
          text: `Item description updated successfully`,
          showConfirmButton: "true",
          confirmButtonColor: "var(--main)",

        })
        Refresh()

      })
      .catch((error) => {
        setisloading(false)
        setShowupdate(false)
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      })




  }



  const handleUpdatePayment = () => {
    if (deliveryId === "" || fulfilername === "" || amountpaid === "") {
      setrequired(true);
    } else {
      setassigning(true);
      authAxios
        .post(`m-dillivry-admin-portal`, {
          payment_type: "cash",
          delivery_id: deliveryId,
          fulfiller_id: fulfilername,
          amount_paid: amountpaid,
          payment_gateway: "NA"

        })
        .then((res) => {
          setassigning(false);
          setShowpopup(false);
          setdeliveryId("")
          setfulfilename("")
          setamountpaid("")
          setupdatepayment(false)
          Swal.fire({
            icon: "success",
            text: `Payment update successfully`,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        })
        .catch((err) => {
          setassigning(false);
          setupdatepayment(false)
          setdeliveryId("")
          setfulfilename("")
          setamountpaid("")
          console.log(err)
          console.log(err.response)

          if (err) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.msg}`,
              showConfirmButton: "true",
              confirmButtonColor: "grey",
            })
          }

        });
    }
  }


  const handleQuoteWithdraw = () => {

    if (deliveryIdwithdraw === "" || fulfilernamewithdraw === "") {
      setrequired(true);
    } else {
      setassigning(true);
      authAxios
        .patch(`m-dillivry-admin-portal`, {


          fulfiller_id: fulfilernamewithdraw,
          delivery_id: deliveryIdwithdraw,


        })
        .then((res) => {
          setassigning(false);
          // setShowpopup(false);
          setdeliveryIdwithdraw("")
          setfulfilenamewithdraw("")

          setwithdrawquote(false)
          Swal.fire({
            icon: "success",
            text: ` Quote withdraw  successfully`,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        })
        .catch((err) => {
          setassigning(false);
          setdeliveryIdwithdraw("")
          setfulfilenamewithdraw("")

          setwithdrawquote(false)
          console.log(err)
          console.log(err.response)

          if (err) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.msg}`,
              showConfirmButton: "true",
              confirmButtonColor: "grey",
            })
          }

        });
    }
  }




  const HandleAssignSubmission = () => {
    if (provider === "" || amount === "") {
      setrequired(true);
    } else {
      setassigning(true);
      authAxios
        .post(`m-dillivry-platform-agent-assignment`, {
          delivery_id: itemDetails[0].delivery_id,
          fulfiller_id: provider,
          delivery_status: itemDetails[0].delivery_status,
          quote_amount: amount,
        })
        .then((res) => {
          setShowpopup(false);
          Swal.fire({
            icon: "success",
            text: `Item reassign to ${provider}`,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        })
        .catch((err) => {
        })
        .finally(() => setassigning(false))
    }
  };

  const handleUpdate = () => {
    console.log("Update");
    setupdating(false);
  };


  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }


  useEffect(() => {
    console.log(visitingCountryCode)
    authAxios.post(`m-dillivry-admin-portal`,
      {
        action_type: "get_providers",

      })

      .then((res) => {
        // console.log(res, "response")
        setallprovider(res?.data);
        // console.log(res.data,"ress")
      })
      .catch((err) => {
        console.log(err)
        console.log(err.data)
      });
  }, [visitingCountryCode]);








  const updatePage = (datum) => {
    console.log("data", datum)
    const filterQuoted = [
      ...datum.data.deliveries,
    ].filter((quoted) => {
      return quoted?.delivery_id === id;
    });

    console.log(datum, "datummmmmmmmmmmm")


    console.log(filterQuoted, "filteredquoteddddddd")

    // console.log("filterQuoted",Object.entries(filterQuoted[0]?.quote))
    var data = [];

    var logistic = Object?.keys(filterQuoted[0]?.quote);
    logistic.forEach((item, key) => {
      const value = Object.getOwnPropertyDescriptor(filterQuoted[0]?.quote, item);
      var obj = {
        provider: item,
        ...value.value
      }
      data.push(obj);
      console.log("object", obj)
    })
    setitemDetailsquote(data);
    // console.log("logistic,",logistic,"obj",obj)
    setitemDetails(filterQuoted);
    setdataToShow(
      Object.entries(filterQuoted[0].quote).slice(0, perpage)
    );
    setActiveDeliveries([...filterQuoted].slice(0, perpage));
    setActiveDeliveriesDup(filterQuoted);
    setitemLength([...filterQuoted].length);
    const startLat = parseFloat(filterQuoted[0].start_location.lat);
    const startlng = parseFloat(filterQuoted[0].start_location.lng);
    const endLat = parseFloat(filterQuoted[0].end_location.lat);
    const endLng = parseFloat(filterQuoted[0].end_location.lng);

    setorigin([
      {
        id: "pickup",
        title: "Pickup",
        coordinates: {
          lat: startLat,
          lon: startlng,
        },
      },
    ]);
    setdestination([
      {
        id: "destination",
        title: "Destination",
        coordinates: {
          lat: endLat,
          lon: endLng,
        },
      },
    ]);
  }





  useEffect(() => {
    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      } else {
        if (deliveriesnew !== undefined) {
          updatePage(deliveriesnew)
          console.log(deliveriesnew, "res")
        }

        else {
          setloading(true);

          // Axios.get(`${endPoint}m-dillivry-dashboard-manager`, {
          //   headers: {
          //     Authorization: localStorage.getItem("o_kj_"),
          //   },
          // })

          Axios
            .post(`${endPoint}m-platform-admin-dashboard`, {
              "requestType": "get_deliveries"
            },
              {
                headers: {
                  Authorization: localStorage.getItem("o_kj_"),
                },
              })
            .then((response) => {
              console.log(response, "res")
              setloading(false);
              updatedeliveries(response)
              updatePage(response)
            });
        }
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);



  const [pageSize, setPageSize] = useState(10)
  const handleGetRowId = (e) => {
    console.log("e", e)
    return e.provider
  }
  console.log("itemDetails----------------------->", itemDetails && itemDetails[0]?.quote)

  const columns = [
    // { field: 'id', headerName: 'Image', width: 70 },
    {
      field: "quote_amount",
      headerName: "QuoteAmount",
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      // width: 160,
      // renderCell: (params) => <Avatar alt={params.row.item_desc} src={`${params.row.image_urls[0]}`} />,
      renderCell: (params) => <div>&#8358;{params.row.quote_amount}</div>,
    },
    {
      field: "provider",
      headerName: "Logistics provider",
      width: 200
    },
    {
      field: "time_stamp",
      headerName: "Date",
      width: 200,
      renderCell: ({ value }) => (
        <>
          {moment(value)?.format("llll").slice(0, 3)}
          {moment(value)?.format("llll").slice(4, 12)}
          {moment(value)?.format("llll").slice(17, 27)}
        </>
      ),
    },
    {
      field: "quote_status",
      headerName: "Quote Status",
      width: 200
    },
    {
      field: "Providers",
      headerName: "Providers info",
      width: 200,
      renderCell: (params) => <img src={profile} onClick={() => history.push(`/users/${params.id}`)}
        style={{ paddingLeft: "20px" }} />,
    },
    {
      field: "message",
      headerName: "Message",
      width: 200,
      renderCell: (params) => <img src={smsicon} onClick={() => history.push(`/messages/view`)} style={{ paddingLeft: "20px" }} />,
    },
  ];





  return (
    <div className="quoted_view_con">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "none",
            position: "fixed",
            top: "0",
            paddingTop: "10px",
            zIndex: "10000",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : null}
      <div className="qoute-nav">
        {
          console.log((window.location.href).includes('open'))
        }

        <Navbar
          role={`${(window.location.href).includes('open') ? "opendeliveries" : "dashboard"}`}
          dashboardtext="Back to dashboard"
          familyname={localStorage.getItem("fam")}
          givenname={localStorage.getItem("name")}
          page={location.state}
        />
      </div>
      {itemDetails.map((itemDetail, index) => {
        return (
          <div key={index} className="delivery-wrapper">
            <div className="topper">
              <span>
                Category:{" "}
                {itemDetail.item_category.charAt(0)?.toUpperCase() +
                  itemDetail.item_category.slice(1).replace(/_/g, " ")}
              </span>
              <span>ID: {itemDetail.delivery_id}</span>
            </div>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <div className="image-quote">
                    <div className="item_imges_con">
                      <div
                        className="item_imges"
                        style={{
                          backgroundImage: `url(${itemDetail.image_urls[0]})`,
                        }}
                      ></div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Item>
                    <div className="view_Map">
                      <GoogleMapWidget
                        origins={destination}
                        destinations={origin}
                      />
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Box>

            <div className="item_detail">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4.5} md={4.5}>
                  <Item sx={{ textAlign: "unset !important", p: 2 }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Delivery infomation
                    </Typography>
                    {/* <div className="item_inform"> */}
                    <div className="itemDescs">
                      <div className="itemDesc itemDescTop">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Document} alt="mode" />
                          </div>
                          <div>
                            {itemDetail.item_desc.charAt(0)?.toUpperCase() +
                              itemDetail.item_desc.slice(1).replace(/_/g, " ")}
                          </div>
                        </div>

                        <div onClick={() => setShowupdate(true)}>
                          <EditIcon fontSize="small" />
                        </div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Category} alt="mode" />
                          </div>
                          <div>
                            {itemDetail.item_category.charAt(0)?.toUpperCase() +
                              itemDetail.item_category
                                .slice(1)
                                .replace(/_/g, " ")}
                          </div>
                        </div>

                        <div></div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Pickup} alt="mode" />
                          </div>
                          <div>{itemDetail.pickup_details?.pickup_address}</div>
                        </div>
                        <div></div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Destination} alt="mode" />
                          </div>
                          <div>
                            {itemDetail.delivery_details?.delivery_address}
                          </div>
                        </div>
                        <div></div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            {/* <img src={Destination} alt="mode" /> */}
                            <span>
                              <AiFillFileText size={16} color="var(--main)" />{" "}
                            </span>
                          </div>
                          <div>
                            {itemDetail?.delivery_status
                              ?.charAt(0)
                              ?.toUpperCase() +
                              itemDetail?.delivery_status
                                ?.slice(1)
                                .replace(/_/g, " ")}
                          </div>
                        </div>
                        <div></div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Distance} alt="mode" />
                          </div>
                          <div>{itemDetail.distance}</div>
                        </div>
                        <div></div>
                      </div>

                      <div className="itemDesc">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <img src={Mode} alt="mode" />
                          </div>
                          <div>
                            {itemDetail?.mode_of_delivery
                              ? itemDetail?.mode_of_delivery
                                ?.charAt(0)
                                ?.toUpperCase() +
                              itemDetail?.mode_of_delivery
                                ?.slice(1)
                                .replace(/_/g, " ")
                              : itemDetail?.mode_of_movement
                                ?.charAt(0)
                                ?.toUpperCase() +
                              itemDetail?.mode_of_movement
                                ?.slice(1)
                                .replace(/_/g, " ")}
                          </div>
                        </div>
                        <div></div>
                      </div>

                      {itemDetail.delivery_status === "awaiting_quote" ||
                        itemDetail.delivery_status === "delivered" ? null : (
                        <div className="itemDesc">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div>
                              <img src={Document} alt="mode" />
                            </div>
                            <div>
                              {Object.values(itemDetail?.quote).map(
                                (x, index) => {
                                  return x?.quote_status === "accepted" ||
                                    x?.quote_status === "out_for_delivery" ||
                                    x?.quote_status === "missed_delivery" ? (
                                    <span className="icon-desc">
                                      {console.log(x, "item-detail")}
                                      {
                                        x?.payment_status === "success"
                                          ? "Paid"
                                          : x?.payment_status ===
                                            "pay_on_delivery"
                                            ? "Pay on delivery"
                                            : x?.payment_status

                                        // x?.payment_status
                                        //     ?.charAt(0)
                                        //     ?.toUpperCase() +
                                        //   x?.payment_status
                                        //     ?.slice(1)
                                        //     ?.replace(/_/g, " ")
                                      }
                                    </span>
                                  ) : null;
                                }
                              )}
                            </div>
                          </div>
                          <div></div>
                        </div>
                      )}
                      {itemDetail?.payer ? (
                        <div className="itemDesc" style={{ border: "0px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div>
                              {/* <img src={Mode} alt="mode" /> */}
                              <MdPayment
                                style={{
                                  color: "var(--main",
                                  fontSize: "18px",
                                }}
                              />
                            </div>
                            {itemDetail?.payer ? (
                              <div>Payer: {itemDetail.payer}</div>
                            ) : null}
                          </div>
                          <div></div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* </div> */}
                  </Item>
                </Grid>
                <Grid item xs={12} sm={4.5} md={4.5}>
                  <Item sx={{ textAlign: "unset !important", p: 2 }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Sender/Reciever infomation
                    </Typography>

                    <div
                      className="sender_data_con"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        gap: "15px",
                      }}
                    >
                      {/* <div className="sender_data"> */}
                      {/* <div className="item_inform"> */}
                      <div className="itemDescs">
                        <div className="itemDesc itemDescTop">
                          <b>Sender's name:</b>
                          <span className="">
                            {itemDetail?.sender_contact?.sender_name}
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b> Sender's email:</b>
                          <span className="">
                            {itemDetail?.sender_contact?.sender_email}
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b> Sender's number:</b>
                          <span className="">
                            {itemDetail?.sender_contact?.sender_phone_no}
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b>Reciever's name:</b>
                          <span className="">
                            {
                              itemDetail?.delivery_details?.delivery_contact
                                ?.delivery_name
                            }
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b>Reciever's email:</b>
                          <span className="">
                            {
                              itemDetail?.delivery_details?.delivery_contact
                                ?.delivery_email
                            }
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b>Reciever's number:</b>
                          <span className="">
                            {
                              itemDetail?.delivery_details?.delivery_contact
                                ?.delivery_phone_no
                            }
                          </span>
                        </div>

                        <div className="itemDesc">
                          <b>Pickup's name:</b>
                          <span className="">
                            {
                              itemDetail?.pickup_details?.pickup_contact
                                ?.pickup_name
                            }
                          </span>
                        </div>
                        <div className="itemDesc">
                          <b>Pickup's email:</b>
                          <span className="">
                            {
                              itemDetail?.pickup_details?.pickup_contact
                                ?.pickup_email
                            }
                          </span>
                        </div>
                        <div className="itemDesc" style={{ border: "0px" }}>
                          <b> Pickup's number:</b>
                          <span className="">
                            {
                              itemDetail?.pickup_details?.pickup_contact
                                ?.pickup_phone_no
                            }
                          </span>
                        </div>
                      </div>
                      {/* </div> */}
                      {/* </div> */}

                      {/* <div className="assignandpayment">
                      {itemDetail?.delivery_status ===
                        "awaiting_agent_assignment" ||
                      itemDetail?.delivery_status ===
                        "awaiting_provider_acknowledgment" ||
                      itemDetail?.delivery_status === "awaiting_pickup" ? (
                        <button
                          style={{
                            fontSize: "13px",
                            textTransform: "none",

                            padding: "8px",
                            border: "1px solid var(--main)",
                            cursor: "pointer",
                          }}
                          className="btn-"
                          size="small"
                          variant="contained"
                          onClick={() => setupdatepayment(true)}
                        >
                          Update payment
                        </button>
                      ) : null}

                      {itemDetail?.delivery_status === "awaiting_quote" &&
                      Object.getOwnPropertyNames(itemDetail?.quote)
                        ?.length !== 0 ? (
                        <button
                          style={{
                            fontSize: "13px",
                            textTransform: "none",

                            padding: "8px",
                            border: "1px solid var(--main)",
                            cursor: "pointer",
                          }}
                          className="btn-"
                          size="small"
                          variant="contained"
                          onClick={() => setwithdrawquote(true)}
                        >
                          Withdraw quote
                        </button>
                      ) : null}

                      <div>
                        {" "}
                        {itemDetail?.delivery_status ===
                          "awaiting_agent_assignment" ||
                        itemDetail?.delivery_status === "awaiting_pickup" ? (
                          <button
                            style={{
                              fontSize: "13px",
                              textTransform: "none",

                              padding: "8px",
                              border: "1px solid var(--main)",
                              cursor: "pointer",
                            }}
                            className="btn-"
                            size="small"
                            variant="contained"
                            onClick={() => setShowpopup(true)}
                          >
                            Reassign provider
                          </button>
                        ) : null}
                      </div>
                    </div> */}
                    </div>
                  </Item>
                </Grid>

                {/* <Grid item xs={12} sm={3} md={3}>
                {itemDetail?.delivery_status === "awaiting_quote" &&
                Object.getOwnPropertyNames(itemDetail?.quote)?.length !==
                  0 ? (
                  <Button
                    onClick={() => setwithdrawquote(true)}
                    sx={{ gap: 2, color: "#000", display: "flex" }}
                  >
                    <img src={userAdd} alt="user Add" />
                    Withdraw quote
                  </Button>
                ) : null}
                <Button sx={{ gap: 2, color: "#000", display: "flex" }}>
                  <img src={userAdd} alt="user Add" /> Assign provider
                </Button>
                {itemDetail?.delivery_status ===
                  "awaiting_agent_assignment" ||
                itemDetail?.delivery_status === "awaiting_pickup" ? (
                  <Button sx={{ gap: 2, color: "#000", display: "flex" }}>
                    <img
                      src={userAdd}
                      alt="user Add"
                      onClick={() => setShowpopup(true)}
                    />{" "}
                    Re-assign provider
                  </Button>
                ) : null}

                <Button sx={{ gap: 2, color: "#000", display: "flex" }}>
                  <img src={userAdd} alt="user Add" /> Assign agent
                </Button>

                {itemDetail?.delivery_status ===
                  "awaiting_agent_assignment" ||
                itemDetail?.delivery_status ===
                  "awaiting_provider_acknowledgment" ||
                itemDetail?.delivery_status === "awaiting_pickup" ? (
                  <Button sx={{ gap: 2, color: "#000", display: "flex" }}>
                    <img
                      src={userAdd}
                      alt="user Add"
                      onClick={() => setupdatepayment(true)}
                    />{" "}
                    Update payment
                  </Button>
                ) : null}
                <Item sx={{ textAlign: "unset !important", p: 2 }}>
                  <div className="itemDescs">
                    <div className="itemDesc">
                      <b> Pickup code : </b>
                      <span className="">DKF48374F5</span>
                    </div>
                    <div className="itemDesc" style={{ border: "0px" }}>
                      <b> Delivery code : </b>
                      <span className="">DKF48374F5</span>
                    </div>
                  </div>
                </Item>
              </Grid> */}
              </Grid>
            </div>
            {Object.keys(itemDetail?.quote).length === 0 ? (
              <></>
            ) : (
              <div
                // className="table-body"
                style={{ margin: "0", marginTop: "20px" }}
              >
                <h4>Quote history</h4>
                <div style={{ width: "100%" }}>
                  <DataGrid
                    density="comfortable"
                    sx={{
                      p: "30px",
                      background: "#fff",
                      "& .MuiDataGrid-cell": {
                        whiteSpace: "break-spaces !important",
                      },
                    }}
                    rows={
                      itemDetailsquote !== undefined &&
                        itemDetailsquote.length !== 0
                        ? itemDetailsquote
                        : []
                    }
                    columns={columns}
                    pageSize={pageSize}
                    autoHeight={true}
                    // onCellClick={(params, event, details) =>{

                    //   history.push({
                    //     pathname: `/delivery/${params.row.delivery_id}`,
                    //     state: paginateActivePage,
                    //   })}
                    // }
                    onPageSizeChange={(value) => setPageSize(value)}
                    rowsPerPageOptions={[5, 10, 20]}
                    getRowId={handleGetRowId}
                  // components={{
                  //   Toolbar: CustomToolbar,
                  // }}
                  // checkboxSelection
                  />
                </div>
              </div>
            )}
            {/* {Object.keys(itemDetail?.quote).length === 0 ? (
            <></>
          ) : (
            <div
              className="table-body"
              style={{ margin: "0", marginTop: "20px" }}
            >
              <div className="main-parent">
                <div className="main-father" style={{ position: "relative" }}>
                  <header className="qoute-header">
                    <h4>Quote history</h4>
                  </header>
                </div>

                <div>
                  <div className="flexboxes">
                    <span
                      className="paginateButtons2"
                      style={{
                        color: paginateActivePage === 1 ? "white" : "",
                        backgroundColor:
                          paginateActivePage === 1 ? "var(--main)" : "",
                        cursor:
                          paginateActivePage === 1
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={() => {
                        setdataToShow(
                          Object.entries(itemDetails[0].quote).slice(0, n)
                        );
                        setPaginateActivePage(1);
                      }}
                    >
                      First
                    </span>
                    {paginateActivePage === 1 ? (
                      <span
                        className="paginateButtons2"
                        style={{ color: "#989898", cursor: "not-allowed" }}
                      >
                        Previous
                      </span>
                    ) : (
                      <span
                        className="paginateButtons2"
                        onClick={() => {
                          setdataToShow(
                            Object.entries(itemDetails[0].quote).slice(
                              (paginateActivePage - 2) * perpage,
                              (paginateActivePage - 1) * perpage
                            )
                          );
                          setPaginateActivePage(paginateActivePage - 1);
                        }}
                      >
                        Previous
                      </span>
                    )}

                    <span className="pagination">
                      <ol>
                        {[...Array(Math.ceil(itemLength / perpage))].map(
                          (e, i) => (


                            <li
                              key={i}
                              className={
                                paginateActivePage === i + 1
                                  ? "active paginateButtons"
                                  : "paginateButtons"
                              }
                              style={{
                                backgroundColor:
                                  paginateActivePage === i + 1
                                    ? "var(--main)"
                                    : "",
                                color:
                                  paginateActivePage === i + 1 ? "white" : "",
                              }}
                              onClick={() => {
                                setdataToShow(
                                  Object.entries(itemDetails[0].quote).slice(
                                    perpage * i,
                                    perpage * i + perpage
                                  )
                                );
                                setPaginateActivePage(i + 1);
                              }}
                            >
                              <a href="#">{i + 1}</a>
                            </li>
                          )
                        )}
                      </ol>
                    </span>

                    {paginateActivePage ===
                    Math.ceil(itemLength / perpage) ? (
                      <span
                        className="paginateButtons2"
                        style={{ color: "#989898", cursor: "not-allowed" }}
                      >
                        Next
                      </span>
                    ) : (
                      <span
                        className="paginateButtons2"
                        onClick={() => {
                          setdataToShow(
                            Object.entries(itemDetails[0].quote).slice(
                              perpage * paginateActivePage,
                              perpage * (paginateActivePage + 1)
                            )
                          );
                          setPaginateActivePage(paginateActivePage + 1);
                        }}
                      >
                        Next
                      </span>
                    )}
                    <span
                      className="paginateButtons2"
                      style={{
                        backgroundColor:
                          paginateActivePage ===
                          Math.ceil(itemLength / perpage)
                            ? "var(--main)"
                            : "",
                        color:
                          paginateActivePage ===
                          Math.ceil(itemLength / perpage)
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        setdataToShow(
                          Object.entries(itemDetails[0].quote).slice(
                            Math.floor(itemLength / perpage) * perpage,
                            itemLength
                          )
                        );
                        setPaginateActivePage(
                          Math.ceil(itemLength / perpage)
                        );
                      }}
                    >
                      Last
                    </span>
                  </div>
                </div>
              </div>

              <table
                id="pdf-table"
                style={{ margin: "0", marginTop: "20px" }}
              >
                <thead>
                  <tr>
                    <th>Qoute amount</th>
                    <th>Logistics provider</th>
                    <th>Date</th>
                    <th>Quote Status</th>
                    <th>Providers info</th>
                    <th>Message</th>
                  </tr>
                </thead>

                <tbody>
                  {dataToShow.map((item) => {
                    // console.log("items",item)
                    return (
                      <tr>
                        <td>&#8358;{item[1].quote_amount}</td>

                        <td>{item[0]}</td>

                        {item[1]?.time_stamp ? (
                          <td>
                            {moment(item[1]?.time_stamp)
                              ?.format("llll")
                              .slice(0, 3)}
                            {moment(item[1]?.time_stamp)
                              ?.format("llll")
                              .slice(4, 12)}
                            {moment(item[1]?.time_stamp)
                              ?.format("llll")
                              .slice(17, 27)}
                          </td>
                        ) : (
                          <td></td>
                        )}

                        <td>
                          {item[1]?.quote_status?.charAt(0)?.toUpperCase() +
                            item[1]?.quote_status
                              ?.slice(1)
                              ?.replace(/_/g, " ")}
                        </td>
                        <td>
                          <Button
                            style={{
                              background: "var(--main)",
                              fontSize: "13px",
                              textTransform: "none",
                            }}
                            color="success"
                            className="btn-"
                            size="small"
                            variant="contained"
                            onClick={() => history.push(`/users/${item[0]}`)}
                          >
                            View Provider
                          </Button>
                        </td>
                        <td>
                          <Button
                            style={{
                              background: "var(--main)",
                              fontSize: "13px",
                              textTransform: "none",
                            }}
                            color="success"
                            className="btn-"
                            size="small"
                            variant="contained"
                            onClick={() => history.push(`/messages/view`)}
                          >
                            Message
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )} */}

            <DialogPop
              openPopup={withdrawquote}
              setopenPopup={setwithdrawquote}
              head="Withdraw quote"
            >
              <div className="app-">
                <div style={{ margin: "0 auto", width: 300 }}>
                  {/* <SelectSearch
                  options={options}
                  search
                  filterOptions={fuzzySearch}
                    placeholder="Select Provider"
                  value={provider}
                  onChange={(e) => setprovider(e)}
                /> */}
                </div>
              </div>

              <form style={{ margin: "0 auto", width: 300 }}>
                {required ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "15px",
                    }}
                  >
                    All field is required
                  </div>
                ) : (
                  <></>
                )}

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginBottom: "10px" }}
                >
                  <InputLabel id="select">
                    <span style={{ color: "var(--main)", marginRight: "10px" }}>
                      <FaGripHorizontal />
                    </span>
                    Delivery Id
                  </InputLabel>
                  <InputField
                    required
                    name="email"
                    id="email"
                    type="text"
                    value={deliveryIdwithdraw}
                    labelWidth={90}
                    onChange={(e) => {
                      setdeliveryIdwithdraw(e.target.value);
                      setrequired(false);
                    }}
                    icon={
                      <RiLockPasswordLine
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <div style={{ margin: "0 auto", width: 300 }}>
                  <SelectSearch
                    options={options}
                    search
                    filterOptions={fuzzySearch}
                    placeholder="Select Provider"
                    value={fulfilernamewithdraw}
                    onChange={(e) => setfulfilenamewithdraw(e)}
                  />
                </div>

                {/* <FormControl
                variant="outlined"
                className={classes.formControl}
                // style={{marginBottom: "30px"}}
              >
                <InputLabel id="select">
                  <span style={{ color: "var(--main)", marginRight: "10px" }}>
                    <FaGripHorizontal />
                  </span>
                   Amount Paid
                </InputLabel>
                <InputField
                  required
                  name="email"
                  id="email"
                  type="number"
                  value={amountpaid}
                  labelWidth={90}
                  onChange={(e) => {
                    setamountpaid(e.target.value);
                    setrequired(false);
                  }}
                  icon={
                    <RiLockPasswordLine
                      style={{ marginRight: "5px", color: "var(--main)" }}
                    />
                  }
                />
              </FormControl> */}

                <Button
                  style={{
                    background: "var(--main)",
                    fontSize: "13px",
                    textTransform: "none",
                    marginTop: "20px",
                    boxShadow: "none",
                    border: "1px solid var(--main)",
                  }}
                  color="success"
                  className="btn-"
                  size="small"
                  variant="contained"
                  onClick={handleQuoteWithdraw}
                  disabled={assigning}
                >
                  {assigning ? "Loading..." : "Withdraw quote"}
                </Button>
              </form>
            </DialogPop>

            <DialogPop
              openPopup={updatepayment}
              setopenPopup={setupdatepayment}
              head="Update cash payment"
            >
              <div className="app-">
                <div style={{ margin: "0 auto", width: 300 }}>
                  {/* <SelectSearch
                  options={options}
                  search
                  filterOptions={fuzzySearch}
                    placeholder="Select Provider"
                  value={provider}
                  onChange={(e) => setprovider(e)}
                /> */}
                </div>
              </div>

              <form style={{ margin: "0 auto", width: 300 }}>
                {required ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "15px",
                    }}
                  >
                    All field is required
                  </div>
                ) : (
                  <></>
                )}

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginBottom: "10px" }}
                >
                  <InputLabel id="select">
                    <span style={{ color: "var(--main)", marginRight: "10px" }}>
                      <FaGripHorizontal />
                    </span>
                    Delivery Id
                  </InputLabel>
                  <InputField
                    required
                    name="email"
                    id="email"
                    type="text"
                    value={deliveryId}
                    labelWidth={90}
                    onChange={(e) => {
                      setdeliveryId(e.target.value);
                      setrequired(false);
                    }}
                    icon={
                      <RiLockPasswordLine
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <div style={{ margin: "0 auto", width: 300 }}>
                  <SelectSearch
                    options={options}
                    search
                    filterOptions={fuzzySearch}
                    placeholder="Select Provider"
                    value={fulfilername}
                    onChange={(e) => setfulfilename(e)}
                  />
                </div>

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                // style={{marginBottom: "30px"}}
                >
                  <InputLabel id="select">
                    <span style={{ color: "var(--main)", marginRight: "10px" }}>
                      <FaGripHorizontal />
                    </span>
                    Amount Paid
                  </InputLabel>
                  <InputField
                    required
                    name="email"
                    id="email"
                    type="number"
                    value={amountpaid}
                    labelWidth={90}
                    onChange={(e) => {
                      setamountpaid(e.target.value);
                      setrequired(false);
                    }}
                    icon={
                      <RiLockPasswordLine
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <Button
                  style={{
                    background: "var(--main)",
                    fontSize: "13px",
                    textTransform: "none",
                    marginTop: "20px",
                    boxShadow: "none",
                    border: "1px solid var(--main)",
                  }}
                  color="success"
                  className="btn-"
                  size="small"
                  variant="contained"
                  onClick={handleUpdatePayment}
                  disabled={assigning}
                >
                  {assigning ? "Loading..." : "Update payment"}
                </Button>
              </form>
            </DialogPop>

            <DialogPop
              openPopup={showpopup}
              setopenPopup={setShowpopup}
              head="Reassign to provider"
            >
              <div className="app-">
                <div style={{ margin: "0 auto", width: 300 }}>
                  <SelectSearch
                    options={options}
                    search
                    filterOptions={fuzzySearch}
                    placeholder="Select Provider"
                    value={provider}
                    onChange={(e) => setprovider(e)}
                  />
                </div>
              </div>

              <form>
                {required ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "15px",
                    }}
                  >
                    All field is required
                  </div>
                ) : (
                  <></>
                )}

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                // style={{marginBottom: "30px"}}
                >
                  <InputLabel id="select">
                    <span style={{ color: "var(--main)", marginRight: "10px" }}>
                      <FaGripHorizontal />
                    </span>
                    Quote Amount
                  </InputLabel>
                  <InputField
                    required
                    name="email"
                    id="email"
                    type="number"
                    value={amount}
                    labelWidth={90}
                    onChange={(e) => {
                      setamount(e.target.value);
                      setrequired(false);
                    }}
                    icon={
                      <RiLockPasswordLine
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <Button
                  style={{
                    background: "var(--main)",
                    fontSize: "13px",
                    textTransform: "none",
                    marginTop: "20px",
                    boxShadow: "none",
                    border: "1px solid var(--main)",
                  }}
                  color="success"
                  className="btn-"
                  size="small"
                  variant="contained"
                  onClick={HandleAssignSubmission}
                  disabled={assigning}
                >
                  {assigning ? "Loading..." : "Reassign"}
                </Button>
              </form>
            </DialogPop>

            <DialogPop
              openPopup={showupdate}
              setopenPopup={setShowupdate}
              head="Update Description"
            >
              <div className="update_itemdesc_con">
                <form className="update_itemdesc_form">
                  <textarea
                    type="text"
                    style={{ outlineColor: "var(--main)" }}
                    placeholder="Update item desc.."
                    onChange={(e) => {
                      setitemdescUpdate(e.target.value);
                    }}
                    value={itemdescUpdate}
                    className="update_itemdesc_text"
                  />
                  <div className="update_itemdesc_btncon">
                    {itemdescUpdate === "" || itemdescUpdate === undefined ? (
                      <button disabled className="update_itemdesc_btn">
                        Update
                      </button>
                    ) : (
                      <button
                        onClick={updateItemDesc}
                        className="update_itemdesc_btn"
                      >
                        {isloading ? (
                          <PuffLoader
                            color="white"
                            loading={isloading}
                            speedMultiplier={1}
                            size={34}
                          />
                        ) : (
                          <span>Update</span>
                        )}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </DialogPop>

            <ReactTooltip
              id="mode"
              aria-haspopup="true"
              type="light"
              role="example"
            >
              <p>Edit item description</p>
              {/* <p>Mode of movement</p> */}
            </ReactTooltip>
          </div>
        );
      })}
    </div>
  )
}

export default Open_view