/* eslint-disable import/no-anonymous-default-export */
export default {
    GET_DEFAULT_DATA: 'GET_DEFAULT_DATA',
    GET_DASHDATA_NEW: 'GET_DASHDATA_NEW',
    GET_DELIVERIES_DATA: ' GET_DELIVERIES_DATA',
    Val_Bank_Details: 'Val_Bank_Details',
    Val_Profile_Photo: 'Val_Profile_Photo',
    Val_Profile_Message: 'Val_Profile_Message',
    Val_Insurance: "Val_Insurance",
    Val_Form_Id: "Val_Form_Id",
    General_Val: "General_Val",

    Rej_Bank_Details: 'Rej_Bank_Details',
    Rej_Profile_Photo: 'Rej_Profile_Photo',
    Rej_Profile_Message: 'Rej_Profile_Message',
    Rej_Insurance: "Rej_Insurance",
    Rej_Form_Id: "Rej_Form_Id",
    GET_OPENDELIVERIES_DATA: "GET_OPENDELIVERIES_DATA"
    // General_Val: "General_Val"
  };