import React, { useState, useEffect, useContext } from "react";
import "./Corporate.css";
import PuffLoader from "react-spinners/PuffLoader";
import approvedicon from "../../assets/approvedicon.svg";
import formdocumenticon from "../../assets/formdocumenticon.svg";
import {
  FormControl,
  Button,
  InputLabel,
} from "@material-ui/core";
import "jspdf-autotable";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { useHistory, useParams,useLocation } from "react-router-dom";
import { AgentContext } from "../../context/DataContext/AgentProvider";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";

import Navbar from "../../components/Navbar";
import "./UserDrilldown.css";

import Verified from "../../assets/Verified.svg";
import ValidPopup from "../../components/DialogPop/ValidPopup";
import axios from 'axios';
import DialogOutClick from "../../components/DialogOutClick";
import { ConnContext } from "../../context/ConnContext";
import InputField from "../../InputField";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import PhoneNumber from "../AdminUsers/PhoneNumber";
import { Capitalizer } from "../../utils/Capitalizer";

import {UserContext} from "../../context/UserContext"
import moment from "moment";
import { Avatar, Grid } from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";
import { AiOutlineWarning } from "react-icons/ai";


const Agentsdrilldown = () => {

  const {
    valPhoto,
    rejPhoto,
    valFormId,
    rejFormId,
  } = useContext(UserContext);

    let { id } = useParams();
    const [showing, setshowing] = useState('Profile')
    
    const [ShowPerma, setShowPerma] = useState(false)

    const location = useLocation();
    const tabs = ['Profile',"Actions", "Documents" ]

  const endpoint = process.env.REACT_APP_API
  const {visitingCountryCode} = useContext(ConnContext)
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const [submitting, setsubmitting] = useState(false);
  const [rejecting, setrejecting] = useState(false);
  const [comment, setcomment] = useState("");
  const endPoint = process.env.REACT_APP_API;
  // const endPoint2 = process.env.REACT_APP_API_PLATFORM;


  // const {GeneralVal } = useContext(AgentContext);

  const [showpop, setshowpop] = useState(false);
  const [clicked, setclicked] = useState("");
  const [corporatedetails, setcorporatedetails] = useState([]);
 const [showReason, setshowReason] = useState(false);
 const [updateinsuranceamount, setupdateinsuranceamount] = useState(false);
 const [toreject, setToreject] = useState('');
const [showAmount, setshowAmount] = useState(false)
const [amount, setamount] = useState("")

const [Showpop, setShowpop] = useState(false)
const [showreset, setShowreset] = useState(false)
const [Showpopemail, setShowpopemail] = useState(false);
const [Showpopsms, setShowpopsms] = useState(false);
const [Showpopsuspend, setShowpopsuspend] = useState(false);
const [Showpopenable, setShowpopenable] = useState(false);
const [Showpopresetpassword, setShowpopresetpassword] = useState(false);
  const [permanentlyDelete, setpermanentlyDelete] = useState('')

  const [ios, setios] = useState("")
  const [iemi, setiemi] = useState("")
  const [iosversion, setiosversion] = useState("")
  const [emailsubject, setemailsubject] = useState("");
  const [emailtextarea, setemailtextarea] = useState("");
  const [emailreset, setemailreset] = useState("");
  const [smssubject, setsmssubject] = useState("");

  const [addingUser, setaddingUser] = useState(false);

const Buttoncard = ({className,onClick, disabled, color, text, loading }) =>{
return(
  <div> <button onClick={onClick} className={className} disabled={disabled} color={color}>{ loading ? <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={24}/> : text}</button></div>
)
}

const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers:{
      Authorization:  localStorage.getItem('o_kj_')
    }
  });


  const refetch = ()=>{
    // Axios.get(`${endPoint}platform/v3/ng/user_details?user_id=${id}`, {
    Axios.get(`${endPoint}admin/v1/ng/user_details?user_id=${id}`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    }).then((response) => {
        if (response.status === 200) {
        
          setcorporatedetails(response?.data?.data)

        setloading(false);
      }
    });
  }

  const handlesendEmial = async (e) => {
    e.preventDefault();
    setsubmitting(true);

    await authAxios
      .post(`${endPoint}adminportal/v3/ng/send_email`, {
        email: [corporatedetails[0].user_id],
        email_subject: emailsubject,
        message_body: emailtextarea,
      })
      .then((response) => {
        if (response) {
          setShowpopemail(!Showpopemail);
          setsubmitting(false);
          if (response.status === 200) {
            setShowpopemail(!Showpopemail);
            setemailsubject("");
            setemailtextarea("");

            Swal.fire({
              icon: "success",
              title: "Email Sent",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 3000,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error?.response);
        setShowpopemail(!Showpopemail);
        setsubmitting(false);
        if (error) {
          setemailsubject("");
          setemailtextarea("");
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.msg}`,
            confirmButtonColor: "red",
          });
        }
      });
  };


  const handlesendSms = async (e) => {
    e.preventDefault();
    setsubmitting(true);

    await authAxios
      .post(`${endPoint}adminportal/v3/ng/send_sms`, {
        phone_number: corporatedetails?.[0]?.phone_number,
        message_body: smssubject,
      })
      .then((response) => {
        if (response) {
          setShowpopsms(!Showpopsms);
          setsubmitting(false);
          if (response.status === 200) {
            setShowpopsms(!Showpopsms);
            setsmssubject("");

            Swal.fire({
              icon: "success",
              title: "Sms Sent",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 2000,
            });
          }
        }
      })
      .catch((error) => {
        setShowpopsms(!Showpopsms);
        setsubmitting(false);
        if (error) {
          setsmssubject("");
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "red",
          });
        }
      });
  };


  const handledevicereset = async (e) =>{
    e.preventDefault();
    setsubmitting(true);
  
    const authAxios = Axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    });
    await authAxios
      .patch(`${endPoint}m-dillivry-admin-portal`, {
        user_id: corporatedetails[0].user_id,
        // email: emailreset,
        // ios: ios,
      //  ios_version: iosversion,
      //  iemi: iemi
      })
      .then((response) => {
        if (response) {
          setShowreset(!showreset)
          setsubmitting(false);
          if (response.status === 200) {
            console.log(response);
            setShowreset(!showreset)
           setemailreset("")
           setios("")
           setiosversion("")
           setiemi("")
  
            Swal.fire({
              icon: "success",
              title: `${response?.data?.msg}`,
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              timer: 6000,
            });
          }
        }
      })
      .catch((error) => {
        // setShowpopemail(!Showpopemail);
        setShowreset(!showreset)
        setsubmitting(false);
        if (error) {
          console.log(error.response, "error")
          setemailreset("")
           setios("")
           setiosversion("")
           setiemi("")
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${error.response.data.msg}`,
            confirmButtonColor: "red",
          });
        }
      });
  }

  const handleSuspendacct = async (e) => {
    setsubmitting(true);
    console.log("hello");

    await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "disable_user",
        email: corporatedetails[0]?.user_id,
        country_code: visitingCountryCode,
      },
    })
      .then((res) => {
        setsubmitting(false);

        console.log(res);
        if (res.status === 200) {
          setShowpopsuspend(false);
          Swal.fire({
            icon: "success",
            title: "Account Suspended",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 6000,
          });
        }
      })
      .catch((error) => {
        setsubmitting(false);
        setShowpopsuspend(false);
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };

  const Cards = ({ x, i, title, stateCheck, type }) => {
    return (
      <div>
        {stateCheck?.state === undefined ? 
        <div className="profile-photo-pending">Not uploaded yet</div>
        :
        
      <div
        key={i}
        className={
          stateCheck?.state === "verified"
            ? "profile-photo"
            : stateCheck?.state === "not_verified"
            ? "profile-photo-pending"
            : "profile-photo-reject"
        }
        onClick={() => {
          setshowpop(!showpop);
          // console.log(type, "type")
          setclicked(type);
        }}
      >
        <div className="photoicon">
          {stateCheck?.state === "verified" ? (
            <img src={Verified} alt="form-icons" />
          ) : stateCheck?.state === "not_verified" ? (
            <img src={formdocumenticon} alt="form-icons" />
          ) : (
            <img src={formdocumenticon} alt="form-icons" />
          )}
        </div>
        <div className="photo-text">
          <p className="upper-text">{title}</p>
          <p className="status-verified-or-pending">
            {stateCheck?.state === "verified"
              ? "Verified"
              : stateCheck?.state === "not_verified"
              ? "Pending"
              : "Rejected"}
          </p>
        </div>
      </div>
      }
      </div>
    );
  };


  const validateProfile_photo = (type)=>{ 
    if(type === "validate"){
      setsubmitting(true);

       authAxios.post(`v2/m-fulfiller-document-verify`,{
        action_type : "validate_acct", 
        validation: "validated",
        account_name : corporatedetails[0]?.account_name,
        attribute_to_change: "profile_photo", 
        vcc: visitingCountryCode,
        user_id: corporatedetails[0]?.user_id
       
      })
      .then((response)=>{
        setsubmitting(false)
        if(response.status === 201 ){
          // valPhoto(corporatedetails[0]?.user_id)
          refetch()
          setshowpop(false);
          Swal.fire({
            icon: 'success',
            title: 'Validated',
            showConfirmButton: false,
            confirmButtonColor:'var(--main)',
            timer: 3000})    
        } 
        if(response.status === 200){
          // valPhoto(corporatedetails[0]?.user_id)
          refetch()
          // GeneralVal(corporatedetails[0]?.user_id)
          setshowpop(false);
          Swal.fire({
            icon: 'success',
            title: 'Validated',
            showConfirmButton: false,
            confirmButtonColor:'var(--main)',
            timer: 3000}) 

        }
      }
      
      )
      .catch((err)=>{
        if(err.response.status === 403){
          Swal.fire({
            icon: 'info',
            text: 'Session expired',
            confirmButtonColor:'var(--main)',
            })
            .then((result)=>{
              if (result.value) {
                history.push(`/`);
                localStorage.clear()
              }
            })
          }
      })

    }
    if(type === "reject"){
     setrejecting(true)
       authAxios.post(`v2/m-fulfiller-document-verify`,{
        action_type : "validate_acct", 
        validation: "rejected",
        account_name : corporatedetails[0]?.account_name,
        attribute_to_change: "profile_photo",
        comment: comment,
        vcc: visitingCountryCode,
        user_id: corporatedetails[0]?.user_id
      })
      .then((response)=>{
        setrejecting(false);  setshowReason(false); setupdateinsuranceamount(false);    setcomment('')
        if(response.status === 201){
          // rejPhoto(corporatedetails[0]?.user_id)
          refetch()
          setshowpop(false);
          Swal.fire({
            icon: 'info',
            text: 'Rejected',
            showConfirmButton: false,
            timer: 3000})
        }}
      )
      .catch((err)=>{
        if(err.response.status === 403){
          Swal.fire({
            icon: 'info',
            text: 'Session expired',
            confirmButtonColor:'var(--main)',
            })
            .then((result)=>{
              if (result.value) {
                history.push(`/`);
                localStorage.clear()
              }
            })
          }
      })
    }
  }

  // console.log(corporatedetails?.account_name, "musco")

  const validateProof = (type)=>{

    if(type === "validate"){
      setsubmitting(true);

      authAxios.post(`v2/m-fulfiller-document-verify`,{
       action_type : "validate_acct", 
       validation: "validated",
       account_name : corporatedetails[0]?.account_name,
       attribute_to_change: "proof_of_identity",
       vcc: visitingCountryCode,
       user_id: corporatedetails[0]?.user_id
     })
     .then((response)=>{
      setsubmitting(false)
       if(response.status === 201  ){
        // valFormId(corporatedetails[0]?.user_id)
         setshowpop(false);
         Swal.fire({
           icon: 'success',
           title: 'Validated',
           showConfirmButton: false,
           confirmButtonColor:'var(--main)',
           timer: 3000})
       }
       if(response.status === 200){
        refetch()
        // valFormId(corporatedetails[0]?.user_id)
        // GeneralVal(corporatedetails[0]?.user_id)
         setshowpop(false);
         Swal.fire({
           icon: 'success',
           title: 'Validated',
           showConfirmButton: false,
           confirmButtonColor:'var(--main)',
           timer: 3000})

       }
      
      })
       .catch((err)=>{
        if(err.response.status === 403){
          Swal.fire({
            icon: 'info',
            text: 'Session expired',
            confirmButtonColor:'var(--main)',
            })
            .then((result)=>{
              if (result.value) {
                history.push(`/`);
                localStorage.clear()
              }
            })
          }
      })
   }
   if(type === "reject"){
      setrejecting(true)
      authAxios.post(`v2/m-fulfiller-document-verify`,{
      //  action_type : "validate_acct", 
      //  validation: "rejected",
      //  account_name : corporatedetails.account_name,
      //  attribute_to_change: "proof_of_identity",
      //  comment: comment,
      //  vcc: visitingCountryCode,
      //  user_id: corporatedetails.user_id
      action_type : "validate_acct", 
       validation: "validated",
       account_name : corporatedetails[0]?.account_name,
       attribute_to_change: "proof_of_identity",
       vcc: visitingCountryCode,
       user_id: corporatedetails[0]?.user_id,
       comment: comment,
     })
     .then((response)=>{
     setrejecting(false);  setshowReason(false); setupdateinsuranceamount(false);   setcomment('')

       if(response.status === 201 ){
        // rejFormId(corporatedetails[0]?.user_id)
        refetch()
         setshowpop(false);
         Swal.fire({
           icon: 'info',
           text: 'Rejected',
           showConfirmButton: false,
           timer: 3000})
       }}
     )
     .catch((err)=>{
      if(err.response.status === 403){
        Swal.fire({
          icon: 'info',
          text: 'Session expired',
          confirmButtonColor:'var(--main)',
          })
          .then((result)=>{
            if (result.value) {
              history.push(`/`);
              localStorage.clear()
            }
          })
        }
    })
   }
  }


  const validateBank = (type)=>{}


const validateInsurance = (type)=>{}

const handleDelete = async (e) => {
  setsubmitting(true);
  await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
    data: {
      action_type: "delete_user",
      email: corporatedetails?.[0]?.user_id,
      vcc: visitingCountryCode,
    },
  })
    .then((res) => {
      setsubmitting(false);

      if (res.status === 200) {
        setShowpop(false);
        Swal.fire({
          icon: "success",
          title: "User deleted successfully",
          showConfirmButton: "false",
          confirmButtonColor: "grey",
          timer: 2000,
        })
        .then(()=>{
          // refetchUsers()
        })
      }
    })
    .catch((error) => {
      console.log(error.response);
      setsubmitting(false);
      setShowpop(false);
      if (error) {
        Swal.fire({
          icon: "error",
          title: `${error?.response?.data?.error}`,
          confirmButtonColor: "grey",
        });
      }
    })
    .finally(()=>setpermanentlyDelete(''))
};

const handleDeletePerma = async (e) => {
  setsubmitting(true);
    await Axios.delete(`${endPoint}adminportal/v3/ng/remove_user`, {
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
    data: {
      user_id: corporatedetails?.[0]?.user_id,
    },
  })
    .then((res) => {
      setsubmitting(false);

      if (res.status === 200) {
        setShowPerma(false);
        Swal.fire({
          icon: "success",
          title: "User deleted permanently successfully",
          showConfirmButton: "false",
          confirmButtonColor: "grey",
          timer: 2000,
        })
        .then(()=>{
          history.goBack()
        })
      }
    })
    .catch((error) => {
      setsubmitting(false);
      setShowPerma(false);
      if (error) {
        Swal.fire({
          icon: "error",
          title: `${error?.response?.data?.error}`,
          confirmButtonColor: "grey",
        });
      }
    })
    .finally(()=>setpermanentlyDelete(''))
};



const handleenableacct = async (e) => {
  setsubmitting(true);

  await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
    data: {
      action_type: "enable_user",
      email: corporatedetails[0]?.user_id,
      country_code: visitingCountryCode,
    },
  })
    .then((res) => {
      setsubmitting(false);

      console.log(res);
      if (res.status === 200) {
        setShowpopenable(false);
        Swal.fire({
          icon: "success",
          title: "Account Enabled",
          showConfirmButton: "false",
          confirmButtonColor: "grey",
          timer: 6000,
        });
      }
    })
    .catch((error) => {
      setsubmitting(false);
      setShowpopenable(false);
      if (error) {
        Swal.fire({
          icon: "error",
          title: "error",
          confirmButtonColor: "grey",
        });
      }
    });
};


const checkInsurance = ()=>{}


useEffect(() => {
    setloading(true);

    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      }
      
      else {
          setloading(true);
          // Axios.get(`${endPoint}platform/v3/ng/user_details?user_id=${id}`, {
          Axios.get(`${endPoint}admin/v1/ng/user_details?user_id=${id}`, {
            headers: {
              Authorization: localStorage.getItem("o_kj_"),
            },
          }).then((response) => {
              if (response.status === 200) {
              
                setcorporatedetails(response?.data?.data)

              setloading(false);
            }
            console.log(response)
          })
          .catch((err)=>{
            console.log(err,"error")
          })
          .finally(()=>setloading(false))
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);

  const [isloading, setisloading] = useState(false)

  const handleresetPassword = (e) =>{
    setisloading(true)
    authAxios
      .patch(`adminportal/v3/ng/reset_password`, {
        email: corporatedetails?.[0]?.user_id,
      })
     .then((response)=>{
       setisloading(false)
       setShowpopresetpassword(false)
       Swal.fire({
        icon: "success",
        text: `A reset passcode has been sent to this user`,
        showConfirmButton: false,
        confirmButtonColor: "var(--main)",
        timer: 3000,

       })

     })
     .catch((error)=>{
      setisloading(false)
      setShowpopresetpassword(false)
      if(error){
        Swal.fire({
          icon: "error",
          title: "error",
          confirmButtonColor: "grey",
        });
      }
    })
  }

    return (
        <div>
      {loading === true ? (
        <div>
          <Box sx={{ width: "100%", marginTop: "2px" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : (
        <div className="main-grand">
         <div className="drill-down-main">
         <Navbar
            dashboardtext="Back to dashboard"
            familyname={localStorage.getItem('fam')}
            givenname={localStorage.getItem('name')}
            page={location.state}
            role={corporatedetails[0]?.role}
          />
         </div>
          <div className="main-father">
          </div>

          <div className="drill-down-main">
          {corporatedetails.map((corp)=>(
            <div key={corp.account_id} className="drill-down-main-container">
          <Grid container spacing={4}>
            <Grid item sm={3}>
              <div className="cards_container">
                <Avatar
                  sx={{ width: 65, height: 65 }}
                  alt={corp.cop_logo}
                  src={`${corp.cop_logo}`}
                />
              <div>
                <div className="verify_name">{ corp?.account_name && Capitalizer(corp.account_name)}</div>
                <div className="verify_flex">
                  {
                    corp.account_status.state === "active" ?
                    <img src={ approvedicon} alt="" />
                    :
                    <AiFillCloseSquare size={16} color="red"/>
                  }
                  <span>Verified</span>
                </div>
                <div className="verify_flex">
                  {
                    corp?.good_in_transit_insurance?.insurance_coverage === "None" ?
                    <AiFillCloseSquare size={16} color="red"/>
                    :
                    <img src={ approvedicon} alt="" />
                  }
                  <span>Insurance</span>
                </div>
              </div>
            </div>
            </Grid>
            <Grid item sm={9}>
              <div className="move_tabs">
                {
                  tabs.map((x,i)=>(
                    <span key={i} 
                      onClick={()=>setshowing(x)} 
                      className={x === showing ? "tab_flex_active" : "tab_flex"}>{x}</span>
                  ))
                }
              </div>
             
              <div>
                {
                  showing === 'Profile' ? 
                    <div className="move_profile_container">
                      <div className="content_flex">
                        <span className="bold_span">Email</span>
                        <span>{corp?.user_id}</span>
                      </div>
                      <div className="content_flex">
                        <span className="bold_span">Phone number</span>
                        <span>{corp?.phone_number}</span>
                      </div>
                      <div className="content_flex">
                        <span className="bold_span">Date registered</span>
                        <span>{moment(corp.account_status.joined_date).format('DD/MM/YYYY')}</span>
                      </div>
                      <div className="content_flex _last">
                        <span className="bold_span">Status</span>
                        <span>{corp?.account_status?.state && Capitalizer(corp.account_status.state).replace(/_/g, ' ')}</span>
                      </div>
                      {/* <div className="content_flex">
                        <span className="bold_span">Location</span>
                        <span>Not coming yet</span>
                      </div>
                      <div className="content_flex _last">
                        <span className="bold_span">Not coming yet</span>
                        <span></span>
                      </div> */}
                    </div>
                    : showing === "Actions" ? (
                      <div className="move_profile_container">
                        <div className="content_flex">
                          <span
                            className="cursor_span"
                            onClick={() => setShowpopresetpassword(true)}
                          >
                            Reset password
                          </span>
                        </div>
                       
                        <div className="content_flex">
                          <span
                            className="cursor_span"
                            onClick={() => setShowpopemail(true)}
                          >
                            Email user
                          </span>
                        </div>
                        <div className="content_flex">
                          <span
                            className="cursor_span"
                            onClick={() => setShowpopsms(true)}
                          >
                            SMS user
                          </span>
                        </div>
                        <div className="content_flex">
                          <span
                            className="cursor_span"
                            onClick={() => setShowpopsuspend(true)}
                          >
                            Suspend account
                          </span>
                        </div>
                        <div className="content_flex">
                          <span
                            className="cursor_span"
                            onClick={() => setShowpopenable(true)}
                          >
                            Restore/enable account
                          </span>
                        </div>
                        {
                            corp?.account_status?.deleted_date?.state !== "deleted" ?
                            <div className="content_flex _last">
                              <span
                                className="cursor_span"
                                onClick={() => setShowpop(true)}
                              >
                                Delete account
                              </span>
                            </div>
                            :
                            <div className="content_flex _last">
                              <span
                                className="cursor_span"
                                onClick={() => setShowPerma(true)}
                              >
                                Delete account permanently
                              </span>
                            </div>
                         }
                      </div>)
                    :
                    showing === 'Documents' ?
                      <div className="move_profile_container">
                        <div className="inner-documents">
                          {corporatedetails?.map((x, i) => {
                            return (
                              <Cards
                                x={x}
                                i={i}
                                title="Profile Photo"
                                stateCheck={x?.profile_photo}
                                type="profile_photo"
                              />
                            );
                          })}
                          {corporatedetails?.map((x, i) => {
                            return (
                              <Cards
                                x={x}
                                i={i}
                                title="Form of ID"
                                stateCheck={x?.proof_of_identity}
                                type="proof_of_identity"
                              />
                            );
                          })}
                        </div>
                      </div>
                    :
                    <></>
                }
              </div>
            </Grid>
          </Grid>
          </div>
          ))}
          </div>
        </div>
      )}


      {showpop ? (
        <ValidPopup
          title={
            clicked === "profile_photo"
              ? "Profile Photo"
              : clicked === "proof_of_identity"
              ? "Form of ID"
              : clicked === "good_in_transit_insurance"
              ? "Insurance Policy"
              : clicked === "bank_detail"
              ? "Bank Details"
              : null
          }
          trigger={showpop}
          setTrigger={setshowpop}
        >
          <div className="pop-up-content-parent">
            <div className="pop-up-content">
              <div className="img-content">
                {
                  clicked === "profile_photo"
                  ? <img src={corporatedetails[0]?.profile_photo?.profile_photo} alt="corporate-logo"  className="image-zoom" width='150px' />
                  : clicked === "proof_of_identity"
                  ? <img src={corporatedetails[0]?.proof_of_identity?.form_of_id} alt="corporate-logo" className="image-zoom"   width='250px' />
                  : null
                }
              </div>
              <div className="pending">
                {
                  clicked === "profile_photo"
                  ? corporatedetails[0]?.profile_photo?.state === "verified" ? "Verified" : corporatedetails[0]?.profile_photo?.state === "not_verified" ? "Pending" : "Rejected"
                  : clicked === "proof_of_identity"
                  ? corporatedetails[0]?.proof_of_identity?.state === "verified" ? "Verified" : corporatedetails[0]?.proof_of_identity?.state === "not_verified" ? "Pending" : "Rejected"
                 : null
                }
              </div>             
            </div>
            <div className="content">
              {clicked === "profile_photo"
                ? <div>Make sure the face on the profile photo is clear with good lighting <br/>  No object is covering the face on the profile photo.</div>
                : clicked === "proof_of_identity"
                ? <div>Make sure the photo on the From of Identification is clear with good lighting.<br/>
                    No object is covering the face on the From of Identification.<br/>
                    All infomation on the From of Identification is visible.
                  </div>
                : clicked === "good_in_transit_insurance"
                ? <div>Make sure the Insurance policy is clear with good lighting<br/>
                    No object is covering the any infomation on the documents.</div>
                : clicked === "bank_detail"
                ? <div>Make sure you verify account number <br/>
                The Bank Verification Number is included</div>
                : null
              }
            </div>

          <div className="btn-parent" >
            {
              clicked === "profile_photo"? 
              corporatedetails[0]?.profile_photo?.state === "verified" ? 
                <>
                  <Buttoncard text='Validate' disabled className='disabled'/>
                  <Buttoncard text='Reject' disabled className='disabled'/>
                </> 
                : 
              corporatedetails[0]?.profile_photo?.state === "not_verified" ? 
                <>
                  <Buttoncard onClick={()=>validateProfile_photo("validate")} text='Validate' loading={submitting} className='success'/>
                  <Buttoncard onClick={()=>{setshowReason(true); setToreject('photo')}} text='Reject' className='reject'/>
                </>
               : 
               <>
                <Buttoncard text='Validate' disabled className='disabled'/>
                <Buttoncard text='Reject' disabled className='disabled'/>
              </> 

              : 
              clicked === "proof_of_identity"? 
                corporatedetails[0]?.proof_of_identity?.state === "verified" ? 
                <>
                  <Buttoncard text='Validate' disabled className='disabled'/>
                  <Buttoncard text='Reject' disabled className='disabled'/>
                </>  
                : 
                corporatedetails[0]?.proof_of_identity?.state === "not_verified" ? 
                <>
                  <Buttoncard onClick={()=>validateProof("validate")} text='Validate' loading={submitting} className='success'/>
                  <Buttoncard onClick={()=>{setshowReason(true); setToreject('id')}} text='Reject' className='reject'/>
                </>
                : 
                <>
                  <Buttoncard text='Validate' disabled className='disabled'/>
                  <Buttoncard text='Reject' disabled className='disabled'/>
                </>
              
              : null
            }
          
          </div>


          </div>
        </ValidPopup>
      ) : null}

       <DialogOutClick 
        open={showAmount}
          handleClose={() => setshowAmount(false)}>
            <div style={{padding:'30px', width:'300px'}}>
              <p>Input the Amount</p>
          <div style={{padding:'15px 0'}}>
            <input type="text" value={amount} onChange={(e)=>setamount(e.target.value)} style={{width:'100%', padding:'10px',border:'none', borderRadius:'5px', outline:'1px solid var(--main)'}}/>
          </div>
          {
            amount !== "" ?
            <Buttoncard onClick={()=>validateInsurance("validate")} text='Validate' loading={submitting} className='success'/>
            :
            <Buttoncard text='Validate' disabled className='disabled'/>
          }
          </div>
      </DialogOutClick>


      <DialogOutClick open={Showpop} handleClose={() => setShowpop(false)}>
        <div style={{ padding: "30px 0 20px ", width: "400px" }}>
          <div style={{ padding: "0 30px" }}>
            <div className="caution_card">
              <AiOutlineWarning color="red" size={24} />
              <div>Deleting a user, removes the user permanently from Dillivry </div>
            </div>

            <div className="cofirm-text">To confirm deletion, type delete in the field</div>
            <input
              type={"text"}
              value={permanentlyDelete}
              onChange={(e) => setpermanentlyDelete(e.target.value)}
              placeholder={"delete"}
              className="perm-input"
            />
          </div>
          <div className="btn-parent">

            <Buttoncard
              onClick={() => {
                setShowpop(false);
                setpermanentlyDelete('')
              }}
              text="Cancel"
              className="canceller"
            />
            {
              permanentlyDelete === "delete" ?
                <Buttoncard
                  onClick={handleDelete}
                  text="Delete"
                  loading={submitting}
                  className="deleter"
                />
                :
                <Buttoncard
                  text="Delete"
                  loading={submitting}
                  className="deleter_disable"
                />

            }

          </div>
        </div>
      </DialogOutClick>

      <DialogOutClick open={ShowPerma} handleClose={() => setShowPerma(false)}>
        <div style={{ padding: "30px 0 20px ", width: "400px" }}>
          <div style={{ padding: "0 30px" }}>
            <div className="caution_card">
              <AiOutlineWarning color="red" size={24} />
              <div>Deleting a user, removes the user permanently from Dillivry </div>
            </div>

            <div className="cofirm-text">To confirm deletion, type Permanently delete in the field</div>
            <input
              type={"text"}
              value={permanentlyDelete}
              onChange={(e) => setpermanentlyDelete(e.target.value)}
              placeholder={"Permanently delete"}
              className="perm-input"
            />
          </div>
          <div className="btn-parent">
            <Buttoncard
              onClick={() => {
                setShowPerma(false);
                setpermanentlyDelete('')
              }}
              text="Cancel"
              className="canceller"
            />
            {
              permanentlyDelete === "Permanently delete" ?
                <Buttoncard
                  onClick={handleDeletePerma}
                  text="Delete"
                  loading={submitting}
                  className="deleter"
                />
                :
                <Buttoncard
                  text="Delete"
                  loading={submitting}
                  className="deleter_disable"
                />

            }

          </div>
        </div>
      </DialogOutClick>


      <DialogOutClick
        open={Showpopemail}
        handleClose={() => setShowpopemail(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p style={{ fontSize: '12px', color: 'black' }}>Complete the form below to send email to the selected users </p>

          <form onSubmit={handlesendEmial} style={{ marginTop: "10px", width: '100%' }}>
            <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "10px", width: '100%' }}
            >
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />{" "}
                Subject
              </InputLabel>
              <InputField
                required
                name="text"
                id="text"
                type="text"
                value={emailsubject}
                labelWidth={80}
                onChange={(e) => {
                  setemailsubject(e.target.value);
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl>
            {/* {
  console.log(item_to_modify,"item_to_modify")
} */}
            <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "20px", width: '100%' }}
            >
              <textarea
                style={{ borderRadius: "10px", height: "150px", padding: "12px 20px", outline: 'none', border: '1px solid #b2c2c2' }}
                placeholder="Message body"
                required
                value={emailtextarea}
                onChange={(e) => {
                  setemailtextarea(e.target.value);
                }}
              ></textarea>
            </FormControl>

            <div className="deletBtn">
              <Button
                size="large"
                variant="contained"
                type="submit"
                className="btnnn"
              >
                <span className="btnlabel">
                  {submitting ? "loading..." : "Email"}{" "}
                </span>
              </Button>
            </div>
          </form>
        </div>
      </DialogOutClick>


      <DialogOutClick 
        open={showReason}
          handleClose={() => setshowReason(false)}>
            <div style={{padding:'30px', width:'300px'}}>
              <p>State reason for rejection</p>
          <div style={{padding:'15px 0'}}>
            <input type="text" value={comment} onChange={(e)=>setcomment(e.target.value)} style={{width:'100%', padding:'10px',border:'none', borderRadius:'5px', outline:'1px solid var(--main)'}}/>
          </div>
          {
            toreject === 'photo' ? 
            <Buttoncard onClick={()=>validateProfile_photo("reject")} text='Reject' loading={rejecting} className='reject'/>
            :
            toreject === 'id' ? 
            <Buttoncard onClick={()=>validateProof("reject")} text='Reject' loading={rejecting} className='reject'/>
            :
            toreject === 'insurance' ? 
            <Buttoncard onClick={()=>validateInsurance("reject")} text='Reject' loading={rejecting} className='reject'/>
            :
            toreject === 'bank' ? 
            <Buttoncard onClick={()=>validateBank("reject")} text='Reject' loading={rejecting} className='reject'/>
            :
            null
            
          }
          </div>
      </DialogOutClick>

      <DialogOutClick
        open={Showpopresetpassword}
        handleClose={() => setShowpopresetpassword(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Reset user's password</p>
          <div className="reset_pass_con">
            <button className="reset_pass_btn1" onClick={() => setShowpopresetpassword(false)}>Cancel</button>
            <button className="reset_pass_btn2" onClick={handleresetPassword}>
              {
                isloading ?
                <PuffLoader color='white' loading={isloading}  speedMultiplier={1} size={34}/> 
                :
                <span>  Reset</span>

              }
            </button>
          </div>

          
        </div>
      </DialogOutClick>


      <DialogOutClick
        open={Showpopsms}
        handleClose={() => setShowpopsms(false)}
      >
        <div style={{ padding: "30px", width: "300px", marginBottom: "20px" }}>
          <p>Complete the form below</p>

          <form className="cFrorm" onSubmit={handlesendSms}>
            <div>
              <div className="phoneTopic" style={{ marginTop: '15px', fontSize: '13px' }}>Phone Number</div>
              <PhoneNumber value={corporatedetails?.[0]?.phone_number} disabled />
            </div>

            <FormControl variant="outlined">
              <textarea
                style={{ width: "50", height: "150px", padding: "15px", borderRadius: '10px' }}
                placeholder="SMS body"
                required
                value={smssubject}
                onChange={(e) => {
                  setsmssubject(e.target.value);
                }}
              ></textarea>
            </FormControl>

            {smssubject === "" ? (
              <Button
                size="large"
                variant="contained"
                type="submit"
                disabled
                style={{
                  backgroundColor: "rgb(151, 199, 168)",
                  width: "100%",
                  marginTop: "10px",
                  color: "white",
                  borderRadius: "10px",
                  padding: "8px",
                  textTransform: "none",
                }}
              >
                <span className="btnlabel">
                  {submitting ? "loading" : "Send sms"}{" "}
                </span>
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "var(--main)",
                  width: "100%",
                  marginTop: "10px",
                  color: "white",
                  borderRadius: "10px",
                  padding: "8px",
                  textTransform: "none",
                }}
              >
                {addingUser ? (
                  <PuffLoader
                    color="white"
                    loading={addingUser}
                    speedMultiplier={1}
                    size={24}
                  />
                ) : (
                  <span className="btnlabel">
                    {submitting ? "loading" : "Send sms"}
                  </span>
                )}
              </Button>
            )}
          </form>
        </div>
      </DialogOutClick>


      <DialogOutClick
        open={showreset}
        handleClose={() => setShowreset(false)}
      >




            <div style={{padding:'30px', width:'300px'}}>
              <p>Are you sure you want to reset this device?</p>

              <div  className="btn-parent" >
              <Buttoncard onClick={handledevicereset} text='Yes' loading={submitting} className='reject'/>
              <Buttoncard onClick={()=> setShowreset(false)} text='No' className='success'/>
              </div>
          
          
          </div>




       
      </DialogOutClick>


      <DialogOutClick
        open={Showpopsuspend}
        handleClose={() => setShowpopsuspend(false)}
      >


        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to suspend this user?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleSuspendacct}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpopsuspend(false)}
              text="No"
              className="success"
            />
          </div>
        </div>

      </DialogOutClick>


      <DialogOutClick
        open={Showpopenable}
        handleClose={() => setShowpopenable(false)}
      >

        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to Enable this user?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleenableacct}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpopenable(false)}
              text="No"
              className="success"
            />
          </div>
        </div>

      </DialogOutClick>

    </div>
    );
}

export default Agentsdrilldown;