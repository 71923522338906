/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import "./Corporate.css";
import PuffLoader from "react-spinners/PuffLoader";
import approvedicon from "../../assets/approvedicon.svg";
import pendingicon from "../../assets/pendingicon.svg";
import feedbackhaticon from "../../assets/feedbackhaticon.svg";
import formdocumenticon from "../../assets/formdocumenticon.svg";

import "jspdf-autotable";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { useHistory, useParams,useLocation } from "react-router-dom";

import { AgentContext } from "../../context/DataContext/AgentProvider";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";

import Navbar from "../../components/Navbar";
import "./UserDrilldown.css";

import Verified from "../../assets/Verified.svg";
import ValidPopup from "../../components/DialogPop/ValidPopup";
import axios from 'axios';
import DialogPop from "../../components/DialogOutClick";
import DialogOutClick from "../../components/DialogOutClick";
import { ConnContext } from "../../context/ConnContext";
import avatar from "../../assets/boy.jpg"
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import PhoneNumber from "../AdminUsers/PhoneNumber";
import {UserContext} from "../../context/UserContext"

import {
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
} from "@material-ui/core";

import InputField from "../../InputField";

const Sendersdrilldown = () => {

  const { updateUser, users } = useContext(UserContext);

    let { id } = useParams();
    const location = useLocation();
  const endpoint = process.env.REACT_APP_API
  const {visitingCountryCode} = useContext(ConnContext)
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const [submitting, setsubmitting] = useState(false);
  const [rejecting, setrejecting] = useState(false);
  const [comment, setcomment] = useState("");
  const endPoint = process.env.REACT_APP_API;

  const { updateData, datas, ValProfilePhoto, RejProfilePhoto, ValFormId, RejFormId, ValInsurance, RejInsurance, ValBankState, RejBankState ,GeneralVal } = useContext(AgentContext);
  const [showpop, setshowpop] = useState(false);

  const [clicked, setclicked] = useState("");
  const [corporatedetails, setcorporatedetails] = useState([]);
 const [showReason, setshowReason] = useState(false);
 const [updateinsuranceamount, setupdateinsuranceamount] = useState(false);
 const [toreject, setToreject] = useState('');
const [showAmount, setshowAmount] = useState(false)
const [amount, setamount] = useState("")
const numberWithComma = new Intl.NumberFormat("en-GB")
const [Showpop, setShowpop] = useState(false)
const [emailarray, setemailarray] = useState([])

const [email, setemail] = useState("");
  const [ios, setios] = useState("")
  const [iemi, setiemi] = useState("")
  const [iosversion, setiosversion] = useState("")
  const [emailsubject, setemailsubject] = useState("");
  const [emailtextarea, setemailtextarea] = useState("");
  const [emailreset, setemailreset] = useState("");
  const [smssubject, setsmssubject] = useState("");
  const [sms, setsms] = useState("");
  const [phone_num, setphone_num] = useState("");
  const [resetpassword, setresetpassword] = useState("");
  const [showpassword, setshowpassword] = useState(false);

  const [addingUser, setaddingUser] = useState(false);

const Feedbackdetails = [
    {
      Thead: "",
    },
    {
      Thead: "1 month",
    },
    {
      Thead: "2 months",
    },

    {
      Thead: "3 months",
    },
  ];

  const Feedbackbodydetails = [
    {
      Positive: "Positive",
      firstmonth: "NA",
      secondmonth: "NA",
      thirdmonth: "NA",
    },
    {
      Positive: "Negative",
      firstmonth: "NA",
      secondmonth: "NA",
      thirdmonth: "NA",
    },
  ];

const Buttoncard = ({className,onClick, disabled, color, text, loading }) =>{
return(
  <div> <button onClick={onClick} className={className} disabled={disabled} color={color}>{ loading ? <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={24}/> : text}</button></div>
)

}

const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers:{
      Authorization:  localStorage.getItem('o_kj_')
    }
  });



const Cards = ({ x, i, title, stateCheck, type }) => {
    return (
      <div>
        {stateCheck?.state === undefined ? 
        <div className="profile-photo-pending">Not uploaded yet</div>
        :
        
      <div
        key={i}
        className={
          stateCheck?.state === "verified"
            ? "profile-photo"
            : stateCheck?.state === "not_verified"
            ? "profile-photo-pending"
            : "profile-photo-reject"
        }
        onClick={() => {
          setshowpop(!showpop);
          setclicked(type);
        }}
      >
        <div className="photoicon">
          {stateCheck?.state === "verified" ? (
            <img src={Verified} alt="form-icons" />
          ) : stateCheck?.state === "not_verified" ? (
            <img src={formdocumenticon} alt="form-icons" />
          ) : (
            <img src={formdocumenticon} alt="form-icons" />
          )}
        </div>
        <div className="photo-text">
          <p className="upper-text">{title}</p>
          <p className="status-verified-or-pending">
            {stateCheck?.state === "verified"
              ? "Verified"
              : stateCheck?.state === "not_verified"
              ? "Pending"
              : "Rejected"}
          </p>
        </div>
      </div>
      }
      </div>
    );
  };





  const handleDelete = async (e) => {
    setsubmitting(true);
    console.log("hello");
  
    await Axios.delete(`${endPoint}m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "delete_user",
        // account_name: corporatedetails[0].account_name,
        email: corporatedetails[0].user_id,
        country_code: visitingCountryCode,
      },
    })
      .then((res) => {
        setsubmitting(false);
  
        console.log(res);
        if (res.status === 200) {
          setShowpopsuspend(false);
          setShowpop(false)
          Swal.fire({
            icon: "success",
            title: "Account Deleted",
            showConfirmButton: "false",
            confirmButtonColor: "grey",
            timer: 6000,
          });
        }
      })
      .catch((error) => {
        setsubmitting(false);
        setShowpopsuspend(false);
        setShowpop(false)
        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "grey",
          });
        }
      });
  };


const handlesendEmial = async (e) => {
  e.preventDefault();
  setsubmitting(true);

  const authAxios = Axios.create({
    baseURL: `${endPoint}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });
  await authAxios
    .post(`${endPoint}m-dillivry-admin-portal`, {
      action_type: "send_email",
      // email: email.toLowerCase(),
      email: emailarray,
      email_subject: emailsubject,
      message_body: emailtextarea,
    })
    .then((response) => {
      if (response) {
        setShowpopemail(!Showpopemail);
        setsubmitting(false);
        if (response.status === 200) {
          console.log(response);
          setShowpopemail(!Showpopemail);
          setemail("");
          setemailsubject("");
          setemailtextarea("");

          Swal.fire({
            icon: "success",
            title: "Email Sent",
            showConfirmButton: "false",
            confirmButtonColor: "var(--main)",
            timer: 6000,
          });
        }
      }
    })
    .catch((error) => {
      console.log(error)
      console.log(error.message)
      setShowpopemail(!Showpopemail);
      setsubmitting(false);
      if (error) {
        setemail("");
        setemailsubject("");
        setemailtextarea("");
        Swal.fire({
          icon: "error",
          title: `Error`,
          confirmButtonColor: "red",
        });
      }
    });
};



const handlesendSms =  async (e) => {
  e.preventDefault();
  setsubmitting(true);

  const authAxios = Axios.create({
    baseURL: `${endPoint}`,
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
  });
  await authAxios
    .post(`${endPoint}m-dillivry-admin-portal`, {
      action_type: "send_sms",
      phone_number: phone_num,
      message_body: smssubject,
    })
    .then((response) => {
      if (response) {
        setShowpopsms(!Showpopsms)
        setsubmitting(false);
        if (response.status === 200) {
          console.log(response);
          setShowpopsms(!Showpopsms);
          setphone_num(" ")
          setsmssubject("")

          Swal.fire({
            icon: "success",
            title: "Sms Sent",
            showConfirmButton: "false",
            confirmButtonColor: "var(--main)",
            timer: 6000,
          });
        }
      }
    })
    .catch((error) => {
      // setShowpopemail(!Showpopemail);
      setShowpopsms(!Showpopsms);
      setsubmitting(false);
      if (error) {
        setemail("");
        setphone_num(" ")
          setsmssubject("")
        Swal.fire({
          icon: "error",
          title: "error",
          confirmButtonColor: "red",
        });
      }
    });
};


const handleSuspendacct = async (e) => {
  setsubmitting(true);
  console.log("hello");

  await Axios.delete(`${endPoint}m-dillivry-user-manager-delete_ops`, {
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
    data: {
      action_type: "disable_user",
      email: corporatedetails[0].user_id,
      country_code: visitingCountryCode,
    },
  })
    .then((res) => {
      setsubmitting(false);

      console.log(res);
      if (res.status === 200) {
        setShowpopsuspend(false);
        Swal.fire({
          icon: "success",
          title: "Account Suspended",
          showConfirmButton: "false",
          confirmButtonColor: "grey",
          timer: 6000,
        });
      }
    })
    .catch((error) => {
      setsubmitting(false);
      setShowpopsuspend(false);
      if (error) {
        Swal.fire({
          icon: "error",
          title: "error",
          confirmButtonColor: "grey",
        });
      }
    });
};


const handleenableacct = async (e) => {
  setsubmitting(true);
  console.log("hello");

  await Axios.delete(`${endPoint}m-dillivry-user-manager-delete_ops`, {
    headers: {
      Authorization: localStorage.getItem("o_kj_"),
    },
    data: {
      action_type: "enable_user",
      email: corporatedetails[0].user_id,
      country_code: visitingCountryCode,
    },
  })
    .then((res) => {
      setsubmitting(false);

      console.log(res);
      if (res.status === 200) {
        setShowpopenable(false);
        Swal.fire({
          icon: "success",
          title: "Account Enabled",
          showConfirmButton: "false",
          confirmButtonColor: "grey",
          timer: 6000,
        });
      }
    })
    .catch((error) => {
      setsubmitting(false);
      setShowpopenable(false);
      if (error) {
        Swal.fire({
          icon: "error",
          title: "error",
          confirmButtonColor: "grey",
        });
      }
    });
};


useEffect(() => {

    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;

      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            localStorage.clear();
            history.push("/");
          }
        });
      }
      
      else {
        setloading(true);
        if (users !== undefined) {
          console.log(datas, 'datas')

          setcorporatedetails(
            [...users?.data?.all_users?.filter(
              (x) => x?.role === "lister") ].filter(
              (x) => x?.user_id === id
            )
          );

          setloading(false);
        } 
        
        else {
          setloading(true);
          Axios.post(`${endPoint}m-platform-admin-dashboard`, {
            "requestType":"get_users"
          },
          {
            headers: {
              Authorization: localStorage.getItem("o_kj_"),
            },
          }).then((response) => {
            if (response.status === 200) {
              updateUser(response);
              setcorporatedetails(
                [ ...response?.data?.all_users?.filter(
                  (x) => x?.role === "lister")].filter(
                  (x) => x?.user_id === id
                )
              );
              setloading(false);
            }
          });
        }
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);

  const [isloading, setisloading] = useState(false)
  const [Showpopresetpassword, setShowpopresetpassword] = useState(false);
  const [showreset, setShowreset] = useState(false)
const [Showpopemail, setShowpopemail] = useState(false);
const [Showpopsms, setShowpopsms] = useState(false);
const [Showpopsuspend, setShowpopsuspend] = useState(false);
const [Showpopenable, setShowpopenable] = useState(false);


  const handleresetPassword = (e) =>{
    setisloading(true)
      console.log(corporatedetails[0]?.user_id,'reset password');

      authAxios.patch( `m-dillivry-admin-portal`,{
        action_type: "reset_password",
        email: corporatedetails[0].user_id,
        // country_code: visitingCountryCode,
        // vcc: visitingCountryCode
      })
     .then((response)=>{
       console.log(response)
       setisloading(false)
       setShowpopresetpassword(false)
       Swal.fire({
        icon: "success",
        text: `A reset passcode has been sent to this user`,
        showConfirmButton: false,
        confirmButtonColor: "var(--main)",
        timer: 3000,

       })

     })
     .catch((error)=>{
      setisloading(false)
      setShowpopresetpassword(false)
      if(error){
        Swal.fire({
          icon: "error",
          title: "error",
          confirmButtonColor: "grey",
        });
      }
    })
     
     

      


  }




    return (
        <div>
      {loading === true ? (
        <div>
          <Box sx={{ width: "100%", marginTop: "2px" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : (
        <div className="main-grand">

        {/* {
          console.log(location, "locationstate")
        } */}

         <div className="drill-down-main">
         <Navbar
            dashboardtext="Back to dashboard"
            familyname={localStorage.getItem('fam')}
            givenname={localStorage.getItem('name')}
            page={location.state}
            role={corporatedetails[0]?.role}
            datatouse={location.hash}
          />
         </div>
          <div className="main-father">
           
          </div>

          <div className="drill-down-main">
           
       
        
            <div className="upper-detail">
              <div className="upper-details-left">
                <div className="upper-inner-left">
                    {
                        corporatedetails?.profile_photo ?
                        <img
                        src={ 
                           
    
                            corporatedetails?.map(
                          (x) => x?.profile_photo?.profile_photo
                        )}
                        alt="corporatelogo"
                      />
                      
                        :
                        <img src={avatar} alt="corporatelogo" style={{height:"100%"}} />
                      
                    }
                
                </div>

                <div className="upper-inner-right">
                  <div className="company-name">
                    <h4 className="company-text">
                      { corporatedetails?.map((x) => x?.given_name) }
                    </h4>
                  </div>
                  <div className="iconss">
                    <div className="img-icon">
                      {" "}
                      <img
                        src={corporatedetails?.map((x) =>
                          x?.account_status?.state === "active"
                            ? approvedicon
                            : x?.account_status?.state !== "active" ?   pendingicon : pendingicon
                        )}
                        alt="approvedicon"
                        className="approvedicon"
                      />
                      <span>
                        {corporatedetails?.map((x) =>
                          x?.account_status?.state === "active"
                            ? "Verified"
                            : "Pending"
                        )}
                      </span>
                    </div>
                    <div className="img-icon">
                      {" "}
                      <img
                        src={corporatedetails?.map((x) =>
                          x?.account_status?.state === "active"
                            ? approvedicon
                            : x?.account_status?.state !== "active" ?   pendingicon : pendingicon
                        )}
                        alt="approvedicon"
                        className="approvedicon"
                      />
                      <span>
                        {corporatedetails?.map((x) =>
                          x?.account_status?.state === "active"
                            ? "Insurance"
                            : "Pending"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upper-details-right">
                <div className="upper-inner-top">
                  <img src={feedbackhaticon} alt="feedbackicon" />
                  <span className="feedback">Feedback</span>
                </div>
                <div className="table-right">
                  <table>
                    <thead>
                      <tr>
                        {Feedbackdetails.map((x, i) => (
                          <th key={i}>{x.Thead}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Feedbackbodydetails.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td>{x.Positive}</td>
                            <td>{x.firstmonth}</td>
                            <td>{x.secondmonth}</td>
                            <td>{x.thirdmonth}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="bellow-upper-details">
              <div className="contact-information">
                {
                  console.log(corporatedetails)
                }
                <div className="top-info">
                  <h2 className="contact-detail">Company Information</h2>
                </div>
                <div className="contact-main-info">
                  <div className="left-one">
                    {corporatedetails?.map((x, i) => {
                      return (
                        <div key={i} className="additional-details">
                          <p className="Email">Email: {x?.user_id}</p>
                          <p className="phonenumber">
                            Phone number { x?.phone_number?.phone_number   ?   x?.phone_number?.phone_number  :  x?.phone_number}
                          </p>
                          <p className="type">Type: NA</p>
                          <p className="dateregistered">
                            Date registered:{" "}
                            {x?.account_status?.create_time_stamp?.substring(0, 10)}
                          </p>
                          <p className="status">
                            Status: {x?.account_status?.state}
                          </p>
                          <p className="agents">Agents: NA</p>
                          <p className="insurancecoverage">
                            Insurance coverage: NA
                          </p>
                          <p className="completedjobs">Completed Jobs: NA</p>
                          <p className="rejectedjobs"> Rejected Jobs: NA</p>
                          <p className="positivefeedbacks">
                            Positive feedbacks: NA
                          </p>
                        </div>
                      );
                    })}
                  </div>

                  <div className="left-two">
                    <div className="info-right">
                    {/* <p className="resetpassword"  onClick={() => setShowpopresetpassword(true)} > Reset password</p>
                     
                      <p className="updateaccount">Update Account</p>
                      <p className="emailuser">Email user</p>
                      <p className="smsuser">SMS user</p>
                      <p className="suspend-account">Suspend Account</p>
                      <p className="restoredata">Restore data</p>
                      <p className="deleteaccount" onClick={() => setShowpop(true) }>Delete Account</p> */}
                    
                    <p className="resetpassword"   onClick={() => setShowpopresetpassword(true)}>Reset password</p>
                    <p className="emailuser"       onClick={() => setShowpopemail(true)} >Email user</p>
                    <p className="smsuser"    onClick={() => setShowpopsms(true)} > SMS user</p>
                    <p className="deleteaccount" onClick={() => setShowpop(true) }>Delete Account</p>
                  
                    <p className="suspend-account"    onClick={() => setShowpopsuspend(true)} >Suspend Account</p>
                    <p className="restoredata" onClick={() => setShowpopenable(true)} >Enable Account</p>
                    
                     
  
                    
                    <p className="resetdevice"  onClick={() => setShowreset(true)} >Reset Device</p>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="documents">
                <div className="top-info">
                  <h2 className="contact-detail">Documents</h2>
                </div>

                <div className="documents-uploads">
                  <div className="inner-documents">
                    {corporatedetails?.map((x, i) => {
                      return (
                        <Cards
                          x={x}
                          i={i}
                          title="Profile Photo"
                          stateCheck={x?.profile_photo}
                          type="profile_photo"
                        />
                      );
                    })}
                    {corporatedetails?.map((x, i) => {
                      return (
                        <Cards
                          x={x}
                          i={i}
                          title="Form of ID"
                          stateCheck={x?.proof_of_identity}
                          type="proof_of_identity"
                        />
                      );
                    })}
                    {/* {corporatedetails?.map((x, i) => {
                      return (
                        <Cards
                          x={x}
                          i={i}
                          title="Insurance Policy"
                          stateCheck={x?.good_in_transit_insurance}
                          type="good_in_transit_insurance"
                        />
                      );
                    })} */}
                    {/* {corporatedetails?.map((x, i) => {
                      return (
                        <Cards
                          x={x}
                          i={i}
                          title="Bank Details"
                          stateCheck={x?.bank_detail}
                          type="bank_detail"
                        />
                      );
                    })} */}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    



      <DialogOutClick 
        open={Showpop}
          handleClose={() => setShowpop(false)}>
            <div style={{padding:'30px', width:'300px'}}>
              <p>Are you sure you want to delete this user?</p>

              <div  className="btn-parent" >
              <Buttoncard onClick={handleDelete} text='Yes' loading={submitting} className='reject'/>
              <Buttoncard onClick={()=> setShowpop(false)} text='No' className='success'/>
              </div>
          
          
          </div>
      </DialogOutClick>
      <DialogOutClick
        open={Showpopresetpassword}
        handleClose={() => setShowpopresetpassword(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Reset user's password</p>
          <div className="reset_pass_con">
            <button className="reset_pass_btn1" onClick={() => setShowpopresetpassword(false)}>Cancel</button>
            <button className="reset_pass_btn2" onClick={handleresetPassword}>
              {
                isloading ?
                <PuffLoader color='white' loading={isloading}  speedMultiplier={1} size={34}/> 
                :
                <span>  Reset</span>

              }
            </button>
          </div>

          
        </div>
      </DialogOutClick>


  



      <DialogOutClick
        open={Showpopemail}
        handleClose={() => setShowpopemail(false)}
      >
        <div style={{ padding: "30px", width: "300px" }}>
          <p>Please complete the form below </p>

          <form onSubmit={handlesendEmial} style={{ marginTop: "20px" }}>
            <FormControl className="deleteform" variant="outlined">
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />{" "}
                User Email
              </InputLabel>
              <InputField
                required
                name="email"
                id="email"
                type="email"
                // value={email}
                value={emailarray}
                labelWidth={90}
                onChange={(e) => {
                  // setemail(e.target.value);
                  setemailarray([e.target.value])
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl>

            <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "20px" }}
            >
              <InputLabel htmlFor="email">
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />{" "}
                Subject
              </InputLabel>
              <InputField
                required
                name="text"
                id="text"
                type="text"
                value={emailsubject}
                labelWidth={90}
                onChange={(e) => {
                  setemailsubject(e.target.value);
                }}
                icon={
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                }
              />
            </FormControl>

            <FormControl
              className="subjectform"
              variant="outlined"
              style={{ marginTop: "20px" }}
            >
              <textarea
                style={{ width: "50", height: "150px", padding: "12px 20px" }}
                placeholder="message body"
                required
                value={emailtextarea}
                onChange={(e) => {
                  setemailtextarea(e.target.value);
                }}
              ></textarea>
            </FormControl>

            <div className="deletBtn">
              <Button
                size="large"
                variant="contained"
                type="submit"
                className="btnnn"
              >
                <span className="btnlabel">
                  {submitting ? "loading..." : "Email"}{" "}
                </span>
              </Button>
            </div>

            
          </form>
        </div>
      </DialogOutClick>





      <DialogOutClick
        open={Showpopsms}
        handleClose={() => setShowpopsms(false)}
      >
        <div style={{ padding: "30px", width: "300px", marginBottom: "20px" }}>
          <p  >Please complete the form below</p>


          <form className="cFrorm" onSubmit={handlesendSms}>
            <div>
              <div className="phoneTopic">Phone Number</div>
              <PhoneNumber  value={phone_num} setValue={setphone_num} />
            </div>

            <FormControl variant="outlined">
              <textarea
                style={{ width: "50", height: "150px", padding: "12px 20px" }}
                placeholder="message body"
                required
                value={smssubject}
                onChange={(e) => {
                  setsmssubject(e.target.value);
                }}
              ></textarea>
            </FormControl>

            {phone_num === "" ? (
              <Button
                size="large"
                variant="contained"
                type="submit"
                disabled
                style={{
                  backgroundColor: "rgb(151, 199, 168)",
                  width: "100%",
                  marginTop: "20px",
                  color: "white",
                  borderRadius: "10px",
                  padding: "8px",
                  textTransform: "none",
                }}
              >
                <span className="btnlabel">{ submitting ? "loading" : "Send sms" } </span>
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "var(--main)",
                  width: "100%",
                  marginTop: "20px",
                  color: "white",
                  borderRadius: "10px",
                  padding: "8px",
                  textTransform: "none",
                }}
              >
                {addingUser ? (
                  <PuffLoader
                    color="white"
                    loading={addingUser}
                    speedMultiplier={1}
                    size={24}
                  />
                ) : (
                  <span className="btnlabel">{ submitting ? "loading" : "Send sms" }</span>
                )}
              </Button>
            )}
          </form>
        </div>
      </DialogOutClick>



      <DialogOutClick
        open={Showpopsuspend}
        handleClose={() => setShowpopsuspend(false)}
      >


        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to suspend this user?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleSuspendacct}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpopsuspend(false)}
              text="No"
              className="success"
            />
          </div>
        </div>

      </DialogOutClick>



      <DialogOutClick
        open={Showpopenable}
        handleClose={() => setShowpopenable(false)}
      >


        <div style={{ padding: "30px", width: "300px" }}>
          <p>Are you sure you want to Enable this user?</p>

          <div className="btn-parent">
            <Buttoncard
              onClick={handleenableacct}
              text="Yes"
              loading={submitting}
              className="reject"
            />
            <Buttoncard
              onClick={() => setShowpopenable(false)}
              text="No"
              className="success"
            />
          </div>
        </div>

      </DialogOutClick>

    </div>
    );
}

export default Sendersdrilldown;