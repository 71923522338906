import {createContext, useReducer} from 'react';
import DataReducer, { INITIAL_STATE } from './DataReducer';
import DataTypes from './DataType';



export const DataContext = createContext({
    ...INITIAL_STATE
  });



const DataProvider = ({ children }) => {
    const [store, dispatch] = useReducer(DataReducer, INITIAL_STATE);

    const { datas, newdata, opendeliveriesdata } = store;


    const getData = (data) => {
      // console.log(data, 'here man')
      dispatch({
        type: DataTypes.GET_DASHDATA_NEW,
        payload: data
      });
    }


    const getOpendeliveriesdata = (data) =>{
      dispatch({
        type: DataTypes.GET_OPENDELIVERIES_DATA,
        payload: data
      })
    }


    const getDeliveriesdata = (data) =>{
      dispatch({
        type: DataTypes.GET_DELIVERIES_DATA,
        payload: data
      })

    }


    const updateData = data => {
        dispatch({
          type: DataTypes.GET_DEFAULT_DATA,
          payload: data
        });
      };

      const ValBankState = data => {
        dispatch({
          type: DataTypes.Val_Bank_Details,
          payload: data
        });
      };
      const RejBankState = data => {
        dispatch({
          type: DataTypes.Rej_Bank_Details,
          payload: data
        });
      };


      const ValInsurance = data => {
        dispatch({
          type: DataTypes.Val_Insurance,
          payload: data
        });
      };

      const RejInsurance = data => {
        dispatch({
          type: DataTypes.Rej_Insurance,
          payload: data
        });
      };

      const ValProfilePhoto = data => {
        dispatch({
          type: DataTypes.Val_Profile_Photo,
          payload: data
        });
      };
      const RejProfilePhoto = data => {
        dispatch({
          type: DataTypes.Rej_Profile_Photo,
          payload: data
        });
      };

      const ValProfileMessage = data => {
        dispatch({
          type: DataTypes.Val_Profile_Message,
          payload: data
        });
      };
      const RejProfileMessage = data => {
        dispatch({
          type: DataTypes.Rej_Profile_Message,
          payload: data
        });
      };





      
      const ValFormId = data => {
        dispatch({
          type: DataTypes.Val_Form_Id,
          payload: data
        });
      };

      const RejFormId = data => {
        dispatch({
          type: DataTypes.Rej_Form_Id,
          payload: data
        });
      };
      
      const GeneralVal = data => {
        dispatch({
          type: DataTypes.General_Val,
          payload: data
        });
      };



      return (
        <DataContext.Provider value={{
            updateData,
            ValFormId,
            ValBankState,
            ValInsurance,
            ValProfilePhoto,
            ValProfileMessage,
            RejProfileMessage,
            GeneralVal,
            RejBankState,
            RejFormId,
            RejInsurance,
            RejProfilePhoto,
            getData,
            getDeliveriesdata,
            getOpendeliveriesdata,
            datas,
            newdata,
            opendeliveriesdata
        }}>
          {children}
        </DataContext.Provider>
      );
}
export default DataProvider;