// I Just commeted this part out for now beacuse there's no API for the search value and last evaluated key
// Incase we are using the old table and API.
// Anytime there's an update to the API, just remove this comment and adjust the code


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from "react";
import "jspdf-autotable";
import { useHistory } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import Axios from "axios";
import { Box } from "@mui/system";
import "./AdminUsers.css";
import "./over.css";
import { AiOutlineClear, AiOutlineUserAdd } from "react-icons/ai";
import PuffLoader from "react-spinners/PuffLoader";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { Button, InputAdornment, TextField } from "@mui/material";
import DialogPop from "./DialogPop";
import { BiRename } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import InputField from "../../InputField";
import { RiLockPasswordLine } from "react-icons/ri";
import PhoneNumber from "./PhoneNumber";
import { ConnContext } from "../../context/ConnContext";
import { FaUserPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";

import { UserContext } from "../../context/UserContext";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { SpeedDial } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import TableWithPagination from "../../components/DefaultTableFolder/TablePaginated";
import { ProviderContext } from "../../context/ProviderContext";

export default function AdminUsers() {
  const { visitingCountryCode } = useContext(ConnContext);

  // const {datas,updateData} = useContext(DataContext);

  const { setUsers, users } = useContext(UserContext);

  const [item_to_modify, setitem_to_modify] = useState([])

  const [isloading, setIsloading] = useState(false);
  const history = useHistory();
  const endPoint = process.env.REACT_APP_API;
  const [search, setsearch] = useState("");
  const [show, setshow] = useState(false);

  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [tempemail, settempemail] = useState("");
  const [phone_num, setphone_num] = useState("");
  const [addingUser, setaddingUser] = useState(false);
  const [removingUser, setremovingUser] = useState(false);
  const [showAddForm, setshowAddForm] = useState(false);
  const [userType, setuserType] = useState("");
  const [deleteUserPop, setdeleteUserPop] = useState(false);
  const [removeUserPop, setremoveUserPop] = useState(false);

  const [Showpop, setShowpop] = useState(false);
  const [Showpopemail, setShowpopemail] = useState(false);
  const [Showpopsms, setShowpopsms] = useState(false);
  const [Showpopsuspend, setShowpopsuspend] = useState(false);
  const [Showpopenable, setShowpopenable] = useState(false);
  const [emailarray, setemailarray] = useState("");

  const [actions, setactions] = useState([])

  const { setlast_evaluated_key } = useContext(ProviderContext);



  const updatePage = (datum) => {

    setlast_evaluated_key(datum?.data?.data[0]?.last_evaluated_key)

    setaccountData(
      datum?.data?.data?.[0]?.data
    )
  };

  const componentRef = useRef();

  const [role, setRole] = React.useState("platform_sys_admin");

  const handleRole = (event) => {
    event.preventDefault();
    setRole(event.target.value);
  };

  const Admin_props = [
    'Profile Name',
    'Email',
    'Role',
  ]

  const NewData = [
    'given_name',
    'user_id',
    'role',
    // 'account_status',
  ]


  // to delete an admin user
  const handleDeleteUser = async (e) => {

    setaddingUser(true);
    e.preventDefault();

    await Axios.delete(`${endPoint}v2/m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        action_type: "delete_user",
        email: emailarray,
        vcc: visitingCountryCode,
      },
    }).then((response) => {
      setaddingUser(false);
      setemail("");
      console.log(response, 'response')
      if (response) {
        if (response.status === 200) {
          setdeleteUserPop(false);
          setemail("");
          Swal.fire({
            icon: "success",
            title: "User Deleted",
            showConfirmButton: false,
            timer: 7000,
          });

          window.location.reload();
        }
      }
    })
      .catch((error) => {
        setaddingUser(false);
        setdeleteUserPop(false);
        setemail("");


        if (error.response.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Unthorized",
            confirmButtonColor: "red",
            text: `${error?.response?.data?.action_status}`
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "red",
          });
        }
      });
  };

  const handleRemovepermanently = async (e) => {
    setremovingUser(false)
    console.log('remove permanently')
    setremovingUser(true);
    e.preventDefault();

    await Axios.delete(`${endPoint}v2/m-dillivry-admin-portal`, {
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
      data: {
        user_id: tempemail
      },
    })

      .then((response) => {
        setremovingUser(false);
        setemail("");
        if (response) {
          if (response.status === 200) {
            setremoveUserPop(false);
            setemail("");
            Swal.fire({
              icon: "success",
              title: "User Removed Successfully",
              showConfirmButton: false,
              timer: 7000,
            });

            window.location.reload();
          }
        }
      })
      .catch((error) => {
        console.log(error.response, 'err')
        setremovingUser(false);
        setremoveUserPop(false);
        setemail("");


        if (error.response.status === 500) {
          Swal.fire({
            icon: "warning",
            title: "Unthorized",
            confirmButtonColor: "red",
            text: `${error?.response?.data?.error}`
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "red",
          });
        }
      });
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setaddingUser(true);

    const authAxios = Axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    });
    await authAxios
      .post(`${endPoint}v2/m-dillivry-user-manager`, {
        action_type: "create",
        email: email.toLowerCase(),
        phone_number: phone_num,
        first_name: firstname,
        last_name: lastname,
        role: role,
        vcc: visitingCountryCode,
      })
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            setaddingUser(false);
            setemail("");
            setphone_num("");
            setfirstname("");
            setlastname("");
            setuserType("");
            setshowAddForm(false);

            Swal.fire({
              icon: "success",
              title: "Account Created",
              showConfirmButton: "false",
              confirmButtonColor: "var(--main)",
              // timer: 3000
            }).then((result) => {
              if (result.value) {
                window.location.reload();
              }
            });
          }
        }
      })
      .catch((error) => {
        setaddingUser(false);
        setshowAddForm(false);
        setemail("");
        setphone_num("");
        setfirstname("");
        setlastname("");
        setuserType("");

        if (error) {
          Swal.fire({
            icon: "error",
            title: "error",
            confirmButtonColor: "red",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
          console.log(error, "errorrrr");
        }
      });
  };


  const refetchUsers = async () => {
    setIsloading(true);

    await Axios.get(
      // `${endPoint}platform/v3/ng/senders`,
      `${endPoint}admin/v1/ng/admin`,
      {
        headers: {
          Authorization: localStorage.getItem("o_kj_"),
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          // setaccountData(response);
          updatePage(response);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsloading(false)
      });
  }

  const Tabledetails = [
    {
      Thead: "Profile name",
    },
    {
      Thead: "Email",
    },
    {
      Thead: "Phone number",
    },
    {
      Thead: "Status",
    },
  ];

  const Drop_down = () => (
    <div
      style={{
        zIndex: "100",
        position: "absolute",
        top: "70px",
        right: "40px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        boxShadow: "1px 1px 44px rgba(96, 97, 96, 0.3)",
        borderRadius: "4px",
      }}
    >
      <div className="each">
        <span style={{ marginLeft: "10px" }}>Admin</span>
      </div>
      <div className="each">
        <span style={{ marginLeft: "10px" }}>Editor</span>
      </div>
      <div className="each">
        <span style={{ marginLeft: "10px" }}>User</span>
      </div>
      <div className="each">
        <span style={{ marginLeft: "10px" }}>Active</span>
      </div>
    </div>
  );



  const HandleNewAccount = () => {
    console.log('CreateUserClicked')
    setshowAddForm(true);
  }


  const filterData = (e) => {
    setsearch(e.target.value.toLowerCase());
    const filteredData = accountData.filter((names) =>
      names.first_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    updateDataView(filteredData);
    //console.log(e.target.value.toLowerCase())
  };


  const actionselectedClick = (val) => {
    // console.log(val, 'valalala')
    // setShowpop(true)
    // console.log(val?.user_id)
    // val && console.log(val?.map(x => x[0]))


    if (val?.user_id) {
      // setdeleteUser(val)
      // setshowdelete(true)
      // setShowpopemail(false)
      setdeleteUserPop(true)
      setemailarray(val?.user_id)
    }

    console.log(val)


  }



  const actionClick = (val, row) => {
    setitem_to_modify(row)

    if (val === 'Delete') {
      // setShowpopemail(true)
      setdeleteUserPop(true)
      setemailarray(row.map((ro) => ro.user_id))
    }
    // else if (val === 'Send sms') {
    //   setShowpopsms(true)
    //   setphone_num(row?.[0]?.phone_number)
    // }
    // else if (val === 'Suspend') {
    //   setShowpopsuspend(true)
    // }
    // else if (val === 'Restore') {
    //   setShowpopenable(true)
    // }
    // else if (val === 'Delete') {
    //   setShowpop(true)
    // }
  }

  // const SearchBar = ()=>{
  //     return(
  //         <div className='search' >
  //             <div className='search-input'>
  //                 <input
  //                 type="text"
  //                 value={search}
  //                 name="search"
  //                 // autoFocus
  //                 placeholder="search.."  onChange={filterData}/>
  //             </div>
  //         </div>
  //     )
  // }

  const [paginateActivePage, setPaginateActivePage] = useState(1);
  const n = 50;
  const [itemLength, setitemLength] = useState(0);
  const [perpage, setperpage] = React.useState(50);
  const [dataToShow, setdataToShow] = useState([]);
  const [accountData, setaccountData] = useState([]);
  const [dataToShowduplicate, setdataToShowduplicate] = useState([]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log(string, results, 'search resulttttttt')
    // console.log([results[0]], 'on search results')
    // setdataToShow(results)
    // setdataToShowduplicate([results[0]])
  };

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result)
  };

  const handleOnSelect = (item) => {
    // the item selected
    // console.log([item], 'item going into ')
    setaccountData([item]);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    // console.log(item, 'itemmmmmm')
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          Given name: {item?.given_name}
        </span>
        <span style={{ display: "block", textAlign: "left" }}>
          Email: {item?.user_id}
        </span>
        <span style={{ display: "block", textAlign: "left" }}>
          Phone number: {item?.phone_number}
        </span>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>Item desc: {item?.item_desc}</span>
            <span style={{ display: 'block', textAlign: 'left' }}>PickUp details: {item?.newpickup}</span>
            <span style={{ display: 'block', textAlign: 'left' }}>Delivery details: {item?.newdestina}</span> */}
      </>
    );
  };

  const SearchBar = () => {
    return (
      // <div className='search' >
      <div className="search-input">
        <div className="searchandclear">
          <div>
            <ReactSearchAutocomplete
              items={dataToShowduplicate}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              // autoFocus
              formatResult={formatResult}
              fuseOptions={{ keys: ["given_name", "user_id", "phone_number"] }}
              // fuseOptions={{ keys: ["delivery_details"] }}
              // fuseOptions={{ keys: ["delivery_address"] }}
              resultStringKeyName="given_name"
            />
            <div
              onClick={() => setdataToShowduplicate(accountData)}
              className="clearbtn"
            >
              {/* <button>
                Clear Search
              </button> */}

              <AiOutlineClear data-tip="Clear Search" />
              <ReactTooltip type="light" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    SearchBar();
  }, [dataToShowduplicate, accountData, SearchBar]);

  const handlevalue = (event) => {
    //console.log(event.target.value);
    setperpage(event.target.value);
    setdataToShow([...accountData].slice(0, event.target.value));
    //console.log(accountData.slice(0, event.target.value))
  };

  const updateDataView = (datum) => {
    setdataToShow(datum.slice(0, perpage));
    setitemLength([...datum].length);
  };




  useEffect(() => {
    if (localStorage.getItem("o_kj_") !== null) {
      const decoded = jwt_decode(localStorage.getItem("o_kj_"));
      const expired = decoded.exp;
      if (expired < Date.now() / 1000) {
        setIsloading(false);
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            history.push(`/`);
            setIsloading(false);
            localStorage.clear();
          }
        });
      }

      // else if (users !== undefined) {
      //   console.log(users, 'userssss')
      //   const data = users.data.all_users.filter(function (value) {
      //     return (
      //       value.role === "datamellon-platform-sys-admin" ||
      //       value.role === "datamellon-platform-read-only" ||
      //       value.role === "datamellon-platform-sys-ops"
      //     );
      //   });
      //   data?.sort(
      //     (x, y) =>
      //       new Date(y.account_status.create_time_stamp) -
      //       new Date(x.account_status.create_time_stamp)
      //   );
      //   setaccountData(data);
      //   updateDataView(data);
      //   setdataToShowduplicate(data);
      // }
      else {
        setIsloading(true);
        Axios.get(
          `${endPoint}admin/v1/ng/admin`,
          {
            headers: {
              Authorization: localStorage.getItem("o_kj_"),
            },
          }
        )
          .then((response) => {
            console.log(response.data.data, 'response-----');
            setIsloading(false);
            setemailarray([]);

            // const data = response?.data?.all_users?.filter(function (value) {
            //   return (
            //     value.role === "datamellon-platform-sys-admin" ||
            //     value.role === "datamellon-platform-read-only" ||
            //     value.role === "datamellon-platform-sys-ops"
            //   );
            // });

            const data = response.data.data[0].data

            console.log(data, 'data---')
            data?.sort(
              (x, y) =>
                new Date(y?.account_status?.create_time_stamp) -
                new Date(x?.account_status?.create_time_stamp)
            );
            setaccountData(data);
            updateDataView(data);
            setdataToShowduplicate(data);
            updatePage(response);
          })
          .catch((err) => {
            if (err?.response?.status === 403) {
              setIsloading(false);
              Swal.fire({
                icon: "error",
                title: "Session Timeout",
                showCancelButton: false,
              });
              history.push(`/`);
            } else {
              setIsloading(false);
              Swal.fire({
                icon: "error",
                title: "error",
                allowOutsideClick: false,
                confirmButtonColor: "var(--main)",
                showCancelButton: false,
              }).then((result) => {
                if (result.value) {
                  history.push(`/`);
                }
              });
            }
          });
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, []);





  const columns = [
    {
      field: "account_name",
      headerName: "Profile Name",
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => row?.first_name + " " + row?.last_name,
    },
    {
      field: "user_id",
      headerName: "Email",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      renderCell: ({ value }) => {
        switch (value) {
          case "datamellon-platform-sys-admin":
            return "admin";

          case "datamellon-platform-read-only":
            return "read only";

          case "datamellon-platform-sys-ops":
            return "platform sys ops";

          default:
            return "";
        }
      },
      minWidth: 100,
      flex: 1,
    },
    // {
    //   field: "phone_number",
    //   headerName: "Phone Number",
    //   minWidth: 100,
    //   flex: 1,
    //   renderCell: ({ value }) => value.phone_number,
    // },
    {
      field: "account_status",
      headerName: "Status",
      minWidth: 170,
      flex: 1,
      renderCell: ({ row }) =>
        row.account_status.state === "active"
          ? "Active"
          : row.account_status.state === "deleted"
            ? "Deleted"
            : row.account_status.state === "FORCE_CHANGE_PASSWORD"
              ? "Force change password"
              : null,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          label={params.row.account_status.state !== "deleted" ? "Remove" : "Remove permanently"}
          onClick={() => {
            if (params.row.account_status.state !== "deleted") {
              setdeleteUserPop(!deleteUserPop);
              settempemail(params.row.user_id);
            } else {
              setremoveUserPop(!removeUserPop);
              settempemail(params.row.user_id);

            }

          }}
          showInMenu
        />,
      ],
    },
  ];
  const [pageSize, setPageSize] = useState(10);
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Admin Users</h4>
        {/* <Button variant="outlined" size="large" onClick={() => setshow(!show)}>Add filter <KeyboardArrowDownIcon/> </Button>
        {show ? <Drop_down /> : null} */}
        {/* <GridToolbarExport /> */}

        <SearchBar />
      </GridToolbarContainer>
    );
  };
  const handleGetRowId = (e) => {
    return e.user_id;
  };
  return (
    <div className="main-grand">
      {isloading ? (
        <Box sx={{ width: "100%", marginBottom: "5px" }}>
          <LinearProgress color="success" />
        </Box>
      ) : null}
      {/* <div style={{ width: "90%" }}>

        <DataGrid
          density="comfortable"
          sx={{
            p: "30px",
            background: "#fff",
            "& .MuiDataGrid-cell": {
              whiteSpace: "break-spaces !important",
            },
          }}
          rows={
            accountData !== undefined && accountData?.length !== 0
              ? accountData
              : []
          }
          columns={columns}
          pageSize={pageSize}
          autoHeight={true}
          onPageSizeChange={(value) => setPageSize(value)}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={handleGetRowId}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div> */}
      <TableWithPagination
        ident="given_name"
        onSelected={(val) => actionselectedClick(val)}
        actions={actions}
        onActionClick={(val, row) => actionClick(val, row)}
        actionUpdate={(val) => {
          if (val > 1) {
            setactions(['Delete'])
          }
          else {
            setactions(['Delete'])
          }
        }}
        data={accountData}
        inputplaceholder="Search by Email"
        props={Admin_props}
        rowsPerPage={5}
        currentPage={0}
        itemsPerPage={5}
        NewData={NewData}
        more="admin"
        onFetchSuccess={(val) => setaccountData(val)}
        property_name="Email"
        createUser={HandleNewAccount}
        refetchDefault={refetchUsers}
        linkclicked={(data) => history.push(`/senders/${data.user_id}`)}
      />


      {jwt_decode(localStorage.getItem("o_kj_"))["custom:role"] ===
        "datamellon-platform-sys-admin" ||
        jwt_decode(localStorage.getItem("o_kj_"))["custom:role"] ===
        "platform-sys-admin" ? (
        <Box
          onClick={() => setshowAddForm(!showAddForm)}
          sx={{
            transform: "translateZ(0px)",
            flexGrow: 1,
            position: "absolute",
            bottom: "30px",
            right: "3%",
          }}
        >
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            icon={<AddIcon sx={{ color: "#fff" }} />}
          >

          </SpeedDial>
        </Box>
      ) : null}


      <DialogPop
        openPopup={showAddForm}
        setopenPopup={setshowAddForm}
        head="Create Admin "
      >
        <form className="cFrorm" onSubmit={submitForm}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="Firstname">
              <BiRename style={{ marginRight: "5px", color: "var(--main)" }} />
              FirstName
            </InputLabel>
            <InputField
              required
              // autoFocus
              name="firstname"
              id="firstname"
              type="text"
              // error={errors.first_name}
              value={firstname}
              labelWidth={90}
              onChange={(e) => {
                setfirstname(e.target.value);
              }}
              icon={
                <HiOutlineMail
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />
              }
            />
          </FormControl>

          <FormControl className="fInput" variant="outlined">
            <InputLabel htmlFor="password">
              <BiRename style={{ marginRight: "5px", color: "var(--main)" }} />
              LastName
            </InputLabel>
            <InputField
              // error={modelisValid}
              required
              name="lastname"
              id="lastname"
              type="text"
              value={lastname}
              labelWidth={90}
              onChange={(e) => {
                setlastname(e.target.value);
              }}
              icon={
                <RiLockPasswordLine
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />
              }
            />
          </FormControl>


          <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Role"
              value={role}
              onChange={handleRole}
            >
              <MenuItem value="platform_sys_admin">System Admin</MenuItem>
              <MenuItem value={"platform_sys_ops"}>System Operation</MenuItem>
              <MenuItem value={"platform_read_only"}>Read only</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="fInput" variant="outlined">
            <InputLabel htmlFor="email">
              <HiOutlineMail
                style={{ marginRight: "5px", color: "var(--main)" }}
              />
              Email
            </InputLabel>
            <InputField
              // error={modelisValid}
              required
              name="email"
              id="email"
              type="email"
              // error={errors.email}
              // type={showpassword ? 'text' : 'password'}

              value={email}
              labelWidth={90}
              onChange={(e) => {
                setemail(e.target.value);
              }}
              icon={
                <RiLockPasswordLine
                  style={{ marginRight: "5px", color: "var(--main)" }}
                />
              }
            />
          </FormControl>

          <div>
            <div className="phoneTopic">Phone Number</div>
            <PhoneNumber value={phone_num} setValue={setphone_num} />
          </div>
          {phone_num === "" ? (
            <Button
              size="large"
              variant="contained"
              type="submit"
              disabled
              style={{
                backgroundColor: "rgb(151, 199, 168)",
                width: "100%",
                marginTop: "20px",
                color: "white",
                borderRadius: "10px",
                padding: "8px",
                textTransform: "none",
              }}
            >
              <span className="btnlabel">
                Add <AiOutlineUserAdd />
              </span>
            </Button>
          ) : (
            <Button
              size="large"
              variant="contained"
              type="submit"
              style={{
                backgroundColor: "var(--main)",
                width: "100%",
                marginTop: "20px",
                color: "white",
                borderRadius: "10px",
                padding: "8px",
                textTransform: "none",
              }}
            >
              {addingUser ? (
                <PuffLoader
                  color="white"
                  loading={addingUser}
                  speedMultiplier={1}
                  size={24}
                />
              ) : (
                <span className="btnlabel">
                  Add <AiOutlineUserAdd />
                </span>
              )}
            </Button>
          )}
        </form>
      </DialogPop>

      <DialogPop
        openPopup={deleteUserPop}
        setopenPopup={setdeleteUserPop}
        head="Delete Admin"
      >
        <form onSubmit={handleDeleteUser}>
          <TextField
            required
            fullWidth
            name="email"
            id="email"
            type="email"
            label="User Email"
            value={emailarray}
            disabled
            // onChange={(e)=> {setemail(e.target.value)}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <div className="deletBtn">
            {addingUser ? (
              <Button
                size="large"
                variant="contained"
                type="submit"
                className="btnnn2"
              >
                <PuffLoader
                  color="#50b14a"
                  loading={addingUser}
                  speedMultiplier={1}
                  size={24}
                />
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                <span className="btnlabel">Delete </span>
              </Button>
            )}
          </div>
        </form>

        {/* </div>
            </div> */}
      </DialogPop>



      <DialogPop
        openPopup={removeUserPop}
        setopenPopup={setremoveUserPop}
        head="Remove Permanently"
      >
        <form onSubmit={handleRemovepermanently}>
          <TextField
            required
            fullWidth
            name="email"
            id="email"
            type="email"
            label="User Email"
            value={tempemail}
            disabled
            // onChange={(e)=> {setemail(e.target.value)}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RiLockPasswordLine
                    style={{ marginRight: "5px", color: "var(--main)" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <div className="deletBtn">
            {removingUser ? (
              <Button
                size="large"
                variant="contained"
                type="submit"
                className="btnnn2"
              >
                <PuffLoader
                  color="#50b14a"
                  loading={removingUser}
                  speedMultiplier={1}
                  size={24}
                />
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                <span className="btnlabel">Remove Permanently </span>
              </Button>
            )}
          </div>
        </form>

        {/* </div>
            </div> */}
      </DialogPop>


      <ReactTooltip
        id="mode"
        aria-haspopup="true"
        type="light"
        role="example"
        place="right"
      >
        <p>Create user</p>
        {/* <p>Mode of movement</p> */}
      </ReactTooltip>
    </div>
  );
}


// The last part of the code here





// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useContext, useEffect, useRef } from "react";
// import "jspdf-autotable";
// import { useHistory } from "react-router-dom";
// import LinearProgress from "@mui/material/LinearProgress";

// import jwt_decode from "jwt-decode";
// import Swal from "sweetalert2";
// import Axios from "axios";
// import { Box } from "@mui/system";
// import "./AdminUsers.css";
// import "./over.css";
// import { AiOutlineClear, AiOutlineUserAdd } from "react-icons/ai";
// import PuffLoader from "react-spinners/PuffLoader";
// import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
// import { Button, InputAdornment, TextField } from "@mui/material";
// import DialogPop from "./DialogPop";
// import { BiRename } from "react-icons/bi";
// import { HiOutlineMail } from "react-icons/hi";
// import InputField from "../../InputField";
// import { RiLockPasswordLine } from "react-icons/ri";
// import PhoneNumber from "./PhoneNumber";
// import { ConnContext } from "../../context/ConnContext";
// import { FaUserPlus } from "react-icons/fa";
// import ReactTooltip from "react-tooltip";

// import { UserContext } from "../../context/UserContext";
// import {
//   DataGrid,
//   GridActionsCellItem,
//   GridToolbarContainer,
//   GridToolbarExport,
// } from "@mui/x-data-grid";
// import { SpeedDial } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { ReactSearchAutocomplete } from "react-search-autocomplete";
// import Breadcrumbs from "../../components/Breadcrumbs";

// export default function AdminUsers() {
//   const { visitingCountryCode } = useContext(ConnContext);

//   // const {datas,updateData} = useContext(DataContext);

//   const { setUsers, users } = useContext(UserContext);

//   const [isloading, setIsloading] = useState(false);
//   const history = useHistory();
//   const endPoint = process.env.REACT_APP_API;
//   const [search, setsearch] = useState("");
//   const [show, setshow] = useState(false);

//   const [firstname, setfirstname] = useState("");
//   const [lastname, setlastname] = useState("");
//   const [email, setemail] = useState("");
//   const [tempemail, settempemail] = useState("");
//   const [phone_num, setphone_num] = useState("");
//   const [addingUser, setaddingUser] = useState(false);
//   const [removingUser, setremovingUser] = useState(false);
//   const [showAddForm, setshowAddForm] = useState(false);
//   const [userType, setuserType] = useState("");
//   const [deleteUserPop, setdeleteUserPop] = useState(false);
//   const [removeUserPop, setremoveUserPop] = useState(false);

//   const componentRef = useRef();

//   const [role, setRole] = React.useState("platform_sys_admin");

//   const handleRole = (event) => {
//     event.preventDefault();
//     setRole(event.target.value);
//   };

//   const handleDeleteUser = async (e) => {
//     // e.preventDefault();
//     // console.log({
//     //   // action_type: "delete_user",
//     //   action_type: "delete_user",
//     //   email: tempemail,
//     //   vcc: visitingCountryCode,
//     // }, "dattttttt")

//     setaddingUser(true);
//     e.preventDefault();
//     // console.log(email, "emaillllll", tempemail);

//     await Axios.delete(`${endPoint}m-dillivry-user-manager-delete_ops`, {
//       headers: {
//         Authorization: localStorage.getItem("o_kj_"),
//       },
//       data: {
//         // action_type: "delete_user",
//         action_type: "delete_user",
//         email: tempemail,
//         vcc: visitingCountryCode,
//       },
//     }).then((response) => {
//       setaddingUser(false);
//       setemail("");
//       console.log(response, 'response')
//       if (response) {
//         if (response.status === 200) {
//           setdeleteUserPop(false);
//           setemail("");
//           Swal.fire({
//             icon: "success",
//             title: "User Deleted",
//             showConfirmButton: false,
//             // confirmButtonColor:'red',
//             timer: 7000,
//           });

//           window.location.reload();
//         }
//       }
//     })
//       .catch((error) => {
//         setaddingUser(false);
//         setdeleteUserPop(false);
//         setemail("");


//         if (error.response.status === 403) {
//           Swal.fire({
//             icon: "warning",
//             title: "Unthorized",
//             confirmButtonColor: "red",
//             text: `${error?.response?.data?.action_status}`
//           });
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "error",
//             confirmButtonColor: "red",
//           });
//         }


//       });
//   };


//   // useEffect(() => {
//   //   console.log(visitingCountryCode, 'visiting country code ')
//   // }, []);



//   const handleRemovepermanently = async (e) => {
//     setremovingUser(false)
//     console.log('remove permanently')
//     setremovingUser(true);
//     e.preventDefault();
//     // console.log(email, "emaillllll", tempemail);

//     await Axios.delete(`${endPoint}m-dillivry-admin-portal`, {
//       headers: {
//         Authorization: localStorage.getItem("o_kj_"),
//       },
//       data: {
//         // action_type: "delete_user",
//         // action_type: "delete",
//         // email: tempemail
//         user_id: tempemail
//       },
//     })

//       .then((response) => {
//         setremovingUser(false);
//         setemail("");
//         //console.log(response)
//         if (response) {
//           if (response.status === 200) {
//             setremoveUserPop(false);
//             setemail("");
//             Swal.fire({
//               icon: "success",
//               title: "User Removed Successfully",
//               showConfirmButton: false,
//               // confirmButtonColor:'red',
//               timer: 7000,
//             });

//             window.location.reload();
//           }
//         }
//       })
//       .catch((error) => {
//         console.log(error.response, 'err')
//         setremovingUser(false);
//         setremoveUserPop(false);
//         setemail("");


//         if (error.response.status === 500) {
//           Swal.fire({
//             icon: "warning",
//             title: "Unthorized",
//             confirmButtonColor: "red",
//             text: `${error?.response?.data?.error}`
//           });
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "error",
//             confirmButtonColor: "red",
//           });
//         }
//       });
//   }



//   const submitForm = async (e) => {
//     e.preventDefault();
//     // console.log(email, 'email', phone_num, firstname, lastname, role, visitingCountryCode)
//     setaddingUser(true);
//     // console.log(role, "role");

//     const authAxios = Axios.create({
//       baseURL: `${endPoint}`,
//       headers: {
//         Authorization: localStorage.getItem("o_kj_"),
//       },
//     });
//     await authAxios
//       .post(`${endPoint}m-dillivry-user-manager`, {
//         action_type: "create",
//         email: email.toLowerCase(),
//         phone_number: phone_num,
//         first_name: firstname,
//         last_name: lastname,
//         // role: "platform_sys_admin",
//         role: role,
//         vcc: visitingCountryCode,
//       })
//       .then((response) => {
//         if (response) {
//           if (response.status === 200) {
//             setaddingUser(false);
//             setemail("");
//             setphone_num("");
//             setfirstname("");
//             setlastname("");
//             setuserType("");
//             setshowAddForm(false);

//             Swal.fire({
//               icon: "success",
//               title: "Account Created",
//               showConfirmButton: "false",
//               confirmButtonColor: "var(--main)",
//               // timer: 3000
//             }).then((result) => {
//               if (result.value) {
//                 // history.push(`/`);
//                 // setIsloading(false)
//                 // localStorage.clear()
//                 window.location.reload();
//               }
//             });
//             // .then(() => {
//             //   window.location.reload()
//             // })
//           }
//         }
//       })
//       .catch((error) => {
//         setaddingUser(false);
//         setshowAddForm(false);
//         setemail("");
//         setphone_num("");
//         setfirstname("");
//         setlastname("");
//         setuserType("");

//         if (error) {
//           Swal.fire({
//             icon: "error",
//             title: "error",
//             confirmButtonColor: "red",
//           }).then((result) => {
//             if (result.value) {
//               // history.push(`/`);
//               // setIsloading(false)
//               // localStorage.clear()
//               window.location.reload();
//             }
//           });
//           console.log(error, "errorrrr");
//         }
//       });
//   };

//   const Tabledetails = [
//     "Profile name",
//     "Email",
//     "Phone number",
//     "Status",
//   ];

//   const Drop_down = () => (
//     <div
//       style={{
//         zIndex: "100",
//         position: "absolute",
//         top: "70px",
//         right: "40px",
//         display: "flex",
//         flexDirection: "column",
//         backgroundColor: "white",
//         boxShadow: "1px 1px 44px rgba(96, 97, 96, 0.3)",
//         borderRadius: "4px",
//       }}
//     >
//       <div className="each">
//         <span style={{ marginLeft: "10px" }}>Admin</span>
//       </div>
//       <div className="each">
//         <span style={{ marginLeft: "10px" }}>Editor</span>
//       </div>
//       <div className="each">
//         <span style={{ marginLeft: "10px" }}>User</span>
//       </div>
//       <div className="each">
//         <span style={{ marginLeft: "10px" }}>Active</span>
//       </div>
//     </div>
//   );

//   const filterData = (e) => {
//     setsearch(e.target.value.toLowerCase());
//     const filteredData = accountData.filter((names) =>
//       names.first_name.toLowerCase().includes(e.target.value.toLowerCase())
//     );
//     updateDataView(filteredData);
//     //console.log(e.target.value.toLowerCase())
//   };

//   // const SearchBar = ()=>{
//   //     return(
//   //         <div className='search' >
//   //             <div className='search-input'>
//   //                 <input
//   //                 type="text"
//   //                 value={search}
//   //                 name="search"
//   //                 // autoFocus
//   //                 placeholder="search.."  onChange={filterData}/>
//   //             </div>
//   //         </div>
//   //     )
//   // }

//   const [paginateActivePage, setPaginateActivePage] = useState(1);
//   const n = 50;
//   const [itemLength, setitemLength] = useState(0);
//   const [perpage, setperpage] = React.useState(50);
//   const [dataToShow, setdataToShow] = useState([]);
//   const [accountData, setaccountData] = useState([]);
//   const [dataToShowduplicate, setdataToShowduplicate] = useState([]);

//   const handleOnSearch = (string, results) => {
//     // onSearch will have as the first callback parameter
//     // the string searched and for the second the results.
//     // console.log(string, results, 'search resulttttttt')
//     // console.log([results[0]], 'on search results')
//     // setdataToShow(results)
//     // setdataToShowduplicate([results[0]])
//   };

//   const handleOnHover = (result) => {
//     // the item hovered
//     // console.log(result)
//   };

//   const handleOnSelect = (item) => {
//     // the item selected
//     // console.log([item], 'item going into ')
//     setaccountData([item]);
//   };

//   const handleOnFocus = () => {
//     console.log("Focused");
//   };

//   const formatResult = (item) => {
//     // console.log(item, 'itemmmmmm')
//     return (
//       <>
//         <span style={{ display: "block", textAlign: "left" }}>
//           Given name: {item?.given_name}
//         </span>
//         <span style={{ display: "block", textAlign: "left" }}>
//           Email: {item?.user_id}
//         </span>
//         <span style={{ display: "block", textAlign: "left" }}>
//           Phone number: {item?.phone_number}
//         </span>
//         {/* <span style={{ display: 'block', textAlign: 'left' }}>Item desc: {item?.item_desc}</span>
//             <span style={{ display: 'block', textAlign: 'left' }}>PickUp details: {item?.newpickup}</span>
//             <span style={{ display: 'block', textAlign: 'left' }}>Delivery details: {item?.newdestina}</span> */}
//       </>
//     );
//   };

//   const SearchBar = () => {
//     return (
//       // <div className='search' >
//       <div className="search-input">
//         <div className="searchandclear">
//           <div>
//             <ReactSearchAutocomplete
//               items={dataToShowduplicate}
//               onSearch={handleOnSearch}
//               onHover={handleOnHover}
//               onSelect={handleOnSelect}
//               onFocus={handleOnFocus}
//               // autoFocus
//               formatResult={formatResult}
//               fuseOptions={{ keys: ["given_name", "user_id", "phone_number"] }}
//               // fuseOptions={{ keys: ["delivery_details"] }}
//               // fuseOptions={{ keys: ["delivery_address"] }}
//               resultStringKeyName="given_name"
//             />
//             <div
//               onClick={() => setdataToShowduplicate(accountData)}
//               className="clearbtn"
//             >
//               {/* <button>
//                 Clear Search
//               </button> */}

//               <AiOutlineClear data-tip="Clear Search" />
//               <ReactTooltip type="light" />
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   useEffect(() => {
//     SearchBar();
//   }, [dataToShowduplicate, accountData, SearchBar]);

//   const handlevalue = (event) => {
//     //console.log(event.target.value);
//     setperpage(event.target.value);
//     setdataToShow([...accountData].slice(0, event.target.value));
//     //console.log(accountData.slice(0, event.target.value))
//   };

//   const updateDataView = (datum) => {
//     setdataToShow(datum.slice(0, perpage));
//     setitemLength([...datum].length);
//   };

//   useEffect(() => {
//     if (localStorage.getItem("o_kj_") !== null) {
//       const decoded = jwt_decode(localStorage.getItem("o_kj_"));
//       const expired = decoded.exp;
//       // console.log(decoded, 'decoded')

//       if (expired < Date.now() / 1000) {
//         setIsloading(false);
//         Swal.fire({
//           icon: "warning",
//           title: "Session!",
//           text: "Session expired login again!",
//           allowOutsideClick: false,
//           confirmButtonColor: "var(--main)",
//         }).then((result) => {
//           if (result.value) {
//             history.push(`/`);
//             setIsloading(false);
//             localStorage.clear();
//           }
//         });
//       } else if (users !== undefined) {
//         // console.log(users.data.all_users.filter(function (value) { return value.role === "datamellon-platform_read_only" }), 'datamellon-platform_read_only')
//         // console.log(users.data.all_users.filter(function (value) { return value.role === "datamellon-platform_sys_ops" }), 'datamellon-platform_sys_ops')
//         // console.log(users.data.all_users.filter(function (value) { return value.role === "platform_sys_ops" }), 'platform_sys_ops')
//         // console.log(users.data.all_users.filter(function (value) { return value.role === "read_only" }), 'platform_read_only')
//         // console.log(users.data.all_users.filter(function (value) { return value?.user_id === "brnmew@playfuny.com" }), 'email')
//         const data = users.data.all_users.filter(function (value) {
//           return (
//             value.role === "datamellon-platform-sys-admin" ||
//             value.role === "datamellon-platform-read-only" ||
//             value.role === "datamellon-platform-sys-ops"
//           );
//         });

//         console.log(data, 'dataaaa')

//         data?.sort(
//           (x, y) =>
//             new Date(y.account_status.create_time_stamp) -
//             new Date(x.account_status.create_time_stamp)
//         );
//         setaccountData(data);
//         updateDataView(data);
//         setdataToShowduplicate(data);
//       } else {
//         setIsloading(true);
//         Axios.post(
//           `${endPoint}m-platform-admin-dashboard`,
//           {
//             requestType: "get_users",
//           },
//           {
//             headers: {
//               Authorization: localStorage.getItem("o_kj_"),
//             },
//           }
//         )
//           .then((response) => {
//             // console.log(users, 'usersss')
//             // console.log(response, 'responseee')
//             // console.log(response.data.all_users.filter(function (value) {
//             //   return (
//             //     value.role === "datamellon-platform-sys-admin" ||
//             //     value.role === "datamellon-platform-read-only" ||
//             //     value.role === "datamellon-platform-sys-ops"
//             //   )
//             // }), 'filteringgggg data')
//             setUsers(response);
//             setIsloading(false);
//             const data = response.data.all_users.filter(function (value) {
//               return (
//                 value.role === "datamellon-platform-sys-admin" ||
//                 value.role === "datamellon-platform-read-only" ||
//                 value.role === "datamellon-platform-sys-ops"
//               );
//             });
//             console.log(data, 'dataaaa')
//             data?.sort(
//               (x, y) =>
//                 new Date(y.account_status.create_time_stamp) -
//                 new Date(x.account_status.create_time_stamp)
//             );
//             setaccountData(data);
//             updateDataView(data);
//             setdataToShowduplicate(data);

//             // console.log(response.data.all_users.filter(function (value) { return value.role === "datamellon-platform_read_only" }), 'datamellon-platform_read_only')
//             // console.log(response.data.all_users.filter(function (value) { return value.role === "datamellon-platform_sys_ops" }), 'datamellon-platform_sys_ops')
//             // console.log(response.data.all_users.filter(function (value) { return value.role === "platform_sys_ops" }), 'platform_sys_ops')
//             // console.log(response.data.all_users.filter(function (value) { return value.role === "read_only" }), 'platform_read_only')
//             // console.log(response.data.all_users.filter(function (value) { return value?.user_id === "brnmew@playfuny.com" }), 'email')
//           })
//           .catch((err) => {
//             if (err?.response?.status === 403) {
//               setIsloading(false);
//               Swal.fire({
//                 icon: "error",
//                 title: "Session Timeout",
//                 showCancelButton: false,
//               });
//               history.push(`/`);
//             } else {
//               setIsloading(false);
//               Swal.fire({
//                 icon: "error",
//                 title: "error",
//                 allowOutsideClick: false,
//                 confirmButtonColor: "var(--main)",
//                 showCancelButton: false,
//               }).then((result) => {
//                 if (result.value) {
//                   history.push(`/`);
//                 }
//               });
//             }
//           });
//       }
//     } else {
//       history.push("/");
//       localStorage.clear();
//     }
//   }, []);

//   const columns = [
//     {
//       field: "account_name",
//       headerName: "Profile Name",
//       minWidth: 100,
//       flex: 1,
//       renderCell: ({ row }) => row?.first_name + " " + row?.last_name,
//     },
//     {
//       field: "user_id",
//       headerName: "Email",
//       minWidth: 100,
//       flex: 1,
//     },
//     {
//       field: "role",
//       headerName: "Role",
//       renderCell: ({ value }) => {
//         // console.log("log",value)
//         switch (value) {
//           case "datamellon-platform-sys-admin":
//             // console.log("jujjojoj")
//             return "admin";

//           case "datamellon-platform-read-only":
//             return "read only";

//           case "datamellon-platform-sys-ops":
//             return "platform sys ops";

//           default:
//             return "";
//         }
//       },
//       minWidth: 100,
//       flex: 1,
//     },
//     // {
//     //   field: "phone_number",
//     //   headerName: "Phone Number",
//     //   minWidth: 100,
//     //   flex: 1,
//     //   renderCell: ({ value }) => value.phone_number,
//     // },
//     {
//       field: "account_status",
//       headerName: "Status",
//       minWidth: 170,
//       flex: 1,
//       renderCell: ({ row }) =>
//         row.account_status.state === "active"
//           ? "Active"
//           : row.account_status.state === "deleted"
//             ? "Deleted"
//             : row.account_status.state === "FORCE_CHANGE_PASSWORD"
//               ? "Force change password"
//               : null,
//     },
//     {
//       field: "actions",
//       type: "actions",
//       width: 80,
//       getActions: (params) => [
//         <GridActionsCellItem
//           label={params.row.account_status.state !== "deleted" ? "Remove" : "Remove permanently"}
//           onClick={() => {
//             if (params.row.account_status.state !== "deleted") {
//               setdeleteUserPop(!deleteUserPop);
//               settempemail(params.row.user_id);
//             } else {
//               setremoveUserPop(!removeUserPop);
//               settempemail(params.row.user_id);

//             }

//           }}
//           showInMenu
//         />,
//       ],
//     },
//   ];
//   const [pageSize, setPageSize] = useState(10);
//   const CustomToolbar = () => {
//     return (
//       <>

//       </>
//       // <GridToolbarContainer
//       //   sx={{ display: "flex", justifyContent: "space-between" }}
//       // >
//       //   <h4>Admin Users</h4>
//       //   {/* <Button variant="outlined" size="large" onClick={() => setshow(!show)}>Add filter <KeyboardArrowDownIcon/> </Button>
//       //   {show ? <Drop_down /> : null} */}
//       //   {/* <GridToolbarExport /> */}

//       //   <SearchBar />
//       // </GridToolbarContainer>
//     );
//   };
//   const handleGetRowId = (e) => {
//     return e.user_id;
//   };
//   return (
//     <div className="main-grand">
//       {isloading ? (
//         <Box sx={{ width: "100%", marginBottom: "5px" }}>
//           <LinearProgress color="success" />
//         </Box>
//       ) : null}
//       {/* <div style={{ width: "90%" }}>

//         <DataGrid
//           density="comfortable"
//           sx={{
//             p: "30px",
//             background: "#fff",
//             "& .MuiDataGrid-cell": {
//               whiteSpace: "break-spaces !important",
//             },
//           }}
//           rows={
//             accountData !== undefined && accountData?.length !== 0
//               ? accountData
//               : []
//           }
//           columns={columns}
//           pageSize={pageSize}
//           autoHeight={true}
//           onPageSizeChange={(value) => setPageSize(value)}
//           rowsPerPageOptions={[5, 10, 20]}
//           getRowId={handleGetRowId}
//           components={{
//             Toolbar: CustomToolbar,
//           }}
//         />
//       </div> */}
//       <Breadcrumbs/>
//        <div className="table_container">
//         <div className="table-filter-info">
//           <div
//             style={{
//               position: "relative",
//             }}
//           ></div>

//         </div>
//         <table id="thead-data">
//           {
//             <tr>
//               {Tabledetails?.map((item, index) => (
//                 <>
//                   <th>{item}</th>
//                 </>
//               ))}
//             </tr>
//           }

//           <tbody>
//             {accountData?.map((x, i) => (
//               <tr key={i} className="table__row">
//                 <>
//                   <td>
//                     {[
//                       [
//                         x?.first_name +
//                           "  " +
//                           x?.last_name,
//                       ],
//                     ].join("")}
//                   </td>
//                   <td>{x?.user_id}</td>
//                   <td>{x?.phone_number}</td>
//                   <td>{x?.account_status.state}</td>
//                 </>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       {jwt_decode(localStorage.getItem("o_kj_"))["custom:role"] ===
//         "datamellon-platform-sys-admin" ||
//         jwt_decode(localStorage.getItem("o_kj_"))["custom:role"] ===
//         "platform-sys-admin" ? (
//         <Box
//           onClick={() => setshowAddForm(!showAddForm)}
//           sx={{
//             transform: "translateZ(0px)",
//             flexGrow: 1,
//             position: "absolute",
//             bottom: "30px",
//             right: "3%",
//           }}
//         >
//           <SpeedDial
//             ariaLabel="SpeedDial basic example"
//             icon={<AddIcon sx={{ color: "#fff" }} />}
//           >
//             {/* {actions.map((action) => (
//           <SpeedDialAction
//             key={action.name}
//             icon={action.icon}
//             tooltipTitle={action.name}
//           />
//         ))} */}
//           </SpeedDial>
//         </Box>
//       ) : null}

//       <DialogPop
//         openPopup={showAddForm}
//         setopenPopup={setshowAddForm}
//         head="Create Admin "
//       >
//         <form className="cFrorm" onSubmit={submitForm}>
//           <FormControl variant="outlined">
//             <InputLabel htmlFor="Firstname">
//               <BiRename style={{ marginRight: "5px", color: "var(--main)" }} />
//               FirstName
//             </InputLabel>
//             <InputField
//               required
//               // autoFocus
//               name="firstname"
//               id="firstname"
//               type="text"
//               // error={errors.first_name}
//               value={firstname}
//               labelWidth={90}
//               onChange={(e) => {
//                 setfirstname(e.target.value);
//               }}
//               icon={
//                 <HiOutlineMail
//                   style={{ marginRight: "5px", color: "var(--main)" }}
//                 />
//               }
//             />
//           </FormControl>

//           <FormControl className="fInput" variant="outlined">
//             <InputLabel htmlFor="password">
//               <BiRename style={{ marginRight: "5px", color: "var(--main)" }} />
//               LastName
//             </InputLabel>
//             <InputField
//               // error={modelisValid}
//               required
//               name="lastname"
//               id="lastname"
//               type="text"
//               value={lastname}
//               labelWidth={90}
//               onChange={(e) => {
//                 setlastname(e.target.value);
//               }}
//               icon={
//                 <RiLockPasswordLine
//                   style={{ marginRight: "5px", color: "var(--main)" }}
//                 />
//               }
//             />
//           </FormControl>

//           {/* sx={{ m: 1, minWidth: 120 }} */}
//           {/*
//           <FormControl className='fInput' >
//             <InputLabel htmlFor="password"><BiRename style={{ marginRight: "5px", color: "var(--main)" }} />Role</InputLabel>
//             <Select
//               value={role}
//               onChange={handleRole}
//               displayEmpty
//               inputProps={{ "aria-label": "Without label" }}
//               color="success"
//             >
//               <MenuItem value="Monthly">Admin</MenuItem>
//               <MenuItem value={"Daily"}>System admin</MenuItem>
//               <MenuItem value={"Weekly"}>Platform admin</MenuItem>



//             </Select>
//           </FormControl> */}
//           <FormControl fullWidth variant="outlined">
//             <InputLabel id="demo-simple-select-label">Role</InputLabel>
//             <Select
//               labelId="demo-simple-select-label"
//               id="demo-simple-select"
//               label="Role"
//               value={role}
//               onChange={handleRole}
//             >
//               <MenuItem value="platform_sys_admin">System Admin</MenuItem>
//               <MenuItem value={"platform_sys_ops"}>System Operation</MenuItem>
//               <MenuItem value={"platform_read_only"}>Read only</MenuItem>
//             </Select>
//           </FormControl>

//           <FormControl className="fInput" variant="outlined">
//             <InputLabel htmlFor="email">
//               <HiOutlineMail
//                 style={{ marginRight: "5px", color: "var(--main)" }}
//               />
//               Email
//             </InputLabel>
//             <InputField
//               // error={modelisValid}
//               required
//               name="email"
//               id="email"
//               type="email"
//               // error={errors.email}
//               // type={showpassword ? 'text' : 'password'}

//               value={email}
//               labelWidth={90}
//               onChange={(e) => {
//                 setemail(e.target.value);
//               }}
//               icon={
//                 <RiLockPasswordLine
//                   style={{ marginRight: "5px", color: "var(--main)" }}
//                 />
//               }
//             />
//           </FormControl>

//           <div>
//             <div className="phoneTopic">Phone Number</div>
//             <PhoneNumber value={phone_num} setValue={setphone_num} />
//           </div>
//           {phone_num === "" ? (
//             <Button
//               size="large"
//               variant="contained"
//               type="submit"
//               disabled
//               style={{
//                 backgroundColor: "rgb(151, 199, 168)",
//                 width: "100%",
//                 marginTop: "20px",
//                 color: "white",
//                 borderRadius: "10px",
//                 padding: "8px",
//                 textTransform: "none",
//               }}
//             >
//               <span className="btnlabel">
//                 Add <AiOutlineUserAdd />
//               </span>
//             </Button>
//           ) : (
//             <Button
//               size="large"
//               variant="contained"
//               type="submit"
//               style={{
//                 backgroundColor: "var(--main)",
//                 width: "100%",
//                 marginTop: "20px",
//                 color: "white",
//                 borderRadius: "10px",
//                 padding: "8px",
//                 textTransform: "none",
//               }}
//             >
//               {addingUser ? (
//                 <PuffLoader
//                   color="white"
//                   loading={addingUser}
//                   speedMultiplier={1}
//                   size={24}
//                 />
//               ) : (
//                 <span className="btnlabel">
//                   Add <AiOutlineUserAdd />
//                 </span>
//               )}
//             </Button>
//           )}
//         </form>
//       </DialogPop>

//       <DialogPop
//         openPopup={deleteUserPop}
//         setopenPopup={setdeleteUserPop}
//         head="Delete Admin"
//       >
//         <form onSubmit={handleDeleteUser}>
//           <TextField
//             required
//             fullWidth
//             name="email"
//             id="email"
//             type="email"
//             label="User Email"
//             value={tempemail}
//             disabled
//             // onChange={(e)=> {setemail(e.target.value)}}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <RiLockPasswordLine
//                     style={{ marginRight: "5px", color: "var(--main)" }}
//                   />
//                 </InputAdornment>
//               ),
//             }}
//           />
//           <br />
//           <br />
//           <div className="deletBtn">
//             {addingUser ? (
//               <Button
//                 size="large"
//                 variant="contained"
//                 type="submit"
//                 className="btnnn2"
//               >
//                 <PuffLoader
//                   color="#50b14a"
//                   loading={addingUser}
//                   speedMultiplier={1}
//                   size={24}
//                 />
//               </Button>
//             ) : (
//               <Button
//                 size="large"
//                 variant="contained"
//                 color="primary"
//                 type="submit"
//               >
//                 <span className="btnlabel">Delete </span>
//               </Button>
//             )}
//           </div>
//         </form>

//         {/* </div>
//             </div> */}
//       </DialogPop>



//       <DialogPop
//         openPopup={removeUserPop}
//         setopenPopup={setremoveUserPop}
//         head="Remove Permanently"
//       >
//         <form onSubmit={handleRemovepermanently}>
//           <TextField
//             required
//             fullWidth
//             name="email"
//             id="email"
//             type="email"
//             label="User Email"
//             value={tempemail}
//             disabled
//             // onChange={(e)=> {setemail(e.target.value)}}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <RiLockPasswordLine
//                     style={{ marginRight: "5px", color: "var(--main)" }}
//                   />
//                 </InputAdornment>
//               ),
//             }}
//           />
//           <br />
//           <br />
//           <div className="deletBtn">
//             {removingUser ? (
//               <Button
//                 size="large"
//                 variant="contained"
//                 type="submit"
//                 className="btnnn2"
//               >
//                 <PuffLoader
//                   color="#50b14a"
//                   loading={removingUser}
//                   speedMultiplier={1}
//                   size={24}
//                 />
//               </Button>
//             ) : (
//               <Button
//                 size="large"
//                 variant="contained"
//                 color="primary"
//                 type="submit"
//               >
//                 <span className="btnlabel">Remove Permanently </span>
//               </Button>
//             )}
//           </div>
//         </form>

//         {/* </div>
//             </div> */}
//       </DialogPop>


















//       <ReactTooltip
//         id="mode"
//         aria-haspopup="true"
//         type="light"
//         role="example"
//         place="right"
//       >
//         <p>Create user</p>
//         {/* <p>Mode of movement</p> */}
//       </ReactTooltip>
//     </div>
//   );
// }
