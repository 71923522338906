import { createContext, useEffect, useState } from 'react'
import Axios from 'axios'

export const ConnContext = createContext();

export const ConnProvider = ({ children }) => {

  useEffect(() => {
    Axios.get('https://geolocation-db.com/json/f9902210-97f0-11eb-a459-b997d30983f1')
      .then((response) => {
        console.log(response, 'responseeee-location')
        setvisitingCountryCode(response.data.country_code);
        //  console.log(response.data.country_code, 'country-code')
      })
  }, [])

  const [visitingCountryCode, setvisitingCountryCode] = useState('')

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      fetch('//google.com', {
        mode: 'no-cors',
      })
        .then(() => {
        }).catch(() => { return false })

      return true;
    }
    return false;
  }

  return (
    <ConnContext.Provider value={{
      handleConnectionChange,
      visitingCountryCode
    }}>
      {children}
    </ConnContext.Provider>
  )
}



