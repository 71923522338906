import { GOOGLE_MAP_URL } from './contant'

import MapContainer from './MapContainer'

const GoogleMapWidget = (props) => (
<MapContainer
      googleMapURL={GOOGLE_MAP_URL}
      loadingElement={<div style={{height:"100%"}}  />}
      containerElement={<div style={{height:"100%"}}  />}
      mapElement={<div style={{height:"100%"}}  />}
      {...props}
    /> 
)
export default GoogleMapWidget
