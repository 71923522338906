import React, { useState, useEffect } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import './PhoneNumber.css'
import Axios from 'axios'

function PhoneNumber({ value, setValue, disabled }) {
    const [conuntryCode, setconuntryCode] = useState('');
    useEffect(() => {
        // Axios.get('https://geolocation-db.com/json/f9902210-97f0-11eb-a459-b997d30983f1')
        //     .then((response) => {
        //         setconuntryCode(response.data.country_code);
        //         console.log(response, 'geolocation---')
        //     })
                setconuntryCode('NG');

    }, [])
    return (
        <div>
            <PhoneInput
                international
                defaultCountry={conuntryCode}
                value={value}
                onChange={setValue}
                style={{ marginBottom: '0px' }}
                disabled={disabled}
                required
            />
            {/* <div className="err">{value && isValidPhoneNumber(value) ? '' : 'Invalid phone number combination'}</div> */}
        </div>
    )
}

export default PhoneNumber
