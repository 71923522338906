import React, { useState } from 'react'
import "./Message.css"
import boy from "../../assets/boy.jpg"
import { useHistory} from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Avatar, Box, Button, FormControl, Grid, InputLabel, SpeedDial, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DialogPop from '../AdminUsers/DialogPop';
import {BiRename} from 'react-icons/bi';
import {HiOutlineMail} from 'react-icons/hi'
import {AiOutlineUserAdd} from 'react-icons/ai'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Axios from "axios";
import PuffLoader from 'react-spinners/PuffLoader';

import InputField from '../../InputField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MessageView from './MessageView';
import Breadcrumbs from '../../components/Breadcrumbs';

export default function Message(){
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  
  const Tabledetails = [
    "Image",
    "Name",
    "Description",
    "Date",
  ];

  
  const data =[
        {
            id:"1",
            image:boy,
            name:"John Doe",
            Description:"I can’t make the delivery anymore",
            Date:"December 2nd, 2021. 9:00 AM"
        },
        {
            id:"2",
            image:boy,
            name:"Samuel Jackson",
            Description:"I can’t make the delivery today",
            Date:"December 2nd, 2022. 9:00 AM"
        },
        {
          id:"3",
          image:boy,
          name:"John Emmanuel",
          Description:"I can’t make the delivery today",
          Date:"December 2nd, 2022. 9:00 AM"
      }
    ]
    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        {
          field: "image",
          headerName: "Image",
          flex:1,
          renderCell: (params) => <Avatar alt={params.row.item_desc} src={`${params.row.image}`} />,
        },
        { field: 'name', headerName: 'Name', flex:1, },
        { field: 'Description', headerName: 'Description', flex:1, },
        { field: 'Date', headerName: 'Date', flex:1, },
    
    ]

    const history = useHistory()
    const [pageSize,setPageSize]=useState(10)
    const [showAddForm, setshowAddForm] = useState(false)
    const [providerName, setProviderName] = useState("")
    const [addingUser, setaddingUser] = useState(false)
    const [chatscreen,setChatscreen]=useState([]);
    const endPoint =process.env.REACT_APP_API

    const submitForm = async (e)=>{
      e.preventDefault()
      setaddingUser(true)
    
      const authAxios = Axios.create({
          baseURL: `${endPoint}`,
          headers: {
              Authorization: localStorage.getItem('o_kj_')
          }
      })
      // await authAxios.post(`${endPoint}m-dillivry-user-manager`,{
      //     action_type : 'create',
      //     email: email.toLowerCase(),
      //     phone_number: phone_num,
      //     first_name: firstname,
      //     last_name: lastname,
      //     role: "platform_sys_admin",
      //     vcc: visitingCountryCode
     
      // })
      // .then((response)=>{
      //     if(response){
      //         if(response.status === 200){
      //             setaddingUser(false)
      //             setemail('')
      //             setphone_num('')
      //             setfirstname('')
      //             setlastname('')
      //             setuserType('')
      //             setshowAddForm(false)

      //             Swal.fire({
      //                 icon: 'success',
      //                 title: 'Account Created',
      //                 showConfirmButton: 'false',
      //                 confirmButtonColor:'var(--main)',
      //                 timer: 6000
      //             })
      //         }     
      //     } 
      // })
      // .catch((error)=>{
      //     setaddingUser(false)
      //     setshowAddForm(false)
      //     setemail('')
      //     setphone_num('')
      //     setfirstname('')
      //     setlastname('')
      //     setuserType('')
          
      //     if (error) {
      //         Swal.fire({
      //           icon: "error",
      //           title: "error",
      //           confirmButtonColor: "red",
      //         });
          
      //     }
      // })
      

  }
    const handleGetRowId = (e) => {
        return e.id
      }
    return (
      <Grid container spacing={2}>
      
      {chatscreen.length!==0 && matches?null:<Grid item sm={12} md={chatscreen.length===0?12:6}>
        {/* <div style={{ height: "80vh", width: "100%" }}>
          <DataGrid
            density="comfortable"
            sx={{
              p: "30px",
              background: "#fff",
              "& .MuiDataGrid-cell": {
                whiteSpace: "break-spaces !important",
              },
              // borderColor: 'primary.light',
            }}
            rows={data}
            columns={columns}
            pageSize={pageSize}
            autoHeight={true}
            onCellClick={(params, event, details) => {

              console.log("data",params)
              setChatscreen(params.row)
              // history.push({
              //   pathname: `/messages/view`,
              //   //   state: paginateActivePage,
              // });
            }}
            onPageSizeChange={(value) => setPageSize(value)}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={handleGetRowId}
            //   components={{
            //     Toolbar: CustomToolbar,
            //   }}
            // checkboxSelection
          />
        </div> */}
        <Breadcrumbs/>
        <div className="table_container">
        <div className="table-filter-info">
          <div
            style={{
              position: "relative",
            }}
          ></div>
          
        </div>
        <table id="thead-data">
          {
            <tr>
              {Tabledetails?.map((item, index) => (
                <>
                  <th>{item}</th>
                </>
              ))}
            </tr>
          }

          <tbody>
            {data?.map((x, i) => (
              <tr key={i} className="table__row">
                <>
                  <td className='table--img'>
                    <img src={x?.image} alt={x?.name} className='image--table '/>
                  </td>
                  <td>{x?.name}</td>
                  <td>{x?.Description}</td>
                  <td>{x?.Date}</td>
                </>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        <Box
          onClick={() => setshowAddForm(!showAddForm)}
          sx={{
            transform: "translateZ(0px)",
            flexGrow: 1,
            position: "absolute",
            bottom: "30px",
            right: "3%",
          }}
        >
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            icon={<AddIcon sx={{ color: "#fff" }} />}
          >
            {/* {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))} */}
          </SpeedDial>
        </Box>
        <DialogPop
          openPopup={showAddForm}
          setopenPopup={setshowAddForm}
          head="Create Admin"
        >
          <form className="cFrorm" onSubmit={submitForm}>
            <FormControl variant="outlined">
              <TextField
              label="Enter provider name"
              required
              name="providerName"
                id="providerName"
                type="text"
              value={providerName}
                labelWidth={90}
                onChange={(e) => {
                  setProviderName(e.target.value);
                }}
                />
            </FormControl>
            <Button
              size="large"
              variant="contained"
              type="submit"
              style={{
                backgroundColor: "var(--main)",
                width: "100%",
                marginTop: "20px",
                color: "white",
                borderRadius: "10px",
                padding: "8px",
                textTransform: "none",
              }}
            >
              {addingUser ? (
                <PuffLoader
                  color="white"
                  loading={addingUser}
                  speedMultiplier={1}
                  size={24}
                />
              ) : (
                <span className="btnlabel">
                  Chat <AiOutlineUserAdd />
                </span>
              )}
            </Button>
          </form>
        </DialogPop>
      </Grid>}
      {chatscreen.length===0?null:<Grid item sm={12} md={6} >
        {matches?<span onClick={()=>{setChatscreen([])}}><ArrowBackIcon/></span>:null}
      <MessageView/>
      </Grid>}
      </Grid>
    );
}