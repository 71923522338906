import React, { useState } from "react";
import "./Dashboard.css";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";
import Dropdowncountries from "./DropdownCountries";
import TableComponent from "./TableComponent";
import Breadcrumbs from "./Breadcrumbs";

export default function Dashboard({ children }) {
  const [submitting, setsubmitting] = useState(false);
  const TableBody = () => <TableComponent />;

  return (
    <div>
      {submitting === true ? (
        <div>
          <Box sx={{ width: "100%", marginTop: "2px" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : (
        <main style={{ padding: "45px 30px", backgroundColor: "whitesmoke" }}>
          <Breadcrumbs/>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="opentitle">
                  <h2 style={{ marginBottom: "20px" }}>Open deliveries</h2>
                </div>

                <div className="countryflag">
                  <Dropdowncountries />
                </div>
              </div>
              <TableBody />
           
            </Grid>
          </Grid>
        
        </main>
      )}
    </div>
  );
}
