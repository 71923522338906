/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import Axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
// import image from "../../../src/assets/images/new logo.001.png";
// import imagee from "../../../src/assets/images/login-diliviry.png";
import PuffLoader from "react-spinners/PuffLoader";
import { FormControl, Button, Grid } from "@material-ui/core";

import { HiOutlineMail } from "react-icons/hi";
import InputField from "../../InputField";
import loginLogo from "../../assets/loginLogo.svg";
import sideImage from "../../assets/resetImage.png";
import { ConnContext } from "../../context/ConnContext";

export default function ForgotPassword() {
  const history = useHistory();
  const location = useLocation();
  const endPoint = process.env.REACT_APP_API;
  const { visitingCountryCode } = useContext(ConnContext);

  useEffect(() => {
    setusername(location.state);
  }, []);

  const [username, setusername] = useState("");
  const [passcode, setpasscode] = useState("");
  const [setcpassword] = useState("");
  const [password, setpassword] = useState("");
  const [submitting, setsubmitting] = useState(false);

  const handleReset = (e) => {
    e.preventDefault();
    setsubmitting(true);
    var encodedPassword = btoa(password);

    const authAxios = Axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: localStorage.getItem("o_kj_"),
      },
    });

    authAxios
      .post(`${endPoint}auth-v3/resetforgetpass/ng`, {
        "auth_type": "resetforgetpass",
        username: username,
        password: encodedPassword,
        passcode: passcode,
      })
      .then((response) => {
        setsubmitting(false);
        if (response.status === 200) {
          localStorage.setItem(
            "eid",
            // btoa(btoa(response.data.response_body.request_body.username))
            btoa(btoa(response.data))
          );

          Swal.fire({
            icon: "success",
            text: "Your password has been reset successfully, you can now access your account",

            confirmButtonColor: "#50B14A",
          }).then((result) => {
            // history.push({
            //   pathname: "/RestPassword",
            //   state: username,
            // });
            history.push("/");
            // history.push("/setnewpassword");
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setsubmitting(false);
      });
  };
  return (
    <>
      <Grid container className="LoginContainer">
        <Grid item xs={12} className="LogoContainer">
          <Link to="/">
            <div>
              <img src={loginLogo} alt="logo" />
            </div>{" "}
          </Link>
        </Grid>
        <Grid container className="Form-Image">
          <Grid
            item
            xs={6}
            className="LoginForm"
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            <form className="FormInput-Button" onSubmit={handleReset}>
              {/* ***Input fields*** */}
              <div className="formCont">
                <div>Email</div>
                <FormControl className="formInput" variant="outlined">
                  {/* <InputLabel htmlFor="username"><HiOutlineMail style={{ marginRight:"5px", color:"var(--main)"}}/>Email</InputLabel> */}
                  <InputField
                    required
                    name="username"
                    id="username"
                    type="email"
                    value={username}
                    // labelWidth={60}
                    onChange={(e) => {
                      setusername(e.target.value);
                    }}
                    icon={
                      <HiOutlineMail
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <div>Password</div>
                <FormControl className="formInput" variant="outlined">
                  {/* <InputLabel htmlFor="username"><HiOutlineMail style={{ marginRight:"5px", color:"var(--main)"}}/>Email</InputLabel> */}
                  <InputField
                    required
                    name="password"
                    id="password"
                    type="password"
                    // labelWidth={60}
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    icon={
                      <HiOutlineMail
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <div>Confirm Password</div>
                <FormControl className="formInput" variant="outlined">
                  {/* <InputLabel htmlFor="username"><HiOutlineMail style={{ marginRight:"5px", color:"var(--main)"}}/>Email</InputLabel> */}
                  <InputField
                    required
                    name=" confirm password"
                    id=" confirm password"
                    type="password"
                    // labelWidth={60}
                    onChange={(e) => {
                      // setcpassword(e.target.value);
                      setpassword(e.target.value);
                    }}
                    icon={
                      <HiOutlineMail
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                <div>Passcode</div>
                <FormControl className="formInput" variant="outlined">
                  {/* <InputLabel htmlFor="username"><HiOutlineMail style={{ marginRight:"5px", color:"var(--main)"}}/>Email</InputLabel> */}
                  <InputField
                    required
                    name="passcode"
                    id="passcode"
                    type="passcode"
                    // labelWidth={60}
                    onChange={(e) => {
                      setpasscode(e.target.value);
                    }}
                    icon={
                      <HiOutlineMail
                        style={{ marginRight: "5px", color: "var(--main)" }}
                      />
                    }
                  />
                </FormControl>

                {/* ***Login Button*** */}

                <Grid className="lgBtnn">
                  {submitting ? (
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      disabled
                      style={{
                        backgroundColor: "var(--main)",
                        width: "100%",
                        color: "white",
                        borderRadius: "10px",
                        padding: "10px",
                        height: "40px",
                        textTransform: "none",
                      }}
                    >
                      <span className="btnlabe">
                        <PuffLoader
                          color="white"
                          loading={submitting}
                          speedMultiplier={1}
                          size={24}
                        />
                      </span>
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      style={{
                        backgroundColor: "var(--main)",
                        width: "100%",
                        color: "white",
                        borderRadius: "5px",
                        padding: "10px",
                        height: "40px",
                        textTransform: "none",
                      }}
                    >
                      <span className="btnlabe">Reset </span>
                    </Button>
                  )}
                </Grid>
              </div>
            </form>
          </Grid>
          <Grid
            justifyContent="flex-start"
            alignItems="center"
            className="LoginImage"
            container
            item
            xs={6}
          >
            {/* ***Side Image*** */}
            <div>
              <img alt="sideImage" style={{ width: "100%" }} src={sideImage} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
