import { createContext, useEffect, useState } from 'react'

export const DeliveryContext = createContext();

export const DeliveriesProvider = ({ children }) => {

  const [allDeliveries, setallDeliveries] = useState([])
  const [quotedDeliveries, setquotedDeliveries] = useState([])


  const [deliveriesnew, setdeliveries] = useState(undefined)

  const [individualdata, setindividualdata] = useState(undefined)

  const [openddata, setopenddata] = useState([])

  const updatedeliveries = (data) => {
    setdeliveries(data)
  }

  const UpdateNewData = (data) => {
    setindividualdata(data)
    //  console.log(individualdata, "data-from-new-data")
  }

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input?.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }



  const joiner = (data) => {
    const newarrrr = data.split("-");
    const merge = newarrrr[0].concat(newarrrr[1], newarrrr[2]);
    const newarrr = merge.split(" ");
    const merged = newarrr[0].concat(newarrr[1]);
    const newarrrf = merged.split(":");
    const mergedd = newarrrf[0].concat(newarrrf[1], newarrrf[2]);
    const newarrrff = mergedd.split("+");
    const mergeddd = newarrrff[0].concat(newarrrff[1]);
    const newarrrfff = mergeddd.split(".");
    const mergedddd = newarrrfff[0].concat(newarrrfff[1]);
    const newarrrffff = mergedddd.split("T");
    const mergeddddd = newarrrffff[0].concat(newarrrffff[1]);
    return mergeddddd;
  };


  const UpdateOpendData = (data) => {
    // console.log(data, 'data')
    setopenddata(data)

  }



  return (
    <DeliveryContext.Provider value={{
      allDeliveries,
      setallDeliveries,
      quotedDeliveries,
      setquotedDeliveries,


      updatedeliveries,
      deliveriesnew,
      UpdateNewData,
      individualdata,
      UpdateOpendData,
      openddata,
    }}>
      {children}
    </DeliveryContext.Provider>
  )
}