import { createContext, useState } from 'react'

export const UserContext = createContext();

export const UserProvider = ({ children }) => {



    const [users, setUsers] = useState(undefined)

    const [pendingdata, setPendingData] = useState()

    const [agentsData, setAgentsData] = useState(undefined)
    const [last_evaluated_keyagt, setlast_evaluated_keyagt] = useState()
    const [last_evaluated_keysnd, setlast_evaluated_keysnd] = useState()
    const [last_evaluated_keyadm, setlast_evaluated_keyadm] = useState()
    const [filteredAgents, setFilteredAgents] = useState([undefined])
    const [agent_filter_option, setAgent_filter_option] = useState({
        country: [],
        mode: [],
        status: []
    })

    const [sendersData, setSendersData] = useState(undefined)
    const [filteredSender, setFilteredSender] = useState([undefined])
    const [sender_filter_option, setSender_filter_option] = useState({
        country: [],
        mode: [],
        status: []
    })


    const valPhoto = (user_id) => {
        const photo_item = []
        users?.data?.data[0]?.filter(
            (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
                if (element.user_id === user_id) {
                    element.profile_photo.state = "verified"
                    photo_item.push(element)
                }
                else {
                    photo_item.push(element)
                }
            })
        // users.data.all_users = photo_item
        users.data.data.data[0].data = photo_item
        // users.data?.data[0]?.data = photo_item
    }

    const rejPhoto = (user_id) => {
        const photo_item = []
        users?.data?.data[0]?.data.filter(
            (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
                if (element.user_id === user_id) {
                    element.profile_photo.state = "not_valid"
                    photo_item.push(element)
                }
                else {
                    photo_item.push(element)
                }
            })
            users.data.data.data[0].data = photo_item
        }


    const valFormId = (user_id) => {
        const photo_item = []
        users?.data?.data[0]?.data.filter(
            (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
                if (element.user_id === user_id) {
                    element.proof_of_identity.state = "verified"
                    photo_item.push(element)
                }
                else {
                    photo_item.push(element)
                }
            })
            users.data.data.data[0].data = photo_item
        }

    const rejFormId = (user_id) => {
        const photo_item = []
        users?.data?.data[0]?.data.filter(
            (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
                if (element.user_id === user_id) {
                    element.proof_of_identity.state = "not_valid"
                    photo_item.push(element)
                }
                else {
                    photo_item.push(element)
                }
            })
            users.data.data.data[0].data = photo_item
        }

    return (
        <UserContext.Provider
            value={{
                valPhoto,
                rejPhoto,
                valFormId,
                rejFormId,

                setAgent_filter_option,
                agent_filter_option,
                setFilteredAgents,
                filteredAgents,
                setAgentsData,
                agentsData,

                users,
                setUsers,

                sendersData,
                setSendersData,
                filteredSender,
                setFilteredSender,
                sender_filter_option,
                setSender_filter_option,
                pendingdata, setPendingData,
                last_evaluated_keyagt, setlast_evaluated_keyagt,
                last_evaluated_keysnd, setlast_evaluated_keysnd,
                last_evaluated_keyadm, setlast_evaluated_keyadm

            }}
        >
            {children}
        </UserContext.Provider>
    );
}