import dataType from './AgentType';

export const INITIAL_STATE =  []

const DataReducer = (state, action) => {
    switch(action.type) {

    


      case dataType.GET_DEFAULT_DATA:
        return {
            datas: 
            action.payload
        };

        case dataType.Val_Bank_Details:
            const bank_item = []

            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.bank_detail.state = "verified"
                  // console.log(element.bank_detail.state)
                  bank_item.push(element)
                }
                else{
                  bank_item.push(element)
                }
              })

              state.datas.data.all_users = bank_item

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };



            case dataType.Rej_Bank_Details:
              const bank_item2 = []
  
              state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                  if(element.user_id === action.payload){
                    // element.user_id = "new_email";
                    element.bank_detail.state = "not_valid"
                    // console.log(element.bank_detail.state)
                    bank_item2.push(element)
                  }
                  else{
                    bank_item2.push(element)
                  }
                })
  
                state.datas.data.all_users = bank_item2
  
                //console.log( state, 'updateed data')
  
              return {
                  datas: state.datas
  
              };

            
            case dataType.Val_Profile_Photo:
            const photo_item = []
            //console.log( state.datas.data, 'cotext-data')
            // [ ...response?.data?.all_users?.filter(
            //     (x) => x?.role === "dillivry-corp-last-mile-ops")]

           state?.datas?.data?.all_users?.filter(
                (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.profile_photo.state = "verified"
                  // console.log(element.bank_detail.state)
                  photo_item.push(element)
                }
                else{
                  photo_item.push(element)
                }
              })

              state.datas.data.all_users =photo_item

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };


            case dataType.Rej_Profile_Photo:
              const photo_item2 = []
  
              state?.datas?.data?.all_users?.filter(
                (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
                  if(element.user_id === action.payload){
                    // element.user_id = "new_email";
                    element.profile_photo.state = "not_valid"
                    // console.log(element.bank_detail.state)
                    photo_item2.push(element)
                  }
                  else{
                    photo_item2.push(element)
                  }
                })
  
                state.datas.data.all_users =photo_item2
  
                //console.log( state, 'updateed data')
  
              return {
                  datas: state.datas
  
              };


            case dataType.Val_Insurance:
            const ins_item = []

            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.good_in_transit_insurance.state = "verified"
                  // console.log(element.bank_detail.state)
                  ins_item.push(element)
                }
                else{
                  ins_item.push(element)
                }
              })

              state.datas.data.all_users = ins_item

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };

            case dataType.Rej_Insurance:
            const ins_item2 = []

            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.good_in_transit_insurance.state = "not_valid"
                  // console.log(element.bank_detail.state)
                  ins_item2.push(element)
                }
                else{
                  ins_item2.push(element)
                }
              })

              state.datas.data.all_users = ins_item2

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };


            case dataType.Val_Form_Id:
            const id_item = []

            state?.datas?.data?.all_users?.filter(
                (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.proof_of_identity.state = "verified"
                  //console.log(element)
                  id_item.push(element)
                }
                else{
                  id_item.push(element)
                }
              })

              state.datas.data.all_users = id_item

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };


            case dataType.Rej_Form_Id:
            const id_item2 = []

            state?.datas?.data?.all_users?.filter(
                (x) => x?.role === "dillivry-corp-last-mile-ops").forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.proof_of_identity.state = "not_valid"
                  //console.log(element)
                  id_item2.push(element)
                }
                else{
                  id_item2.push(element)
                }
              })

              state.datas.data.all_users = id_item2

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };







            case dataType.General_Val:
            const general_item = []

            state.datas.data.fulfillers.account_pending_validation.forEach(function (element) {
                if(element.user_id === action.payload){
                  // element.user_id = "new_email";
                  element.account_status.state = "active"
                  // //console.log(element.bank_detail.state)
                  general_item.push(element)
                }
                else{
                  general_item.push(element)
                }
              })

              state.datas.data.all_users =general_item

              //console.log( state, 'updateed data')

            return {
                datas: state.datas

            };
      default:
        return state;
    }
  };

export default DataReducer;