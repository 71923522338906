import React from 'react';
import "./MessageView.css"
import copy from "../../assets/copy.png"
import { InputAdornment, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';


export default function MessageView(){
    const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
    return(
    <>
        <div className='right-wrapper'>
            <div class="message-right">
                <span><p> Thank you for trusting our platform </p> </span>
                <span className='message-timeStamp'><p>11:31 AM</p></span>
            </div>
        </div>
        <div className='left-wrapper'>
            <span class="imageView-cropper">
                <img src={copy} class="rounded" />
            </span>
            <div class="message-left">
                <span>
                    <strong>John Doe </strong>
                </span>
                <span>
                    <p>you're welcome </p>
                </span>
                <span className='message-timeStamp'><p>11:35 AM</p></span>
            </div>
        </div>

        <TextField
          id="outlined-multiline-flexible"
          hiddenLabel
          multiline
          fullWidth
          maxRows={2}
          value={value}
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end"><SendIcon/></InputAdornment>,
          }}
        />
    </>
    )
}